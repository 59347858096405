<template>
  <div>
    <archived-trashed-status
      :form="message"
      :processing="processing"
      :title="$route.meta.title"
    />

    <template v-if="errors && errors.message">
      <div v-if="errors.message != 'Network Error'">
        <div class="alert alert-danger">
          {{ errors.message }}
        </div>
      </div>
    </template>

    <template v-if="message.test && message.test == 1 && message.last_status">
      <div class="alert alert-info">This message was simulated and not sent to the intended recipient.</div>
    </template>

    <div class="row">
      <div class="col-sm-12 col-md-4">
          <h1>{{ message.direction }} {{ message.channel }}</h1>
          <p>
            <router-link :to="{name: 'dashboard.index'}">Dashboard</router-link> /
            <router-link :to="{name: 'messages.index'}">Messages</router-link> /
            Edit {{ $route.meta.title }}
          </p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-2">

        <template v-if="message.url">
            <portal-links :url="'o/'+message.url"></portal-links>
        </template>

      </div><!--Col-->


      <div class="col-sm-12 col-md-6 text-right">

        <div class="btn-group">

          <template v-if="message.uuid">
            <template v-if="message.sent != 1 && message.direction == 'outbound'">
              <button type="button" class="btn btn-outline-success" @click="sendMessage()" :disabled="processing">Send Now</button>
            </template>
          <button type="button" class="btn btn-secondary" @click="getPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
          <button type="button" class="btn btn-outline-secondary" data-target="#duplicateModal" data-toggle="modal" :disabled="processing">Duplicate</button>
          </template>

            <div class="btn-group">
              <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Tools
              </button>
              <div class="dropdown-menu">

                <a v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="refreshStatuses()"
                >Refresh Statuses</a>

                <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="trashRecord(message)"
                  :hidden="!showTrash"
                >Trash</a>
              </div>
            </div>
            
            <button type="button" class="btn btn-success" @click="postForm()" :disabled="processing">Save</button>
            
          </div>
      </div><!--Col-->
    </div><!--Row-->

    <div id="main" class="row">
      <div class="col-sm-12 col-md-4">
        <template v-if="message && message.uuid">
          <template v-if="message.direction == 'inbound'">
            <div class="card mb-3">
              <div class="card-body">
                <p class="card-title">
                  Reply to {{ message.from }}
                </p>

                <form @submit.prevent="postReply">
                  <div class="form-group">
                    <label
                      for="message"
                      class="text-capitalize"
                    >{{ message.channel }} Message</label>
                    <textarea
                      id="message"
                      v-model="replyForm.content"
                      class="form-control"
                      rows="3"
                    />
                  </div>

                  <button
                    type="submit"
                    class="btn btn-success"
                    :disabled="processing"
                  >
                    Send Reply
                  </button>
                </form>
              </div><!--Body-->
            </div><!--Card-->
          </template>


          <template v-if="message.last_status == 'scheduled' && message.direction == 'outbound'">
            <div class="card mb-3">
              <div class="card-body">
                <p class="card-title">
                  Update Message To {{ message.to }}
                </p>

                <form @submit.prevent="postForm()">

                  <div class="form-group">
                    <label for="from">From</label>
                    <select id="from" class="form-control" v-model="message.from" :disabled="processing">
                      <template v-for="(agency_from, agency_from_index) in agency_froms">
                        <option :option="agency_from">{{ agency_from }}</option>
                      </template>
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="to">To</label>
                    <input id="to" type="text" class="form-control" v-model="message.to">
                  </div>

                    <div class="form-group">
                      <label for="subject">Subject</label>
                      <input
                        id="subject"
                        v-model="message.subject"
                        type="text"
                        class="form-control"
                      >
                    </div>

                  <div class="form-group">
                    <label
                      for="message"
                      class="text-capitalize"
                    >{{ message.channel }} Message</label>
                    <textarea
                      id="message"
                      v-model="message.content"
                      class="form-control"
                      rows="5"
                    />
                  <template v-if="message.content && message.content.length >= 1">
                   <span class="badge badge-pill text-lowercase text-small" :class="setCharacterClass(message.content)">{{ message.content.length }} characters</span>
                  </template>
                  </div>

                  <div class="row">
                    <div class="col-6">
                      <div class="form-group vbDatePicker">
                        <label for="send_date">Send Date</label>
                        <input
                          id="send_date"
                          v-model="message.date"
                          placeholder="mm/dd/yyyy"
                          type="date"
                          class="form-control"
                        >
                      </div>
                    </div><!--Col-->
                    <div class="col-6">
                      <div class="form-group vbTimePicker">
                        <label for="send_time">Send Time</label>
                        <input
                          id="send_time"
                          v-model="message.time"
                          placeholder="hh:mm AM"
                          type="time"
                          class="form-control"
                        >
                      </div>
                    </div><!--Col-->
                  </div><!--Row-->

                  <div class="form-group">
                    <label for="channel">Channel</label>
                    <select id="channel" class="form-control" v-model="message.channel" :disabled="processing">
                      <option value=""></option>
                      <template v-for="(channel, channel_index) in channels">
                        <option :value="channel">{{ channel }}</option>
                      </template>
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="language">Language</label>
                    <select id="language" class="form-control" v-model="message.language" :disabled="processing">
                      <option value=""></option>
                      <template v-for="language in languages">
                        <option :value="language.key">{{ language.label }}</option>
                      </template>
                    </select>
                  </div>

                  <div class="form-group custom-control custom-switch">
                    <input
                      id="test"
                      v-model="message.test"
                      type="checkbox"
                      class="custom-control-input"
                      @change="postForm()"
                      :disabled="processing"
                    >
                    <label
                      class="custom-control-label"
                      for="test"
                    >Simulate Mode</label>
                  </div>

                  <template v-if="!form.deleted_at">
                    <div class="row">
                      <div class="col-sm-12 col-md-6">
                        <button
                          type="submit"
                          class="btn btn-success"
                          :disabled="processing"
                        >
                        Save
                        </button>
                      </div><!--Col-->

                      <div class="col-sm-12 col-md-6 text-right">
                        <button
                          type="button"
                          class="btn btn-danger"
                          :disabled="processing"
                          :hidden="!showTrash"
                          @click="trashRecord(message)"
                        >
                          <i class="fa fa-trash" /> Trash
                        </button>
                      </div><!--Col-->
                    </div><!--Row-->
                  </template>
                </form>
              </div><!--Body-->
            </div><!--Card-->
          </template>

          <div class="card">
            <div class="card-body">

              <p class="card-title">
                {{ message.channel }} {{ $route.meta.title }}
              </p>

              <ul class="list-group">

                <template v-if="message.to">
                  <li class="list-group-item">
                    To <a :href="'/contacts?search='+message.to">{{ message.to }}</a>
                  </li>
                </template>

                <template v-if="message.from">
                  <li class="list-group-item">
                    From {{ message.from }}
                  </li>
                </template>

                <template v-if="message.channel">
                  <li class="list-group-item">
                    Channel {{ message.channel }}
                  </li>
                </template>

                <template v-if="message.type">
                  <li class="list-group-item">
                    Type {{ message.type }}
                  </li>
                </template>

                <template v-if="message.segments && message.channel == 'text'">
                  <li class="list-group-item">
                    Segments {{ message.segments }}
                  </li>
                </template>

                <template v-if="message.segments && message.channel == 'voice'">
                  <li class="list-group-item">
                    Duration {{ message.segments }} seconds
                  </li>
                </template>

                <template v-if="message.language">
                  <li class="list-group-item">
                    Language {{ setLanguage(message.language) }}
                  </li>
                </template>

                <template v-if="countCharacters >= 1">
                  <li class="list-group-item">
                    Characters {{ countCharacters.toLocaleString() }}
                  </li>
                </template>

                <template v-if="message.direction && message.direction == 'outbound' && message.channel == 'text'">

                  <li class="list-group-item">
                    <span data-toggle="tooltip" data-tooltip="Non GSM messages have lower character limits by carriers" class="mr-1">GSM</span>
                    <template v-if="isNotGSM">
                      <span class="badge badge-danger">No</span>
                    </template>
                    <template v-else>
                      <span class="badge badge-success">Yes</span>
                    </template>
                  </li>

                </template>

                <template v-if="message.credits >= 0">
                  <li class="list-group-item">Credits {{ message.credits }}
                  
                    <template v-if="message.credits >= 3">
                      <span class="badge badge-danger">High</span>
                    </template>

                    <template v-if="!message.token && $root.current_region != 'staging'">
                      <span class="small text-muted text-uppercase tooltip-right" data-toggle="tooltip" data-tooltip="Scheduled credits are estimated until the message is sent"
                      >Estimated</span>
                    </template>

                    <template v-if="$root.current_region == 'staging' && message.direction == 'outbound'">
                      <span class="small text-muted text-uppercase tooltip-right" data-toggle="tooltip" data-tooltip="Outbound credits are simulated in staging"
                      >Simulated</span>
                    </template>
                    
                  </li>
                </template>

                <template v-if="message.scheduled_at && message.direction == 'outbound'">
                  <li class="list-group-item">
                    <span class="text-capitalize">Scheduled</span>
                                    
                    {{ message.scheduled_at | datetime }} {{ message.scheduled_at | dateTimezone | timeago }}
                  </li>
                </template>

                <template v-if="message.url">
                  <li class="list-group-item">
                    URL {{ message.url }}
                  </li>
                </template>

                <template v-if="message.token">
                  <li class="list-group-item">
                    Token {{ message.token }} <button type="button" class="btn btn-primary btn-sm" @click="copyToClipboard(message.token)" :disabled="processing"><i class="fa fa-copy"></i></button>
                  </li>
                </template>
              </ul>
            </div><!--Body-->
          </div><!--Card-->
        </template>

        <template v-if="message.error_code">
          <div class="card mt-3">
            <div class="card-body">

              <div class="alert alert-danger">
                <i class="fa fa-exclamation-triangle" />
                Message {{ message.last_status }}
              </div>

              <ul class="list-group">
                
                <template v-if="message.error_code">
                  <li class="list-group-item">
                    Error Code {{ message.error_code }}
                  </li>
                </template>


                  <li class="list-group-item">
                    Error Reason {{ setErrorCode(message.error_code) }}
                  </li>

                <template v-if="error_description">
                  <li class="list-group-item">
                    Error Description {{ error_description }}
                  </li>
                </template>

              </ul>
            </div><!--Body-->
          </div><!--Card-->
        </template>

        <template v-if="message.uuid">
          <audit-logs
            class="mt-3"
            :form="message"
          />
        </template>

      </div><!--Col-->

      <div class="col-sm-12 col-md-4">

        <template v-if="message && message.uuid">

<div class="card mb-3">
  <div class="card-body">

    <div class="row">
      <div class="col-sm-12 col-md-6">
        <p class="card-title">Lookup Data</p>
      </div><!--Col-->
      <div class="col-sm-12 col-md-6 text-right">
        <template v-if="lookup && lookup.uuid">
        <router-link tag="button" class="btn btn-primary btn-sm" :to="{ 'name': 'lookups.edit', 'params': {'id': lookup.uuid} }">
          <i class="fa fa-database"></i> View Lookup
        </router-link>
        </template>
      </div><!--Col-->

    </div><!--Row-->


    <template v-if="lookup && lookup.uuid">
        <template v-if="lookup.valid">
          <span class="badge badge-success">VALID</span>
        </template>
        <template v-else>
          <span class="badge badge-danger">INVALID</span>
        </template>

      <ul class="list-group my-3">

        <li class="list-group-item">Status {{ lookup.status }}</li>
        <li class="list-group-item">Created {{ lookup.created_at | dateTimezone | timeago }}</li>

      <template v-if="lookup.data && lookup.data.carrier">

        <template v-if="lookup.data.carrier.type">
          <li class="list-group-item">Type {{ lookup.data.carrier.type }}</li>
        </template>

        <template v-if="lookup.data.carrier.name">
          <li class="list-group-item">Name {{ lookup.data.carrier.name }}</li>
        </template>

      </template>

        <template v-if="lookup.data && lookup.data.caller_name && lookup.data.caller_name.caller_name">
          <li class="list-group-item">
            Caller Name {{ lookup.data.caller_name.caller_name }}
          </li>
        </template>

      </ul>


    </template>

    <template v-if="!processing && !lookup">
      <div class="alert alert-danger">No lookup found</div>

      <button type="button" class="btn btn-success" @click="runLookup()" :disabled="processing">Run Lookup</button>
    </template>
  </div>
</div>

</template>

        <template v-if="message && message.client">
          <client-card class="mb-3" :client="message.client" />
        </template>

        <template v-if="message && message.direction === 'outbound' && message.to">
          <contact-card :contact="message.to" />
        </template>

        <template v-if="message && message.direction === 'inbound' && message.from">
          <contact-card class="mb-3" :contact="message.from" />
        </template>

        <template v-if="message && message.uuid && message.direction == 'outbound' && message.error_code">

        <div class="card my-3">
          <div class="card-body">

            <p class="card-title">Resend Message</p>

            <p>Try resending this failed message through a different channel and/or from.</p>

            <form @submit.prevent="postResendForm()">

            <div class="form-group">
              <fieldset>
                <legend>Channel</legend>
                <label
                    class="mr-2 text-capitalize"
                    for="voice"
                >
                  <input
                      id="voice"
                      type="radio"
                      v-model="resendForm.channel"
                      name="voice"
                      value="voice"
                  > Voice (Robocall)
                </label>
                <label
                    class="mr-2 text-capitalize"
                    for="text"
                >
                  <input
                      id="text"
                      type="radio"
                      v-model="resendForm.channel"
                      name="channel"
                      value="text"
                  > Text (SMS)
                </label>
              </fieldset>
            </div>

            <div class="form-group">
              <label for="resend_from">From</label>
              <select id="resend_from" class="form-control" v-model="resendForm.from" required :disabled="processing">
                <option value=""></option>
                <template v-for="(agency_from, agency_from_index) in agency_froms">
                  <option :value="agency_from">{{ agency_from }}</option>
                </template>
              </select>
            </div>

              <div class="form-group">
                <label for="resend_language">Language</label>
                <select id="resend_language" class="form-control" v-model="resendForm.language" :disabled="processing">
                  <option value=""></option>
                  <template v-for="language in languages">
                    <option :value="language.key">{{ language.label }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="resend_subject">Subject</label>
                <input id="resend_subject" class="form-control" v-model="resendForm.subject">
              </div>

              <div class="form-group">
                <label for="resend_message">Message</label>
                <textarea id="resend_message" class="form-control" v-model="resendForm.content" rows="3" required></textarea>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Resend</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        </template>

        <template v-if="message && message.file">
          <sent-file-card :uuid="message.file" />
        </template>

        <template v-if="message && message.event">
          <event-card
            :event="message.event"
            class="my-3"
          />
        </template>

        <template v-if="message && message.payment">
          <payment-card
            :payment="message.payment"
            class="mt-3"
          />
        </template>

        <template v-if="message && message.case">
          <case-card
            :case="message.case"
            class="mt-3"
          />
        </template>

      </div><!--Col-->
            
      <div class="col-sm-12 col-md-4">
        <template v-if="replyMessage.uuid">
          <message :message="replyMessage" />
        </template>

        <template v-if="message.uuid">
          <message :message="message" />
        </template>

        <template v-if="message.mms >= 1 && !message.medias">
          <div class="alert alert-danger">
            <i class="fa fa-photo-video" />
            No media found
          </div>
        </template>

        <template v-if="message.medias && message.medias.length > 0">
          <div class="card mb-3">
            <div class="card-body">
                
                <p class="card-title">
                  {{ message.medias.length }} Medias
                </p>

              <ul class="list-group">
                <template v-for="(media, media_index) in message.medias">
                  <li
                    class="list-group-item"
                  >
                    <div class="row">
                      <div class="col-md-9">
                        {{ media.name }}<br>
                        <small class="text-muted">File Size {{ media.size | fileSize }}</small><br>
                        <small class="text-muted">File Type {{ media.ext }}</small>
                      </div><!--Col-->
                      <div class="col-md-3 text-right">
                        <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          :disabled="downloading"
                          @click="downloadMedia(media)"
                        >
                          <i class="fa fa-download" /> Open
                        </button>
                      </div><!--Col-->
                    </div><!--Row-->
                  </li>
                </template>
              </ul>
            </div><!--Body-->
          </div><!--Card-->
        </template>

        <template v-if="message.last_status && opens && opens.length">
 

              <template v-if="!opens.length && !processing">
                <div class="alert alert-warning">
                  No opens found.
                </div>
              </template>

              <template v-if="ip_address && ip_address.city && ip_address.city.names">
                Last Opened Near {{ ip_address.city.names['en'] }} 
                
                <template v-if="ip_address.subdivisions && ip_address.subdivisions.length > 0">
                {{ ip_address.subdivisions[0]['names']['en'] }}
                </template>
                
                <template v-if="ip_address.connection && ip_address.connection.isp">
                  <br><small class="text-muted">ISP {{ ip_address.connection.isp }}</small>
                </template>

              </template>

              <template v-if="opens[0] && opens[0].user_agent">
                <user-agent
                  :raw="opens[0].user_agent"
                  class="mt-3"
                />
              </template>

              <template v-if="opens.length && opens.length > 0">
                <ul class="list-group mt-3">
                  <template v-for="(open, index) in opens">
                    <li
                      class="list-group-item"
                    >
                      <a :href="'/opens/'+open.uuid">{{ open.ip_address }} {{ open.url }} {{ open.created_at | dateTimezone | timeago }}</a>
                    </li>
                  </template>
                </ul>
              </template>
        </template>

        <template v-if="meta_datas && meta_datas.length > 0">

          <div class="card">
            <div class="card-body">
              <p class="card-title m-0">Message Meta Data</p>

              <p>Meta data is available up to 90 days after the message is sent or received.</p>

              <ul class="list-group">

              <template v-for="meta_data in meta_datas">

                <li class="list-group-item list-group-item-action">

                  <template v-if="meta_data.errors && meta_data.errors.length">
                    <template v-for="meta_error in meta_data.errors">
                      <div class="alert alert-danger">{{ meta_error.code }} {{ meta_error.detail }}</div>
                    </template>
                  </template>

                  <template v-if="meta_data.status">
                    <p class="m-0">Status {{ meta_data.status }}</p>
                  </template>

                  <template v-if="meta_data.encoding">
                    <p class="m-0">Encoding {{ meta_data.encoding }}</p>
                  </template>

                  <template v-if="message.direction == 'outbound' && meta_data.to && meta_data.to[0]">

                    <template v-if="meta_data.to[0]['carrier']">
                      <p class="m-0">Carrier {{ meta_data.to[0]['carrier'] }}</p>
                    </template>

                    <template v-if="meta_data.to[0]['line_type']">
                      <p class="m-0">Line Type {{ meta_data.to[0]['line_type'] }}</p>
                    </template>

                    <template v-if="meta_data.to[0]['status']">
                      <p class="m-0">Status {{ meta_data.to[0]['status'] }}</p>
                    </template>

                  </template>

                  <template v-if="meta_data.completed_at">
                    <p class="m-0">Completed At {{ meta_data.completed_at | datetimeSec }}</p>
                  </template>

                  <template v-if="meta_data.sent_at">
                    <p class="m-0">Sent At {{ meta_data.sent_at | datetimeSec }}</p>
                  </template>

                  <template v-if="meta_data.received_at">
                    <p class="m-0">Received At {{ meta_data.received_at | datetimeSec }}</p>
                  </template>

                  <template v-if="meta_data.tcr">
                    <template v-if="meta_data.tcr.campaign_id">
                      <p class="m-0">Campaign ID {{ meta_data.tcr.campaign_id }}</p>
                    </template>
                  </template>

                </li>

              </template>

              </ul>

            </div>
          </div>


        </template>

        <template v-if="messages && messages.length > 0">

        <p class="card-title my-3">Related Messages</p>
        
        <template v-for="message in messages">
          <message :message="message" />
        </template>

        </template>


      </div><!--Col-->
    </div><!--Row-->

      <dialog
        id="duplicateModal"
        ref="duplicateModal"
        class="modal fade"
        tabindex="-1"
        aria-labelledby="duplicateModalTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-scrollable modal-lg"
          role="document"
        >
          <div class="modal-content">

            <div class="modal-header">
              <p class="modal-title" id="duplicateModalTitle">Duplicate {{ $route.meta.title }}</p>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

              <template v-if="message && message.content">
               <messages-form :content="message.content" :subject="message.subject" />
              </template>

          </div>
        </div>
      </dialog><!--Modal-->


  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';
  
  export default {
    
    mixins: [ queries ],

data() {
    return {
        processing: false,
        downloading: false,
        message: {},
        client: {},
        opens: [],
        messages: [],
        error_reason: '',
        replyForm: {},
        replyMessage: {},
        form: {},
        resendForm: {},
        agency_froms: [],
        ip_address: {},
        meta_datas: [],
        channels: ['text', 'voice', 'email'],
        languages: [],
        lookup: {},
        errors: []
    }
},

computed: {


showTrash: function() {
  if(!this.message) {
    return false;
  }

  if(this.message.deleted_at) {
    return false;
  }

  var scheduled = this.$moment.utc(this.message.scheduled_at, 'YYYY-MM-DD HH:mm:ss');

  if(scheduled.isBefore(this.$moment.utc())) {
    return false;
  }

  return true;
},

countCharacters() {

let count = 0;

if(this.message.subject && this.message.subject.length) {
  count += this.message.subject.length;
}

if(this.message.content && this.message.content.length) {
  count += this.message.content.length;
}

return count;
},

isNotGSM() {

  if(!this.message.content && !this.message.subject) {
    return false;
  }

  let value = this.message.content + this.message.subject;

  return value.match(/[^\x00-\x7F]/g);

},

},


mounted() {

  var self = this;

  this.$eventBus.$on('opens', function(data) {
      self.addNewOpen(data);
  });

  this.$eventBus.$on('errors', function (errors) {
    self.errors = errors.data || errors;
    self.processing = false;
  });

},

created() {
    this.errors = [];
    this.resetReplyForm();
    this.resetResendForm();
    this.getRecord();
    this.getLanguages();
    this.getErrorCodes();
},

methods: {

    getClass(message) {

        if(message && message.direction) {
            return message.direction
        }

        return 'outbound';

    },

    formatDateTime() {
      
      if(!this.message.scheduled_at) {
        return;
      }

      this.message.date = this.formatDate(this.message.scheduled_at, 'YYYY-MM-DD');
      this.message.time = this.formatDate(this.message.scheduled_at, 'HH:mm');
    },

    formatDate (value, format) {
    var date = this.$moment.utc(value, 'YYYY-MM-DD HH:mm:ss');
    return date.tz(this.timezone).format(format);
    },

    getRecord() {
        
        this.processing = true;

        this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id+'?slim=true')
        .then(response => {
            if(response && response.data) {

                this.message = response.data;

                if(this.message.error_code) {
                    this.getErrorReason(this.message.error_code);
                }

                this.formatDateTime();

                this.getAgencyFroms();

                if(this.message.last_open) {
                    this.getOpens();
                }

                if(this.message.sent == 1) {
                  this.getMetas();
                }

                this.getLookup();

                this.setResendForm();

                this.getMessages();

                this.processing = false;
            }
        })
    },


    getPDF() {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.message.uuid+'/pdf', {responseType: 'blob'})
      .then(response => {
        if(response && response.data) {
          this.processing = true;

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.$route.meta.base_url+'-'+this.message.uuid+'.pdf');
          document.body.appendChild(link);
          link.click();

          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);

          this.processing = false;
        }
      })
    },

  resetResendForm() {
      this.resendForm = {
        from: null,
        to: null,
        channel: null,
        message: null,
        subject: null,
        language: 'en'
      }
  },

  setResendForm() {

      this.resetResendForm();

    if(!this.message) {
      return;
    }

    this.resendForm = {
      from: this.message.from,
      to: this.message.to,
      channel: this.message.channel,
      content: this.message.content,
      subject: this.message.subject,
      language: this.message.language
    }

    if(this.message.channel == 'text') {
      this.resendForm.channel = 'voice';
    }

  },

    getLookup() {

      if(!this.message.uuid) {
        return;
      }

      var lookup = this.message.to;

      if(this.message.direction == 'inbound') {
        lookup = this.message.from;
      }

      this.lookup = null;

      this.$http.get('/lookups?limit=1&sort=created_at&sortDirection=desc&lookup='+lookup)
          .then(response => {
            if(response && response.data && response.data[0]) {
              this.lookup = response.data[0];
            }
          })
    },

    getMetas() {
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.message.uuid+'/metas')
          .then(response => {
            if(response && response.data) {
              this.meta_datas = response.data;
            }
          })
    },

    getAgencyFroms() {
      if(!this.message.direction || this.message.direction == 'inbound') {
        return;
      }

      if(this.message.channel == 'email') {
        this.getAgencyEmails();
      }

      if(this.message.channel == 'text' || this.message.channel == 'voice') {
        this.getAgencyPhones();
      }
    },

    getAgencyEmails() {
      this.agency_froms = [];
      this.$http.get('/agency_emails?slim=true&fields=email')
      .then(response => {
        if(response && response.data) {
          this.agency_froms = response.data.map(r => r.email);
        }
      })
    },

    getAgencyPhones() {
      this.agency_froms = [];
      this.$http.get('/agency_phones?slim=true&fields=phone_number')
      .then(response => {
        if(response && response.data) {
          this.agency_froms = response.data.map(r => r.phone_number);
        }
      })

    },

    postForm() {

        if(this.form.deleted_at) {
            return;
        }

        this.processing = true;
        this.$http.patch('/'+this.$route.meta.base_url+'/'+this.message.uuid, this.message)
        .then(response => {
            if(response && response.data) {

                this.processing = false;

                if(response.data.updated_at) {
                    new this.$noty({text: this.$route.meta.title + ' updated'}).show();
                    this.form = {...this.form, ...response.data};
                }
            }
        })
    },

    getOpens() {
        this.$http.get('/opens?message='+this.message.uuid)
        .then(response => {
            if(response && response.status === 200) {
                this.opens = response.data;

                if(this.opens[0] && this.opens[0]['ip_address']) {
                  this.getIPAddress(this.opens[0]['ip_address']);
                }
            }
        })
    },

    getMessages() {
      this.getFromMessages();
      this.getToMessages();
    },

    getFromMessages() {

      if(this.message.direction == 'outbound') {
        return;
      }


      this.$http.get('/messages?search_field=from&search='+this.message.from+'&sort=scheduled_at&sortDirection=desc')
        .then(response => {
          if(response && response.data && response.data.data) {

            if(!response.data.data.length) {
              return;
            }

            var messages = response.data.data.filter(m => m.uuid != this.message.uuid);

            this.messages = this.messages.concat(messages)
          }
        })
    },

    getToMessages() {

      var search = this.message.to;

      if(this.message.direction == 'inbound') {
        search = this.message.from;
      }


      this.$http.get('/messages?search_field=to&search='+search+'&sort=scheduled_at&sortDirection=desc')
        .then(response => {
          if(response && response.data && response.data.data) {

            if(!response.data.data.length) {
              return;
            }


            var messages = response.data.data.filter(m => m.uuid != this.message.uuid);

            this.messages = this.messages.concat(messages);

          }
        })
    },

    getIPAddress(ip) {
      this.$http.get('/ips/'+ip)
      .then(response => {
        if(response && response.data) {
          this.ip_address = response.data;
        }
      })
    },

    getErrorReason(error_code) {
        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/error_reasons.json')
        .then(response => {
            if(response && response.data) {
                if(response.data[error_code]) {
                    this.error_description = response.data[error_code];
                }
            }
        })
    },

    downloadMedia(media) {
        this.downloading = true;
        this.$http.get('/'+this.$route.meta.base_url+'/'+this.message.uuid+'/medias/'+media.name)
        .then(response => {
            if(response && response.data) {
                this.downloading = false;
                window.open(response.data.url, '_blank');
            }
        })

    },

    trashRecord(message) {
        this.processing = true;
        this.$http.delete('/'+this.$route.meta.base_url+'/'+message.uuid)
        .then(response => {
            if(response && response.status === 204) {
                new this.$noty({text: this.$route.meta.title + ' trashed', type: 'error'}).show();
                this.message.deleted_at = new Date().toISOString();
            }
        })
    },

    resetReplyForm() {

        this.replyForm = {
            to: '',
            channel: '',
            content: '',
            subject: ''
        };
    },

    addNewOpen(data) {

      if(!data.message) {
        return;
      }

      if(data.message != this.message.uuid) {
        return;
      }

      if(data.created_at) {
        this.message.last_open = data.created_at;
      }

      this.opens.unshift(data);
    },

    postReply() {

        this.errors = [];

        this.replyForm.to = this.message.from;
        this.replyForm.channel = this.message.channel;

        this.replyMessage = {};

        this.processing = true;
        this.$http.post('/'+this.$route.meta.base_url+'/oneoffs', this.replyForm)
        .then(response => {
            if(response && response.data) {
                this.processing = false;
                this.replyMessage = response.data;
                this.resetReplyForm();
            }
        })
    },

  refreshStatuses() {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.message.uuid+'/statuses')
          .then(response => {
            if(response && response.status === 200) {
              if(response.data.uuid) {
                this.message = {...this.message, ...response.data};
                this.processing = false;
                new this.$noty({text: this.$route.meta.title + ' statuses refreshed'}).show();
              }
            }
          })
  },

  runLookup() {
      if(!this.message.uuid) {
        return;
      }

      this.processing = true;

      var lookup = this.message.to;

      if(this.message.direction == 'inbound') {
        lookup = this.message.from;
      }

      this.$http.post('/lookups', {lookup: lookup})
          .then(response => {
            if(response && response.data) {
              this.lookup = response.data;
              this.processing = false;
            }
          })
  },

  postResendForm() {

      this.errors = [];

      this.processing = true;

      this.$http.post('/messages/oneoffs', this.resendForm)
          .then(response => {
            if(response && response.data) {
              this.processing = false;
              this.resetResendForm();
              new this.$noty({text: this.$route.meta.title + ' scheduled'}).show();
            }
          })
  },

  getLanguages () {
    this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/languages.json')
        .then(response => {
          if(response && response.data) {
            this.languages = response.data;
          }
        })
  },

  setCharacterClass(value) {

    if(!value) {
      return;
    }

  if(value.length >= 9999) {
    return 'badge-danger';
  }

  if(value.length > 360) {
    return 'badge-warning';
  }

  if(value.length > 10 && value.length < 360) {
    return 'badge-success';
  }

  return 'badge-secondary';
  },

  sendMessage() {
    this.processing = true;
    this.$http.post('/'+this.$route.meta.base_url+'/'+this.message.uuid+'/send')
        .then(response => {
          if(response && response.data) {
            this.processing = false;

            if(response.data.sent == 1) {
              this.message = {...this.message, ...response.data}
              new this.$noty({text: this.$route.meta.title + ' sent'}).show();
            }
          }
        })
  },

        setLanguage(language) {
          
          if(!language) {
            return;
          }

          var result = _.find(this.languages, {'key': language});

          if(!result) {
            return language;
          }

          return result.label;

        },

        getErrorCodes () {

          if(this.$root['error_codes']) {
            return;
          }

        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/error_codes.json')
            .then(response => {
              if (response && response.data) {
                this.$root['error_codes'] = response.data;
              }
            })
      },

        setErrorCode(code) {

        if(!code) {
          return;
        }

        if(!this.$root['error_codes']) {
          return;
        }

        if(!this.$root['error_codes'][code]) {
          return;
        }

        return this.$root['error_codes'][code];
        },

      }

}
</script>