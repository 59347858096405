<template>
  <div>
    <form @submit.prevent="searchClients">
      <div class="input-group mb-3">
        <input
          v-model="search_clients"
          type="text"
          class="form-control text-lowercase"
          aria-label="Client"
          aria-describedby="client"
        >
        <div class="input-group-append">
          <button
            id="client"
            class="btn btn-primary"
            type="submit"
            :disabled="processing"
          >
            Filter by Client
          </button>
        </div>
      </div>

      <template v-if="records && records.length > 0">
        <ul class="list-group mb-3">
          <template v-for="record in records">
            <button
              type="button"
              class="list-group-item list-group-item-action"
              @click="setSearchResult(record)"
            >
              {{ record.result }}
            </button>
          </template>
        </ul>
      </template>
    </form>
  </div>
</template>
<script>
export default {

props: ['client'],

data() {
    return {
        processing: false,
        search_clients: '',
        records: []
    }
},

created() {
},

methods: {


        searchClients() {
            this.processing = true;
            this.$http.get('/search?search='+this.search_clients)
            .then(response => {
                if(response && response.data) {
                    this.processing = false;
                    this.records = response.data;
                }
            })
        },

        setSearchResult(record) {

            if(!record.client_uuid) {
                return;
            }

            this.$eventBus.$emit('search_clients', record.client_uuid);
            this.records = [];
            this.search_clients = '';
        }

}

}
</script>