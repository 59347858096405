<template>
  <div>
    <button
      type="button"
      class="btn btn-outline-danger btn-sm float-right"
      :disabled="processing"
      @click="trashForm"
    >
      <i class="fa fa-trash" />
    </button>
  </div>
</template>

<script>

export default {

props: ['base_url', 'uuid', 'title'],

data() {
    return {
        processing: false,
    }
},

methods: {

    trashForm() {
        this.processing = true
        this.$http.delete(this.base_url+'/'+this.uuid)
        .then(response => {
            if(response) {
                this.$eventBus.$emit('trashForm', this.uuid)
                new this.$noty({text: 'Trashing', type: 'error'}).show();
            }
            this.processing = false;
        })
    }

},

}

</script>