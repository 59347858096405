<template>
  <div>
    <template v-if="record.uuid">
      Upload Template <a :href="base_url+'/'+record.uuid">{{ record.name }}</a>
    </template>
    <template v-if="!processing && !record.uuid">
      <span class="badge badge-pill badge-danger">Upload Template not found</span>
    </template>
  </div>
</template>
<script>
export default {

// props: ['uuid'],

props: {
    uuid: {
        type: String,
        default: null
    }
},

data() {
    return {
        processing: false,
        record: {},
        base_url: '/upload_templates'
    }
},

created() {
    this.getRecord();
},

methods: {

        getRecord() {
            
            if(!this.uuid) {
                return;
            }

            this.processing = true;
            this.record = {};

            this.$http.get(this.base_url+'/'+this.uuid+'?slim=true&fields=uuid,name')
            .then(response => {
                if(response && response.data) {
                    this.record = response.data;
                    this.processing = false;
                }
            })
        },

}

}
</script>