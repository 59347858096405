<template>
  <div>

    <template v-if="$route.meta.requiresAuth">

        <div class="btn-group d-print-none" data-html2canvas-ignore>

          <div class="dropdown dropdown-menu-right" id="searchAgencies">

              <button id="searchAgenciesButton" class="btn btn-secondary dropleft" type="button" data-toggle="dropdown" aria-expanded="false"
              @click="getAgencies"
              >
                <i class="fa fa-landmark-dome"></i> 
                <template v-if="current_agency && current_agency.name">
                  {{ current_agency.name }}
                </template>

              </button>

                <div
                  class="dropdown-menu p-2"
                  aria-labelledby="searchAgenciesButton"
                  style="overflow-y: auto; max-height: 600px; width: 400px;"
                >
                  <p class="dropdown-header">Switch Agency</p>

                  <form @submit.prevent="getAgencies">
                    <div class="input-group">
                      <label
                        class="sr-only"
                        for="search_agencies"
                      >Search</label>
                      <input
                        id="search_agencies"
                        v-model="search_agencies"
                        type="text"
                        class="form-control"
                        placeholder="Search agencies..."
                      >
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-primary"
                          type="submit"
                          :disabled="processing"
                        >
                        <template v-if="!processing">
                          <i class="fa fa-search" /> <small class="sr-only">Search</small>
                        </template>
                        <template v-if="processing">
                          <loader :isLoading="processing" />
                        </template>
                        </button>
                      </div>
                    </div>

                    <template v-if="isGlobalDomain">
                      <div class="form-group mt-1">
                        <fieldset>
                          <legend class="m-0">
                            Region
                          </legend>

                          <template v-for="region in regions">
                            <label
                              class="m-0 mr-2"
                              :for="region"
                            >
                              <input
                                :id="region"
                                v-model="agencies_region"
                                type="radio"
                                :name="region"
                                :value="region"
                              > {{ region }}
                            </label>
                          </template>
                        </fieldset>
                      </div>
                    </template>

                    <div class="form-group mt-1">
                      <label for="agencies_sort" class="sr-only">Sort</label>
                      <select id="agencies_sort" class="form-control" v-model="agencies_sort" @change="getAgencies" :disabled="processing">
                        <template v-for="agency_sort in agency_sorts">
                          <option :value="agency_sort.value">Sort by {{ agency_sort.title }}</option>
                        </template>
                      </select>
                    </div>

                  </form>

                  <template v-if="agencies && agencies.length > 0">
                    <template v-for="agency in agencies">
                      <a
                        class="dropdown-item"
                        :class="{ active: isActiveAgency(agency) }"
                        @click="switchAgency(agency)"
                      >
                      <span :class="isPrivacyMode ? 'privacy-mode' : ''">
                      {{ agency.name }}<br><small>{{ agency.reference }} {{ agency.region }}</small>
                      </span>
                      <template v-if="agency.status == 0">
                        <span class="badge badge-pill bg-danger">INACTIVE</span>
                      </template>
                      <template v-if="agency.status == 2">
                        <span class="badge badge-pill bg-success">NEW</span>
                      </template>
                    </a>
                    </template>

                  </template>
                </div>

              </div><!--AgencyDropdown-->

          <div class="dropdown">
            <button class="btn btn-secondary" data-toggle="dropdown" aria-expanded="false">
              <i class="fa fa-globe"></i> {{ $root.current_region }}
            </button>

            <div class="dropdown-menu">
              <p class="dropdown-header">Switch Region</p>
              <template v-for="region in regions">
                <template v-if="region != $root.current_region">
                <a
                    class="dropdown-item"
                    :href="'https://'+region+'.ecourtdate.com'"
                    target="_blank"
                >
                  <template v-if="region == 'app'">
                    main
                  </template>
                  {{ region }}</a>
                </template>
              </template>
            </div>
          </div>

        </div><!--Group-->

      </template>
</div>
</template>
<script>
import queries from '../mixins/queries';

export default {

    mixins: [ queries ],

    data () {
        return {
            agencies: [],
            processing: false,
            search_agencies: '',
            agencies_region: '',
            agencies_sort: 'name_asc',
            agency_sorts: [
              {title: 'Name a-z', value: 'name_asc'},
              {title: 'Name z-a', value: 'name_desc'},
              {title: 'Reference a-z', value: 'reference_asc'},
              {title: 'Reference z-a', value: 'reference_desc'},
              {title: 'Status active', value: 'status_desc'},
              {title: 'Status inactive', value: 'status_asc'}
            ],
            regions: ['app', 'us-east', 'us-west', 'us-south', 'staging'],
            current_agency: {},
        }
    },

    computed: {

        isGlobalDomain() {

            var localURL = process.env.VUE_APP_API_URL;

            if(localURL != null) {
                return false;
            }

            const host = window.location.host;
            const parts = host.split('.');

            if (parts[0] === 'app') {
              return true;
            }

            return false;
        },

    },

    created() {
      this.getCurrentAgency();

      window.addEventListener('keydown', (e) => {

        if (e.ctrlKey && e.key === 'k' || e.metaKey && e.key === 'k') {
          document.getElementById('searchAgenciesButton').click();
          document.getElementById('search_agencies').focus();
        }

      });
    },


    methods: {

      getCurrentAgency() {

        if(this.settings && this.settings.name) {
          if(this.current_agency && this.current_agency.uuid === this.settings.uuid) {
            return;
          }
        }

        this.$http.get('/settings')
        .then(response => {
          if(response && response.data) {
            this.current_agency = response.data;
          }
        })
      },

        getAgencies() {
            this.processing = true;

            this.agencies = [];

            var sort = 'name';
            var direction = 'asc';

            if(this.agencies_sort == 'name_asc') {
              sort = 'name';
              direction = 'asc';
            }

            if(this.agencies_sort == 'name_desc') {
              sort = 'name';
              direction = 'desc';
            }

            if(this.agencies_sort == 'reference_asc') {
              sort = 'reference';
              direction = 'asc';
            }

            if(this.agencies_sort == 'reference_desc') {
              sort = 'reference';
              direction = 'desc';
            }

            if(this.agencies_sort == 'status_asc') {
              sort = 'status';
              direction = 'asc';
            }

            if(this.agencies_sort == 'status_desc') {
              sort = 'status';
              direction = 'desc';
            }

            this.$http.get('/agencies?limit=25&slim=y&fields=uuid,name,reference,region,status&search='+this.search_agencies+'&region='+this.agencies_region+'&sort='+sort+'&sortDirection='+direction)
            .then(response => {
                if(response && response.data) {
                    this.processing = false;
                    this.agencies = response.data;
                }
            })
        },

        switchAgency (agency) {

          new this.$noty({text: 'Switching Agency'}).show();

          this.$http.post('switch_agency/'+agency.uuid+'?region='+agency.region)
          .then(response => {
            if (response && response.status === 200) {

              this.clearAgencySession();

              location.reload();
            }
          })
        },

        clearAgencySession() {
          
          var fields = ['fields', 'timezone', 'agency', 'send_mode', 'settings'];

          fields.forEach(field => {
            localStorage.removeItem(field);
          })

        },

        isActiveAgency(agency) {

            if(agency.uuid === localStorage.getItem('agency')) {
                return true;
            }

            return false;
        },

    },
    
}
</script>