<template>
    <div>
  
      <page-tabs page="message_templates" />
  
      <div class="row">
        <div class="col-sm-12 col-md-3">
  
          <div class="card mb-3">
            <div class="card-body">
              <p class="card-title">{{ $route.meta.title }}</p>

  
              <form @submit.prevent="postForm()">
  
                <div class="form-group">
                  <label for="prompt">Your Prompt</label>
                  <textarea id="prompt" v-model="form.prompt" class="form-control" :disabled="processing"></textarea>
                </div>

                <div class="form-group">
                  <label for="model">Model</label>
                  <select v-model="form.model" class="form-control" id="model" :disabled="processing">
                    <option value="">Select a model</option>
                    <option v-for="model in ai_models" :value="model">{{ model }}</option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="temperature">Temperature</label>
                  <input type="range" v-model="form.temperature" class="form-control" id="temperature" min="0" max="1" step="0.1" :disabled="processing">
                  <p class="text-muted">A higher temperature will result in more creative responses.</p>
                </div>

                <div class="form-group">
                  <label for="max_tokens">Max Tokens</label>
                  <input type="number" v-model="form.max_tokens" class="form-control" id="max_tokens" :disabled="processing">
                  <p class="text-muted">The maximum number of tokens to generate.</p>
                </div>
  
                <button type="submit" class="btn btn-success" :hidden="generating">Generate</button>

                <template v-if="generating">
                  <button type="button" class="btn btn-success" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Generating...
                  </button>
                </template>
  
              </form>
  
              <template v-if="errors && errors.message">
                <div v-if="errors.message != 'Network Error'">
                  <div class="alert alert-danger mt-3">
                    {{ errors.message }}
                  </div>
                </div>
              </template>
  
            </div>
          </div>
  
 
        </div><!--Col-->
  
        <div class="col-sm-12 col-md-9">
  
         <template v-if="messages && messages.length > 0">
          
          <template v-for="message in messages">
          
            <div>
              <div class="row mb-3">

                <div class="col-sm-12 col-md-6">
                  <template v-if="message.direction == 'inbound'">
                  <i class="fa fa-user"></i> User
                  </template>
                  <template v-else>
                    <i class="fa fa-robot"></i> AI Bot
                  </template>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6 text-right">
                  <button type="button" class="btn btn-primary btn-sm" @click="copyToClipboard(message.message)" :disabled="processing"><i class="fa fa-copy"></i> Copy</button>
                </div><!--Col-->

              </div><!--Row-->

            <p :class="message.direction">
              <span v-html="renderMarkdown(message.message)"></span>
            </p>
            </div>
          
          </template>
          
        </template>


        <template v-if="messages && messages.length === 0">

        <div class="card">
          <div class="card-body">
            <p class="card-title">Generate AI-assisted messages to quickly create responses and sample templates.</p>

            <p>Do not use this feature with personally identifiable information.</p>
          </div><!--Body-->
        </div><!--Card-->
        
        </template>



        </div><!--Col-->
      </div><!--Row-->
    </div>
  </template>
  <script>
  import queries from '../mixins/queries';
  
  export default {
  
    mixins: [ queries ],
  
    data () {
      return {
        processing: false,
        generating: false,
        ai_models: [
          'gpt-3.5-turbo',
          'gpt-3.5-turbo-davinci',
          'gpt-3.5-turbo-codex',
          'gpt-4.0-turbo',
          'gpt-4.0-turbo-davinci',
          'gpt-4o'
        ],
        messages: [],
        form: {},
        errors: null
      }
    },
  
    mounted() {
  
      var self = this;

      this.$eventBus.$on('errors', function (errors) {
        self.errors = errors.data || errors;
        self.processing = false;
        self.generating = false;
      });
  
    },
  
    created() {
      this.resetForm();
    },
  
    methods: {
  
  
      resetForm() {
        this.form = {
          prompt: null,
          temperature: 0.5,
          max_tokens: 100,
          model: 'gpt-3.5-turbo',
          format: 'text'
        }
  
        this.errors = null;
      },
    
      postForm() {

        if(!this.messages) {
          this.messages = [];
        }

        this.messages.unshift({message: this.form.prompt, direction: 'inbound'});

        var form = this.form;

        this.resetForm();

        this.generating = true;
        this.$http.post('/'+this.$route.meta.base_url, form)
            .then(response => {
              if(response && response.data) {
                this.generating = false;
                  
                if(response.data && response.data.message) {

                  if(this.messages.length == 0) {
                    this.messages = [];
                  }

                  this.messages.unshift({message: response.data.message, direction: 'outbound'});

                }

              }
            })
      },

      renderMarkdown(content) {
        const md = this.$markdown({
          html: true,
          linkify: true,
          typographer: true,
          breaks: true
        });

        
        var result = md.render(content);

        return this.$DOMPurify.sanitize(result);
      },

      copyToClipboard(value) {
        navigator.clipboard.writeText(value);
        new this.$noty({text: 'Copied to clipboard'}).show();
      },

    },
  }
  </script>