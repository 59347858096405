<template>
    <div class="row">
      <div class="col-md-3 col-sm-12">
  
          <div class="card mb-3">
            <div class="card-body">
              <p class="card-title">
                Current Agency
              </p>
  
              <template v-if="$root.current_user && !$root.current_user.current_agency">
                <div class="alert alert-danger">No agencies found.</div>
              </template>
  
              <template v-if="$root.current_user && $root.current_user.current_agency">
                <h4>{{ $root.current_user.current_agency.name }}</h4>
  
                <p class="m-0"><strong>Region:</strong> {{ $root.current_user.current_agency.region }}</p>

                <template v-if="$root.current_user.super_admin">

                <div class="btn-group">

                <a :href="'https://console.ecourtdate.com/agencies/'+$root.current_user.current_agency.uuid" target="_blank" class="btn btn-primary btn-sm my-3">View in Console</a>

                <template v-if="record && record.uuid">
                    <a :href="'https://help.ecourtdate.com/chats/'+record.uuid" target="_blank" class="bt\n btn-primary btn-sm my-3">View in Help Center</a>
                </template>

                </div>

                </template>
  
              </template>
               
            </div><!--Body-->
          </div><!--Card-->

          <div class="card my-3">
            <div class="card-body">
                <p class="card-title">Current User</p>

                <template v-if="$root.current_user">
                    <p class="m-0"><strong>Name:</strong> {{ $root.current_user.name }}</p>
                    <p class="m-0"><strong>Email:</strong> {{ $root.current_user.email }}</p>
                    <template v-if="$root.current_user.roles && $root.current_user.roles.length">
                    <p class="m-0"><strong>Roles:</strong> 

                        <template v-for="role in $root.current_user.roles">
                            <span class="badge badge-pill badge-secondary">{{ role }}</span>
                        </template>
                    </p>
                    </template>
                        <p class="m-0"><strong>Super Admin:</strong>

                            <template v-if="$root.current_user.super_admin">
                                <span class="badge badge-pill badge-success ml-1">YES</span>
                            </template>
                            <template v-else>
                                <span class="badge badge-pill badge-danger ml-1">NO</span>
                            </template>
                        </p>
                </template>
            </div><!--Body-->
          </div><!--Card-->
          
        </div><!--Col-->

        <div class="col-md-9 col-sm-12">

            <template v-if="record.content">
                <p :class="record.direction">{{ record.content }}</p>
            </template>

            <template v-if="record.file && record.file_url">
                <a :href="record.file_url" :download="record.file_url" target="_blank" class="btn btn-primary btn-sm mt-1 mb-3">
                <i class="fa fa-link"></i> Open {{ record.file }}
                </a>
            </template>

            <template v-if="record.created_by">
                <p class="m-0"><strong>Created By:</strong> {{ record.created_by }}</p>
            </template>

            <template v-if="record.created_at">
                <p class="m-0"><strong>Created At:</strong> {{ record.created_at }}</p>
            </template>
            
          </div><!--Col-->
  
        </div><!--Row-->
    </div>
  </template>
  <script>
  import queries from '../mixins/queries';
  
  export default {
  
      mixins: [ queries ],
  
      data () {
          return {
              processing: false,
              record: {}
            }
      },
  
  
      created () {
          this.getRecord();
      },
  
      methods: {

          getRecord () {
  
              this.processing = true;
              this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
              .then(response => {
                  if(response && response.data) {
  
                      this.record = response.data;
    
                      this.processing = false;
    
                  }
              })
          }
  
  
      }
  }
  </script>   