<template>
  <div>
    <a
      :href="'/'+url+'?client='+client"
      class="btn btn-secondary btn-sm"
    >{{ total }}</a>
  </div>
</template>
<script>
export default {

props: ['client', 'url'],

data() {
    return {
        total: 0
    }
},

created() {
    this.getTotal();
},

methods: {

    getTotal() {
        this.$http.get('/'+this.url+'/total?client='+this.client)
        .then(response => {
            if(response && response.status === 200) {
                this.total = response.data;
            }
        })
    }

}

}
</script>