<template>
  <div>

    <div class="btn-group">

        <a :href="'http://maps.apple.com/?address='+this.address" 
        class="btn btn-outline-secondary"
        target="_blank">
        Apple Maps</a>

        <a :href="'https://www.google.com/maps/search/?api=1&query='+this.address" 
        class="btn btn-outline-secondary"
        target="_blank">Google Maps</a>

    </div>

  </div>
</template>
<script>
export default {

props: ['address'],

data() {
    return {

    }
},

created() {
},

methods: {


}

}
</script>