export default {
  methods: {
    parseJSON(str) {
      try {
        if (typeof str === "undefined" || str === null || str === "") {
          return str;
        }
        return JSON.parse(str);
      } catch (error) {
        return str;
      }
    }

  }

}