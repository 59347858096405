<template>
  <div>
    <a
      :href="'/messages?event='+event"
      class="btn btn-outline-secondary btn-sm"
    >{{ total }}</a>
  </div>
</template>
<script>
export default {

props: ['event'],

data() {
    return {
        total: 0
    }
},

created() {
    this.getTotal();
},

methods: {

    getTotal() {
        this.$http.get('/messages/total?event='+this.event)
        .then(response => {
            if(response && response.status === 200) {
                this.total = response.data;
            }
        })
    }

}

}
</script>