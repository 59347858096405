<template>
  <div>

    <page-tabs page="message_templates" />

    <div class="row">
      <div class="col-lg-3 col-md-12 mb-3">

        <div class="card">
          <div class="card-body">

            <p class="card-title">
              Add {{ $route.meta.title }}
            </p>

            <form
              @submit.prevent="postForm"
            >
              <div class="form-group">
                <label for="name">{{ $route.meta.title }} Name</label>
                <input
                  id="name"
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  required
                >
              </div>
       
              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >
                Add
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">

            <div class="row">
              <div class="col-sm-12 col-md-6">
                <p class="card-title">
                  Search {{ $route.meta.title }}s
                </p>
            </div><!--Col-->

            <div class="col-sm-12 col-md-6 text-right">
              <button
                type="button"
                class="btn btn-secondary btn-sm"
                @click="resetSearch()"
                :disabled="processing"
                :hidden="!searched"
              >
                Reset
              </button>
            </div><!--Col-->
          </div><!--Row-->

            <form @submit.prevent="searchRecords()">

              <div class="form-group">
                <label for="search">Search</label>
                <input
                  id="search"
                  v-model="queries.search"
                  class="form-control"
                  type="search"
                  placeholder="Search templates..."
                >
              </div>

              <div class="form-group">
                <label for="name">Name</label>
                <input
                  id="name"
                  v-model="queries.name"
                  class="form-control"
                  type="text"
                  placeholder="Name..."
                  :disabled="processing"
                >
              </div>

              <div class="form-group">
                <label for="search_trigger">Trigger</label>
                <select id="search_trigger" class="form-control" v-model="queries.trigger" 
                @change="searchRecords()"
                :disabled="processing">
                  <option value=""></option>
                  <template v-for="trigger in triggers">
                    <option :value="trigger.value">{{ trigger.label }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_value">Field Value</label>
                <select id="search_value" class="form-control" v-model="queries.value" :disabled="processing">
                  <option value=""></option>
                  <template v-for="status in statuses">
                    <option :value="status.name">{{ status.name }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="search_simulate"
                    v-model="queries.simulate"
                    type="checkbox"
                    class="custom-control-input"
                    @change="searchRecords()"
                    :disabled="processing"
                >
                <label
                    class="custom-control-label"
                    for="search_simulate"
                >Simulate Mode</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="search_trashed"
                  v-model="queries.trashed"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="processing"
                  @change="searchRecords()"
                >
                <label
                  class="custom-control-label"
                  for="search_trashed"
                >Trashed</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="search_archived"
                  v-model="queries.archived"
                  type="checkbox"
                  class="custom-control-input"
                  :disabled="processing"
                  @change="searchRecords()"
                >
                <label
                  class="custom-control-label"
                  for="search_archived"
                >Archived</label>
              </div>

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->
      <div id="main" class="col-lg-9 col-md-12">

        <div class="card">
          <div class="card-body">

            <div class="row">

              <div class="col-sm-12 col-md-6">

                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-right">

                <div class="btn-group d-print-none" data-html2canvas-ignore>
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                  <button type="button" class="btn btn-secondary" :class="{'active': layout === 'table'}" @click="setLayout('table')" 
                  data-toggle="tooltip" data-placement="top" data-tooltip="Table view" data-html2canvas-ignore                  
                  :disabled="processing"><i class="fa fa-table"></i> <span class="sr-only">Table</span></button>
                  <button type="button" class="btn btn-secondary" :class="{'active': layout === 'grid'}" @click="setLayout('grid')" 
                  data-toggle="tooltip" data-placement="top" data-tooltip="Grid view" data-html2canvas-ignore
                  :disabled="processing"><i class="fa fa-grid"></i> <span class="sr-only">Grid</span></button>
                </div>
              </div><!--Col-->

            </div><!--Row-->


            <div class="table-responsive my-3" v-if="layout == 'table'">

            <table class="table table-striped table-hover">
              <sort-columns
                :columns="columns"
                :queries="queries"
                :processing="processing"
                :base_url="$route.meta.base_url"
              />

              <tbody>
                <template v-for="record in records">
                  <tr>
                    <td>
                      <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid }}">
                        {{ record.name }}
                      </router-link>
                    </td>
                    <td>
                      <template v-if="record.trigger">
                        {{ filterTrigger(record.trigger) }}
                      </template>
                    </td>
                    <td>
                      {{ setSendMode(record) }}
                    </td>
                    <td>
                      {{  setRecordDelay(record.delay) }}
                    </td>
                      <td>{{ record.created_by }}</td>
                    <td>{{ record.created_at | datetime }}

                      <template v-if="record.deleted_at">
                        <span class="badge badge-pill badge-danger">TRASHED</span>
                      </template>
                    </td>
                    <td>{{ record.updated_at | datetime }}</td>
                  </tr>
                </template>
              </tbody>
            </table>

              </div><!--Table responsive-->

            <template v-if="layout == 'grid'">

              <div class="row my-3">

              <template v-for="(record, record_grid) in records">

                <div class="col-sm-12 col-md-4 mb-3">

                    <div class="card">
                      <div class="card-body">

                        <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid }}"
                          class="card-title"
                        >
                          <i class="fa fa-edit"></i> Edit {{ record.name }}
                        </router-link>

                        <hr class="my-3">

                        <template v-if="record.simulate == 1">
                          <div class="alert alert-primary" :hidden="processing">
                            <i class="fa fa-check-double"></i> Simulate Mode
                          </div>
                        </template>

                        <p class="lead mb-0">Templates</p>

                        <template v-if="record.subject">
                          <p class="m-0">
                            <strong>Subject:</strong>
                             {{ record.subject }}
                          </p>
                        </template>

                        <template v-if="!record.subject">
                          <div class="alert alert-warning" :hidden="processing">
                            <i class="fa fa-exclamation-triangle"></i> No subject found.
                          </div>
                        </template>

                        <template v-if="record.templates">

                          <template v-if="!record.templates['en']">
                            <div class="alert alert-danger" :hidden="processing">
                              <i class="fa fa-exclamation-triangle"></i> No English template found.
                            </div>
                          </template>


                          <template v-for="(template, template_index) in record.templates">
                            <p class="mb-0" :hidden="!template">
                              <strong>{{ setLanguageName(template_index) }}:</strong>
                            </p>
                            <template v-if="template">
                              <p class="outbound" :class="setValidateClass(template)">{{ template }}</p>
                            </template>
                          </template>
                        </template>

                        <template v-if="!record.templates || record.templates.length === 0">
                          <div class="alert alert-warning" :hidden="processing">
                            No templates found.
                          </div>
                        </template>


                        <template v-if="record.file_url && setFileName(record.file_url)">
                          <p class="m-0">
                            <strong>File:</strong> <a :href="'/files?url='+record.file_url" target="_blank">{{ setFileName(record.file_url) }}</a>
                          </p>
                        </template>

                        <hr class="my-3">

                        <p class="lead mb-0">Settings</p>

                        <template v-if="record.trigger">
                          <p class="m-0">
                            <strong>Trigger:</strong> {{ filterTrigger(record.trigger) }}
                          </p>

                          <template v-if="record.value">
                            when field is <span class="badge badge-pill badge-primary">{{ record.value }}</span>
                          </template>
                        </template>

                        <p class="m-0">
                          <strong>Delay:</strong> {{ setRecordDelay(record.delay) }}
                        </p>

                        <p class="m-0"><strong>Mode:</strong>  {{ setSendMode(record) }}</p>

                        <template v-if="record.future_events == 1">
                          <span class="badge badge-pill badge-primary">Future Date Required</span>
                        </template>


                      </div><!--Body-->
                    </div><!--Card-->

                </div><!--Col-->

              </template>

              </div><!--Row-->

            </template>

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->


        <template v-if="records && records.length && total > records.length">
          <div class="row">
            <div class="col-12 text-center">
              <button
                type="button"
                ref="loadMoreButton"
                class="btn btn-outline-primary mt-3"
                :disabled="processing"
                @click="loadMore"
              >
                Load more
              </button>
            </div>
          </div>
        </template>


      </div><!--Col-->
    </div><!--Row-->

  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {
  
  mixins: [ queries ],

  data () {
    return {
      records: [],
      downloads: [],
      processing: false,
      searched: false,
      downloading: false,
      layout: 'table',
      total: 0,
      queries: {},
      form: {},
      columns: [
          {key: 'name', label: 'Name'},
          {key: 'trigger', label: 'Trigger'},
          {key: 'simulate', label: 'Simulate'},
          {key: 'delay', label: 'Delay'},
          {key: 'created_by', label: 'Creator'},
          {key: 'created_at', label: 'Created'},
          {key: 'updated_at', label: 'Updated'}
      ],
      triggers: [],
      languages: [],
      statuses: [],
      merge_tags: [],
      files: []
    }
  },

  mounted() {

    var self = this;

    this.$eventBus.$on('scrolling', function(data) {
        self.shouldLoadMore();
    });

    this.$eventBus.$on('refreshRecords', function(view) {

      if(view !== self.$route.meta.base_url) {
          return;
      }

      self.resetRecords();
      self.getRecords();

    });
  },

  created () {
    this.resetRecords();
    this.resetQueries();
    this.resetForm();
    this.setURLParams();
    this.getTriggers();
    this.getLanguages();
    this.getRecords();
    this.getStatuses();
    this.setDefaultLayout();
    this.getMergeTags();
    this.getFiles();
  },

  methods: {

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {

          if(key == 'skip') {
            return;
          }

            self.queries[key] = value;
        });
    },

    resetRecords() {
      this.records = [];
      this.queries.skip = 0;
      this.total = 0;
    },

    searchRecords() {
      this.searched = true;
      this.resetRecords();
      this.getRecords();
    },

    resetSearch() {
      this.searched = false;
      this.resetQueries();
      this.resetRecords();
      this.getRecords();
    },

    resetForm() {
      this.form = {
        name: null,
        trigger: 'manual',
        templates: {
          'en': 'New message'
        }
      }
    },

    resetQueries() {
      this.queries = {
        limit: 10,
        skip: 0,
        sort: 'created_at',
        sortDirection: 'desc',
        trigger: null,
        value: null,
        search: null,
        trashed: false,
        archived: false,
        simulate: false
      }
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    loadMore() {
      this.getRecords();
    },

    getRecords () {

      this.processing = true;

      this.updateURL();

      this.totalRecords();

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
      .then(response => {
        if (response && response.data) {

          this.processing = false;

          if(response.data.length) {

            this.queries.skip += response.data.length;

            var self = this;

            response.data.forEach(function(data) {
              self.records.push(data);
            });

          }
        }
      })
    },

    totalRecords() {
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
      .then(response => {
        if(response && response.status === 200) {
          this.total = response.data;
          this.setTitle();
        }
      })
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    postForm() {
        this.processing = true;

        this.$http.post('/'+this.$route.meta.base_url, this.form)
        .then(response => {
            if(response && response.status) {

              this.processing = false;

                if(response.status === 201) {
                  this.resetForm();
                  this.total++;
                  this.setTitle();
                  this.records.unshift(response.data);
                  new this.$noty({text: this.$route.meta.title + ' added'}).show();
                  window.location.href = '/'+this.$route.meta.base_url+'/'+response.data.uuid;
                }


            }
        });
    },

    filterTrigger(trigger) {

        var result = _.find(this.triggers, {'value': trigger});

        if(!result) {
          return 'manual';
        }

        return result.label;
    },

    getTriggers() {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/options.json')
      .then(response => {
        if(response && response.data && response.data.auto_message_triggers) {
          this.triggers = response.data.auto_message_triggers;
        }
      })
    },

    getStatuses() {
      this.$http.get('/statuses?limit=100&fields=name&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.statuses = response.data;
        }
      })
    },

    getLanguages () {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/languages.json')
          .then(response => {
            if(response && response.data) {
              this.languages = response.data;
            }
          })
    },

    setDefaultLayout() {
      var preferences = localStorage.getItem('preferences');

      if(preferences) {
        preferences = JSON.parse(preferences);
        this.layout = preferences['default_layout'] || this.layout;
      }

    },

    setLayout(layout) {
      this.$eventBus.$emit('updatePreferences', {'default_layout': layout})
      this.layout = layout;
    },

    setLanguageName(language) {

      if(!language) {
        return;
      }

      var language = _.find(this.languages, {'key': language});

      if(!language || !language.label) {
        return language;
      }

      return language.label;
    },

    shouldLoadMore() {

    if(this.processing) {
      return;
    }

    if(this.total <= this.records.length) {
      return;
    }

    const loadMoreButton = this.$refs.loadMoreButton;

    if(!loadMoreButton) {
      return;
    }

    const rect = loadMoreButton.getBoundingClientRect();
    if (rect.top <= window.innerHeight) {
      this.loadMore();
      return;
    }
    },

    setFileName(url) {

      if(!url) {
        return;
      }

      var file = _.find(this.files, {'url': url});

      if(!file) {
        return;
      }

      return file.name;
    },

    setRecordDelay(delay) {
      if(delay) {
        return delay + 'm';
      }

      if(delay === 0) {
        return 'Immediate';
      }

      if(this.settings && this.settings.message_delay) {
        return this.settings.message_delay + 'm';
      }

      return 'Immediate';
    },

    setSendMode(record) {

      if(record.simulate == 1) {
        return 'Simulate Mode';
      }

      if(!this.$root.current_region || this.$root.current_region != 'staging') {
        return 'Live Mode';
      }

      return 'Simulate Mode';

    },

    getMergeTags () {
        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/mergetags.json')
            .then(response => {
              if(response && response.data) {
                this.merge_tags = response.data;
              }
            })
    },

    getFiles() {
        this.$http.get('/files?slim=true&fields=name,url')
        .then(response => {
            if(response && response.data) {
                this.files = response.data;
            }
        })
    },

    setValidateClass(value) {

    if(!value) {
      return;
    }

    var tags = this.extractMergeTags(value);

    if(!tags) {
      return;
    }

    var values = this.merge_tags.map(item => item['value']);

    values = values.map(item => item.replace(/\[|\]/g, ''));

    function hasInvalidTags(tags, values) {
      return tags.some(item => !values.includes(item));
    }

    if(hasInvalidTags(tags, values)) {
      return 'bg-danger';
    }

    },

    extractMergeTags(value) {
    const mergeTagRegex = /\[([^\]]+)\]/g;
    const mergeTags = [];
    let match;

    while ((match = mergeTagRegex.exec(value)) !== null) {
      mergeTags.push(match[1].replace(/%.*?%/g, '').trim());
    }

    return mergeTags;
    },

  }
}
</script>
