<template>
  <div>
    <div
      class="row mb-1"
      :class="isPrivacyMode ? 'privacy-mode' : ''"
    >
      <div class="col-12">

        <template v-if="$route.params.id && $route.params.id == message.uuid">

          <p class="m-0" :class="getClass(message)"
           :dir="setLangDirection()"
          >
          <template v-if="translate && translate.content">
            <span class="text-white">{{ translate.content }}</span>
          </template>

          <template v-if="!translate || !translate.content">

            <template v-if="message.subject">
              <em>{{ message.subject }}</em><br>
            </template>

          <template v-if="message.content">
            <span v-html="filterContent(message.content)"></span>
          </template>

          </template>

          </p>

        </template>

        <template v-else>

        <button type="button" @click="goToMessage(message)"
          :class="getClass(message)"
          style="width:100%; text-align:left;border:0"
          :dir="setLangDirection()"
        >

          <template v-if="translate && translate.content">
            <span class="text-white">{{ translate.content }}</span>
          </template>

          <template v-else>

          <template v-if="message.subject">
            <em>{{ message.subject }}</em><br>
          </template>

          <template v-if="message.content">
            <span v-html="filterContent(message.content)"></span>
          </template>

          </template>

        </button>

        </template>
        
      </div><!--Col-->
    </div><!--Row-->

    <div
      class="row mb-3 mt-1"
    >
      <div class="col-sm-12 col-md-9">

        <template v-if="message.last_status && !message.error_code">
          <a
            :href="'/messages/'+message.uuid"
            :class="setStatusClass(message.last_status)"
            class="badge-pill text-uppercase link-hover mr-1"
          >
            {{ message.last_status }}
          </a>
        </template>

          <template v-if="message.last_status && message.error_code">
            <span class="badge badge-pill badge-danger mr-1"><span class="text-uppercase">{{ message.last_status }}</span> {{ setErrorCode(message.error_code) }}</span>
          </template>


        <template v-if="message.uuid && !message.last_status && message.direction == 'outbound'">
          <output class="spinner-grow spinner-grow-sm text-primary">
            <span class="sr-only">Loading...</span>
          </output>
        </template>

        <template v-if="message.test === 1">
          <span class="badge badge-pill badge-primary mr-1">SIMULATED</span>
        </template>
                            
        <template v-if="message.mms && message.mms > 0">
          <span class="badge badge-primary mr-1"><i class="fa fa-photo-video" /> MEDIA</span>
        </template>

        <template v-if="message.last_open">
          <button type="button" @click="getLastOpen" class="btn btn-link btn-sm p-0 ml-1">
            <span class="badge badge-pill badge-success">                                    
              <i class="fa fa-eye"></i>
              OPENED
              {{ message.last_open | dateTimezone | timeago }}
            </span>
          </button>
        </template>

        <strong class="mr-1">From</strong>

        <template v-if="message.direction == 'outbound' && message.channel == 'email'">
          <a :href="'/agency_emails/'+message.from" class="mr-1">{{ message.from }}</a>
        </template>

        <template v-if="message.direction == 'outbound' && message.channel != 'email'">
          <a :href="'/agency_phones/'+message.from" class="mr-1">{{ message.from }}</a>
        </template>

        <template v-if="message.direction == 'inbound'">
          <a :href="'/messages?search_field=from&search='+message.from" class="mr-1">{{ message.from }}</a>
        </template>

                                
        <strong class="mr-1">To</strong>

        <template v-if="message.direction == 'inbound' && message.channel == 'email'">
          <a :href="'/agency_emails/'+message.to" class="mr-1">{{ message.to }}</a>
        </template>

        <template v-if="message.direction == 'inbound' && message.channel != 'email'">
          <a :href="'/agency_phones/'+message.to" class="mr-1">{{ message.to }}</a>
        </template>

        <template v-if="message.direction == 'outbound'">
          <a :href="'/messages?search_field=to&search='+message.to" class="mr-1"
            :class="isPrivacyMode ? 'privacy-mode' : ''"
          >{{ message.to }}</a>
        </template>

        {{ message.scheduled_at | dateTimezone | timeago }}

      </div><!--Col-->

      <div class="col-sm-12 col-md-3 text-right">
        <template v-if="message.client && $route.name != 'clients.edit'">
          <template v-if="message.first_name && message.last_name">
            <a :href="'/clients/'+message.client.uuid" class="mr-1">{{ message.client_reference }} {{ message.first_name }} {{ message.last_name }}</a>
          </template>
          <template v-else>
            <client-button :uuid="message.client" />
          </template>
        </template>

        <template v-if="message.direction && message.direction == 'inbound'">
          <template v-if="detect_language && detect_language != 'en'">
          <button type="button" class="btn btn-link btn-sm" @click="postTranslate()" :disabled="processing">
            <i class="fa fa-language"></i> Translate {{ setLanguage(detect_language) }}
          </button>
          </template>
        </template>

      </div>
    </div><!--Row-->       
  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {

    mixins: [ queries ],  
    props: [ 'message' ],

    data() {
    return {        
        processing: false,
        translate: '',
        detect_language: '',
      }
    },

    mounted() {

      var self = this;
  
      this.$eventBus.$on('message_statuses', function (data) {
          self.updateRecord(data);
      });


      this.$eventBus.$on('opens', function(data) {
          self.addNewOpen(data);
      });

      // this.attachContent();

      },

      created() {
        this.detectLanguage();
      },

methods: {

    setStatusClass(status) {

       status = status.toLowerCase();

        if(_.includes(['opted-out', 'undelivered', 'failed', 'duplicate', 'bounced', 'missed', 'invalid', 'busy', 'timeout', 'cancel', 'rejected'], status)) {
            return 'badge badge-danger';
        }
        if(_.includes(['delivered', 'opened', 'answered', 'hangup'], status)) {
            return 'badge badge-success';
        }
        if(_.includes(['dispatched', 'sent'], status)) {
            return 'badge badge-primary';
        }
        return 'badge badge-primary';
    }, 

    getClass(message) {

      var result = '';

      if(this.isPrivacyMode) {
        result += 'privacy-mode ';
      }

        if(message && message.direction) {
            result += message.direction;
        } 

        return result;
      },

    addNewOpen(data) {

      if(!data.message) {
        return;
      }

      if(data.message != this.message.uuid) {
        return;
      }

      if(data.created_at) {
        this.message.last_open = data.created_at;
      }
    },

    getLastOpen() {
      if(!this.message || !this.message.last_open) {
        return;
      }

      this.processing = true;

      this.$http.get('/opens?message='+this.message.uuid+'&limit=1&fields=uuid&sort=created_at&sortDirection=desc')
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.data[0] && response.data[0].uuid) {
            window.open('/opens/'+response.data[0].uuid, '_blank');
          }
        }
      })
    },

    updateRecord(data) {

      if(!data.uuid) {
        return;
      }

      if(data.uuid != this.message.uuid) {
        return;
      }

      this.message = {...this.message, ...data};
      
    },

    setLangDirection() {

      let language = 'en';

      if(this.message && this.message.language) {
        language = this.message.language;
      }

      if(this.detect_language) {
        language = this.detect_language;
      }

      if(this.$root.rtl_langs.includes(language)) {
        return 'rtl';
      }

      return 'ltr';
    },

    setErrorCode(code) {

      if(!code) {
        return;
      }

      if(!this.$root['error_codes']) {
        return;
      }

      if(!this.$root['error_codes'][code]) {
        return;
      }

      return this.$root['error_codes'][code];
    },

    filterContent(content) {
        
        if(!content) {
          return;
        }
        
        return this.$DOMPurify.sanitize(content);
    },

    goToMessage(message) {

      if(this.$route.params.id && this.$route.params.id == message.uuid) {
        return;
      }

      window.location.href = '/messages/'+message.uuid;
    },

    detectLanguage() {

      this.resetTranslate();

      if(!this.message || !this.message.content) {
        return;
      }

      if(this.$route.params.id && this.$route.params.id != this.message.uuid) {
        return;
      }

      this.translate = this.message.content;

      if(this.message.subject) {
        this.translate = this.message.subject + ' ' + this.translate;
      }

      this.translate = this.translate.replace(/<[^>]*>?/gm, '');

      this.processing = true;
      this.$http.post('/translator/detect', {content: this.translate})
      .then(response => {
        if(response && response.data) {
          this.processing = false;
          if(response.data.language) {
            this.detect_language = response.data.language;

            if(this.detect_language == 'en') {
              return;
            };

          }
        }
      })
    },

    postTranslate() {

      if(this.translate && this.translate.content) {
        this.detectLanguage();
        return;
      }

      this.processing = true;

      this.$http.post('/translator', {content: this.translate, source: this.detect_language, target: 'en'})
      .then(response => {
        if(response && response.data) {
          this.processing = false;

          if(response.data.content) {
            this.translate = response.data;
          }
        }
      })
    },

    resetTranslate() {
      this.translate = '';
      this.detect_language = '';
    }
}
}
</script>