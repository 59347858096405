<template>
    <div>
  
      <archived-trashed-status
        :form="form"
        :processing="processing"
        :title="$route.meta.title"
        />
          
      <div class="row">
        <div class="col-sm-12 col-md-8">  
            <h3>Assigned Task</h3>

            <p><router-link :to="{name: 'tasks.index'}">Tasks</router-link> / Edit Task</p>
        </div><!--Col-->
  
        <div class="col-sm-12 col-md-4 text-right">
          <div class="btn-group">
  
  
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-outline-primary dropdown-toggle radius-right-0"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Tools
                </button>
                <div class="dropdown-menu">
  
                  <a
                    v-if="!form.archived_at && !form.deleted_at"
                    class="dropdown-item"
                    @click="archiveRecord()"
                  >Archive</a>
  
                  <a
                    v-if="!form.deleted_at && !form.archived_at"
                    class="dropdown-item"
                    @click="trashRecord()"
                  >Trash</a>
  
                  <a
                    v-if="form.deleted_at || form.archived_at"
                    class="dropdown-item"
                    @click="restoreRecord()"
                  >Restore</a>
  
                </div>
            </div><!--BtnGroup-->
              
            <button
              v-if="!form.archived_at && !form.deleted_at"
              type="button"
              class="btn btn-success"
              :disabled="processing"
              @click="postForm()"
            >
              Save
            </button>
  
            <router-link
              :to="{ name: 'tasks.index' }"
              class="btn btn-danger"
            >
              Close
            </router-link>
  
          </div>
        </div><!--Col-->
      </div><!--Row-->
  
      <div class="row">
        <div class="col-sm-12 col-md-4">
  
          <div class="card">
            <div class="card-body">
  
              <p class="card-title">Edit</p>
  
              <form @submit.prevent="postForm()">
                
                <label for="client">Task Content</label>
                <editor 
                id="content"
                ref="content"
                class="mb-3"
                :options="editorOptions"
                initialEditType="wysiwyg"
                @change="updateContent"
                />

                <div class="form-group">
                    <label for="due_at" class="form-label">Due Date</label>
                    <input id="due_at" type="date" class="form-control" v-model="form.due_at">
                </div>

                <div class="form-group">
                  <label for="flow">Flow</label>
                  <select id="flow" class="form-control" v-model="form.flow" :disabled="processing">
                    <option value=""></option>
                    <template v-for="flow in flows">
                      <option :value="flow.uuid">{{ flow.name }}</option>
                    </template>
                  </select>
                </div>

                <template v-if="!processing && flows && flows.length === 0">
                    <div class="alert alert-primary">No task flows found. <a href="/flows" target="_blank">Create a flow</a></div>
                </template>

                <div class="form-group">
                  <label for="status">Status</label>
                  <select id="status" class="form-control text-uppercase" v-model="form.status">
                    <option value=""></option>
                    <template v-for="status in statuses">
                      <option :value="status">{{ status }}</option>
                    </template>
                  </select>
                </div>
  
                <button type="submit" class="btn btn-success" :disabled="processing">Save</button>
  
              </form>
  
            </div><!--Body-->
          </div><!--Card-->
  
          <audit-logs :form="form" class="mt-3" />
  
        </div><!--Col-->
        <div class="col-sm-12 col-md-8">
  
  
          <div class="row">
            <div class="col-sm-12 col-md-6">
  
          <template v-if="form.client">
            <client-card
              :client="form.client"
            />
          </template>
  
            </div><!--Col-->
  
            <div class="col-sm-12 col-md-6">
  
              <p class="card-title">{{ records.length }} Tasks</p>
  
              <template v-if="records && records.length > 0">

                <ul class="list-group">
  
                <template v-for="record in records">
  
                  <li class="list-group-item my-3"
                  :class="{'active': record.uuid === form.uuid}"
                  >

                    <template v-if="record.status">
                      <p class="badge badge-primary text-uppercase">{{ record.status }}</p>
                    </template>

                    <p v-html="record.content"></p>
                    
                    <template v-if="record.uuid !== form.uuid">
                      <a :href="'/tasks/'+record.uuid" class="btn btn-sm btn-primary mb-3">View</a>
                    </template>
                  <p class="small m-0">{{ record.created_by }} created {{ record.created_at | dateTimezone | timeago }}</p>
                  </li>
  
                </template>

                </ul><!--ListGroup-->
  
              </template>
  
  
            </div><!--Col-->
          </div><!--Row-->
        </div><!--Col-->
      </div><!--Row-->
    </div><!--Container-->
  </template>
  <script>
  import queries from '../mixins/queries';
  export default {
      
      mixins: [ queries ],
  
      data () {
          return {
              processing: false,
              form: {},
              records: [],
              flows: [],
              statuses: ['open', 'closed', 'pending', 'in progress', 'review', 'completed'],
              editorOptions: {
                minHeight: '200px',
                language: 'en-US',
                useCommandShortcut: false,
                usageStatistics: false,
                hideModeSwitch: true,
                previewStyle: 'vertical',
                useCommandShortcut: true,
                toolbarItems: [
                ['link', 'heading', 'bold', 'italic', 'strike'],
                ['hr', 'quote'],
                ['ul', 'ol', 'task', 'indent', 'outdent'],
                ['table', 'image']
                ]
            }
          }
      },
  
      created() {
        this.resetForm();
        this.getRecord();
      },
  
  
      methods: {
  
        resetForm() {
          this.form = {
            client: null,
            content: null,
            due_at: null,
            status: null,
            flow: null
          }
        },
  
        getRecord() {
          this.processing = true;
          this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
          .then(response => {
            if(response && response.data) {
              this.form = response.data;
              this.processing = false;

                this.$refs['content'].invoke('setHTML', this.form.content);
  
              this.getRecords();
            }
          })
        },

        updateContent() {
            this.form.content = this.$refs['content'].invoke('getHTML');
        },
  
        getRecords() {
  
          if(!this.form.client) {
            return;
          }
  
          var queries = {
            client: this.form.client,
            sort: 'created_at',
            sortDirection: 'desc',
            limit: 1000,
            slim: true
          }
  
          this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, queries))
          .then(response => {
            if(response && response.data) {
              this.records = response.data;
            }
          })
        },
  
        postForm() {
  
          if(!this.form.uuid) {
            return;
          }
  
          this.processing = true;
          this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
          .then(response => {
            if(response && response.status) {
  
  
              if(response.data.updated_at) {
                new this.$noty({text: this.$route.meta.title + ' updated'}).show();
                this.form.updated_at = response.data.updated_at;
              }
  
              this.processing = false;
  
            }
          })
        },
  
        trashRecord() {
            this.processing = true;
            this.$http.delete('/'+this.$route.meta.base_url+'/'+this.form.uuid)
            .then(response => {
                if(response && response.status === 204) {
                    new this.$noty({text: this.$route.meta.title + ' deleted', type: 'error'}).show();
                    this.form.deleted_at = new Date().toISOString();
                }
            })
        },
  
        archiveRecord() {
            this.processing = true;
            this.$http.put('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/archive')
            .then(response => {
                if(response && response.status === 200) {
                    new this.$noty({text: this.$route.meta.title + ' archived', type: 'error'}).show();
                    this.form.archived_at = new Date().toISOString();
                }
            })
        },
  
        restoreRecord () {
            this.processing = true;
            this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
            .then(response => {
                if (response && response.data) {
  
                    this.processing = false;
  
                    if(response.status === 200) {
                        new this.$noty({text: this.$route.meta.title + ' restored'}).show();
                        this.form.deleted_at = null;
                        this.form.archived_at = null;
                    }
                    
                }
            })
        },

        getFlows() {
            this.$http.get('/flows?slim=true&fields=name,uuid&limit=100&sort=name&sortDirection=asc&type=task')
            .then(response => {
            if(response && response.data) {
                this.flows = response.data;
            }
            })
        },
          
  
      }
  }
  </script>
  