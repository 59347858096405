<template>
  <div>
    <div class="card" v-if="!processing && records && records.length > 0">
      <div class="card-body">

          <p class="card-title">
            {{ records.length }} Opts
          </p>


          <template v-for="record in records">

            <button 
            type="button" 
            class="list-group-item list-group-item-action" 
            @click="toggleRecord(record)"
            >

              {{ record.reason }}

              {{ record.created_at | datetime }}

            </button>

          </template>

          <template v-if="show_record === true">

            <p class="my-3">Opt</p>

            <ul class="list-group">

            <template v-if="current_record.type">
              <li class="list-group-item"><strong>Type</strong> {{ current_record.type }}</li>
            </template>

            <template v-if="current_record.reason">
              <li class="list-group-item"><strong>Reason</strong> {{ current_record.reason }}</li>
            </template>

            <template v-if="current_record.comments">
              <li class="list-group-item"><strong>Comments</strong> {{ current_record.comments }}</li>
            </template>


            <template v-if="current_record.user_agent">
              <li class="list-group-item"><strong>User Agent</strong> {{ current_record.user_agent }}</li>
            </template>

            <template v-if="current_record.ip_address">
              <li class="list-group-item"><strong>IP Address</strong> <a :href="'/ips/'+current_record.ip_address">{{ current_record.ip_address }}</a></li>
            </template>

            <template v-if="current_record.created_at">
              <li class="list-group-item"><strong>Created</strong> {{ current_record.created_at | datetime }}</li>
            </template>

            </ul>

          </template>
          
      </div><!--Body-->
    </div><!--Card-->
  </div>
</template>
<script>
export default {

props: ['contact'],

data() {
    return {
        processing: false,
        show_record: false,
        records: [],
        base_url: '/opts',
        current_record: {}
    }
},

created() {
    this.getRecords();
},

methods: {

    toggleRecord(record) {
      this.show_record = this.show_record == false ? true : false;

      this.current_record = record;
    },       

    getRecords() {
        
        if(!this.contact) {
            return;
        }

        this.processing = true;

        this.$http.get(this.base_url+'?contact='+this.contact+'&sort=created_at&sortDirection=desc')
        .then(response => {
            if(response && response.data) {
                this.records = response.data;
                this.processing = false;
            }
        })
    },

}

}
</script>