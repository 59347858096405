<template>
  <div>
    <div class="card mt-3">
      <div
        class="card-body"
        style="max-height: 500px;overflow-y:auto"
      >
        <p class="card-title">
          Upcoming Schedules
        </p>

        <form @submit.prevent="getUpcomingSchedules">
          <div class="form-group">
            <label for="schedules_start_date">Start Date</label>
            <input
              id="schedules_start_date"
              v-model="form.start_date"
              type="date"
              class="form-control"
            >
          </div>

          <div class="form-group">
            <label for="max_days">Max Days</label>
            <input
              id="max_days"
              v-model="form.max_days"
              type="number"
              class="form-control"
              min="1"
              max="7"
            >
          </div>

          <button
            type="submit"
            class="btn btn-outline-primary btn-block"
            :disabled="processing"
          >
            List Schedules
          </button>
        </form>

        <ul class="list-group mt-3">
          <template v-if="upcoming_schedules && upcoming_schedules.length > 0">
            <template v-for="upcoming_schedule in upcoming_schedules">
              <li
                class="list-group-item"
              >
                {{ upcoming_schedule.start_date }}

                <template v-if="upcoming_schedule.colors && upcoming_schedule.colors.length > 0">
                  <template v-for="color in upcoming_schedule.colors">
                    <small
                      class="text-uppercase"
                    ><i
                      class="fa fa-circle"
                      :style="'color:' + color"
                    /> {{ color }}</small>
                  </template>
                </template>
              </li>
            </template>
          </template>
        </ul>
      </div><!--Body-->
    </div><!--Card-->
  </div>
</template>

<script>

export default {

data() {
    return {
        processing: false,
        base_url: 'schedules',
        upcoming_schedules: [],
        form: {
            start_date: '',
            max_days: 1
        }
    }
},

created() {
    this.getUpcomingSchedules();
},

methods: {


    getUpcomingSchedules() {
        this.processing = true;
        this.$http.get(this.base_url+'/upcoming?start_date='+this.form.start_date+'&max_days='+this.form.max_days)
        .then(response => {
            if(response && response.data) {
                this.upcoming_schedules = response.data;
                this.processing = false;
            }
        })
    }
}

}

</script>