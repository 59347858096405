<template>
  <div>

    <archived-trashed-status
        :form="form"
        :processing="processing"
        :title="$route.meta.title"
    />
    <div class="row my-3">

      <div class="col-sm-12 col-md-4">
        <h1>{{ form.name }}</h1>
        <template v-if="form.status">
          <span class="badge text-uppercase" :class="setStatusClass(form.status)">{{ form.status | rmDashes }}</span>
        </template>

        <p class="my-1">
          <a href="/events">Events</a> /
          <router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-2">

        <div class="btn-group">

        <template v-if="portal && portal.link && portal.domain">
          <a
              :href="portalLink"
              target="_blank"
              class="btn btn-outline-primary"
          ><i class="fas fa-external-link-alt"></i>
          Open Portal</a>

        </template>

        </div>

      </div><!--Col-->

      <div class="col-sm-12 col-md-2">
        <button type="button" class="btn btn-secondary" @click="getPDF()" :disabled="processing"><i class="fa fa-file-pdf"></i> PDF </button>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4 text-right">
        <div class="btn-group">
          <button
              type="button"
              class="btn btn-outline-primary dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              :disabled="processing"
          >
            Tools
          </button>
          <div class="dropdown-menu">

                <a
                  v-if="form.archived_at || form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord"
                >Restore</a>

                <a
                  v-if="!form.archived_at && !form.deleted_at"
                  class="dropdown-item"
                  @click="archiveRecord"
                >Archive</a>

                <a
                  v-if="!form.deleted_at && !form.archived_at"
                  class="dropdown-item"
                  @click="trashRecord"
                >Trash</a>

          </div>

          <template v-if="!form.archived_at && !form.deleted_at">
            <button
                type="button"
                class="btn btn-success"
                :disabled="processing"
                @click="postForm"
            >
              Save
            </button>
          </template>

          <router-link
              :to="{ name: $route.meta.base_url+'.index' }"
              class="btn btn-danger"
          >
            Close
          </router-link>
        </div><!--BtnGroup-->
      </div><!--Col-->


    </div><!--Row-->

    <div class="row">

      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Edit {{ $route.meta.title }}
            </p>

            <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="name" class="form-label">Name</label>
                <input id="name" type="text" class="form-control" v-model="form.name" required>
              </div>
                          
              <label for="phone" class="form-label m-0">Phone</label><br>
              <div class="input-group phone-input-container mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="phoneCountry">
                    <template v-if="lookup && lookup.country">
                      <img :src="'https://ecdassets.s3.amazonaws.com/images/flags/'+lookup.country+'.svg'" width="18" :alt="lookup.country">
                    </template>
                  </span>
                </div>
                <input id="phone" type="text" class="form-control phone-input" v-model="form.phone" @input="runPhoneLookup" aria-describedby="phoneCountry" />
              </div>

              <div class="form-group">
                <label for="email" class="form-label">Email</label>
                <input id="email" type="email" class="form-control" v-model="form.email">
              </div>

              <div class="form-group">
                <fieldset>
                  <legend>Status</legend>
                  <template v-for="(status, status_key) in $root.checkin_statuses">
                    <label
                        class="mr-2 text-capitalize"
                        :for="'status_'+status_key"
                    >
                      <input
                          :id="'status_'+status_key"
                          v-model="form.status"
                          type="radio"
                          name="status"
                          :value="status_key"
                          @change="postForm"
                      > {{ status }}
                    </label>
                  </template>
                </fieldset>
            </div>

              <div class="form-group">
                <label for="location">Location</label>
                <select
                    id="location"
                    v-model="form.location"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value=""></option>
                  <template v-if="locations && locations.length > 0">
                    <template v-for="location in locations">
                      <option
                          :value="location.uuid"
                      >
                        {{ location.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="service">Service</label>
                <input id="service" type="text" class="form-control" v-model="form.service">
              </div>

              <div class="form-group">
                <label for="department">Department</label>
                <input id="department" type="text" class="form-control" v-model="form.department">
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="virtual"
                    v-model="form.virtual"
                    type="checkbox"
                    class="custom-control-input"
                >
                <label
                    class="custom-control-label"
                    for="virtual"
                >Virtual</label>
              </div>

              <div class="form-group text-capitalize">
                <label for="language">Language</label>
                <select
                    id="language"
                    v-model="form.language"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value=""></option>
                  <option
                      v-for="language in languages"
                      :value="language.key"
                  >
                    {{ language.label }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label for="notes">Notes</label>
                <textarea id="notes" class="form-control" v-model="form.notes"></textarea>
              </div>

              <template v-if="form.data && form.data.length > 0">

                <template v-for="(value, data_key) in form.data">

                  <template v-if="!Array.isArray(value)">

                    <div class="form-group">
                      <label :for="'data_'+data_key" class="form-label">{{ data_key }}</label>
                      <input :id="'data_'+data_key" type="text" class="form-control" v-model="form.data[data_key]">
                    </div>

                  </template>

                </template>
                  
              </template>

              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <template v-if="form.uuid">
          <audit-logs :form="form" class="my-3" />
        </template>

      </div><!--Col-->

      <div class="col-sm-12 col-md-6">

          <div class="card">
            <div class="card-body">

              <template v-if="!form.data || !form.data.geolocation">
                <p class="card-title m-0"><i class="fas fa-map-marker-alt"></i> Geolocation</p>
                <p class="card-text">No geolocation data</p>
              </template>

              <template v-if="form.data && form.data.geolocation">

                <div class="row">

                  <div class="col-sm-12 col-md-6">
                    <template v-if="form.data.geolocation.timestamp">
                      <p class="card-title m-0"><i class="fas fa-map-marker-alt"></i> Geolocation {{ form.data.geolocation.timestamp | timeago }}</p>
                    </template>
                  </div><!--Col-->

                  <div class="col-sm-12 col-md-6 text-end">
                    <div class="btn-group">
                      <template v-if="geolocations && geolocations.length > 0">
                        <a :href="'/geolocations?url='+portalLink" target="_blank" class="btn btn-secondary">{{ geolocations.length }} View Geolocations</a>                      
                      </template>
                      <template v-if="form.data.geolocation && form.data.geolocation.latitude && form.data.geolocation.longitude">
                      <button type="button" class="btn btn-secondary" @click="goToGeoLocation()" :disabled="processing">Go to Last Geo</button>
                      </template>
                      <template v-if="form.data.geolocation_checkin && form.data.geolocation_checkin.latitude && form.data.geolocation_checkin.longitude">
                      <button type="button" class="btn btn-secondary" @click="goToCheckin()" :disabled="processing">Go to Checkin</button>
                      </template>
                      <template v-if="form.data.geolocation_checkout && form.data.geolocation_checkout.latitude && form.data.geolocation_checkout.longitude">
                      <button type="button" class="btn btn-secondary" @click="goToCheckout()" :disabled="processing">Go to Checkout</button>
                      </template>
                    </div>
                  </div><!--Col-->

                </div>

              <template v-if="form.data.geolocation.latitude && form.data.geolocation.longitude">
                <span class="badge badge-primary">Distance {{ calculateDistance() }}</span>
              </template>

              <template v-if="form.data.geolocation.accuracy">
                <span class="badge badge-secondary">Accuracy {{ form.data.geolocation.accuracy | metersFeet }}</span>              
              </template>

              </template>

                <div
                id="geo_map"
                class="map-lg"
                />

          </div><!--Body-->
          </div><!--Card-->

        <template v-if="form.location">
          <location-card :uuid="form.location" class="my-3" />
        </template>
        
      </div><!--Col-->

      <div class="col-sm-12 col-md-3">

        <template v-if="form.event">
        <event-card
            :event="form.event"
            class="mb-3"
        />
        </template>

        <template v-if="form.client">
        <client-card
            :client="form.client"
            class="my-3"
        />
        </template>

        <div class="card">
          <div class="card-body">
            <p class="card-title">Send a Message</p>

            <form @submit.prevent="sendMessage">

              <div class="form-group">
                <label for="subject">Subject</label>
                <input id="subject" type="text" class="form-control" v-model="messageForm.subject">
              </div>

              <div class="form-group">
                <label for="content">Content</label>
                <textarea id="content" class="form-control" v-model="messageForm.content"></textarea>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Send</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <p class="card-title mt-3">{{ messages.length }} Messages</p>

        <template v-if="messages && messages.length > 0">
          <template v-for="message in messages">
            <message
                :message="message"
            />
          </template>
        </template>
      </div><!--Col-->

    </div><!--Row-->

    </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

    data () {
        return {
          processing: false,
          form: {},
          event: {},
          portal: {},
          messages: [],
          languages: [],
          locations: [],
          geolocations: [],
          current_location: null,
          next_status: 'ready',
          lookup: {},
          messageForm: {}
        }
    },

    computed: {

      portalLink() {
        if(!this.portal || !this.portal.link || !this.portal.domain) {
          return;
        }

        return 'https://'+this.portal.link+'.'+this.portal.domain+'/checkins/'+this.form.url;
      }

    },

    mounted() {

    var self = this;

    this.$eventBus.$on(this.$route.meta.base_url, function(data) {
        
        if(!data) {
            return;
        }

        if(data.uuid === self.form.uuid) {
            self.form = {...self.form, ...data};
        }

    });

    },

    beforeDestroy() {
    if(this.map) {
        this.map.remove();
    }
    },

    created () {
        this.resetForm();
        this.resetMessage();
        this.getRecord();
        this.getLanguages();
        this.getLocations();
    },
    methods: {

        resetForm() {
            this.form = {
              client: null,
              event: null,
              status: null,
              language: null,
              service: null,
              department: null,
              notes: null,
              location: null,
              virtual: false,
              phone: null,
              email: null,
              data: {}
            }
        },

        resetMessage() {
          this.messageForm = {
            subject: null,
            content: null
          }
        },

      setTitle() {
        document.title = this.form.name + ' | ' + this.$route.meta.title;
      },

        getRecord() {
            this.processing = true;
            this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
            .then(response => {
                if(response && response.data) {
                    this.form = response.data;
                    this.processing = false;

                    if(!this.form.data) {
                        this.form.data = {};
                    }

                    this.setGeolocation();

                    this.getLocation();

                    this.initMap();
                    
                    this.setTitle();

                    this.loadMessages();

                    this.runPhoneLookup();

                    this.getPortal();
                }
            })
        },

        getGeolocations() {

          if(!this.portal || !this.portal.uuid) {
            return;
          }

          this.$http.get('/geolocations?limit=100&url='+this.portalLink)
              .then(response => {
                if(response && response.data) {
                  this.geolocations = response.data;
                }
              })
        },

        getPortal() {

          if(!this.form.portal) {
            return;
          }

          this.$http.get('/portals/'+this.form.portal)
              .then(response => {
                if(response && response.data) {
                  this.portal = response.data;

                  this.getGeolocations();

                }
              })
        },

        setGeolocation() {
          if(this.form.data && this.form.data.geolocation) {
            if(typeof this.form.data.geolocation === 'string') {
                this.form.data.geolocation = JSON.parse(this.form.data.geolocation);
            }
          }
        },

      setStatusClass(status) {
          if(!status) {
            return 'badge-secondary';
          }

          if(status == 'waiting') {
            return 'badge-danger';
          }

          if(status == 'ready') {
            return 'badge-success';
          }

          if(status == 'in-progress') {
            return 'badge-primary';
          }

          if(status == 'checked_out') {
            return 'badge-success';
          }

          return 'badge-secondary';
        },

        postForm() {
            this.processing = true;
            this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
            .then(response => {
                if(response && response.data) {
                    this.processing = false;
                    this.form = {...this.form, ...response.data};
                    this.setGeolocation();
                    new this.$noty({text: this.$route.meta.title + ' saved'}).show();

                    this.getLocation();
                    this.setTitle();
                }
            })
        },

        sendMessage() {

          let post = {
            subject: this.messageForm.subject,
            content: this.messageForm.content
          }

          if(this.form.client) {
            post.client = this.form.client;
            this.postMessage(post);
            this.resetMessage();
            return;
          }

          if(this.form.phone) {
            post.to = this.form.phone;
            this.postMessage(post);
          }

          if(this.form.email) {
            post.to = this.form.email;
            this.postMessage(post);
          }

          this.resetMessage();

        },

        postMessage(post) {

          post.scheduled_at = this.$moment().tz(this.timezone).format('YYYY-MM-DD HH:mm:ss');
          post.timezone = this.timezone;
          post.checkin = this.form.uuid;
          post.content += ' [CheckinLink]';

          this.processing = true;
          this.$http.post('/messages/oneoffs', post)
          .then(response => {
            if(response && response.data) {
              this.processing = false;

              if(!this.messages) {
                this.messages = [];
              }

              this.messages.unshift(response.data);
              new this.$noty({text: 'Message sent'}).show();
            }
          })
        },

      getLanguages () {
        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/languages.json')
            .then(response => {
              if(response && response.data) {
                this.languages = response.data;
              }
            })
      },

      getLocation() {
        if(!this.form.location) {
          return;
        }

        // if(this.current_location && this.current_location.uuid === this.form.location) {
        //   return;
        // }

        this.$http.get('/locations/'+this.form.location)
            .then(response => {
              if(response && response.data) {
                this.current_location = response.data;
              }
            })
      },

      loadMessages() {

        if(this.messages.length > 0) {
            this.messages = [];
        }

        if(this.form.email) {
          this.getMessages('to', this.form.email);          
          this.getMessages('from', this.form.email);
        }

        if(this.form.phone) {
          this.getMessages('to', this.form.phone);
          this.getMessages('from', this.form.phone);
        }

      },

      getMessages(key, value) {
        this.$http.get('/messages?sort=scheduled_at&sortDirection=desc&limit=20&slimt=true&'+key+'='+value)
            .then(response => {
              if(response && response.data && response.data.data) {
                if(response.data.data.length > 0) {

                  response.data.data.forEach(message => {
                    this.messages.push(message);
                  });
                }
                
              }
            })
      },

      getLocations () {
        this.$http.get(this.buildQueries('/locations', {limit: 1000, fields: 'uuid,name', slim: true, sort: 'name', sortDirection: 'asc'}))
            .then(response => {
              if (response && response.data) {
                this.locations = response.data;
              }
            })
      },

      restoreRecord () {
            this.processing = true;
            this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
            .then(response => {
                if (response && response.data) {

                    this.processing = false;

                    if(response.status === 200) {
                        new this.$noty({text: this.$route.meta.title + ' restored'}).show();
                        this.form.archived_at = null;
                        this.form.deleted_at = null;
                    }
                    
                }
            })
        },

        archiveRecord () {
            this.processing = true;
            this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/archive')
            .then(response => {
                if (response && response.data) {

                    this.processing = false;

                    if(response.status === 200) {
                        new this.$noty({text: this.$route.meta.title + ' archived'}).show()
                        this.form.archived_at = new Date();
                    }

                }
                
            })
        },

        trashRecord () {
            this.processing = true;
            this.$http.delete('/'+this.$route.meta.base_url + '/' + this.form.uuid)
            .then(() => {
                this.processing = false;
                new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
                this.form.deleted_at = new Date();
              })
        },

          runPhoneLookup() {

            this.lookup = {};

            if(!this.form || !this.form.phone) {
              return;
            }

            const lookup = this.$parsePhoneNumber(this.form.phone, 'US')

            if(lookup && lookup.country) {
              lookup.country = lookup.country.toLowerCase();
              this.lookup = lookup;
            }
          },

          getPDF() {
          this.processing = true;
          this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/pdf', {responseType: 'blob'})
          .then(response => {
            if(response && response.data) {
              this.processing = true;

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', this.$route.meta.base_url+'-'+this.form.uuid+'.pdf');
              document.body.appendChild(link);
              link.click();

              link.parentNode.removeChild(link);
              window.URL.revokeObjectURL(url);

              this.processing = false;
            }
          })
        },

        initMap() {

          this.map = window.L.map("geo_map").setView(this.$root.coordinates, 12);

          window.L.tileLayer(this.$root.map_layer).addTo(this.map);

          this.setMarker();
        },

        updateMarker() {
        if(this.marker) {
            this.marker.remove();
        }

        this.setMarker();
        },

        setCheckoutMarker() {
          if(!this.form.data.geolocation_checkout || !this.form.data.geolocation_checkout.latitude || !this.form.data.geolocation_checkout.longitude) {
            return;
          }

          const checkoutIcon = window.L.divIcon({
            html: '<i class="fa fa-check-double text-success fa-2x"></i>',
            iconSize: [20, 20],
            className: 'map-icon'
          });

          var popup = '<p class="m-0">Checkout Geolocation</p>';

          if(this.form.data.geolocation_checkout.timestamp) {
            popup += '<p class="m-0">'+this.$options.filters.timeago(this.form.data.geolocation_checkout.timestamp)+'</p>';
          }

          window.L.marker([this.form.data.geolocation_checkout.latitude, this.form.data.geolocation_checkout.longitude], {icon: checkoutIcon, draggable: true})
          .addTo(this.map)
          .bindPopup(popup)
          .openPopup();

        },

        setCheckinMarker() {
          if(!this.form.data.geolocation_checkin || !this.form.data.geolocation_checkin.latitude || !this.form.data.geolocation_checkin.longitude) {
            return;
          }

          const checkinIcon = window.L.divIcon({
            html: '<i class="fa fa-double-check text-danger fa-2x"></i>',
            iconSize: [20, 20],
            className: 'map-icon'
          });

          var popup = '<p class="m-0">Checkin Geolocation</p>';

          if(this.form.data.geolocation_checkin.timestamp) {
            popup += '<p class="m-0">'+this.$options.filters.timeago(this.form.data.geolocation_checkin.timestamp)+'</p>';
          }

          window.L.marker([this.form.data.geolocation_checkin.latitude, this.form.data.geolocation_checkin.longitude], {icon: checkinIcon, draggable: true})
          .addTo(this.map)
          .bindPopup(popup)
          .openPopup();
        },

        goToGeoLocation() {
          if(!this.form.data.geolocation || !this.form.data.geolocation.latitude || !this.form.data.geolocation.longitude) {
            return;
          }

          this.map.flyTo([this.form.data.geolocation.latitude, this.form.data.geolocation.longitude], 14);
        },

        goToCheckin() {
          if(!this.form.data.geolocation_checkin || !this.form.data.geolocation_checkin.latitude || !this.form.data.geolocation_checkin.longitude) {
            return;
          }

          this.map.flyTo([this.form.data.geolocation_checkin.latitude, this.form.data.geolocation_checkin.longitude], 14);
        },

        goToCheckout() {
          if(!this.form.data.geolocation_checkout || !this.form.data.geolocation_checkout.latitude || !this.form.data.geolocation_checkout.longitude) {
            return;
          }

          this.map.flyTo([this.form.data.geolocation_checkout.latitude, this.form.data.geolocation_checkout.longitude], 14);
        },

        setMarker() {

        this.setCheckinMarker();
        this.setCheckoutMarker();

        if(!this.form.data || !this.form.data.geolocation || !this.form.data.geolocation.latitude || !this.form.data.geolocation.longitude) {
            return;
        }

        var accuracy = this.form.data.geolocation.accuracy || 10;

        if(accuracy > 100) {
            accuracy = 100;
        }

        var popup = '<p class="m-0">Last Geolocation</p>';

        if(this.form.data.geolocation.timestamp) {
          popup += '<p class="m-0">'+this.$options.filters.timeago(this.form.data.geolocation.timestamp)+'</p>';
        }


        this.map.panTo([this.form.data.geolocation.latitude, this.form.data.geolocation.longitude], 14);

        var circle = window.L.circle([this.form.data.geolocation.latitude, this.form.data.geolocation.longitude], {
            color: 'blue',
            fillColor: '#2196f3',
            fillOpacity: 0.2,
            radius: accuracy
        }).addTo(this.map);

        circle.bindPopup(popup).openPopup();

        },

        calculateDistance() {
        if(!this.form.data || !this.form.data.geolocation) {
            return;
        }

        if(!this.current_location || !this.current_location.lat || !this.current_location.lng) {
            return;
        }

        let lat1 = this.form.data.geolocation.latitude;
        let lon1 = this.form.data.geolocation.longitude;

        let lat2 = this.current_location.lat || this.settings.latitude || this.$root.coordinates[0];
        let lon2 = this.current_location.lng || this.settings.longitude || this.$root.coordinates[1];

        let R = 6371;
        let dLat = (lat2 - lat1) * Math.PI / 180;
        let dLon = (lon2 - lon1) * Math.PI / 180;
        lat1 = lat1 * Math.PI / 180;
        lat2 = lat2 * Math.PI / 180;

        let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let distance = R * c;

          if(distance < 10) {
            return (distance * 5280).toFixed(0) + ' ft';
          }

        return distance.toFixed(2) + ' mi';
},

    }
}
</script>
