<template>
    <div>
  
      <page-tabs page="settings" />
  
      <div class="row">
        <div class="col-sm-12 col-md-3">
          <div class="card">
            <div class="card-body">
              <p class="card-title">
                Create
              </p>
  
              <form @submit.prevent="postForm()">
  
                <div class="form-group">
                  <label for="name">Name</label>
                  <input
                      id="name"
                      v-model="form.name"
                      class="form-control"
                      required
                  >
                </div>
  
  
                <button
                    type="submit"
                    class="btn btn-success"
                    :disabled="processing"
                >
                  Add
                </button>
  
              </form>
  
            </div><!--Body-->
          </div><!--Card-->
  
          <div class="card mt-3">
            <div class="card-body">
              <p class="card-title">Search</p>
  
              <form @submit.prevent="searchRecords()">
  
                <div class="form-group">
                  <label for="search">Search</label>
                  <input id="search" type="search" class="form-control" v-model="queries.search">
                </div>
  
                <div class="form-group custom-control custom-switch">
                  <input
                      id="search_archived"
                      v-model="queries.archived"
                      type="checkbox"
                      class="custom-control-input"
                      :disabled="processing"
                      @change="searchRecords()"
                  >
                  <label
                      class="custom-control-label"
                      for="search_archived"
                  >Archived</label>
                </div>
  
                <div class="form-group custom-control custom-switch">
                  <input
                      id="search_trashed"
                      v-model="queries.trashed"
                      type="checkbox"
                      class="custom-control-input"
                      :disabled="processing"
                      @change="searchRecords()"
                  >
                  <label
                      class="custom-control-label"
                      for="search_trashed"
                  >Trashed</label>
                </div>
  
                <button type="submit" class="btn btn-primary" :disabled="processing">Search</button>
  
              </form>
  
            </div><!--Body-->
          </div><!--Card-->

  
        </div><!--Col-->
  
        <div class="col-sm-12 col-md-9">
          <div class="card">
            <div class="card-body">
  
              <div class="row">
  
              <div class="col-sm-12 col-md-6">
  
                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{ $route.meta.title }}s
                </p>
  
              </div><!--Col-->
  
              <div class="col-sm-12 col-md-6 text-right">
  
                <div class="btn-group d-print-none">
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                </div>
              </div><!--Col-->
  
              </div><!--Row-->
  
              <div class="table-responsive my-3">
                <table class="table table-striped table-hover">
                  <thead>
                  <tr>
                    <template v-for="column in columns">
                      <th
                          class="text-uppercase"
                      >
                        <template v-if="column.key">
                          <span
                              class="text-capitalize link-hover"
                              @click="sortColumn(column)"
                          >{{ column.label }}</span>
                        </template>
  
                        <template v-if="!column.key">
                          <span class="text-capitalize">{{ column.label }}</span>
                        </template>
  
                        <template v-if="column.key == queries.sort">
                          <button
                              type="button"
                              class="btn btn-outline-secondary btn-sm ml-1"
                              :disabled="processing"
                              @click="updateSortDirection()"
                          >
                            <template v-if="queries.sortDirection == 'asc'">
                              <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                            </template>
  
                            <template v-if="queries.sortDirection == 'desc'">
                              <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                            </template>
                          </button>
                        </template>
                      </th>
                    </template>
                  </tr>
                  </thead>
  
                  <tbody>
                  <template v-if="records && records.length > 0">
                    <template v-for="record in records">
                      <tr>
                        <td>
                            <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid}}">
                            <i class="fa fa-edit"></i> Edit
                          </router-link>
                        </td>
                        <td>
                            {{ record.name }}
                        </td>
                        <td>{{ record.model }}</td>
                        <td>{{ record.status }}</td>
                        <td>{{ record.created_by }}</td>
                        <td>{{ record.created_at | datetime }}</td>
                        <td>{{ record.updated_at | datetime }}</td>
                      </tr>
                    </template>
                  </template>
                  </tbody>
                </table>
              </div><!--Responsive-->
  
              <template v-if="!processing && !records.length">
                <div class="alert alert-warning">
                  No {{ $route.meta.title }}s found.
                </div>
              </template>
            </div><!--Body-->
          </div><!--Card-->
  
          <template v-if="records && records.length && total > records.length">
  
            <div class="row mt-3">
              <div class="col-12 text-center">
  
                <button
                    type="button"
                    class="btn btn-outline-primary"
                    :disabled="processing"
                    @click="getRecords"
                >
                  Load more
                </button>
  
              </div><!--Col-->
            </div><!--Row-->
  
          </template>
  
        </div><!--Col-->
      </div><!--Row-->
    </div>
  </template>
  <script>
  import queries from '../mixins/queries';
  
  export default {
  
    mixins: [ queries ],
  
    data () {
      return {
        processing: false,
        total: 0,
        queries: {},
        records: [],
        form: {},
        columns: [
          {label: 'Edit'},
          { key: 'name', label: 'Name'},
          { key: 'model', label: 'AI Model'},
          { key: 'status', label: 'Status'},
          { key: 'created_by', label: 'Creator'},
          { key: 'created_at', label: 'Created'},
          { key: 'updated_at', label: 'Updated'}
        ]
      }
    },
  
    mounted() {
      var self = this;
  
      this.$eventBus.$on('errors', function (errors) {
        self.processing = false;
      });
    },
  
    created() {
      this.resetForm();
      this.resetQueries();
      this.resetRecords();
      this.setURLParams();
      this.getRecords();
    },
  
    methods: {

  
      resetQueries() {
        this.queries = {
          limit: 20,
          slim: false,
          search: null,
          name: null,
          sort: 'created_at',
          sortDirection: 'desc'
        }
      },
  
      resetForm() {
        this.form = {
          name: null,
          model: 'auto',
          status: 'active'
        }
      },
  
      resetRecords() {
        this.records = [];
        this.total = 0;
        this.queries.skip = 0;
      },
  
      updateSortDirection() {
  
        if(this.processing) {
          return;
        }
  
        if(this.queries.sortDirection == 'asc') {
          this.queries.sortDirection = 'desc';
        } else {
          this.queries.sortDirection = 'asc';
        }
  
        this.resetRecords();
  
        this.getRecords();
      },
  
      sortColumn(column) {
  
        if(this.processing) {
          return;
        }
  
        this.queries.sort = column.key;
  
        this.updateSortDirection();
      },
  
      searchRecords() {
        this.resetRecords();
        this.getRecords();
      },
  
      getRecords() {
  
        this.processing = true;
  
        this.updateURL();
        this.getTotal();
  
        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
            .then(response => {
              if(response && response.data) {
  
                if(response.data.length) {
  
                  var self = this;
  
                  response.data.forEach(function(data) {
                    self.records.push(data);
                  });
                }
  
                this.queries.skip = this.records.length;
  
                this.processing = false;
              }
            })
      },
  
      getTotal() {
        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
            .then(response => {
              if(response && response.status === 200) {
                this.total = response.data;
                this.setTitle();
              }
            })
      },
  
      setTitle() {
        document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
      },
  
      postForm() {
        this.processing = true;
        this.$http.post(this.$route.meta.base_url, this.form)
            .then(response => {
              if(response && response.data) {
  
                this.processing = false;
  
                if(response.status === 201) {
                  this.resetForm();
                  this.total++;
                  this.records.unshift(response.data);
                  new this.$noty({text: this.$route.meta.title + ' created'}).show();
                }
  
              }
            })
      },

  
    },
  }
  </script>