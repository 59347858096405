<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />

    <div class="row">
      <div class="col-sm-12 col-md-4">
        <h1 class="m-0"><span v-if="form && form.name">{{ form.name }}</span></h1>

        <template v-if="form.extension">
          <code>Extension: {{ form.extension }}</code>
        </template>

        <template v-if="form.size">
          <code class="ml-3">Size: {{ form.size | fileSize }}</code>
        </template>

        <template v-if="form.downloaded_at">
          <span class="badge badge-pill badge-success ml-3">Downloaded {{ form.downloaded_at | dateTimezone | timeago }}</span>
        </template>

        <template v-if="form.expires_at">
          <span class="badge badge-pill badge-danger">Expiration {{ form.expires_at | dateTimezone | timeago }}</span>
        </template>

        <p>
          <router-link :to="{name: 'portals.index'}">Portals</router-link> /
          <router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}
        </p>

      </div><!--Col-->

      <div class="col-sm-12 col-md-4">

        <template v-if="form && form.public && form.url">
          <a :href="setPortalLink()" class="btn btn-secondary mr-3" target="_blank" :disabled="processing"><i class="fa fa-external-link"></i> Open Portal</a>
          
          <template v-if="qr_code">
            <a :href="setPortalLink()" target="_blank">
            <img
              :src="'data:image/svg+xml;base64,'+qr_code"
              width="50"
              height="50"
              alt="QR Code"
            >
            </a>
          </template>

          </template>

      </div><!--Col-->

      <div class="col-sm-12 col-md-4 text-right">
        <div class="btn-group">

          <a
            :href="download_url"
            target="_blank"
            class="btn btn-primary"
            :hidden="!download_url || form.deleted_at"
            >
            Open File
          </a>

          <button
            type="button"
            class="btn btn-outline-primary dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            :disabled="processing"
          >
            Tools
          </button>
          <div class="dropdown-menu">

            <a
                v-if="!form.archived_at && !form.deleted_at"
                class="dropdown-item"
                @click="archiveRecord()"
            >Archive</a>

            <a
              v-if="!form.deleted_at"
              class="dropdown-item"
              @click="trashRecord()"
            >Trash</a>
            <a
              v-if="form.deleted_at || form.archived_at"
              class="dropdown-item"
              @click="restoreRecord()"
            >Restore</a>
          </div>

          <template v-if="!form.deleted_at && !form.archived_at">
            <button
              type="button"
              class="btn btn-success"
              :disabled="processing"
              @click="postForm"
            >
              Save
            </button>
          </template>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>
        </div><!--BtnGroup-->
      </div><!--Col-->
    </div><!--Row-->

      <div class="row mt-3">
        <div class="col-sm-12 col-md-3">
          <div class="card">
            <div class="card-body">

              <form @submit.prevent="postForm">

                <div class="form-group">
                  <label for="name">Name</label>
                  <input id="name" type="text" class="form-control" v-model="form.name">
                </div>

                <div class="form-group">
                  <label for="description">Description</label>
                  <textarea id="description" class="form-control" rows="3" v-model="form.description"></textarea>
                </div>

                <div class="form-group">
                  <label for="portal">Portal

                  <template v-if="form.portal">
                    <a
                    :href="'/portals/'+form.portal"
                    target="_blank"
                    data-field="Edit Portal">
                    <i class="fa fa-edit"></i> <span class="sr-only">Edit Portal</span>
                    </a>
                  </template>
                  
                  </label>
                  <select
                    id="portal"
                    v-model="form.portal"
                    class="form-control"
                    @change="postForm"
                    :disabled="processing"
                  >
                    <option value="">
                      Choose Portal
                    </option>
                    <template v-for="portal in portals">
                      <option
                        :value="portal.uuid"
                      >
                        {{ portal.name }}
                      </option>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="language">Default Language</label>
                  <select
                    id="language"
                    v-model="form.language"
                    class="form-control"
                    :disabled="processing"
                  >
                    <option value="">
                      Choose Language
                    </option>
                    <option value="en">English</option>

                    <template v-for="language in languages">

                      <option
                        :value="language.key"
                        :hidden="language.key == 'en'"
                      >
                        {{ language.label }}
                      </option>

                    </template>

                  </select>
                </div>

                <div class="form-group">
                  <label for="merge_tag">Merge Tag <merge-tag v-if="form.merge_tag" :merge_tag="'{'+form.merge_tag+'}'" /></label>
                  <input id="merge_tag" type="text" class="form-control" v-model="form.merge_tag">
                </div>

                <div class="form-group">
                  <label for="type">Type</label>
                  <input id="type" type="text" class="form-control" v-model="form.type">
                </div>

                <div class="form-group">
                  <label for="notes">Internal Notes</label>
                  <textarea id="notes" class="form-control" rows="3" v-model="form.notes"></textarea>
                </div>

                <div class="form-group">
                  <label for="expire_date">Expiration Date</label>
                  <input id="expire_date" class="form-control" type="date" v-model="form.expire_date">
                </div>

                <div class="form-group">
                  <label for="expire_time">Expiration Time</label>
                  <input id="expire_time" class="form-control" type="time" v-model="form.expire_time">
                </div>

                <div class="form-group custom-control custom-switch">
                  <input
                    id="attach_emails"
                    v-model="form.attach_emails"
                    type="checkbox"
                    class="custom-control-input"
                  >
                  <label
                    class="custom-control-label"
                    for="attach_emails"
                  > Attach to Emails</label>
                </div>

                <div class="form-group custom-control custom-switch">
                  <input
                    id="public"
                    v-model="form.public"
                    type="checkbox"
                    class="custom-control-input"
                  >
                  <label
                    class="custom-control-label"
                    for="public"
                  > Allow Public Downloads</label>
                </div>

                <template v-if="!form.deleted_at && !form.archived_at">
                  <button type="submit" class="btn btn-success" :disabled="processing">Save</button>
                </template>

              </form>

            </div><!--Body-->
          </div><!--Card-->

          <audit-logs
            :form="form"
            class="mt-3"
          />

        </div><!--Col-->

        <div class="col-sm-12 col-md-6">

          <template v-if="form && download_url">
            <file-embed :form="form" :url="download_url" />
          </template>

          <div class="card my-3">
            <div class="card-body">
              <p class="card-title">Add Field</p>

              <form @submit.prevent="addField">

              <div class="form-group">
                <label for="newField">Field Name</label>
                <input id="newField" type="text" class="form-control" v-model="newField.name" required>
              </div>

              <div class="form-group">
                <label for="field_type">Field Type</label>
                <select
                  id="field_type"
                  v-model="newField.type"
                  class="form-control"
                  :disabled="processing"
                  required
                >
                  <option value="">
                    Choose Field Type
                  </option>
                  <template v-for="field_type in field_types">
                    <option
                      :value="field_type"
                    >
                      {{ field_type }}
                    </option>
                  </template>
                </select>
              </div>

              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >
                Add Field
              </button>

              </form>

            </div><!--Body-->
          </div><!--Card-->

          <template v-if="form.fields && form.fields.length > 0">

            <div class="card my-3">
              <div class="card-body">
                <p class="card-title">{{ form.fields.length }} Fields</p>
                    
                    <template v-for="(field, field_index) in form.fields">
  
                      <div class="form-group">
                        <label :for="'field_'+field_index">{{ field.name }}</label>
                        <input :id="'field_'+field_index" type="text" class="form-control" v-model="form.fields[field_index].name">
                      </div>

                      <div class="form-group">
                        <label :for="'field_type_'+field_index">Field Type</label>
                        <select
                          :id="'field_type_'+field_index"
                          v-model="form.fields[field_index].type"
                          class="form-control"
                          :disabled="processing"
                        >
                          <option value="">
                            Choose Field Type
                          </option>
                          <template v-for="field_type in field_types">
                            <option
                              :value="field_type"
                            >
                              {{ field_type }}
                            </option>
                          </template>
                        </select>
                      </div>

                      <button
                        type="button"
                        class="btn btn-danger btn-sm my-3"
                        @click="deleteField(field_index)"
                        :disabled="processing"
                      >
                        Delete Field
                      </button>

                    </template>

              </div><!--Body-->
            </div><!--Card-->

          </template>

        </div><!--Col-->

        <div class="col-sm-12 col-md-3">

          <div class="card">
            <div class="card-body">

              <p class="card-title">Send {{ $route.meta.title }}</p>

              <template v-if="!form.portal">
                <div class="alert alert-danger">Choose a portal to send</div>
              </template>

              <form @submit.prevent="sendFile()">

                <template v-if="client && client.uuid">
                      <div class="row mb-3">
                        <div class="col-sm-12 col-md-6">
                          <p class="card-title">
                            <a :href="'/clients/'+client.uuid" target="_blank">
                              View Client
                            </a>
                          </p>
                        </div><!--Col-->

                        <div class="col-sm-12 col-md-6 text-right">
                          <button
                            type="button"
                            class="btn btn-danger btn-sm"
                            :disabled="processing"
                            @click="resetClient()"
                          >
                            <i class="fa fa-times" /> Close Client
                          </button>
                        </div><!--Col-->
                      </div><!--Row-->

                      <template v-if="client.first_name || client.last_name">
                        <p class="m-0">
                          Name: {{ client.first_name }} {{ client.middle_name }} {{ client.last_name }}
                        </p>
                      </template>

                      <template v-if="client.client_reference">
                        <p class="m-0">
                          Reference: {{ client.client_reference }}
                        </p>
                      </template>

                      <template v-if="client.language">
                        <p class="m-0">
                          Language: {{ setLanguage(client.language) }}
                        </p>
                      </template>

                      <template v-if="client.group">
                        <p class="m-0">
                          Group: {{ client.group }}
                        </p>
                      </template>

                      <template v-if="client.type">
                        <p class="m-0">
                          Type: {{ client.type }}
                        </p>
                      </template>

                      <template v-if="client.status">
                        <p class="m-0">
                          Status: {{ client.status }}
                        </p>
                      </template>

                </template>

                <template v-if="client && !client.uuid">
                  <client-search />
                </template>

                <div class="form-group mt-3">
                  <label for="due_at">Due Date</label>
                  <input id="due_at" class="form-control" type="date" v-model="send_form.due_at">
                  <small class="form-text text-muted">Defaults to 30 days ahead</small>
                </div>

                <div class="form-group" :hidden="!flows.length">
                  <label for="flow">Flow</label>
                  <select
                    id="flow"
                    v-model="send_form.flow"
                    class="form-control"
                    :disabled="processing"
                  >
                    <option value="">
                      Choose Flow
                    </option>
                    <template v-for="flow in flows">
                      <option
                        :value="flow.uuid"
                      >
                        {{ flow.name }}
                      </option>
                    </template>
                  </select>
                </div>

                  <div class="form-group custom-control custom-switch">
                    <input
                      id="signature"
                      v-model="send_form.signature"
                      type="checkbox"
                      class="custom-control-input"
                      :disabled="processing"
                    >
                    <label
                      class="custom-control-label"
                      for="signature"
                    >Require eSignature</label>
                  </div>

                <button type="submit" class="btn btn-success" :disabled="processing">Create</button>

              </form>

            </div><!--Body-->
          </div><!--Card-->


          <p class="m-0 mt-3">{{ sent_files.length }} Sent {{ $route.meta.title }}s</p>

          <ul class="list-group">

          <template v-for="sent_file in sent_files">

            <li class="list-group-item list-group-item-action">
              <a :href="'/sent_files/'+sent_file.uuid" target="_blank">

            {{  sent_file.name }}
            
            <template v-if="sent_file.client">
              <client-name :uuid="sent_file.client" />
            </template>

              <template v-if="sent_file.status">
                <file-status :status="sent_file.status" />
              </template>

            {{ sent_file.created_at | dateTimezone | timeago }}

            </a>
            </li>

          </template>

          </ul>

        </div><!--Col-->

      </div><!--Row-->
  </div>
</template>

<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data() {
    return {
        processing: false,
        form: {},
        send_form: {},
        clients: [],
        client: {},
        queries: {
          client: null
        },
        sent_files: [],
        portals: [],
        auto_messages: [],
        download_url: null,
        languages: [],
        qr_code: null,
        flows: [],
        newField: {},
        field_types: ['short text', 'long text', 'date', 'time', 'number', 'email', 'phone']
    }
},

mounted() {

    var self = this;

    this.$eventBus.$on('search_clients', function(uuid) {

       self.queries.client = uuid;

       self.getClient();

    });

},

created() {
    this.resetForm();
    this.resetSendForm();
    this.setURLParams();
    this.getRecord();
    this.getLanguages();
    this.getPortals();
    this.getAutoMessages();
    this.getFlows();
},

methods: {

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });

    },

    resetForm() {
      this.form = {
        name: '',
        description: '',
        notes: '',
        status: '',
        type: '',
        expire_date: null,
        expire_time: null,
        attach_emails: false,
        public: false,
        language: 'en'
      }
    },

    resetSendForm() {
      this.send_form = {
        name: null,
        portal: null,
        client: null,
        status: null,
        flow: null,
        due_at: null,
        language: 'en',
        signature: true
      }
    },

    getClient() {

        this.updateURL();

        if(!this.queries.client) {
            this.resetClient();
            return;
        }

        this.processing = true;

        this.$http.get('/clients/'+this.queries.client+'?slim=true&fields=uuid,client_reference,first_name,last_name,language')
        .then(response => {
            if(response && response.data) {
                this.client = response.data;

                if(this.client.uuid) {
                  this.send_form.client = this.client.uuid;
                  this.client.language = this.client.language || 'en';
                  this.send_form.language = this.client.language;
                }

                this.processing = false;
            }
        })
    },

    resetClient() {
        this.queries.client = null;
        this.send_form.client = null;
        this.client = {};
        this.updateURL();
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url+'/'+this.$route.params.id, this.queries));
    },

    getRecord() {

        this.processing = true;

        this.updateURL();

        this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
        .then(response => {
            if(response && response.data) {
                this.form = response.data;

                this.setTitle();

                this.getSentFiles();

                this.getClient();

                this.getPortal();

                this.getDownloadURL();
            }
        })
    },

    getSentFiles() {

      this.sent_files = [];

      this.$http.get('/sent_files?file='+this.form.uuid+'&sort=created_at&sortDirection=desc&slim=true&fields=name,uuid,created_at,status,client')
      .then(response => {
        if(response && response.status) {
          this.sent_files = response.data;
        }
      })
    },

    setTitle() {
      document.title = this.form.name +  ' | ' +this.$route.meta.title;
    },

    postForm() {

      if(this.form.archived_at || this.form.deleted_at) {
        new this.$noty({text: 'Cannot edit archived or trashed '+this.$route.meta.title, type: 'error'}).show();
        return;
      }

      this.processing = true;
      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if(response && response.data) {
          if(response.data.updated_at) {
            this.form.updated_at = response.data.updated_at;
            new this.$noty({text: this.$route.meta.title + ' updated'}).show();
            this.processing = false;
            this.getPortal();
            this.setTitle();
          }
        }
      })
    },

    getDownloadURL() {
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/download?skip_downloaded=true')
      .then(response => {
        if(response && response.data) {
          this.download_url = response.data;
          this.processing = false;
        }
      })
    },

  archiveRecord() {
    this.processing = true;
    this.$http.put('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/archive')
        .then(response => {
          if(response && response.status === 200) {
            this.processing = false;
            new this.$noty({text: this.$route.meta.title + ' archived', type: 'error'}).show();
            this.form.archived_at = new Date().toISOString();
          }
        })
  },

    trashRecord() {
        this.processing = true;
        this.$http.delete('/'+this.$route.meta.base_url+'/'+this.form.uuid)
        .then(response => {
            if(response && response.status === 204) {
                this.processing = false;
                new this.$noty({text: this.$route.meta.title + ' trashed', type: 'error'}).show();
                this.form.deleted_at = new Date().toISOString();
            }
        })
    },

    restoreRecord () {
        this.processing = true;
        this.$http.put('/'+this.$route.meta.base_url+'/' + this.form.uuid + '/restore')
        .then(response => {
            if (response && response.status === 200) {
                new this.$noty({text: this.$route.meta.title + ' restored'}).show();
                this.form.deleted_at = null;
                this.form.archived_at = null;
                this.processing = false;
            }
            
        })
    },

    deleteField(index) {

      if(!this.form.fields) {
        return;
      }

      this.form.fields.splice(index, 1);

      this.postForm();
    },

    addField() {
      if(!this.form.fields) {
        this.form.fields = [];
      }

      this.form.fields.push(this.newField);

      this.newField = {};

      this.postForm();
    },

    sendFile() {

      if(!this.send_form.client) {
        new this.$noty({text: 'Choose a client to send'}).show();
        return;
      }

      if(!this.form.portal) {
        new this.$noty({text: 'Choose a portal to send'}).show();
        return;
      }

      this.send_form.name = this.form.name;
      this.send_form.portal = this.form.portal;
      this.send_form.status = 'pending';

      this.processing = true;
      this.$http.post('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/send', this.send_form)
      .then(response => {
        if(response && response.status) {
          this.processing = false;

          if(response.status === 201) {
            new this.$noty({text: 'Sending file'}).show();

            this.resetSendForm();

            // this.postMessage(response.data);

            if(!this.sent_files) {
              this.sent_files = [];
            }

            this.sent_files.unshift(response.data);

            window.location.href = '/sent_files/'+response.data.uuid;

          }

        }
      })
    },

    getQRCode() {
      this.qr_code = null;
      this.$http.post('/qrs?content='+this.setPortalLink())
      .then(response => {
          if(response && response.data) {
              this.qr_code = response.data;
          }
      })
    },

    setPortalLink() {
      if(!this.portal || !this.portal.link) {
        return;
      }

      if(!this.form || !this.form.url) {
        return;
      }

      var language = this.form.language || 'en';

      return 'https://'+this.portal.link+'.'+this.portal.domain+'/fi/'+this.form.url+'?lang='+language;
    },

    getPortal() {

      if(!this.form.portal) {
        return;
      }

      if(this.portal && this.form.portal == this.portal.uuid) {
        return;
      }

      this.$http.get('/portals/'+this.form.portal+'?slim=true&fields=domain,link')
      .then(response => {
        if(response && response.data) {
          this.portal = response.data;

          this.getQRCode();
        }
      })
    },

    getPortals() {
        this.$http.get('/portals?limit=1000&slim=true&fields=name,uuid&not_domain=ecourtpayment.com')
        .then(response => {
            if(response && response.data) {
                this.portals = response.data;
            }
        })
    },

    changeAutoMessage (uuid) {
        this.auto_message = _.find(this.auto_messages, {'uuid': uuid});

        var language = this.send_form.language || 'en';

        this.send_form.content = this.auto_message.templates[language] || this.auto_message.templates['en'];

        if(this.auto_message.subject) {
          this.send_form.subject = this.auto_message.subject;
        }
    },

    getAutoMessages () {
        this.$http.get('/auto_messages?limit=100&fields=name,uuid,templates,subject,simulate')
        .then(response => {
            if(response && response.data) {
                this.auto_messages = response.data;
            }
        })
    },

    getLanguages () {
        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/languages.json')
        .then(response => {
            if(response && response.data) {
                this.languages = response.data;
            }
        })
    },

    getFlows() {
    this.$http.get('/flows?slim=true&fields=name,uuid&limit=100&sort=name&sortDirection=asc&type=file')
    .then(response => {
      if(response && response.data) {
        this.flows = response.data;

        if(this.flows.length >= 1 && !this.send_form.flow) {
          this.send_form.flow = this.flows[0].uuid;
        }
      }
    })
  },

}

}
</script>