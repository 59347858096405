<template>
  <div>
    <div
      v-if="form && form.uuid"
      class="card"
    >
      <div class="card-body">
        <p class="card-title">
          Payment <a :href="'/payments/'+form.uuid">{{ form.payment_reference }}</a>
        </p>

        <ul class="list-group">
          <template v-if="form.amount">
            <li class="list-group-item">
              AMOUNT ${{ form.amount }}
            </li>
          </template>

          <template v-if="form.issued_at">
            <li class="list-group-item">
              ISSUED {{ form.issued_at | date }}
            </li>
          </template>
        
          <template v-if="form.due_at">
            <li class="list-group-item">
              DUE {{ form.due_at | date }}
            </li>
          </template>

          <template v-if="form.status">
            <li class="list-group-item">
              STATUS {{ form.status }}
            </li>
          </template>
        </ul>
      </div><!--Body-->
    </div><!--Card-->
  </div>
</template>
<script>
export default {

props: ['payment'],

data() {
    return {
        form: {}
    }
},

created() {
    this.getPayment()
},

methods: {

        getPayment() {
            
            if(!this.payment) {
                return;
            }

            this.form = {}

            this.$http.get('/payments/'+this.payment+'?slim=true')
            .then(response => {
                if(response && response.data) {
                    this.form = response.data
                }
            })
        },

}

}
</script>