<template>
  <div>

    <template v-if="record.uuid">

        {{ record.client_reference }} 
        <span v-if="record.name">
          {{ record.name }}
        </span>
        <span v-else>
          {{ record.first_name }} {{ record.last_name }}
        </span>

    </template>
    <div
      v-if="processing"
      class="progress"
    >
      <div
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        aria-valuenow="100"
        aria-valuemin="0"
        aria-valuemax="100"
        style="width: 100%"
      />
    </div>

    <template v-if="!processing && !record.uuid">
      <span data-tooltip="No client found"><i class="fa fa-times-circle text-danger"></i></span>
    </template>

  </div>
</template>
<script>
export default {

  props: {
    uuid: {
      type: String,
      default: null
    }
  },

data() {
    return {
        processing: false,
        record: {},
        base_url: '/clients'
    }
},

created() {
    this.getRecord();
},

methods: {

        getRecord() {
            
            if(!this.uuid) {
                return;
            }

            if(!this.$options.filters.isValidUUID(this.uuid)) {
              return;
            }

            this.processing = true;
            this.record = {};

            this.$http.get(this.base_url+'/'+this.uuid+'?slim=true&fields=uuid,name,first_name,last_name,client_reference')
            .then(response => {
                if(response && response.data) {
                    this.record = response.data;
                    this.processing = false;
                }
            })
        },

}

}
</script>