<template>
  <div>
    <div class="card">
      <div class="card-body">

        <template v-if="!processing && record.uuid">

        <div class="row">

        <div class="col-sm-12 col-md-8">

          <p class="card-title">
            File
            <router-link :to="{ 'name': 'files.edit', 'params': {'id': record.uuid} }">
              {{ record.name }}
            </router-link>
          </p>

        </div><!--Col-->

        <div class="col-sm-12 col-md-4">
    
            <template v-if="record.downloaded_at">
              <span class="badge badge-pill badge-success">Downloaded {{ record.downloaded_at | dateTimezone | timeago }}</span>
            </template>

            <template v-if="record.public">
              <span class="badge badge-pill badge-primary">Public</span>
            </template>

        </div><!--Col-->

        </div><!--Row-->

        <template v-if="record.extension">
          <code>Extension: {{ record.extension }}</code>
        </template>

        <template v-if="record.size">
          <code class="ml-3">Size: {{ record.size | fileSize }}</code>
        </template>

          <file-embed :uuid="record.uuid" />          

        </template>
      </div><!--Body-->
    </div><!--Card-->
  </div>
</template>
<script>
export default {

props: ['uuid'],

data() {
    return {
        processing: false,
        record: {},
        base_url: '/files'
    }
},

created() {
    this.getRecord();
},

methods: {

        getRecord() {
            
            if(!this.uuid) {
                return;
            }

            this.processing = true;
            this.record = {};

            this.$http.get(this.base_url+'/'+this.uuid+'?slim=true&fields=name,uuid,type,size,extension,downloaded_at,deleted_at')
            .then(response => {
                if(response && response.data) {
                    this.record = response.data;
                    this.processing = false;
                }
            })
        },

}

}
</script>