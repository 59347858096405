<template>
    <div>
  
      <page-tabs page="cases" />
  
      <div class="row">

        <div class="col-xl-9 col-sm-6 col-sm-12 mx-auto">

            <div class="card">
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-sm-12 col-md-6">
                            <template v-if="assessment.title">
                                <h5 class="card-title">{{ assessment.title }}</h5>
                            </template>
                        </div><!--Col-->
                        <div class="col-sm-12 col-md-6 text-right">
                            <button type="button" class="btn btn-secondary" @click="getPDF()" :disabled="processing"><i class="fa fa-file-pdf"></i> PDF </button>
                        </div><!--Col-->
                    </div><!--Row-->

                    <div class="row d-none d-print-block">
                        <div class="col-sm-12 col-md-6">
                            <template v-if="settings && settings.name">
                                        <template v-if="settings.logo">
                                            <img :src="settings.logo" class="img-fluid my-3" :alt="settings.name" style="max-width:100px" />                            
                                        </template>
                                        <h5>{{ settings.name }}</h5>
                                        <template v-if="settings.address">
                                            <p>{{ settings.address }} {{ settings.city || '' }} {{  settings.state || '' }} {{ settings.zip || '' }}</p>
                                        </template>
                            </template>
                        </div><!--Col-->
                        <div class="col-sm-12 col-md-6">
                            <template v-if="qr_code">
                                <img
                                :src="'data:image/svg+xml;base64,'+qr_code"
                                width="150"
                                class="img-fluid"
                                alt="QR Code"
                                >
                            </template>
                        </div><!--Col-->
                    </div><!--Row-->

                    <template v-if="assessment.description">
                        <p class="lead d-print-none">{{ assessment.description }}</p>
                    </template>

                    <template v-if="assessment.url">
                        <p class="d-print-none"><a :href="assessment.url" target="_blank">Learn more</a></p>
                    </template>

                    <div class="form-group">
                        <label for="case_number">Case Number</label>
                        <input type="text" class="form-control" id="case_number" v-model="form.case_number" @keyup.enter="autoPopulate()" :disabled="processing" />
                    </div>

                    <template v-if="form.case_number">
                        <button class="btn btn-success btn-block my-3 d-print-none" @click="autoPopulate()" :disabled="processing">Auto Populate</button>
                    </template>

                    <template v-if="show_client">
                        
                        <template v-if="client && client.uuid">

                            <div class="row">

                                <div class="col-sm-12 col-md-6">

                                    <template v-if="client.client_reference">
                                        <p class="m-0">{{ setCustomField('clients', 'client_reference') }} {{ client.client_reference }}</p>
                                    </template>
                                    <p class="m-0">NAME <strong>{{ client.first_name }} {{ client.middle_name }} {{ client.last_name }}</strong></p>
                                    <template v-if="client.aliases">
                                        <p class="m-0">AKA {{ client.aliases }}</p>
                                    </template>
                                    <template v-if="client.dob">
                                        <p class="m-0">AGE {{ calculateAge() }}</p>
                                    </template>
                                    <template v-if="client.language">
                                    <p class="m-0">LANGUAGE {{ setLanguage(client.language) }}</p>
                                    </template>

                                </div><!--Col-->


                                <div class="col-sm-12 col-md-6">

                                        <template v-for="photo in photos">
                                            <template v-if="photo.url">
                                                <a :href="photo.url" target="_blank">
                                                    <img :src="photo.url" class="img-circle img-thumbnail mb-3" :alt="client.first_name" width="100">
                                                </a>
                                            </template>
                                        </template>

                                </div><!--Col-->

                            </div><!--Row-->

                        </template>
                        
                    </template>

                    <template v-if="client && client.uuid">
                        <div class="btn-group my-3">
                            <button class="btn btn-secondary btn-sm d-print-none" @click="show_client = !show_client" :disabled="processing">Toggle Client</button>
                            <a :href="'/clients/'+client.uuid" target="_blank" class="btn btn-primary btn-sm d-print-none">View Client</a>
                        </div>
                    </template>

                    <template v-for="field in assessment.fields">
                    
                        <template v-if="field.type == 'number'">
                            <div class="form-group" v-if="isHiddenField(field) == false">
                                <label :for="field.key">{{ field.label }}</label>
                                <input type="number" class="form-control" :id="field.key" v-model="form[field.key]"
                                @change="calculateScores()"
                                />
                            </div>
                        </template>

                        <template v-if="field.type == 'range'">
                            <div class="form-group">
                                <label :for="field.key">{{ field.label }}</label>
                                <input type="range" class="form-control-range" :id="field.key" v-model="form[field.key]"
                                @change="calculateScores()"
                                />
                                <template v-if="form[field.key]">
                                    <small>{{ form[field.key] }}</small>
                                </template>
                            </div>
                        </template>

                        <template v-if="field.type == 'checkbox'">
                            <div class="form-check" v-if="isHiddenField(field) == false">
                                <input type="checkbox" class="form-check-input" :id="field.key" v-model="form[field.key]"
                                @change="calculateScores()"
                                />
                                <label class="form-check" :for="field.key">{{ field.label }}</label>
                            </div>
                        </template>

                    </template>

                    <hr>

                    <template v-if="checkErrors !== false">
                        <div class="alert alert-danger">
                            {{ checkErrors }}
                        </div>
                    </template>

                    <template v-if="checkErrors === false">

                        <h4 class="mt-3">Results</h4>

                            <ul class="list-group">
                                <template v-for="score in scores">
                                    <li class="list-group-item"
                                    :class="setScoreClass(score)"
                                    >
                                        <span class="h5">{{ score.label }}</span>:
                                        <template v-if="score.key != 'nvca'">
                                            <span class="float-right" :id="'score_'+score.key">
                                                {{ results[score.key] }}
                                            </span>
                                        </template>
                                            <template v-if="results[score.key] >= 0">
                                                <template v-if="score.key == 'fta'">
                                                    <small>{{ fta_scores[results[score.key]] }}</small>
                                                </template>
                                                <template v-if="score.key == 'nca'">
                                                    <small>{{ nca_scores[results[score.key]] }}</small>
                                                </template>
                                                <template v-if="score.key == 'nvca'">
                                                    <small id="score_nvca" class="float-right">{{ nvca_scores[results[score.key]] }}</small>
                                                </template>
                                            </template>
                                    </li>
                                </template>
                            </ul>

                    <hr>

                    <h4 id="release-level" class="mt-3">Release Level {{ setReleaseLevel }}</h4>
                    
                    <p class="m-0">Recommended conditions:</p>

                    <template v-if="setReleaseLevel">
                        
                    <ul class="list-group">

                    <template v-if="setReleaseLevel >= 1">
                        <li class="list-group-item"><i class="fa fa-check-circle text-success"></i> Mandatory Statutory Conditions</li>
                        <li class="list-group-item"><i class="fa fa-check-circle text-success"></i> Court Date Notifications</li>
                    </template>

                    <template v-if="setReleaseLevel >= 2">
                        <li class="list-group-item"><i class="fa fa-check-circle text-success"></i> Criminal history checks once per month</li>
                    </template>

                    <template v-if="setReleaseLevel >= 3">
                        <li class="list-group-item"><i class="fa fa-check-circle text-success"></i> Check-in once per month</li>
                    </template>

                    <template v-if="supervisions && supervisions">
                    
                        <template v-for="supervision in supervisions">

                            <div class="my-3" v-show="supervision.risk && supervision.risk == setReleaseLevel">

                                    <h5 class="card-title">{{ supervision.name }}</h5>
                                    <p class="card-text">{{ supervision.description }}</p>

                                    <template v-if="supervision.conditions && supervision.conditions.length > 0">
                                        <template v-for="condition in supervision.conditions">
                                            <li class="list-group-item"><i class="fa fa-check-circle text-success"></i> {{ condition }}</li>
                                        </template>
                                    </template>
                            </div><!--Div-->
                        </template>

                    </template>

                    </ul>

                    </template>

                    </template>

                </div>
            </div>
            
        </div><!--Col-->
      </div><!--Row-->
    </div>
  </template>
  
  <script>
  import queries from '../mixins/queries';
  
  export default {
      mixins: [ queries ],
      data () {
          return {
              processing: false,
              show_client: true,
              qr_code: null,
              results: {
                    fta: 0,
                    nca: 0,
                    nvca: 0
              },
              client: {},
              photos: [],
              form: {},
              supervisions: [],
              assessment: {},
              scores: [
                {
                    label: 'New Criminal Arrest (NCA)',
                    key: 'nca',
                },
                {
                    label: 'Failure to Appear (FTA)',
                    key: 'fta',
                },
                {
                    label: 'New Violent Criminal Arrest (NVCA)',
                    key: 'nvca',
                }
              ],
              fta_scores: {
                0: 'Not enough data',
                1: '89% likely to appear',
                2: '85% likely to appear',
                3: '81% likely to appear',
                4: '73% likely to appear',
                5: '69% likely to appear',
                6: '65% likely to appear'
              },
              nca_scores: {
                0: 'Not enough data',
                1: '91% likely arrest-free',
                2: '85% likely arrest-free',
                3: '78% likely arrest-free',
                4: '68% likely arrest-free',
                5: '55% likely arrest-free',
                6: '47% likely arrest-free'
            },
            nvca_scores: {
                0: 'Not enough data',
                1: 'NO',
                2: 'NO',
                3: 'NO',
                4: 'YES',
                5: 'YES',
                6: 'YES'
            }
          }
      },

      computed: {

        setReleaseLevel() {
            if(this.results.fta == 1 && this.results.nca <= 2) {
                return 1;
            }

            if(this.results.fta == 2 && this.results.nca <= 4) {
                return 1;
            }

            if(this.results.fta == 2 && this.results.nca >= 5) {
                return 2;
            }

            if(this.results.fta == 3 && this.results.nca <= 4) {
                return 1;
            }

            if(this.results.fta ==3 && this.results.nca == 5) {
                return 2;
            }

            if(this.results.fta == 3 && this.results.nca == 6) {
                return 3;
            }

            if(this.results.fta == 4 && this.results.nca <= 4) {
                return 1;
            }

            if(this.results.fta == 4 && this.results.nca == 5) {
                return 2;
            }

            if(this.results.fta == 4 && this.results.nca == 6) {
                return 3;
            }

            if(this.results.fta == 5 && this.results.nca <= 5) {
                return 2;
            }

            if(this.results.fta == 5 && this.results.nca == 6) {
                return 3;
            }

            if(this.results.fta == 6 && this.results.nca >= 4) {
                return 3;
            }
        },


        checkErrors() {
            if(!this.form) {
                return false;
            }

            if(!this.form.case_number) {
                return 'Case number is required';
            }

            if(!this.form.age_arrest || this.form.age_arrest == 0) {
                return 'Age at arrest is required';
            }

            // if(!this.form.prior_felony && this.form.prior_conviction) {
            //     return 'Prior felony is required if prior conviction is true';
            // }

            // if(!this.form.prior_felony && this.form.prior_violent >= 1) {
            //     return 'Prior felony is required if prior violent is 1 or more';
            // }

            if(this.form.prior_felony && this.form.prior_violent >= 1 && !this.form.prior_conviction) {
                return 'Prior conviction is required if prior violent is 1 or more';
            }

            if(this.form.prior_sentence && !this.form.prior_conviction) {
                return 'Prior conviction is required if prior sentence is true';
            }

            return false;
        }

      },

  
      created () {
        this.resetForm();
        this.setURLParams();
        this.autoPopulate();
        this.getAssessment();
        this.getSupervisions();
      },
  
      methods: {

        resetForm() {
            this.form = {
                case_number: null,
                age_arrest: 0,
                current_violent_offense: false,
                pending_charge: false,
                prior_misdemeanor: false,
                prior_felony: false,
                prior_violent: 0,
                prior_fta: 0,
                prior_fta_older: false,
                prior_sentence: false
            }
        },

        setURLParams() {
            var searchParams = new URLSearchParams(window.location.search);

            var self = this;

            searchParams.forEach(function(value, key) {

                if(value == 'true') {
                    value = true;
                }

                if(value == 'false') {
                    value = false;
                }

                if(value == 'null' || value == 'undefined') {
                    return;
                }

                self.form[key] = value;

            });

            this.calculateScores();
        },

        updateURL() {
            history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url+'/psa', this.form));
        },

        getAssessment() {
            this.$axios.get('https://ecdassets.s3.us-gov-east-1.amazonaws.com/js/assessments.json')
            .then(response => {
                if(response && response.data) {
                    this.assessment = response.data[this.$route.params.id];
                }
            })
        },

        isHiddenField(field) {
            if(field.conditions) {
                var conditions = field.conditions;

                for(var i = 0; i < conditions.length; i++) {
                    var condition = conditions[i];

                    if(this.form[condition.key] == condition.value) {
                        return false;
                    }
                }

                return true;
            }

            return false;
        },

        setScoreClass(score) {
            if(this.results[score.key] == 0) {
                return 'list-group-item-secondary';
            }

            if(this.results[score.key] >= 5) {
                return 'list-group-item-danger';
            }

            if(this.results[score.key] >= 3) {
                return 'list-group-item-warning';
            }

            return 'list-group-item-success';
        },
        

        calculateScores() {

            this.updateURL();

            for(var i = 0; i < this.scores.length; i++) {
                this.calculateScore(this.scores[i]);
            }
        },

        calculateScore(score) {
            
            if(!score || !score.key) {
                return 0;
            }

            var result = 0;

            if(score.key == 'fta') {
                result = this.calculateFTA();
            }

            if(score.key == 'nca') {
                result = this.calculateNCA();
            }

            if(score.key == 'nvca') {
                result = this.calculateNVCA();
            }

            this.results[score.key] = result;

            return result;
        },

        calculateFTA() {

            if(!this.form) {
                return 0;
            }

            let result = 0;

            if(this.form.pending_charge == true) {
                result += 1;
            }

            if(this.form.prior_misdemeanor == true || this.form.prior_felony == true) {
                result += 1;
            }

            if(this.form.prior_fta && this.form.prior_fta == 1) {
                result += 2;
            }

            if(this.form.prior_fta && this.form.prior_fta >= 2) {
                result += 4;
            }

            if(this.form.prior_fta_older && this.form.prior_fta_older == true) {
                result += 1;
            }
            
            return this.scaleFTAScore(result);

        },

        scaleFTAScore(score) {

            if(score == 0) {
                return 1;
            }

            if(score == 1) {
                return 2;
            }

            if(score == 2) {
                return 3;
            }

            if(score == 3 || score == 4) {
                return 4;
            }

            if(score == 5 || score == 6) {
                return 5;
            }

            return 6;
        },

        calculateNCA() {
                
            if(!this.form) {
                return 0;
            }

            let result = 0;

            if(this.form.age_arrest && this.form.age_arrest <= 22) {
                result += 2;
            }

            if(this.form.pending_charge && this.form.pending_charge == true) {
                result += 3;
            }

            if(this.form.prior_misdemeanor && this.form.prior_misdemeanor == true) {
                result += 1;
            }

            if(this.form.prior_felony && this.form.prior_felony == true) {
                result += 1;
            }

            // if(this.form.prior_violent >= 1 && this.form.prior_violent <= 2) {
            //     result += 1;
            // }

            if(this.form.prior_violent == 1 || this.form.prior_violent == 2) {
                result += 1;
            }

            // if(this.form.prior_violent >= 1 && this.form.prior_violent >= 3) {
            //     result += 2;
            // }

            if(this.form.prior_violent >= 3) {
                result += 2;
            }

            if(this.form.prior_fta == 1) {
                result += 1;
            }

            if(this.form.prior_fta >= 2) {
                result += 2;
            }

            if(this.form.prior_sentence && this.form.prior_sentence == true) {
                result += 2;
            }


            return this.scaleNCA(result);
        },

        scaleNCA(score) {

            if(score == 0) {
                return 1;
            }

            if(score == 1 || score == 2) {
                return 2;
            }

            if(score == 3 || score == 4) {
                return 3;
            }

            if(score == 5 || score == 6) {
                return 4;
            }

            if(score == 7 || score == 8) {
                return 5;
            }

            return 6;

        },

        calculateNVCA() {
            if(!this.form) {
                return 0;
            }

            let result = 0;

            if(this.form.current_violent_offense && this.form.current_violent_offense == true) {
                result += 2;

                if(this.form.age_arrest && this.form.age_arrest <= 20) {
                    result += 1;
                }
            }

            if(this.form.pending_charge && this.form.pending_charge == true) {
                result += 1;
            }

            if(this.form.prior_misdemeanor && this.form.prior_misdemeanor == true) {
                result += 1;
            } else if(this.form.prior_felony && this.form.prior_felony == true) {
                result += 1;
            }

            if(this.form.prior_violent) {
                if(this.form.prior_violent == 1 || this.form.prior_violent == 2) {
                    result += 1;
                }

                if(this.form.prior_violent >= 3) {
                    result += 2;
                }
            }

            return this.scaleNVCA(result);
            
        },

        scaleNVCA(score) {

            if(score <= 1) {
                return 1;
            }

            if(score >= 6) {
                return 6;
            }

            return score;
        },

        autoPopulate() {

            if(!this.form.case_number) {
                return;
            }

            this.processing = true;

            this.$http.get('/cases?slim=true&limit=1&case_number='+this.form.case_number)
            .then(response => {
                if(response && response.status) {
                    this.processing = false;

                    if(!response.data || !response.data.length) {
                        return;
                    }

                    let record = response.data[0];

                    if(record && record.client) {
                        this.getClient(record);
                    }

                    this.setViolentOffense(record);

                    this.calculateScores();
                }
            })
        },

        getRecentFTAs() {

            if(!this.client || !this.client.uuid) {
                return;
            }

            this.$http.get('/events/total?appeared=FTA&client='+this.client.uuid+'&from_date='+this.$moment().subtract(2, 'years').format('YYYY-MM-DD')+'&to_date='+this.$moment().format('YYYY-MM-DD'))
            .then(response => {
                if(response && response.status) {

                    if(response.data && response.data >= 1) {
                        this.form.prior_fta = response.data;
                    }

                    this.getOlderFTAs();
                }
            })
        },

        getOlderFTAs() {

            if(!this.client || !this.client.uuid) {
                return;
            }

            this.$http.get('/events/total?appeared=FTA&client='+this.client.uuid+'&to_date='+this.$moment().subtract(2, 'years').format('YYYY-MM-DD'))
            .then(response => {
                if(response && response.status) {

                    if(response.data && response.data >= 1) {
                        this.form.prior_fta_older = true;
                    }

                    this.calculateScores();
                }
            })
        },

        getClient(record) {
            this.client = {};
            this.processing = true;
            this.$http.get('/clients/'+record.client)
            .then(response => {
                if(response && response.status) {

                    this.processing = false;

                    if(!response.data || !response.data.uuid) {
                        return;
                    }

                    this.client = response.data;

                    this.setArrestAge(record)

                    this.calculateScores();

                    this.getRecentFTAs();

                    this.getPhotos();
                }
            })
        },

        getPhotos() {

            this.photos = [];

            if(!this.client || !this.client.uuid) {
                return;
            }

            this.$http.get('/clients/'+this.client.uuid+'/photos')
            .then(response => {
                if(response && response.data) {
                    this.photos = response.data;
                }
            })
        },

        setArrestAge(record) {

            if(!this.client || !record) {
                return;
            }

            if(!this.client.dob) {
                return;
            }

            let date = record.arrest_date || record.offense_date || '';

            let arrest_date = new Date(record.arrest_date || record.offense_date);
            let dob = new Date(this.client.dob);

            let age = arrest_date.getFullYear() - dob.getFullYear();

            this.form.age_arrest = age;
        },

        setViolentOffense(record) {

            var keywords = ['murder', 'homicide', 'manslaughter', 'kidnapping', 'abduction', 'robbery', 'carjacking', 'arson', 'assault', 'sex offense', 'kidnapped'];

            if(record.offense_description) {

                record.offense_description = record.offense_description.toLowerCase();

                if(keywords.includes(record.offense_description)) {
                    this.form.current_violent_offense = true;
                }

            }
            
        },

        generateReport() {
            this.qr_code = null;
            this.getQRCode();
        },

        printReport() {
            window.print();
        },

        getPDF() {
          this.processing = true;
        
          let queries = {};

          this.queries = this.form;

          this.queries['nca'] = this.results['nca'];

          this.queries['fta'] = this.results['fta'];

          this.queries['nvca'] = this.nvca_scores[this.results['nvca']];

          this.queries['release_level'] = this.setReleaseLevel;

          let url = '/assessments/pdf';

          //build params into url
          const params = new URLSearchParams(this.queries).toString();

          url = `${url}?${params}`;

          this.$http.get(url, {responseType: 'blob'})
          .then(response => {
            if(response && response.data) {
              this.processing = true;

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', this.$route.meta.base_url+'.pdf');
              document.body.appendChild(link);
              link.click();

              link.parentNode.removeChild(link);
              window.URL.revokeObjectURL(url);

              this.processing = false;
            }
          })
        },

        getQRCode() {
        this.processing = true;

        let url = 'https://'+this.$root.current_region+'.ecourtdate.com/'+this.$route.meta.base_url+'/psa?';

        url = this.buildQueries(url, this.form);

        this.$http.post('/qrs?content='+url)
        .then(response => {
            if(response && response.data) {
                this.qr_code = response.data;
                this.processing = false;

                setTimeout(() => {
                    this.printReport();
                }, 1000);
            }
        })
      },

      calculateAge() {

        if(!this.client || !this.client.dob) {
            return;
        }

        let dob = new Date(this.client.dob);

        let date = new Date();

        return date.getFullYear() - dob.getFullYear();
      },


      getSupervisions() {
        this.processing = true;

        this.$http.get('/supervisions')
        .then(response => {
            if(response && response.data) {
                this.supervisions = response.data;
                this.processing = false;
            }
        })
      },
    }
  }
  </script>
  