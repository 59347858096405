<template>
  <div>

    <page-tabs page="message_templates" />

    <div class="row">

      <div class="col-9 mx-auto">

        <h4>Auto Message Templates</h4>
        <p class="lead">These templates will be imported as an Auto Message.</p>
        <auto-templates />

        <h4>Flow Templates</h4>
        <p class="lead">These templates will be imported a Flow Message.</p>
        <flow-templates />

      </div>

    </div>



  </div>
</template>
<script>


export default {
  

  data () {
    return {

    }
  },

  created () {

  },

  methods: {

  }
}
</script>
