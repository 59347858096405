import Vue from 'vue';
import moment from 'moment-timezone';

function currency(value) {
  if(!value) {
    return '$0.00';
  }

  return '$' + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function date (value) {
  if (value && moment(new Date(value)).isValid()) {
    return moment(value, 'YYYY-MM-DD').format('MM/DD/YY');
  }
}

function time (value) {
  if (value) {
    const timeSplit = value.split(':');
    if (Number(timeSplit[0]) > 11) {
      return ((timeSplit[0] % 12) === 0 ? 12 : timeSplit[0] % 12) + ':' + timeSplit[1] + ' PM';
    } else {
      return value + ' AM';
    }
  }
  return value;
}

function timeLocal (value) {
  if (value && moment(value, 'HH:mm:ss').isValid()) {
    var date = moment.utc(value, 'HH:mm:ss');
    return date.tz(moment.tz.guess() || localStorage.getItem('timezone')).format('h:mm A z');
  }
}

function datetime (value) {
  if (value && moment(value).isValid()) {
    var date = moment.utc(value, 'YYYY-MM-DD HH:mm:ss');
    return date.tz(moment.tz.guess() || localStorage.getItem('timezone')).format('MM/DD/YY h:mm A z');
  }
}

function datetimeMil (value) {
  if (value && moment(value, 'YYYY-MM-DD HH:mm:ss:SSS').isValid()) {
    var date = moment.utc(value, 'YYYY-MM-DD HH:mm:ss:SSS');
    return date.tz(moment.tz.guess() || localStorage.getItem('timezone')).format('MM/DD/YY h:mm:ss.SSS A z');
  }
}

function datetimeSec (value) {
  if (value && moment(value, 'YYYY-MM-DD HH:mm:ss').isValid()) {
    var date = moment.utc(value, 'YYYY-MM-DD HH:mm:ss');
    return date.tz(moment.tz.guess() || localStorage.getItem('timezone')).format('MM/DD/YY h:mm:ss A z');
  }
}

function dateDay (value, timezone) {
  if (value && moment.tz(value, timezone).isValid()) {
    var date = moment.tz(value, timezone);
    return date.format('dddd');
  }
}

function dateTimezone (value) {
  if (value && moment(value).isValid()) {
    var date = moment.utc(value, 'YYYY-MM-DD HH:mm:ss');
    return date.tz(moment.tz.guess() || localStorage.getItem('timezone')).format('YYYY-MM-DD HH:mm:ss');
  }
}

function timeago (value) {
  if (value && moment(value).isValid()) {
    return moment(value).fromNow();
  }
  return value;
}

function unixTimeago (value) {
  return moment.unix(new Date(value)).fromNow(true);
}

function unixDate (value) {
  return moment.unix(new Date(value)).format('MM/DD/YY h:mm A z');
}

function rmDashes (value) {
  if (value) {
    var str = value.replace(/_/g, " ");
    return str.replace(/_/, ' ');
  }
}

function metersFeet(value) {
  if(value) {
    return (value * 3.28084).toLocaleString() + ' feet';
  }

  return value
}

function metersMiles(value) {
  if(value) {
    return (value * 2.23694).toLocaleString() + ' MPH';
  }

  return value
}


const isSpecialBrowser = !!window.navigator.userAgent.match(/Trident.*rv:11./) || (window.navigator.userAgent.indexOf('Safari') > -1 && window.navigator.userAgent.indexOf('Chrome') < 0)
function formatDate (date) {
  if (isSpecialBrowser && date) {
    return moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD')
  } else {
    return date
  }
}

function formatTime (time) {
  if (isSpecialBrowser && time && time.toLowerCase().indexOf('m') > -1) {
    return moment('01/01/2020 ' + time, 'MM/DD/YYYY h:mm A').format('HH:mm')
  } else {
    return time
  }
}

function formatFormDate (date) {
  if (isSpecialBrowser && date && moment(date, 'YYYY-MM-DD').isValid()) {
    return moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY')
  } else {
    return date
  }
}

function formatFormTime (time) {
  if (isSpecialBrowser && time) {
    const timeSplit = time.split(':')
    if (Number(timeSplit[0]) > 11) {
      return ((timeSplit[0] % 12) === 0 ? 12 : timeSplit[0] % 12) + ':' + timeSplit[1] + ' PM'
    } else {
      return time + ' AM'
    }
  } else {
    return time
  }
}

function toLocaleTime (date, time) {
  var localDate = moment.utc(date + ' ' + time + ':00', 'YYYY-MM-DD HH:mm:ss')
  return localDate.tz(moment.tz.guess() || localStorage.getItem('timezone')).format('HH:mm')
}

function fileSize (bytes, decimalPoint) {
  if (bytes === 0) return '0 Bytes'
  var k = 1000
  var dm = decimalPoint || 2
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  var i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

function isValidUUID(str) {
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(str);
}

function duration (duration) {
      if(!duration) {
        return;
      }

      if(duration < 60) {
        duration = duration + 's';
      }

      if(duration < 3600) {
        duration = (duration / 60).toFixed(2) + 'm';
      }

      if(duration >= 3600) {
        duration = (duration / 3600).toFixed(2) + 'm';
      }

      if(!duration) {
        return;
      }

      duration = duration.toLocaleString('en-US', { maximumFractionDigits: 1});

      return duration;
}

function slugString(str) {
  
    str = str.replace(/^\s+|\s+$/g, '');
    str = str.toLowerCase();

    str = str.replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-'); 

    return str;
}

function parseMoney(str) {

  const cleaned = str.replace(/[^\d.,]/g, '');

  const periodIndex = cleaned.lastIndexOf('.');
  const commaIndex = cleaned.lastIndexOf(',');

  let normalized;

  if(commaIndex > periodIndex) {
    normalized = cleaned.replace(/\./g, '').replace(',', '.');
  } else {
    normalized = cleaned.replace(/,/g, '');
  }

  return parseFloat(normalized);
}

function formatPhone(str) {
  if (!str.startsWith('+1')) {
    return null;
  }

  const cleaned = str.replace(/\D/g, '').substring(1);

  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return str;
}

function truncateText(str, length) {
  if (str.length > length) {
    return str.substring(0, length) + '...';
  }
  return str;
}

const filters = { currency, date, datetime, dateDay, datetimeMil, datetimeSec, time, timeLocal, timeago, rmDashes, metersFeet, metersMiles, fileSize, unixTimeago, unixDate, formatDate, formatTime, formatFormDate, formatFormTime, dateTimezone, toLocaleTime, isValidUUID, duration, slugString, parseMoney, formatPhone, truncateText }

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

export default filters
