<template>
  <div class="card">
    <div class="card-body">
          <p class="card-title">
            <i class="fa fa-this.browser"></i> User Agent
          </p>
        
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <strong>Platform:</strong> {{ platform }}
            </li>
            <li class="list-group-item">
              <strong>Browser:</strong> {{ browser }}
            </li>
            <li class="list-group-item">
              <strong>OS:</strong> {{ os }}
            </li>
          </ul>

    </div><!--Body-->
  </div><!--Card-->
</template>
<script>
export default {

// props: ['raw'],

props: {
  raw: {
    type: String,
    default: null
  }
},

data() {
    return {
        browser: '',
        os: '',
        device: ''
    }
},


created() {
    this.parseUserAgent();
},

methods: {


    parseUserAgent() {

      this.platform = /Mobile|Tablet|iPad|iPhone/.test(this.raw) ? 'Mobile' : 'Desktop';
      
      if (this.raw.includes('Firefox')) {
        this.browser = 'Firefox';
      } else if (this.raw.includes('Chrome')) {
        this.browser = 'Chrome';
      } else if (this.raw.includes('Safari') && !this.raw.includes('Chrome')) {
        this.browser = 'Safari';
      } else if (this.raw.includes('Edg')) {
        this.browser = 'Edge';
      }
      
      if (this.raw.includes('Windows')) {
        this.os = 'Windows';
      } else if (this.raw.includes('Macintosh') || this.raw.includes('Mac OS X')) {
        this.os = 'macOS';
      } else if (this.raw.includes('Android')) {
        this.os = 'Android';
      } else if (this.raw.includes('iOS') || this.raw.includes('iPhone') || this.raw.includes('iPad')) {
        this.os = 'iOS';
      }
      
    }


    }

}
</script>