<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form
          ref="addForm"
          @submit.prevent="createForms"
        >
          <p>Add Form</p>

          <label for="add_form_search_clients">Search Clients</label>
          <multi-select
            id="add_form_search_clients"
            v-model="chosenClients"
            label="result"
            track-by="client_uuid"
            placeholder="Type to search clients"
            open-direction="bottom"
            :options="clients"
            :multiple="true"
            :searchable="true"
            :loading="processing"
            :internal-search="false"
            :clear-on-select="false"
            :close-on-select="false"
            :options-limit="300"
            :limit-text="limitText"
            :max-height="800"
            :show-no-results="true"
            :hide-selected="true"
            @search-change="searchClients"
          >
            <template slot="clear">
              <div
                v-if="chosenClients && chosenClients.length"
                class="multiselect__clear"
                @mousedown.prevent.stop="clearAll()"
              />
            </template>

            <template
              slot="tag"
              slot-scope="{ option, remove }"
            >
              <span class="multiselect__tag"><span v-if="option.result">{{ option.result }}</span><i
                aria-hidden="true"
                tabindex="1"
                class="multiselect__tag-icon primary"
                @click="remove(option)"
              /></span>
            </template>

            <span slot="noResult">No clients found.</span>
            <span slot="noOptions">Type to search clients by name, reference, or contact.</span>
          </multi-select>           

          <div class="form-group">
            <label for="form_name">Name</label>
            <input
              id="form_name"
              v-model="form.name"
              type="text"
              class="form-control"
            >
          </div>

          <div class="form-group">
            <label for="form_reference">Form Reference</label>
            <input
              id="form_reference"
              v-model="form.reference"
              type="text"
              class="form-control"
            >
          </div>

          <div class="form-group">
            <label for="redirect">Redirect</label>
            <input
              id="redirect"
              v-model="form.redirect"
              type="text"
              class="form-control"
            >
          </div>

          <div class="form-group">
            <label for="expires_at">Expires At</label>
            <input
              id="expires_at"
              v-model="form.expires_at"
              type="datetime-local"
              class="form-control"
            >
          </div>

          <button
            type="submit"
            class="btn btn-success"
            :disabled="processing"
          >
            Add Form
          </button>
        </form>
        <trash-form
          v-if="form && form.uuid"
          :base_url="base_url"
          :uuid="form.uuid"
          :title="'Form'"
        />
      </div><!--Body-->
    </div><!--Card-->
  </div>
</template>
<script>
import forms from '../mixins/forms';
export default {
    mixins: [ forms ],
    props: ['title', 'base_url'],
    data () {
        return {
            processing: false,
            form: {},
            clients: [],
            chosenClients: []
        }
    },

    methods: {

    createForms() {

        this.processing = true

        var self = this

        this.chosenClients.forEach(function(client) {
            
            self.$http.post('/forms?client='+client.uuid, self.form)
            .then(response => {
                if(response && response.data) {
                    new self.$noty({text: 'Form created'}).show();
                    
                    self.$eventBus.$emit("formAdded", response.data);

                    self.processing = false;
                }
            })


        });

        this.form = {}
        this.chosenClients = []

    },

    limitText (count) {
      return `and ${count} other clients`
    },
    searchClients (query) {
      this.processing = true
      this.$http.get('/search?search='+query)
      .then(response => {
        if (response) {
          this.clients = response.data
        }
        this.processing = false
      })
    },
    clearAll () {
      this.chosenClients = []
    },

    }
}
</script>