<template>
  <div>
    <template v-if="record && record.uuid">
      <a :href="base_url+'/'+record.uuid"><span class="badge badge-success">ONLINE {{ record.created_at | dateTimezone | timeago }}</span></a>
    </template>
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  props: [ 'client' ],

  data () {
    return {
      record: {},
      base_url: '/geolocations',
      queries: {
        slim: true,
        fields: 'uuid,created_at',
        sort: 'created_at',
        sortDirection: 'desc',
        limit: 1
      }
    }
  },

  created() {
    this.getRecord();
  },


  methods: {

    getRecord() {

      if(!this.client) {
        return;
      }

      this.queries.client = this.client;

      this.$http.get(this.buildQueries(this.base_url, this.queries))
      .then(response => {
        if(response && response.data && response.data[0]) {

          if(!this.record || !this.record.uuid) {
            this.record = response.data[0];
          }
          
        }
      })
    }

  },
}
</script>
