<template>
  <div>

<page-tabs page="message_templates" />

      <div class="row" id="main">
        <div class="col-sm-12 col-md-3">

          <div class="card">
            <div class="card-body">
              <form @submit.prevent="postForm()">


              <template v-if="client && client.uuid">

                    <div class="row mb-3">
                      <div class="col-md-6">
                        <p class="card-title">
                          Client <router-link :to="{ 'name': 'clients.edit', 'params': {'id': client.uuid} }">
                            {{ client.first_name }} {{ client.middle_name }} {{ client.last_name }}
                            {{ client.language }}
                          </router-link>
                        </p>
                      </div><!--Col-->

                      <div class="col-md-6 text-right">
                        <button
                          type="submit"
                          class="btn btn-danger btn-sm"
                          :disabled="processing"
                          @click="closeClient"
                        >
                          <i class="fa fa-times" /> <span class="sr-only">Close</span>
                        </button>
                      </div><!--Col-->
                    </div><!--Row-->

              </template>

              <template v-if="client && !client.uuid && !queries.simulate">
                <client-search />
              </template>

              <template v-if="!client || !client.uuid">

                <div class="form-group custom-control custom-switch">
                  <input
                    id="simulate"
                    v-model="queries.simulate"
                    type="checkbox"
                    class="custom-control-input"
                  >
                  <label
                    class="custom-control-label"
                    for="simulate"
                  > Simulate Client</label>
                </div>

              </template>

                <div class="form-group">
                  <label for="flow">Flow</label>
                  <select
                    id="flow"
                    v-model="queries.flow"
                    class="form-control"
                  >
                    <option value="" />
                    <template v-for="flow in flows">
                      <option
                        :value="flow.uuid"
                      >
                        {{ flow.name }}
                      </option>
                    </template>
                  </select>
                </div>

                <template v-if="client && client.uuid">
                  <template v-if="events && events.length > 0">
                    <div class="form-group">
                      <label for="event">Event</label>
                      <select
                        id="event"
                        v-model="queries.event"
                        class="form-control"
                      >
                        <option value="" />
                        <template v-for="event in events">
                          <option
                            :value="event.uuid"
                          >
                            {{ event.date | date }} {{ event.time | time }}

                            {{ event.case_number || event.event_reference || '' }}
                          </option>
                        </template>
                      </select>
                    </div>
                  </template>

                  <template v-if="payments && payments.length > 0">
                    <div class="form-group">
                      <label for="payment">Payment</label>
                      <select
                        id="payment"
                        v-model="queries.payment"
                        class="form-control"
                      >
                        <option value="" />
                        <template v-for="payment in payments">
                          <option
                            :value="payment.uuid"
                          >
                            {{ payment.payment_reference }} 

                            <template v-if="payment.amount">
                              ${{ payment.amount }}
                            </template>
                          </option>
                        </template>
                      </select>
                    </div>
                  </template>
                </template>

                <div class="form-group text-capitalize">
                  <label for="language">Language</label>
                  <select
                      id="language"
                      v-model="queries.language"
                      class="form-control"
                  >
                    <option
                        v-for="language in languages"
                        :value="language.key"
                    >
                      {{ language.label }}
                    </option>
                  </select>
                </div>

                <div class="row mb-3">
                  <div class="col-md-6">
                    <button
                      type="button"
                      class="btn btn-outline-primary btn-sm"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Auto Messages
                    </button>
                    <div class="dropdown-menu">
                      <template v-for="auto_message in auto_messages">
                        <a
                          class="dropdown-item"
                          @click="changeAutoMessage(auto_message.uuid)"
                        >{{ auto_message.name }}</a>
                      </template>
                    </div>
                  </div><!--Col-->

                  <div class="col-md-6">
                    <button
                      type="button"
                      class="btn btn-outline-primary btn-sm"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Merge Tags
                    </button>
                    <div class="dropdown-menu">
                      <template v-for="(merge_tag, index) in merge_tags">
                        <a
                          class="dropdown-item"
                          @click="addMergeTag(merge_tag)"
                        >{{ merge_tag }}</a>
                      </template>
                    </div>
                  </div><!--Col-->
                </div><!--Row-->

                <div class="form-group">
                  <label for="content">Content</label>
                  <textarea
                    id="content"
                    ref="formContent"
                    v-model="queries.content"
                    class="form-control"
                    rows="10"
                    @change="postForm()"
                  />
                </div>

                <div class="form-group">
                  <label for="device">Device</label>
                  <select
                    id="device"
                    v-model="device"
                    class="form-control"
                  >
                    <template v-for="(device_option, index) in devices">
                      <option
                        :value="device_option"
                      >
                        {{ device_option }}
                      </option>
                    </template>
                  </select>
                </div>

                <template v-if="device != 'macbook'">
                  <div class="form-group">
                    <fieldset>
                      <legend>Orientation</legend>
                      <label
                        class="mr-2 text-capitalize"
                        for="portrait"
                      >
                        <input
                          id="portrait"
                          v-model="orientation"
                          type="radio"
                          name="orientation"
                          value="portrait"
                        > Portrait
                      </label>
                      <label
                        class="mr-2 text-capitalize"
                        for="landscape"
                      >
                        <input
                          id="landscape"
                          v-model="orientation"
                          type="radio"
                          name="orientation"
                          value="landscape"
                        > Landscape
                      </label>
                    </fieldset>
                  </div>
                </template>

                <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
                >
                  Preview Messages
                </button>
              </form>
            </div><!--Body-->
          </div><!--Card-->
        </div><!--Col-->

        <div class="col-sm-12 col-md-6">
          <div
            class="marvel-device"
            :class="deviceStyle"
          >
            <div class="notch">
              <div class="camera" />
              <div class="speaker" />
            </div>
            <div class="top-bar" />
            <div class="sleep" />
            <div class="bottom-bar" />
            <div class="volume" />
            <div class="overflow">
              <div class="shadow shadow--tr" />
              <div class="shadow shadow--tl" />
              <div class="shadow shadow--br" />
              <div class="shadow shadow--bl" />
            </div>
            <div class="inner-shadow" />
            <div
              class="screen"
              :class="deviceColor"
              style="overflow-y:auto"
            >

              <template v-if="previews && previews.length > 0">
                <template v-for="preview in previews">
                  <span>
                    <p
                      class="mt-3 py-6"
                      style="margin:10px"
                    >{{ preview.message_type }}

                    <template v-if="preview.content">
                      ~{{ preview.content.length }} characters
                    </template>
                    </p>

                    <template v-if="preview.pre_merge">
                      <p
                        class="inbound mt-3 py-6"
                        style="margin:10px"
                      >{{ preview.pre_merge }}</p>
                    </template>

                    <p
                      class="outbound mt-3 py-6"
                      style="margin:10px"
                    >{{ preview.content }}</p>
                                    
                  </span>
                </template>
              </template>
            </div>
          </div>
        </div><!--Col-->

        <div class="col-sm-12 col-md-3">
          <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
        </div>
      </div><!--Row-->
  </div>
</template>
<style>
@import 'https://ecdassets.s3.amazonaws.com/css/devices.min.css';
.marvel-device.iphone-x .screen {padding-top:35px !important}
</style>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data() {
    return {
        processing: false,
        downloading: false,
        client: {},
        queries: {},
        previews: [],
        merge_tags: [],
        auto_messages: [],
        flows: [],
        clients: [],
        events: [],
        payments: [],
        languages: [],
        device: 'iphone-x',
        orientation: 'portrait',
        devices: ['iphone-x', 's5', 'note8', 'iphone8', 'nexus5', 'iphone4s', 'ipad', 'iphone5c', 'macbook']
    }
},

computed: {

    deviceStyle() {
        return this.device + ' ' + this.orientation;
    },

    deviceColor() {

        let theme = localStorage.getItem('theme') || 'light';

        if(theme == 'dark') {
          return 'bg-primary';
        }

        return 'bg-light';
    }
},

mounted() {

    var self = this;

    this.$eventBus.$on('search_clients', function(uuid) {

       self.queries.client = uuid;

       self.getClient();

    });

},

created() {

    document.title = this.$route.meta.title + ' | eCourtDate.com';

    this.resetRecords();
    this.resetQueries();
    this.setURLParams();
    // this.getSearch();
    this.postForm();
    this.getMergeTags();
    this.getLanguages();
    this.getFlows();
    this.getAutoMessages();

},

methods: {

  resetQueries() {
    this.queries = {
      client: null,
      event: null,
      content: null,
      language: 'en',
      simulate: false
    }
  },

  resetRecords() {
    this.previews = [];
    this.clients = [];
    this.events = [];
    this.payments = [];
  },

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });

        if(this.queries.client) {
          this.getClient();
        }
    },

    postForm() {

        this.processing = true;

        if(this.client && this.client.uuid) {
            this.queries.client = this.client.uuid;
        }

        if(this.queries.simulate) {
          this.setSimulate();
        }

        if(!this.queries.simulate) {
          this.resetSimulate();
        }

        this.updateURL();
        
        this.$http.post(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
        .then(response => {
            if(response && response.data) {
                this.processing = false;
                this.previews = response.data;
            }
        })
    },

    setSimulate() {
        this.queries.client_name = 'Jane Doe';
        this.queries.client_reference = 'D-12345';
        this.queries.client_group = 'B-A';
        this.queries.client_language = this.queries.language || 'en';
        this.queries.client_gender = 'm';
        this.queries.client_race = 'w';
        this.queries.event_date = '2025-10-01';
        this.queries.event_time = '10:00';
        this.queries.judge_name = 'Mary Smith';
        this.queries.case_number = '12345';
        this.queries.location_name = 'Courtroom 1';
        this.queries.event_type = 'Hearing';
        this.queries.event_description = 'Hearing on Motion';
    },

    resetSimulate() {
      
      var fields = ['client_name', 'client_group', 'client_language', 'client_status', 'client_gender', 'client_reference', 'event_date', 'event_time', 'judge_name'];

      var self = this;

      fields.forEach(field => {
        self.queries[field] = null;
      })
    },

    updateURL() {
        history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    changeAutoMessage (uuid) {
        this.auto_message = _.find(this.auto_messages, {'uuid': uuid});

        if(!this.auto_message.templates || !this.auto_message.templates['en']) {
          return;
        }

        this.queries.content = this.auto_message.templates['en'];
    },

    getAutoMessages () {
        this.$http.get('/auto_messages')
        .then(response => {
            if(response && response.data) {
                this.auto_messages = response.data;
            }
        })
    },

    getFlows() {
        this.$http.get('/flows?slim=true&fields=name,uuid&limit=1000&sort=name&sortDirection=asc')
        .then(response => {
            if(response && response.data) {
                this.flows = response.data;
            }
        })
    },

    addMergeTag(value) {
      let selectionIndex = this.$refs.formContent.selectionStart;
      let newValue = '';
      if (this.queries.content) {
        newValue = this.queries.content.slice(0, selectionIndex) + ' ' + value + ' ' + this.queries.content.slice(selectionIndex, this.queries.content.length);
      } else {
        newValue = value;
      }
      this.queries.content = newValue;
    },

    getMergeTags () {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/mergetags.json')
      .then(response => {
          if (response && response.data) {
            this.merge_tags = response.data.map(tag => { return tag.value });
          }
      })
    },

    getLanguages () {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/languages.json')
          .then(response => {
            if(response && response.data) {
              this.languages = response.data;
            }
          })
    },

    getRecords() {

        if(!this.client.uuid) {
          return;
        }


        this.getEvents();
        this.getPayments();
    },

    getEvents() {
        this.$http.get('/events?client='+this.client.uuid+'&slim=true&fields=uuid,date,time,event_reference,case_number&sort=date&sortDirection=asc')
        .then(response => {
            if(response && response.data) {
                this.events = response.data;
            }
        })
    },

    getPayments() {

        if(!this.client.uuid) {
          return;
        }

        this.$http.get('/payments?client='+this.client.uuid+'&slim=true&fields=uuid,amount,payment_reference&sort=amount&sortDirection=asc')
        .then(response => {
            if(response && response.data) {
                this.payments = response.data;
            }
        })
    },

    resetClient() {
      this.client = {};
      this.events = [];
      this.payments = [];
      this.queries.client = null;
    },

    getClient() {

        if(!this.queries.client) {
            this.resetClient();
            return;
        }

        this.processing = true;

        this.$http.get('/clients/'+this.queries.client+'?slim=true&fields=uuid,client_reference,first_name,last_name,group,type,language')
        .then(response => {
            if(response && response.data) {
                this.client = response.data;
                this.processing = false;
                this.getRecords();
            }
        })
    },

    closeClient() {
        this.resetClient();
        this.updateURL();
    },


}

}
</script>