<template>
  <div>
    <div class="row">
      <div class="col-md-12 mb-3">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="postForm()">

              <div class="row mb-3">

                <div class="col-sm-12 col-lg-6">


                    <template v-if="dashboard && dashboard.url && dashboard.domain && form && form.uuid">
                      <a :href="'https://'+dashboard.subdomain+'.'+dashboard.domain+'/'+form.uuid+'?preview='+dashboard.url" target="_blank" class="btn btn-outline-primary">
                      <i class="fa fa-link"></i>
                      Open Link
                      </a>
                    </template>

                </div><!--Col-->

                <div class="col-sm-12 col-lg-6 text-right">
                  <button
                    type="submit"
                    class="btn btn-success"
                    :disabled="processing"
                  >
                    Save
                  </button>
                </div><!--Col-->

              </div><!--Row-->

              <div class="form-group">
                <label for="name">Name</label>
                <input
                  id="name"
                  v-model="form.name"
                  class="form-control"
                  @change="changeForm()"
                  required
                >
              </div>

              <div class="form-group">
                <label for="description">Description</label>
                <textarea
                  id="description"
                  v-model="form.description"
                  class="form-control"
                  rows="3"
                  @change="changeForm()"
                />
              </div>

              <div class="form-group">
                <label for="data_source">Data Source</label>
                <select id="data_source" class="form-control" v-model="form.data_source" @change="changeForm()" :disabled="processing">
                  <option value=""></option>
                  <template v-for="source_option in $root.data_sources">
                    <option :value="source_option">{{ source_option }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="type">Type</label>
                <select
                  v-model="form.type"
                  class="form-control"
                  @change="changeForm()"
                  :disabled="processing"
                >
                  <template v-for="data_type in data_types">
                    <option
                      :value="data_type"
                    >
                      {{ data_type }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group" v-if="form.type == 'sum'">
                <label for="sum_field">Sum Field</label>
                <input id="sum_field" type="text" class="form-control" v-model="form.sum_field" @change="changeForm()">
              </div>

              <div class="form-group" v-if="form.type == 'rate'">
                <label for="data_compare">Data Compare

                  <template v-if="form.data_compare">
                    <a
                    :href="'/datapoints/'+form.data_compare"
                    target="_blank"
                    data-tooltip="Edit Datapoint">
                    <i class="fa fa-edit"></i> <span class="sr-only">Edit Datapoint</span>
                    </a>
                  </template>
                </label>
                <select id="data_compare" class="form-control" 
                v-model="form.data_compare"
                @change="changeForm()"
                :disabled="processing">
                  <template v-if="datapoints && datapoints.length > 0">
                  <template v-for="datapoint in datapoints">
                    <option :value="datapoint.uuid || datapoint.key">{{ datapoint.name || datapoint.key }}</option>
                  </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="date_filter">Date Filter</label>
                <select id="date_filter" class="form-control" v-model="form.date_filter" @change="changeForm()" :disabled="processing">
                    <template v-if="dataSource && date_filters[dataSource] && date_filters[dataSource].length">
                      <template v-for="date_filter in date_filters[dataSource]">
                        <option
                          :value="date_filter"
                        >
                          {{ date_filter }}
                        </option>
                      </template>
                    </template>
                </select>
              </div>

              <div class="form-group">
                <label for="group">Group By</label>
                <select id="group" class="form-control" v-model="form.group" :disabled="processing" @change="changeForm()">
                  <option value=""></option>
                  <option value="any">Any</option>
                    <template v-if="dataSource && group_filters[dataSource]">
                      <template v-for="group_filter in group_filters[dataSource]">
                        <option
                          :value="group_filter"
                        >
                          {{ group_filter }}
                        </option>
                      </template>
                    </template>
                </select>
              </div>

              <template v-if="data_groups && data_groups.length >= 0 && form.group">

              <div class="form-group">
                <label for="data"><span v-if="form.data && form.data.length">{{ form.data.length }}</span> Data Groups <template v-if="form.group"><code>({{form.group}})</code></template></label><br>
                <multi-select 
                  id="data"
                  v-model="form.data" 
                  :options="data_groups"
                  :multiple="true"
                  :close-on-select="false"
                  :disabled="processing"
                  @input="changeForm()"
                />
              </div>

              </template>

              <div class="btn-group" :hidden="!form.group">

              <button type="button" class="btn btn-outline-secondary btn-sm mr-1 mb-3" @click="toggleGroup()" :disabled="processing">Add Custom</button>

              <template v-if="form.data && form.data.length != data_groups.length">
                <button type="button" class="btn btn-outline-secondary btn-sm mb-3 mr-1" @click="addAllGroups()" :disabled="processing">Add All</button>
              </template>

              <template v-if="form.data && form.data.length > 0">
                <button type="button" class="btn btn-outline-secondary btn-sm mb-3" @click="removeAllGroups()" :disabled="processing">Remove All</button>
              </template>

              </div>


              <template v-if="show_custom_group">
                  <div class="input-group mb-3">
                    <input type="text" class="form-control" aria-label="Add Custom Group" aria-describedby="button-custom-group" v-model="custom_group">
                    <div class="input-group-append">
                      <button class="btn btn-outline-success" type="button" id="button-custom-group" @click="addGroup()">Add Custom Group</button>
                    </div>
                  </div>
              </template>

              <div class="form-group">
                <label for="x_title">X axis Title</label>
                <input id="x_title" class="form-control" type="text" v-model="form.x_title">
              </div>

              <div class="form-group">
                <label for="y_title">Y axis Title</label>
                <input id="y_title" class="form-control" type="text" v-model="form.y_title">
              </div>

              <div class="form-group">
                <label for="sort">Sort</label>
                <input
                  id="sort"
                  v-model="form.sort"
                  class="form-control"
                  @change="changeForm()"
                >
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  :id="'show_legend_'+component.uuid"
                  v-model="form.show_legend"
                  type="checkbox"
                  class="custom-control-input"
                  @change="changeForm()"
                >
                <label
                  class="custom-control-label"
                  :for="'show_legend_'+component.uuid"
                > Show Legend</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  :id="'show_source_data_'+component.uuid"
                  v-model="form.source_data"
                  type="checkbox"
                  class="custom-control-input"
                  @change="changeForm()"
                >
                <label
                  class="custom-control-label"
                  :for="'show_source_data_'+component.uuid"
                > Show Source Data</label>
              </div>

              <hr>

              <div class="row mb-3">
                <div class="col-md-6">
                  <p class="card-title">
                    {{ form.filters.length }} Filters
                  </p>
                </div><!--Col-->

                <div class="col-md-6 text-right">
                  

                </div><!--Col-->
              </div><!--Row-->

                <ul class="list-group">
                  <template v-for="filter in form.filters">
                    <li
                      class="list-group-item"
                    >

                    <div class="row">
                      <div class="col-9">
                        <code>{{ filter.field }} {{ filter.operator }} {{ filter.value }}</code>
                      </div><!--Col-->

                      <div class="col-3 text-right">

                      <button type="button" class="btn btn-sm btn-danger" 
                      @click="trashFilter(filter_key)"
                      data-tooltip="Trash filter"
                      :disabled="processing"
                      ><i class="fa fa-times"></i> <span class="sr-only">Trash Filter</span></button>

                      </div><!--Col-->

                    </div><!--Row-->

                    </li>
                  </template>
                </ul>

                <br>

                <p class="card-title">Add a Filter</p>

                <form @submit.prevent="addFilter()">

                <div class="form-group">
                  <label for="filter_field">Field</label>
                  <select
                    id="filter_field"
                    class="form-control"
                    v-model="filterForm.field"
                    :disabled="processing"
                    required
                  >
                  <option value=""></option>
                    <template v-if="dataSource && fields[dataSource]">
                      <template v-for="field in fields[dataSource]">
                        <option
                          :value="field"
                        >
                          {{ field }}
                        </option>
                      </template>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="filter_operator">Operator</label>
                  <select
                    id="filter_operator"
                    class="form-control"
                    v-model="filterForm.operator"
                    :disabled="processing"
                  >
                    <template v-for="operator in operators">
                      <option
                        :value="operator"
                      >
                        {{ operator }}
                      </option>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="filter_value">Value</label>
                  <input
                    id="filter_value"
                    v-model="filterForm.value"
                    class="form-control"
                  >
                </div>

                <button
                  type="submit"
                  class="btn btn-outline-success"
                  :disabled="processing"
                >
                  Add Filter
                </button>

              </form>

              <div class="row">
                <div class="col-9">

                </div><!--Col-->

                <div class="col-3 text-right">
                  <button
                    type="button"
                    class="btn btn-danger tooltip-left"
                    :disabled="processing"
                    data-tooltip="Trash component"
                    @click="trashForm"
                  >
                    <i class="fa fa-trash" /> Trash
                  </button>
                </div><!--Col-->
              </div><!--Row-->
            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

    mixins: [ queries ],

    props: ['component', 'datapoints', 'data_source', 'dashboard'],

    data() {
        return {
            processing: false,
            changed: false,
            base_url: '/components',
            form: {},
            queries: {
                slim: true,
                simulate: true
            },
            current_group: null,
            date_filters: {
              'events': ['date', 'created_at'],
              'messages': ['scheduled_at', 'created_at'],
              'clients': ['created_at'],
              'cases': ['file_date', 'created_at'],
              'client_cases': ['file_date', 'created_at'],
              'contacts': ['created_at', 'optout_at', 'event_date'] ,
              'opts': ['scheduled_at', 'created_at'],
              'payments': ['scheduled_at', 'created_at']
            },
            group_filters: {
              'events': ['type', 'status', 'location', 'age', 'judge', 'weather', 'repeat', 'language', 'optin', 'virtual', 'case_class'],
              'messages': ['channel', 'direction', 'error_code', 'location', 'language', 'segments', 'credits', 'status', 'type', 'case_class', 'optin'],
              'clients': ['age', 'group', 'type'],
              'contacts': ['channel', 'valid', 'optin', 'language', 'location', 'case_class', 'type', 'optout_reason', 'delivered_messages', 'failed_messages', 'optout_at', 'event_date'],
              'opts': ['channel', 'type', 'location', 'case_class', 'user_agent'],
              'payments': ['amount', 'status', 'location', 'type'],
              'cases': ['location', 'charge_code', 'judge', 'type', 'status', 'language'],
              'client_cases': ['judge', 'type', 'status', 'language']
            },
            data_types: ['count', 'sum', 'rate'],
            operators: ['=', '!=', 'contains', 'not contains', '<', '<=', '>', '>='],
            data_groups: [],
            fields: {
              'events': ['start_date', 'start_time', 'judge', 'status', 'appeared', 'type', 'location', 'agency_name', 'language'],
              'messages': ['from', 'to', 'status', 'direction', 'channel', 'location', 'agency_name', 'language', 'type', 'error_code', 'segments', 'scheduled_at'],
              'clients': ['type', 'group', 'status'],
              'cases': ['type', 'status', 'judge', 'appeared', 'language'],
              'client_cases': ['status', 'type', 'judge', 'appeared', 'language', 'file_date'],
              'contacts': ['channel', 'type', 'valid', 'notify', 'optin', 'file_date'],
              'opts': ['channel', 'user_agent', 'location', 'agency_name', 'type'],
              'payments': ['status', 'amount', 'type', 'location', 'agency_name']
            },
            show_custom_group: false,
            custom_group: ''
        }
    },

    computed: {

      dataSource() {
        
        if(!this.form.data_source) {
          return this.data_source;
        }
      
        return this.form.data_source;
      }

    },

    mounted() {

      var self = this;
      this.$eventBus.$on('saveDashboard', function () {
        self.postForm();
      });

    },

    created () {
        this.resetForm();
        this.resetFilterForm();
        this.getForm();
    },

    methods: {

        changeForm() {
          this.changed = true;

          // if(this.current_group != this.form.group) {
          //   this.getDataGroups();
          // }
        },

        resetForm() {
            this.form = {
                name: null,
                description: null,
                datapoint: null,
                group: null,
                data: [],
                data_source: null,
                date_filter: null,
                data_compare: null,
                sum_field: null,
                source_data: 0,
                show_legend: 1,
                x_title: null,
                y_title: null,
                type: null,
                sort: null,
                filters: []
            }
        },

        getForm() {
            this.processing = true;
            this.$http.get(this.base_url+'/'+this.component.uuid)
            .then(response => {
                if(response && response.data) {
                    this.processing = false;
                    this.form = response.data;

                    if(!Array.isArray(this.form.filters)) {
                      this.form.filters = [];
                    }

                    // this.getDataGroups();
                }
            })
        },

        getDataGroups() {

          if(!this.dataSource) {
            return;
          }

          this.processing = true;
          this.$http.get('/dashboards/'+this.component.dashboard+'/data_groups?limit=1000&data_source='+this.dataSource+'&group='+this.form.group)
          .then(response => {
            if(response && response.status) {

              this.data_groups = [];

              if(response.data && response.data.length >= 1) {
                this.current_group = this.form.group;
                this.data_groups = response.data;                
              }

              this.processing = false;
            }
          })
        },

        postForm() {

            if(!this.changed) {
              return;
            }

            this.processing = true;
            this.$http.patch(this.base_url+'/'+this.component.uuid, this.form)
            .then(response => {
                if(response && response.data) {
                    this.processing = false;
                    new this.$noty({text: 'Component updated'}).show();
                }
            })
        },

        trashForm() {
            this.processing = true;
            this.$http.delete(this.base_url+'/'+this.component.uuid)
            .then(response => {
                if(response && response.status === 204) {
                    this.processing = false;
                    this.$eventBus.$emit('componentDeleted', this.component)
                }
            })
        },

        resetFilterForm() {
          this.filterForm = {
            field: null,
            operator: '=',
            value: ''
          }
        },

        addFilter() {
          this.form.filters.push(this.filterForm);
          this.resetFilterForm();
          this.changeForm();
        },

        editFilter(key) {
          this.filterForm.field = this.form.filters[key]['field'];
          this.changeForm();
        },

        trashFilter(key) {
          this.form.filters.splice(key, 1);

          this.changeForm();
        },

        addAllGroups() {

          this.changeForm();

          this.form.data = this.data_groups;

          var self = this;

          this.data_groups.forEach(function(group) {
            if(self.form.data.includes(group)) {
              return;
            }

            self.form.data.push(group);
          })
        },

        removeAllGroups() {
          
          this.changeForm();

          this.form.data = [];
        },

        toggleGroup() {
          
          this.changeForm();

          if(this.show_custom_group == true) {
            this.show_custom_group = false;
            return;
          }

          this.show_custom_group = true;
        },

        addGroup() {

          this.changeForm();

          if(!this.data_groups.includes(this.custom_group)) {
            this.data_groups.unshift(this.custom_group);
          }

          if(!this.form.data.includes(this.custom_group)) {
            this.form.data.unshift(this.custom_group);
          }

          this.custom_group = '';

          new this.$noty({text: 'Custom Data Group added'}).show();
        }
    
    }

}
</script>