<template>
  <div>
    <div class="row" :hidden="processing">
      <div class="col-12">
        <template v-if="form.uuid && form.archived_at">
          <div id="archived_alert" class="alert alert-warning d-flex align-items-center">
            {{ title }} was archived on {{ form.archived_at | datetime }}.
          </div>
        </template>

        <template v-if="form.uuid && form.deleted_at">
          <div id="trashed_alert" class="alert alert-danger d-flex align-items-center">
            {{ title }} was trashed on {{ form.deleted_at | datetime }}.
          </div>
        </template>

        <template v-if="!processing && form && !form.uuid">
          <div id="404_alert" class="alert alert-danger">
            {{ title }} not found.
          </div>
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
export default {

props: ['form', 'title', 'processing'],

}
</script>