<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-3">
          <div class="card">
            <div class="card-body">
              <p class="card-title">
                Search Clients
              </p>

              <form @submit.prevent="searchStats">
                <div class="form-group">
                  <label for="stat">Choose Stat</label>
                  <select
                    id="stat"
                    v-model="queries.stat"
                    class="form-control text-capitalize"
                  >
                    <template v-for="stat in stat_options">
                      <option
                        :value="stat.key"
                      >
                        {{ stat.label }}
                      </option>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="client_reference">Client Reference</label>
                  <input
                    id="client_reference"
                    v-model="queries.client_reference"
                    type="type"
                    class="form-control"
                  >
                </div>

                <div class="form-group">
                  <label for="client_group">Client Group</label>
                  <select
                    id="client_group"
                    v-model="queries.group"
                    class="form-control"
                    :disabled="processing"
                  >
                    <option value="" />
                    <template v-for="client_group in client_groups">
                      <option
                        :value="client_group.group"
                      >
                        {{ client_group.group }}
                      </option>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="client_type">Client Type</label>
                  <select
                    id="client_type"
                    v-model="queries.type"
                    class="form-control"
                    :disabled="processing"
                  >
                    <option value="" />
                    <template v-for="client_type in client_types">
                      <option
                        :value="client_type"
                      >
                        {{ client_type }}
                      </option>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="language">Preferred Language</label>
                  <select
                    id="language"
                    v-model="queries.language"
                    class="form-control text-capitalize"
                    :disabled="processing"
                  >
                    <option value="" />
                    <template v-for="language in languages">
                      <option
                        :value="language.key"
                      >
                        {{ language.label }}
                      </option>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="created_from">Created From</label>
                  <input
                    id="created_from"
                    v-model="queries.created_from"
                    type="date"
                    class="form-control"
                  >
                </div>

                <div class="form-group">
                  <label for="created_to">Created To</label>
                  <input
                    id="created_to"
                    v-model="queries.created_to"
                    type="date"
                    class="form-control"
                  >
                </div>

                <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="processing"
                >
                  Search Clients
                </button>
              </form>
            </div><!--Body-->
          </div><!--Card-->
        </div><!--Col-->

        <div class="col-md-9">
          <div class="card">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-6">
                  <p class="card-title">
                    {{ stats.length }} of <template v-if="total >= 0">
                      {{ total.toLocaleString() }}
                    </template> Clients
                  </p>
                </div><!--Col-->
                <div class="col-6 text-right">
                  <template v-if="!downloading">
                    <button
                      type="button"
                      class="btn btn-outline-secondary text-right"
                      :disabled="processing"
                      @click="downloadCSV"
                    >
                      <i class="fa fa-file-csv" /> CSV
                    </button>
                  </template>

                  <template v-if="downloading && downloads.length > 0">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        :aria-valuenow="downloads.length"
                        :style="'width:' +download_progress+'%'"
                        aria-valuemin="0"
                        :aria-valuemax="total"
                      />
                    </div>
                  </template>
                </div><!--Col-->
              </div><!--Row-->

              <table class="table table-hover table-striped">
                <thead>
                  <tr>
                    <template v-for="column in columns">
                      <th>
                        <template v-if="column.key">
                          <span
                            class="link-hover"
                            @click="sortColumn(column)"
                          >{{ column.label }}</span>
                        </template>

                        <template v-if="!column.key">
                          <span>{{ column.label }}</span>
                        </template>

                        <template v-if="column.key === queries.sort">
                          <button
                            type="button"
                            class="btn btn-outline-primary btn-sm ml-1"
                            :disabled="processing"
                            @click="updateSortDirection()"
                          >
                            <template v-if="queries.sortDirection == 'asc'">
                              <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                            </template>

                            <template v-if="queries.sortDirection == 'desc'">
                              <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                            </template>
                          </button>
                        </template>
                      </th>
                    </template>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(client_stat, index) in stats">
                    <tr
                      class="link-hover"
                      @click="goToRecord(client_stat.uuid)"
                    >
                      <td>{{ client_stat.client_reference }}</td>
                      <td>{{ client_stat.first_name }}</td>
                      <td>{{ client_stat.last_name }}</td>
                      <td>{{ client_stat.language }}</td>
                      <td>{{ client_stat.group }}</td>
                      <td>{{ client_stat.type }}</td>
                      <td>{{ client_stat.created_at | datetime }}</td>
                    </tr>
                  </template><!--Client-->
                </tbody>
              </table>
            </div><!--Body-->
          </div><!--Card-->

          <div class="text-center">
            <button
              type="button"
              class="btn btn-outline-primary mt-3"
              :disabled="processing"
              @click="getStats"
            >
              <loader :is-loading="processing" /> <span v-if="!processing">Load more</span>
            </button>
          </div>
        </div><!--Col-->
      </div><!--Row-->
    </div><!--Container-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      base_url: '/stats',
      columns: [
        {label: 'Client Reference', key: 'client_reference'},
        {label: 'First Name', key: 'first_name'},
        {label: 'Last Name', key: 'last_name'},
        {label: 'Language', key: 'language'},
        {label: 'Group', key: 'group'},
        {label: 'Type', key: 'type'},
        {label: 'Created', key: 'created_at'}
      ],
      stat_options: [
      {label: 'no upcoming events', key: 'no_upcoming_events'},
      {label: 'at least 1 upcoming event', key: 'upcoming_events'},
      {label: 'at least 1 valid contact', key: 'valid_contacts'},
      {label: 'no valid contacts', key: 'no_valid_contacts'},
      {label: 'multiple valid contacts', key: 'multiple_contacts'}
      ],
      stats: [],
      queries: {},
      total: 0,
      downloads: [],
      downloadsLimit: 100,
      downloading: false,
      client_groups: [],
      client_types: [],
      languages: []
    }
  },

  computed: {

    download_progress: function() {
      if(!this.downloads.length || !this.total) {
        return 0;
      }

      return (this.downloads.length / this.total) * 100;
    }
  },

  created() {
    this.resetQueries();
    this.setTitle();
    this.setURLParams();
    this.getStats();
    this.getClientGroups();
    this.getClientTypes();
    this.getLanguages();
  },

  methods: {

    goToRecord(uuid) {
      window.location.href = '/clients/'+uuid;
    },

    setTitle() {
        document.title = this.$route.meta.title + ' | eCourtDate.com';
    },

    setURLParams() {

        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });
    },

    updateURLParams() {
      history.pushState({}, null, this.buildQueries('/stats', this.queries));
    },

    sortColumn(column) {
      this.queries.sort = column.key;
      this.updateSortDirection();
    },

    resetQueries() {
      this.queries = {
        stat: 'no_upcoming_events',
        sort: 'created_at',
        sortDirection: 'desc',
        limit: 10,
        skip: 0,
        created_from: null,
        created_to: null,
        client_reference: null,
        group: null,
        type: null,
        language: null
      }
    },

    searchStats() {
      this.queries.skip = 0;
      this.stats = [];
      this.getStats();
    },

    getStats() {

      this.updateURLParams();

      this.totalStats();

      this.processing = true;
      this.$http.get(this.buildQueries(this.base_url, this.queries))
      .then(response => {
        if(response && response.data) {

              if(response.data.length > 0) {
                this.stats = this.stats.concat(response.data);
              }
              
              this.queries.skip = this.stats.length;
              this.processing = false;
        }
      })
    },

    totalStats() {
      this.$http.get(this.buildQueries(this.base_url+'/total', this.queries))
      .then(response => {
        if(response && response.status === 200) {
          this.total = response.data;
        }
      })
    },

    updateSortDirection() {

      if(this.queries.sortDirection == 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.queries.skip = 0;
      this.stats = [];

      this.getStats();
    },

    downloadCSV() {
        this.downloads = [];
        this.getExportData();
    },

    getExportData () {
        
        this.downloading = true;

        this.$http.get(this.buildQueries(this.base_url, {...this.queries, limit: this.downloadsLimit, skip: this.downloads.length}))
        .then(response => {
            if(response && response.data) {

                this.downloads = this.downloads.concat(response.data);

                if (response.data.length < this.downloadsLimit) {
                    this.downloading = false;
                    var csvFile, data, link, title;

                    title = this.queries.stat+'.csv';

                    data = this.convertCSV(this.downloads);

                    csvFile = new Blob([data], {type: "text/csv"});
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveBlob(csvFile, title);
                    } else {
                        link = document.createElement("a");
                        link.download = title;
                        link.href = window.URL.createObjectURL(csvFile);
                        link.style.display = "none";
                        document.body.appendChild(link);
                        link.click();
                    }
                } else {
                    this.getExportData();
                }

            }
        });
    },

    convertCSV(datas) {

        var results = [];

        var keys = this.columns.map((field) => {return field.key})
        var headers = this.columns.map((field) => {return field.label})
        results += headers.join(',')
        results += '\n';

        datas.forEach(function(item) {

            keys.forEach(function(key) {
                results += '"' + (item[key] ? item[key] : '') + '",'
            });

            results += '\n';
        });
        return results;
    },

    getClientGroups () {
      this.$http.get('/client_groups?limit=1000')
      .then(response => {
        if(response && response.data) {
          this.client_groups = response.data;
        }
      })
    },

    getClientTypes () {
      this.$http.get('/client_types')
      .then(response => {
        if(response && response.data && response.data.length) {
          this.client_types = response.data.map((r) => r.type);
        }
      })
    },

    getLanguages () {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/languages.json')
      .then(response => {
          if(response && response.data) {
              this.languages = response.data;
          }
      })
    }

  }


}
</script>