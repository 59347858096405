module.exports = {
  props: ['title', 'base_url'],
  data () {
    return {
      changed: false,
      processing: false,
      form: {},
      errors: {}
    }
  },
  mounted () {
    var self = this
    this.$eventBus.$on('saveForm', function () {
      self.postForm();
    });

    this.$eventBus.$on('activeForm', function (form) {
      if (form === null) {
        self.form = {}
      } else {
        self.form = form
        if (typeof self.setFields !== 'undefined') {
          self.setFields()
        }
      }
    })
    this.$eventBus.$on('updateForm', function (form) {
      if (form === null) {
        self.form = {}
      } else {
        self.form = form
        if (typeof self.setFields !== 'undefined') {
          self.setFields()
        }
      }
    })
    this.$eventBus.$on('trashForm', function () {
      self.form = {}
    })
    this.$eventBus.$on('errors', function (error) {
      if (error && error.data && error.data.errors) {
        self.errors = error.data.errors
      }
    })
  },
  computed: {
    filtered_url: function () {
      if (this.filter_url) {
        return this.filter_url
      }
      return this.base_url
    }
  },
  methods: {
    changeForm () {
      if (this.changed === false) {
        this.changed = true
      }
    },
    postForm () {
      if (this.changed) {
        this.errors = {}
        this.processing = true
        if (typeof this.setForm !== 'undefined') {
          this.setForm()
        }
        if (this.form && this.form.uuid) {
          this.updateForm()
        } else {
          this.createForm()
        }
        this.changed = false
      }
    },
    updateForm () {
      this.$http.patch(this.base_url + '/' + this.form.uuid, this.form)
        .then(response => {
          if (response && response.data) {
            this.errors = {}
            this.form = { ...this.form, ...response.data }
            this.$eventBus.$emit('updateForm', this.form)
            new this.$noty({ text: this.title + ' updated' }).show()
          }
          this.processing = false
        })
    },
    createForm () {
      this.$http.post(this.filtered_url, this.form)
        .then(response => {
          if (response && response.data) {
            this.form = {}
            this.errors = {}
            this.$eventBus.$emit('newForm', response.data)
            new this.$noty({ text: this.title + ' added' }).show()
          }
          this.processing = false
        })
    }
  }
}
