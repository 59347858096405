<template>
  <div>
    <div
      v-if="form && form.uuid"
      class="card"
    >
      <div class="card-body">
        <p class="card-title">
          Address <router-link :to="{'name': 'addresses.edit', 'params': { 'id': form.uuid } }">
            {{ form.full_address }}
          </router-link>
        </p>

        <div
          id="map"
          class="map-sm"
        />

        <timestamps :form="form" />
      </div><!--Body-->
    </div><!--Card-->
  </div>
</template>
<script>
export default {

props: ['address'],

data() {
    return {
        base_url: '/addresses',
        form: {},
        map: {}
    }
},

created() {
    this.getRecord();
},

methods: {

        getRecord() {
            
            if(!this.address) {
                return;
            }

            this.form = {};

            this.$http.get(this.base_url+'/'+this.address+'?slim=true')
            .then(response => {
                if(response && response.data) {
                    this.form = response.data;

                    if(this.form.full_address) {
                        this.getGeocodes(this.form.full_address);
                    }
                }
            })
        },


        getGeocodes(address) {
            this.$http.get('/geocode?address='+address)
            .then(response => {
                if(response && response.data) {

                    if(response.data[0] && response.data[0]['geometry']) {
                        if(response.data[0]['geometry']['location']) {
                            this.form.latitude = response.data[0]['geometry']['location']['lat'];
                            this.form.longitude = response.data[0]['geometry']['location']['lng'];

                            this.initMap();
                        }
                    }

                }
            })
        },

        initMap() {
            this.map = window.L.map("map").setView([this.form.latitude, this.form.longitude], 18);
            
            window.L.tileLayer(this.$root.map_layer).addTo(this.map);

            window.L.marker([this.form.latitude, this.form.longitude], {}).addTo(this.map);
        },

}

}
</script>