<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />

    <div class="row">
      <div class="col-sm-12 col-md-4">
          <h1>{{ $route.meta.title }}</h1>
          <p>
            <router-link :to="{name: 'dashboard.index'}">Dashboard</router-link> /
            <router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}
          </p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4">
        <template v-if="records && records.length > 0">
        <div class="btn-group">
        <button type="button" class="btn btn-secondary" @click="goPrevious()" :disabled="processing">Previous</button>
        <button type="button" class="btn btn-secondary" @click="goNext()" :disabled="processing">Next</button>
        </div><!--Btn Group-->
        </template>

        <template v-if="form.error && form.error.message">
         <span class="badge badge-pill badge-danger">{{ form.error.message }}</span>
        </template>
      </div><!--Col-->


      <div class="col-sm-12 col-md-4 text-right">
        <div class="btn-group">

            <div class="btn-group">
              <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Tools
              </button>
              <div class="dropdown-menu">
                <a
                  v-if="!form.archived_at && !form.deleted_at"
                  class="dropdown-item"
                  @click="archiveRecord()"
                >Archive</a>
                <a
                  v-if="form.archived_at || form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord()"
                >Restore</a>
                <a
                  v-if="!form.deleted_at && !form.archived_at"
                  class="dropdown-item"
                  @click="trashRecord()"
                >Trash</a>
              </div>
          </div><!--BtnGroup-->
            
          <button
            v-if="!form.archived_at && !form.deleted_at"
            type="button"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">

            <template v-if="form && form.created_at">
              <span class="badge" :class="setStatusClass(form)">ONLINE {{ form.created_at | dateTimezone | timeago }}</span>
            </template>

            <ul class="list-group">
              <template v-if="geocodes && geocodes.length > 0">
                <template v-if="geocodes[0] && geocodes[0]['formatted_address']">
                  <li class="list-group-item">
                    PHYSICAL ADDRESS (APPROXIMATE) {{ geocodes[0]['formatted_address'] }}
                  </li>
                </template>
              </template>

              <template v-if="form.latitude">
                <li class="list-group-item">
                  LATITUDE {{ form.latitude }}
                </li>
              </template>


              <template v-if="form.longitude">
                <li class="list-group-item">
                  LONGITUDE {{ form.longitude }}
                </li>
              </template>

              <template v-if="form.accuracy">
                <li class="list-group-item">
                  COORDINATE ACCURACY {{ form.accuracy | metersFeet }}
                </li>
              </template>

              <template v-if="form.altitude">
                <li class="list-group-item">
                  ALTITUDE {{ form.altitude | metersFeet }}
                </li>
              </template>

              <template v-if="form.altitudeAccuracy">
                <li class="list-group-item">
                  ALTITUDE ACCURACY {{ form.altitudeAccuracy | metersFeet }}
                </li>
              </template>

              <template v-if="form.heading">
                <li class="list-group-item">
                  HEADING {{ form.heading }} d
                </li>
              </template>

              <template v-if="form.speed">
                <li class="list-group-item">
                  SPEED {{ form.speed | metersMiles }}
                </li>
              </template>


              <template v-if="form.error">
                <template v-for="(geo_error, error_index) in form.error">
                  <template v-if="geo_error">
                    <li
                      class="list-group-item"
                    >
                      <span class="text-uppercase">{{ error_index }}</span> {{ geo_error }}
                    </li>
                  </template>
                </template>
              </template>
            </ul>

          </div><!--Body-->
        </div><!--Card-->

        <audit-logs :form="form" class="my-3" />

        <template v-if="form.client">
          <client-card :client="form.client" class="mt-3" />
        </template>

        <template v-if="form.user_agent">
          <user-agent
            :raw="form.user_agent"
            class="mt-3"
          />
        </template>

        <template v-if="ip_address">
        <div class="card my-3">
          <div class="card-body">

            <div class="row">

              <div class="col-sm-12 col-md-6">
                <p class="card-title">
                  <i class="fa fa-tower-cell"></i> IP Address
                </p>
              </div><!--Col-->

              <div class="col-sm-12 col-md-6">
                <a :href="'/ips/'+ip_address.ip" class="btn btn-outline-secondary btn-sm float-right">
                  <i class="fa fa-globe" /> <span class="sr-only">IP Address</span>
                </a>
              </div><!--Col-->

            </div><!--Row-->

            <ul class="list-group">
              <template v-if="ip_address.ip">
                <li class="list-group-item">
                  IP ADDRESS <a :href="'/ips/'+ip_address.ip">{{ ip_address.ip }}</a>
                </li>
              </template>

              <template v-if="ip_address.type">
                <li class="list-group-item">
                  TYPE {{ ip_address.type }}
                </li>
              </template>

              <template v-if="ip_address.region_name">
                <li class="list-group-item">
                  LOCATION {{ ip_address.city }} {{ ip_address.region_name }} {{ ip_address.zip }} {{ ip_address.country_code }}
                  <template v-if="ip_address.location && ip_address.location.country_flag_emoji">
                  {{ ip_address.location.country_flag_emoji }}
                </template>
                </li>
              </template>

              <template v-if="ip_address.connection && ip_address.connection.isp">
                <li class="list-group-item">
                  ISP {{ ip_address.connection.isp }}
                </li>
              </template>

              <template v-if="ip_address.time_zone && ip_address.time_zone.id">
                <li class="list-group-item">
                  TIME ZONE {{ ip_address.time_zone.id }} {{ ip_address.time_zone.code }}
                </li>
              </template>

              <template v-if="ip_address.latitude">
                <li class="list-group-item">
                  Latitude {{ ip_address.latitude}}
                </li>
              </template>

              <template v-if="ip_address.longitude">
                <li class="list-group-item">
                  Longitude {{ ip_address.longitude }}
                </li>
              </template>
            </ul>

          </div><!--Body-->
        </div><!--Card-->
        </template>

        <div class="card my-3">
          <div class="card-body">
            <p class="card-title"><i class="fa fa-wifi"></i> Network Info</p>

            <template v-if="form && form.network">

            <ul class="list-group">

              <template v-if="form.network.type">
                <li class="list-group-item">Type {{ form.network.type }}</li>
              </template>

              <template v-if="form.network.downLink">
                <li class="list-group-item">Downlink {{ form.network.downLink }}mbs</li>
              </template>

              <template v-if="form.network.rtt">
                <li class="list-group-item">RTT Speed {{ form.network.rtt }}ms <span class="badge badge-pill bg-secondary">{{ setConnectionSpeed(form.network.rtt) }}</span>
                <br><span class="text-muted small">Estimated connection round-trip time</span>
                </li>
              </template>

              <template v-if="form.network.saveData">
                <li class="list-group-item">Low Data Mode {{ form.network.saveData }}</li>
              </template>

              <template v-if="form.network.referrer">
                <li class="list-group-item">Referrer {{ form.network.referrer }}</li>
              </template>

            </ul>
            </template>

            <template v-if="!processing && !form.network">
              <div class="alert alert-info">No network info found</div>
            </template>

          </div>
        </div>

        <div class="card my-3">
          <div class="card-body">
            <p class="card-title"><i class="fa fa-mobile"></i> Device Info</p>

            <template v-if="form && form.device">

              <ul class="list-group">

                <template v-if="form.device.language">
                  <li class="list-group-item">Language {{ form.device.language }}</li>
                </template>

                <template v-if="form.device.timezone">
                  <li class="list-group-item">Timezone {{ form.device.timezone }}</li>
                </template>

                <template v-if="form.device.battery">

                  <li class="list-group-item">Is Charging
                  <template v-if="form.device.charging">
                    <i class="fa fa-bolt text-success"></i> <span class="sr-only">Yes</span>
                  </template>
                    <template v-else>
                      <i class="fa fa-bolt text-danger"></i> <span class="sr-only">No</span>
                    </template>
                  </li>
                  <li class="list-group-item">Charging Time {{ form.device.battery.chargingTime }}s</li>
                  <li class="list-group-item">Discharging Time {{ form.device.battery.dischargingTime }}s</li>
                  <li class="list-group-item">Level {{ form.device.battery.level * 100 }}%</li>

                </template>

                <template v-if="form.device.screen">

                <template v-if="form.device.screen.width">
                  <li class="list-group-item">Screen Width {{ form.device.screen.width }}px {{ setDeviceSize(form.device.screen.width) }}</li>
                </template>

                <template v-if="form.device.screen.height">
                  <li class="list-group-item">Screen Height {{ form.device.screen.height }}px</li>
                </template>

                </template>


              </ul>
            </template>

            <template v-if="!processing && !form.device">
              <div class="alert alert-info">No device info found</div>
            </template>

          </div>
        </div>


        <template v-if="form && form.client">
          <client-card
            :client="form.client"
            class="mt-3"
          />
        </template>
      </div><!--Col-->

      <div class="col-sm-12 col-md-9">

        <div
          ref="map"
          class="map-xl"
        />

        <div class="card mt-3" v-if="total >= 2">
          <div class="card-body">
            <p class="card-title">
              {{ records.length }} of {{ total.toLocaleString() }} Related {{ $route.meta.title }}s
            </p>

            <div class="table-responsive">
            <table class="table table-striped table-hover">
              <thead>
                  <template v-for="column in columns">
                    <th>
                      <template v-if="column.key">
                        <span
                          class="text-capitalize link-hover"
                          @click="sortColumn(column)"
                        >{{ column.label }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key == queries.sort">
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm ml-1"
                          :disabled="processing"
                          @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </thead>
              <tbody>
                <template v-if="records && records.length > 0">
                  <template v-for="record in records">
                    <tr>
                      <td>
                        <a
                          :href="'/'+$route.meta.base_url+'/'+record.uuid"
                          class="btn btn-outline-secondary btn-sm"
                        ><i class="fa fa-edit" /> Edit</a>
                      </td>
                      <td>{{ record.url }}</td>
                      <td>{{ record.ip_address }}</td>

                      <td>
                        <template v-if="record.client">
                          <client-button :uuid="record.client" />
                        </template>
                      </td>

                      <td>
                        <template v-if="record.message">
                          <a
                            :href="'/messages/'+record.message"
                            class="btn btn-outline-secondary btn-sm"
                          ><i class="fa fa-comment" /> <span class="sr-only">Message</span></a>
                        </template>
                      </td>

                      <td>{{ record.latitude }}</td>
                      <td>{{ record.longitude }}</td>

                      <td>
                        <template v-if="record.error && record.error.message">
                          <span class="badge badge-pill badge-danger text-uppercase">{{ record.error.message }}</span>
                        </template>
                      </td>
                      <td>
                        <template v-if="record.ip_address">
                          <a :href="'/ips/'+record.ip_address">{{ record.ip_address }}</a>
                        </template>
                      </td>
                      <td>{{ record.created_at | datetime }}</td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
            </div><!--Responsive-->

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>

<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      form: {},
      map: null,
      layers: null,
      group: {},
      address: {},
      ip_address: {},
      geocodes: [],
      records: [],
      total: 0,
      queries: {
        sort: 'created_at',
        sortDirection: 'desc',
        client: null,
        skip: 0,
        limit: 10
      },
      columns: [
          {label: 'Geolocation'},
          {key: 'url', label: 'URL'},
          {key: 'ip_address', label: 'IP Address'},
          {key: 'client', label: 'Client'},
          {key: 'message', label: 'Message'},
          {key: 'latitude', label: 'Latitude'},
          {key: 'longitude', label: 'Longitude'},
          {key: 'error', label: 'Error Status'},
          {key: 'created_at', label: 'Created'}
      ]
    }
  },

  mounted() {

    var self = this;

    this.$eventBus.$on(this.$route.meta.base_url, function(data) {

      if(data.geo_data.latitude) {
        data.latitude = data.geo_data.latitude;
        data.longitude = data.geo_data.longitude;
        data.accuracy = data.geo_data.accuracy;
      }
      self.setCurrentGeolocation(data);

    });


  },

  created() {
    this.getRecord();

    this.map = null;
  },

  beforeDestroy() {
    if(this.map) {
      this.map.remove();
    }
  },

  methods: {


    getRecord() {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url + '/' + this.$route.params.id)
      .then(response => {
        if (response && response.data) {

            this.processing = false;
            this.form = this.mapRecord(response.data);

            this.initMap();

            this.getRecords();

            if(this.form.client) {
              this.getAddresses(this.form.client);
            }

            this.reverseGeocode();

            this.getIPAddress();

      }})
    },

    getIPAddress() {
      if(!this.form.ip_address) {
        return;
      }

      this.ip_address = null;

      this.$http.get('/ips/'+this.form.ip_address)
      .then(response => {
        if(response && response.data) {
          this.ip_address = response.data;

          this.addIPMap();

        }
      })
    },

    addIPMap() {
      if(!this.ip_address.latitude || !this.ip_address.longitude) {
        return;
      }

      var popup = '<h4>IP Address <a href='+'/ips/'+this.ip_address.ip+'>'+this.ip_address.ip+'</a>'

      const ipIcon = window.L.divIcon({
        html: '<i class="fa fa-globe text-primary fa-2x"></i>',
        iconSize: [20, 20],
        className: 'map-icon'
      });

      window.L.marker([this.ip_address.latitude, this.ip_address.longitude], {icon: ipIcon}).addTo(this.map).bindPopup(popup);
    },

    setStatusClass(record) {

      var diff = parseInt(this.$moment().utc().diff(this.$moment.utc(record.created_at, 'minutes')));

      if(diff <= 5) {
        return 'badge-success';
      }

      return 'badge-secondary';
    },

    setTitle() {
      document.title = this.$route.meta.title + ' | eCourtDate.com';
    },

    mapRecord(record) {
      if(!record.geo_data || !record.geo_data.latitude) {
          return record;
      }

      if(record.geo_data.latitude) {
          record.latitude = record.geo_data.latitude;
      }

      if(record.geo_data.longitude) {
          record.longitude = record.geo_data.longitude;
      }

      if(record.geo_data.accuracy) {
          record.accuracy = record.geo_data.accuracy;
      }

      return record;
    },

    getRecords() {

      // this.queries.client = this.form.client;

      this.queries.url = this.form.url;

      this.getTotal();

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
      .then(response => {
        if(response && response.data) {

          var self = this;

          response.data.forEach((record) => {
            if(record.uuid == this.$route.params.id) {
                return;
            }

            record = self.mapRecord(record);

            this.records.push(record);

            this.createLine(record);

          })

        }
      })
    },

    getTotal() {
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
      .then(response => {
        if(response && response.status) {
          this.total = response.data;
        }
      })
    },

    createLine(record) {

      if(!record.latitude || !record.longitude) {
        return;
      }

      let newLatLng = window.L.LatLng(record.latitude, record.longitude);

      window.L.marker(newLatLng).addTo(this.map);

      if (lastLatLng) {
          let polyline = L.polyline([lastLatLng, newLatLng], { color: 'red' }).addTo(this.map);
      }

      return newLatLng;
    },

    getAddresses(client) {
      this.$http.get('/addresses?client='+client+'&limit=1')
      .then(response => {
        if(response && response.data && response.data[0]) {
          
          this.address = response.data[0];

          if(this.address.full_address) {
            if(!this.address.latitude && !this.address.longitude) {
              this.getGeocodes(this.address.full_address);
            }
          }

          this.addAddressMap();
        }
      })
    },

    setCurrentGeolocation(record) {
      
      if(!record.latitude && !record.longitude) {
        return;
      }

      if(!this.map) {
        return;
      }

        var radius = parseInt(record.accuracy || 100);

        if(radius > 100) {
          radius = 100;
        }

      const geoIcon = window.L.divIcon({
        html: '<i class="fa fa-location-arrow text-primary fa-2x"></i>',
        iconSize: [20, 20],
        className: 'map-icon'
      });

      var self = this;

      window.L.marker([record.latitude, record.longitude], {icon: geoIcon}).addTo(this.map).on('click', function(e){
        self.map.setView(e.latlng, 18);
      });

      this.map.panTo([record.latitude, record.longitude], 18);

    },

    addAddressMap() {

      if(!this.address.latitude && !this.address.longitude) {
        return
      }

      var popup = '<h4>Address <a href='+'/addresses/'+this.address.uuid+'>'+this.address.full_address+'</a>'

      window.L.marker([this.address.latitude, this.address.longitude], {}).addTo(this.map).bindPopup(popup);
    },

    getGeocodes(address) {
        this.$http.get('/geocode?address='+address)
        .then(response => {
            if(response && response.data && response.data[0]) {

                if(response.data[0]['geometry']) {
                    if(response.data[0]['geometry']['location']) {
                        this.address.latitude = response.data[0]['geometry']['location']['lat'];
                        this.address.longitude = response.data[0]['geometry']['location']['lng'];
                        this.addAddressMap();
                    }
                }

            }
        })
    },

    reverseGeocode() {

      if(!this.form.latitude || !this.form.longitude) {
        return;
      }

      this.$http.get('/geocode?latitude='+this.form.latitude+'&longitude='+this.form.longitude)
      .then(response => {
        if(response && response.data) {
          this.geocodes = response.data;
        }
      })
    },

    initMap() {

      if(this.map) {
        return;
      }

      this.map = window.L.map(this.$refs.map).setView(this.setDefaultCoordinates(), 4);

      window.L.tileLayer(this.$root.map_layer).addTo(this.map);

      this.map.panTo(this.setDefaultCoordinates(), 18);

      this.setCurrentGeolocation(this.form);
    },

    setDefaultCoordinates() {

      if(!this.$root.current_user || !this.$root.current_user.current_agency) {
        return this.$root.coordinates;
      }

      if(this.$root.current_user && this.$root.current_user.current_agency && this.$root.current_user.current_agency.settings) {
        if(this.$root.current_user.current_agency.settings.latitude && this.$root.current_user.current_agency.settings.longitude) {
        return [this.$root.current_user.current_agency.settings.latitude, this.$root.current_user.current_agency.settings.longitude];
      }
      }

      return this.$root.coordinates;

    },

    updateSortDirection() {

      if(this.queries.sortDirection == 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.resetRecords();

      this.getRecords();
    },

    resetRecords() {
      this.records = [];
      this.queries.skip = 0;
    },

    sortColumn(column) {

      this.queries.sort = column.key;

      this.updateSortDirection();

    },

    goNext() {
      window.location.href = '/'+this.$route.meta.base_url+'/'+this.records[0]['uuid'];
    },

    goPrevious() {
    },

    setConnectionSpeed(value) {

        if(value < 100) {
          return 'Excellent';
        }

        if(value < 200) {
          return 'Good';
        }

        if(value < 300) {
          return 'Average';
        }

        if(value < 400) {
          return 'Poor';
        }

        return 'Very Poor';
    },

    setDeviceSize(value) {
      if (value <= 640) {
        return "Small Phone";
      } else if (value <= 768) {
        return "Large Phone";
      } else if (value <= 1024) {
        return "Tablet";
      } else if (value <= 1440) {
        return "Small Monitor";
      }
    },

    restoreRecord () {
          this.processing = true;
          this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
          .then(response => {
              if (response && response.data) {

                  this.processing = false;

                  if(response.status === 200) {
                      new this.$noty({text: this.$route.meta.title + ' restored'}).show();
                      this.form.deleted_at = null;
                      this.form.archived_at = null;
                  }
                  
              }
          })
      },

      archiveRecord () {
          this.processing = true
          this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/archive')
          .then(response => {
              if (response && response.data) {

                  this.processing = false;

                  if(response.status === 200) {
                      new this.$noty({text: this.$route.meta.title + ' archived'}).show()
                      this.form.archived_at = new Date().toISOString();
                  }

              }
              
          })
      },

      trashRecord () {
          this.processing = true;
          this.$http.delete('/'+this.$route.meta.base_url + '/' + this.form.uuid)
          .then((response) => {
            if(response && response.status) {
              this.processing = false;

              if(response.status === 204) {
                new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
                this.form.deleted_at = new Date().toISOString();
              }
            }
          })
      },

  },
}
</script>