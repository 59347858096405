<template>
  <div>
    <template v-if="record && record.link">
        <a :href="'https://'+record.link+'.'+record.domain+'/'+url" class="btn btn-outline-primary" target="_blank"><i class="fa fa-link"></i> Portal Link</a>
    </template>
  </div>
</template>
<script>
export default {

props: ['uuid', 'url'],

data() {
    return {
        processing: false,
        record: {},
        base_url: '/portals'
    }
},

created() {
    this.getRecord();
},

methods: {

        getRecord() {
            
            if(!this.uuid) {
                return;
            }

            this.processing = true;
            this.record = {};

            this.$http.get(this.base_url+'/'+this.uuid+'?slim=true&fields=name,link,domain')
            .then(response => {
                if(response && response.data) {
                    this.record = response.data;
                    this.processing = false;
                }
            })
        },

}

}
</script>