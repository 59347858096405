<template>
  <div>
    <a
      :href="'/'+url+'?user='+user"
      class="btn btn-secondary btn-sm"
    >{{ total }}</a>
  </div>
</template>
<script>
export default {

props: ['user', 'url'],

data() {
    return {
        total: 0
    }
},

created() {
    this.getTotal();
},

methods: {

    getTotal() {
        this.$http.get('/'+this.url+'/total?user='+this.user)
        .then(response => {
            if(response && response.status === 200) {
                this.total = response.data;
            }
        })
    }

}

}
</script>