<template>
  <div
    class="form-group mb-3"
    style="width: 100%;"
  >
    <label for="waitlist_search">Waitlist Search</label>
    <input
      id="waitlist_search"
      v-model="search"
      type="search"
      class="form-control"
      placeholder="Search waitlist"
      @input="searchWaitlist"
    >
  </div>
</template>
<script>
import _ from 'lodash';
export default {
data() {
    return {
        search: ''
    }
},

methods: {

    searchWaitlist: _.debounce(function () {
          this.$eventBus.$emit('searchWaitlist', this.search)
    }, 1000),

}
}
</script>

