<template>
  <div>

    <page-tabs page="settings" />

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Create
            </p>

            <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="name">Name</label>
                <input
                    id="name"
                    v-model="form.name"
                    class="form-control"
                    required
                >
              </div>

              <div class="form-group">
                <label for="type">Type</label>
                <select
                    id="type"
                    v-model="form.type"
                    class="form-control text-uppercase"
                    :disabled="processing"
                >
                  <option value=""></option>
                  <template v-for="status_type in $root.status_types">
                    <option
                        :value="status_type"
                    >{{ status_type }}</option>
                  </template>
                </select>
              </div>

              <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
              >
                Add
              </button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">
            <p class="card-title">Search</p>

            <form @submit.prevent="searchRecords()">

              <div class="form-group">
                <label for="search">Search</label>
                <input id="search" type="search" class="form-control" v-model="queries.search">
              </div>

              <div class="form-group">
                <label for="search_type">Type</label>
                <select
                    id="search_type"
                    v-model="queries.type"
                    class="form-control text-uppercase"
                    :disabled="processing"
                >
                  <option value=""></option>
                  <template v-for="status_type in $root.status_types">
                    <option :value="status_type">{{ status_type }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="search_disable_messages"
                    v-model="queries.disable_messages"
                    type="checkbox"
                    class="custom-control-input"
                    :disabled="processing"
                    @change="searchRecords()"
                >
                <label
                    class="custom-control-label"
                    for="search_disable_messages"
                >Disable Messages</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="search_fta"
                    v-model="queries.fta"
                    type="checkbox"
                    class="custom-control-input"
                    :disabled="processing"
                    @change="searchRecords()"
                >
                <label
                    class="custom-control-label"
                    for="search_fta"
                >FTA</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="search_auto_archive"
                    v-model="queries.auto_archive"
                    type="checkbox"
                    class="custom-control-input"
                    :disabled="processing"
                    @change="searchRecords()"
                >
                <label
                    class="custom-control-label"
                    for="search_auto_archive"
                >Auto Archive Status</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="search_archived"
                    v-model="queries.archived"
                    type="checkbox"
                    class="custom-control-input"
                    :disabled="processing"
                    @change="searchRecords()"
                >
                <label
                    class="custom-control-label"
                    for="search_archived"
                >Archived</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="search_trashed"
                    v-model="queries.trashed"
                    type="checkbox"
                    class="custom-control-input"
                    :disabled="processing"
                    @change="searchRecords()"
                >
                <label
                    class="custom-control-label"
                    for="search_trashed"
                >Trashed</label>
              </div>

              <button type="submit" class="btn btn-primary" :disabled="processing">Search</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <div class="card my-3">
          <div class="card-body">

            <p class="card-title">Import Sample</p>

            <p class="card-text">Import sample statuses to get started quickly.</p>

            <ul class="list-group">

              <template v-for="sample in samples">
                <li class="list-group-item list-group-item-action">
                  <h5 class="list-group-item-heading">{{ sample.name }}</h5>
                  
                  <p>
                  <template v-for="status in sample.statuses">
                    <span class="badge badge-secondary mr-1">{{ status }}</span>
                  </template>
                  </p>

                  <button type="button" class="btn btn-success btn-sm" @click="importSample(sample)" :disabled="processing">Import</button>
                </li>
              </template>

            </ul>

        </div><!--Body-->
      </div><!--Card-->

      </div><!--Col-->

      <div class="col-sm-12 col-md-9">
        <div class="card">
          <div class="card-body">

            <div class="row">

            <div class="col-sm-12 col-md-6">

              <p class="card-title">
                {{ records.length }} of {{ total.toLocaleString() }} Results
              </p>

            </div><!--Col-->

            <div class="col-sm-12 col-md-6 text-right">

              <div class="btn-group d-print-none">
                <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
              </div>
            </div><!--Col-->

            </div><!--Row-->

            <div class="table-responsive my-3">
              <table class="table table-striped table-hover">
                <thead>
                <tr>
                  <template v-for="column in columns">
                    <th
                        class="text-uppercase"
                    >
                      <template v-if="column.key">
                        <span
                            class="text-capitalize link-hover"
                            @click="sortColumn(column)"
                        >{{ column.label }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key == queries.sort">
                        <button
                            type="button"
                            class="btn btn-outline-secondary btn-sm ml-1"
                            :disabled="processing"
                            @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </tr>
                </thead>

                <tbody>
                <template v-if="records && records.length > 0">
                  <template v-for="record in records">
                    <tr>
                      <td>
                        <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid}}">
                          {{ record.name }}
                          <template v-if="record.default">
                            <span class="badge badge-pill badge-primary">DEFAULT</span>
                          </template>
                        </router-link>
                      </td>
                      <td>
                      <template v-if="record.type">
                        <span class="badge badge-secondary text-uppercase">{{ record.type }}</span>
                      </template>
                      </td>

                      <td>
                        <template v-if="record.disable_messages">
                          <span class="badge badge-pill badge-danger">Messages Disabled</span>
                        </template>
                        <template v-else>
                          <span class="badge badge-pill badge-success">Messages Enabled</span>
                        </template>
                      </td>
                      <td>
                        <template v-if="record.archive">
                          <span class="badge badge-pill badge-danger">Auto-Archive</span>
                        </template>
                      </td>
                      <td>
                        <template v-if="record.fta">
                          <span class="badge badge-pill badge-danger">FTA</span>
                        </template>
                      </td>
                      <td>{{ record.created_by }}</td>
                      <td>{{ record.created_at | datetime }}</td>
                      <td>{{ record.updated_at | datetime }}</td>
                    </tr>
                  </template>
                </template>
                </tbody>
              </table>
            </div><!--Responsive-->

            <template v-if="!processing && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}es found.
              </div>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">

          <div class="row mt-3">
            <div class="col-12 text-center">

              <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="processing"
                  @click="getRecords"
              >
                Load more
              </button>

            </div><!--Col-->
          </div><!--Row-->

        </template>

      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      validating: false,
      validated: false,
      total: 0,
      queries: {},
      records: [],
      form: {},
      columns: [
        { key: 'name', label: 'Name'},
        { key: 'type', label: 'Type'},
        { key: 'disable_messages', label: 'Disable Messages'},
        { key: 'archive', label: 'Auto Archive'},
        { key: 'fta', label: 'Fail to Appear'},
        { key: 'created_by', label: 'Creator'},
        { key: 'created_at', label: 'Created'},
        { key: 'updated_at', label: 'Updated'}
      ],
      samples: [
        { name: 'Client', statuses: ['active', 'inactive', 'unverified']},
        { name: 'Event', statuses: ['scheduled', 'canceled', 'rescheduled']},
        { name: 'Case', statuses: ['pending', 'arraigned', 'sentenced']},
        { name: 'Payment', statuses: ['outstanding', 'paid', 'draft', 'void', 'refunded']},
      ]
    }
  },

  mounted() {
    var self = this;

    this.$eventBus.$on('errors', function (errors) {
      self.processing = false;
    });
  },

  created() {
    this.resetForm();
    this.resetQueries();
    this.resetRecords();
    this.setURLParams();
    this.getRecords();
  },

  methods: {

    setStatusClass(record) {

      if(record.status == 'private') {
        return 'badge-danger';
      }

      if(record.status == 'draft') {
        return 'badge-secondary';
      }

      return 'badge-success';
    },

    resetQueries() {
      this.queries = {
        limit: 20,
        slim: false,
        search: null,
        name: null,
        type: null,
        disable_messages: null,
        archived: null,
        fta: null,
        sort: 'created_at',
        sortDirection: 'desc'
      }
    },

    resetForm() {
      this.form = {
        name: null,
        type: 'event'
      }
    },

    resetRecords() {
      this.records = [];
      this.total = 0;
      this.queries.skip = 0;
    },

    updateSortDirection() {

      if(this.processing) {
        return;
      }

      if(this.queries.sortDirection == 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.resetRecords();

      this.getRecords();
    },

    sortColumn(column) {

      if(this.processing) {
        return;
      }

      this.queries.sort = column.key;

      this.updateSortDirection();
    },

    searchRecords() {
      this.resetRecords();
      this.getRecords();
    },

    getRecords() {

      this.processing = true;

      this.updateURL();
      this.getTotal();

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
          .then(response => {
            if(response && response.data) {

              if(response.data.length) {

                var self = this;

                response.data.forEach(function(data) {
                  self.records.push(data);
                });
              }

              this.queries.skip = this.records.length;

              this.processing = false;
            }
          })
    },

    getTotal() {
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
          .then(response => {
            if(response && response.status === 200) {
              this.total = response.data;
              this.setTitle();
            }
          })
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 'es | eCourtDate.com';
    },

    postForm() {

      if(!this.form.type) {
        this.form.type = 'event';
      }

      this.processing = true;
      this.$http.post(this.$route.meta.base_url, this.form)
          .then(response => {
            if(response && response.data) {

              this.processing = false;

              if(response.status === 201) {
                this.resetForm();
                this.total++;
                this.records.unshift(response.data);
                new this.$noty({text: this.$route.meta.title + ' created'}).show();
              }

            }
          })
    },

    importSample(sample) {

      if(!sample.statuses) {
        return;
      }

      var self = this;

      sample.statuses.forEach(function(status) {

        self.form = {
          name: status,
          type: sample.name.toLowerCase()
        };

        if(['rescheduled', 'canceled', 'sentenced', 'paid', 'void', 'refunded'].includes(status)) {
          self.form.disable_messages = true;
        }

        self.postForm();
      });
    },

  },
}
</script>