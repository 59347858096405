<template>
  <div>
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              <i class="fa fa-tower-cell"></i>

              <template v-if="ip == 'self'">Current</template> IP Address

              <template v-if="result.location && result.location.country_flag_emoji">
                {{ result.location.country_flag_emoji }}
              </template>

            </p>

            <ul class="list-group">
              <template v-if="result.ip">
                <li class="list-group-item">
                  IP ADDRESS <a :href="base_url+result.ip">{{ result.ip }}</a>
                </li>
              </template>

              <template v-if="result.type">
                <li class="list-group-item">
                  TYPE {{ result.type }}
                </li>
              </template>

              <template v-if="result.city && result.city.names && result.city.names['en']">
                <li class="list-group-item">
                  LOCATION {{ result.city.names['en'] }}
                </li>
              </template>

              <template v-if="result.traits">

              <template v-if="result.traits.connection_type">
                <li class="list-group-item">
                CONNECTION TYPE {{ result.traits.connection_type }}
                </li>
              </template>

              <template v-if="result.traits.user_type">
                <li class="list-group-item">
                USER TYPE {{ result.traits.user_type }}
                </li>
              </template>

              <template v-if="result.traits.isp">
                <li class="list-group-item">
                  ISP {{ result.traits.isp }}
                </li>
              </template>

              </template><!--Traits-->

              <template v-if="result.location && result.location.time_zone">
                <li class="list-group-item">
                  TIME ZONE {{ result.location.time_zone }}
                </li>
              </template>

              <template v-if="result.latitude">
                <li class="list-group-item">
                  Latitude {{ result.latitude}}
                </li>
              </template>

              <template v-if="result.longitude">
                <li class="list-group-item">
                  Longitude {{ result.longitude }}
                </li>
              </template>
            </ul>

          </div><!--Body-->
        </div><!--Card-->

  </div>
</template>
<script>
export default {

  props: ['ip'],

  data () {
    return {
      base_url: '/ips/',
      result: {}
    }
  },

  created() {
    this.resetResult();
    this.getRecord();
  },

  methods: {

    resetResult() {
      this.result = {
        uuid: null
      }
    },

    getRecord() {

      if(!this.ip) {
        return;
      }

      if(this.ip == '127.0.0.1')  {
        return;
      }

      this.$http.get(this.base_url+this.ip)
          .then(response => {
            if(response && response.data) {
              this.result = response.data;
            }
          })
    },

  },
}
</script>