<template>
    <div class="row">
      <div class="col-md-3 col-sm-12">
  
          <div class="card mb-3">
            <div class="card-body">
              <p class="card-title">
                Current Agency
              </p>
  
              <template v-if="$root.current_user && !$root.current_user.current_agency">
                <div class="alert alert-danger">No agencies found.</div>
              </template>
  
              <template v-if="$root.current_user && $root.current_user.current_agency">
                <h4>{{ $root.current_user.current_agency.name }}</h4>
  
                <p class="m-0"><strong>Region:</strong> {{ $root.current_user.current_agency.region }}</p>

                <a :href="'https://console.ecourtdate.com/agencies/'+$root.current_user.current_agency.uuid" target="_blank" class="btn btn-primary btn-sm my-3">View in Console</a>
  
              </template>
               
            </div><!--Body-->
          </div><!--Card-->

          <div class="card my-3">
            <div class="card-body">
                <p class="card-title">Current User</p>

                <template v-if="$root.current_user">
                    <p class="m-0"><strong>Name:</strong> {{ $root.current_user.name }}</p>
                    <p class="m-0"><strong>Email:</strong> {{ $root.current_user.email }}</p>
                    <template v-if="$root.current_user.roles && $root.current_user.roles.length">
                    <p class="m-0"><strong>Roles:</strong> 

                        <template v-for="role in $root.current_user.roles">
                            <span class="badge badge-pill badge-secondary">{{ role }}</span>
                        </template>
                    </p>
                    </template>
                        <p class="m-0"><strong>Super Admin:</strong>

                            <template v-if="$root.current_user.super_admin">
                                <span class="badge badge-pill badge-success ml-1">YES</span>
                            </template>
                            <template v-else>
                                <span class="badge badge-pill badge-danger ml-1">NO</span>
                            </template>
                        </p>
                </template>
            </div>
          </div>
          
        </div><!--Col-->

        <div class="col-md-9 col-sm-12">

          <p class="card-title">{{ records.length }} {{ $route.meta.title }}s</p>
    
            <template v-for="record in records">
  
                    <p class="m-0" :class="record.direction"><a :href="'/'+$route.meta.base_url+'/'+record.uuid">{{ record.content }}</a></p>

                    <template v-if="record.created_by">
                        <span class="m-0"><strong>Created By:</strong> {{ record.created_by }}</span>
                    </template>

                    <template v-if="record.created_at">
                        <span class="m-0 float-right"><strong>Created At:</strong> {{ record.created_at | datetime }}</span>
                    </template>

                    <br>
  
            </template>
  
          </div><!--Col-->
  
        </div><!--Row-->
    </div>
  </template>
  <script>
  import queries from '../mixins/queries';
  
  export default {
  
      mixins: [ queries ],
  
      data () {
          return {
              processing: false,
              records: []
          }
      },
  
  
      created () {
          this.getRecords();
      },
  
      methods: {

          getRecords () {
  
              this.processing = true;
              this.$http.get('/'+this.$route.meta.base_url+'?sort=created_at&sortDirection=desc')
              .then(response => {
                  if(response && response.data) {
  
                      if(response.data.length) {
  
                          var self = this;
  
                          response.data.forEach(function(data) {
                              self.records.push(data);
                          });
                      }
    
                      this.processing = false;
  
                      this.setTitle();
  
                  }
              })
          },
  
          setTitle() {
            document.title = this.records.length + ' ' + this.$route.meta.title + ' | eCourtDate.com';
          }
  
      }
  }
  </script>   