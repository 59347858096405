module.exports = {

data() {
	return {
		active_tab: null
	}
},

created() {

	var self = this;

	this.$eventBus.$on('tabChange', function(tab) {
		self.active_tab = tab;
	});

},


};