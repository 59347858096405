<template>
  <div>
    <template v-if="showChat()">
    <div class="chat-container mr-3 mb-3 d-print-none" data-html2canvas-ignore>
        <div class="row" :hidden="close_chat">
          <div class="col-12">
            <div class="card">
              <div class="card-body">

                <div class="row">
                  <div class="col-sm-12 col-md-9">
                    <form @submit.prevent="searchRecords()">

                    <div class="form-group">
                      <input type="search" class="form-control" v-model="queries.search" placeholder="Search chats" @keyup.enter="searchRecords()">
                    </div>

                      <button type="submit" class="btn btn-primary" :disabled="processing" hidden>Search</button>

                    </form>

                  </div><!--Col-->
                  <div class="col-sm-12 col-md-3 text-right">

                    <div class="btn-group">
                      <router-link tag="button" :to="{'name': 'chats.index' }" class="btn btn-secondary btn-sm tooltip-left" data-toggle="tooltip" data-tooltip="Go to Chats" onclick="this.blur()"><i class="fa fa-comments"></i> <span class="sr-only">Go to Chats</span></router-link>
                      <button type="button" class="btn btn-danger btn-sm tooltip-left" @click="closeChat()" data-tooltip="Close Chats" onclick="this.blur()" :disabled="processing"><i class="fa fa-times"></i></button>
                    </div>

                  </div><!--Col-->

                  </div><!--Row-->

                <template v-if="records && records.length > 0">

                <div class="row" style="max-height:40vh;overflow-y:auto">

                  <div class="col-12">

                      <template v-for="record in records">
                        <div class="list-group">
                          <a :class="setChatClass(record) + ' list-group-item list-group-item-action'" :href="'/'+base_url+'/'+record.uuid">{{ record.content }}</a>
                          <div class="row">
                            <div class="col-sm-12 col-md-6">
                              <template v-if="record.client">
                                <client-button :uuid="record.client" />
                              </template>
                            </div><!--Col-->

                            <div class="col-sm-12 col-md-6 text-right">
                              <p class="text-muted small m-0">{{ record.created_at | dateTimezone | timeago }}</p>
                              <template v-if="record.direction == 'outbound'">
                                <small class="text-muted">by {{ setEmailCreator(record.created_by) }}</small>
                              </template>
                            </div><!--Col-->

                          </div>

                        </div>
                      </template>

                  </div><!--Col-->

                </div><!--Row-->

                </template>

                <template v-if="records && records.length == 0 && !processing">
                  <div class="alert alert-warning">No chats found</div>
                </template>

                <form @submit.prevent="postForm()">

                    <div class="form-group mt-1">
                      <textarea class="form-control" v-model="form.content" rows="3" required></textarea>
                    </div>

                  <button type="submit" class="btn btn-success" :disabled="processing">Send</button>

                </form>


                </div><!--Body-->
            </div><!--Card-->
          </div><!--Col-->
        </div><!--Row-->
        <div class="row" :hidden="!close_chat">
          <div class="col-12">
            <button type="button" class="btn btn-primary tooltip-left" @click="openChat()" data-tooltip="Open chats" :disabled="processing"><i class="fa fa-comments"></i></button>
          </div>
        </div>
    </div><!--ChatContainer-->
    </template>
  </div>
</template>
<style>
.chat-container {position: absolute; bottom:0;right:0;z-index:9}
</style>
<script>
import queries from '../mixins/queries';
export default {

  mixins: [ queries ],

  data() {
    return {
      processing: false,
      close_chat: true,
      base_url: 'chats',
      queries: {},
      form: {},
      records: []
    }
  },

  mounted() {

    var self = this;

    this.$eventBus.$on(this.base_url, function(data) {

      if(!data.uuid) {
        return;
      }

      self.pushRecord(data);

    });


  },

  created() {

    var chat_status = localStorage.getItem('chat_status');

    if(chat_status && chat_status == 'opened') {
      this.close_chat = false;
    }


    this.resetForm();
    this.resetRecords();
    this.resetQueries();
    this.getRecords();
  },

  methods: {

    resetForm() {
      this.form = {
        content: null,
        direction: 'outbound'
      }
    },

    resetQueries() {
      this.queries = {
        search: null,
        skip: 0,
        sort: 'created_at',
        sortDirection: 'asc'
      }
    },

    searchRecords() {
      this.resetRecords();
      this.getRecords();
    },

    resetRecords() {
      this.records = [];
      this.queries.skip = 0;
    },

    pushRecord(record) {
      this.records.push(record);
    },

    getRecords() {

      if(this.close_chat) {
        return;
      }

      this.processing = true;
      this.$http.get(this.buildQueries('/'+this.base_url, this.queries))
          .then(response => {
            if(response && response.data) {
              this.processing = false;

              if(response.data.length) {
                var self = this;
                response.data.forEach(record => {
                  self.pushRecord(record);
                });
              }
            }
          })
    },

    setChatClass(record) {
      if(!record.direction) {
        return;
      }

      return record.direction;
    },

    postForm() {
      this.processing = true;
      this.$http.post('/'+this.base_url, this.form)
          .then(response => {
            if(response && response.data) {
              this.resetForm();
              this.processing = false;
            }
          })
    },

    closeChat() {
      localStorage.setItem('chat_status', 'closed');
      this.close_chat = true;
      this.resetRecords();
    },

    openChat() {
      localStorage.setItem('chat_status', 'opened');
      this.close_chat = false;
      this.getRecords();
    },

    setEmailCreator(email) {
      var parts = email.split("@");

      if (parts.length > 1) {
        return parts[0]+'@';
      } else {
        return email;
      }
    },

    showChat() {
        var settings = this.getSettings('enable_chat');

        if(!settings) {
          return false;
        }

        if(settings == true || settings == 'true' || settings == '1') {
          return true;
        }

        return false;
    },

    getSettings(key) {

    var settings = localStorage.getItem('settings');

    if(settings) {
      settings = JSON.parse(settings);
    }

    if(this.$root.current_user && this.$root.current_user.current_agency && this.$root.current_user.current_agency.settings) {
      settings = this.$root.current_user.current_agency.settings;
    }

    if(!settings) {
      return null;
    }

    return settings[key] || null;
    },


  }
}
</script>
