<template>
  <div>

    <div class="alert alert-primary">Launch a Payment Portal with <a href="https://govpayplan.com" class="alert-link" target="_blank">GovPayPlan.com</a></div>
    
    <div class="row">
      <div class="col-sm-12 col-md-3">
        <template v-if="client && client.uuid">
          <div class="card mb-3">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-md-6">
                  <p class="card-title m-0">
                    Client <router-link :to="{ 'name': 'clients.edit', 'params': {'id': client.uuid} }">
                      {{ client.first_name }} {{ client.middle_name }} {{ client.last_name }}

                    </router-link>
                  </p>
                  <small class="text-muted"><i class="fa fa-language"></i> {{ setLanguageName(client) }}</small>

                </div><!--Col-->

                <div class="col-md-6 text-right">
                  <button
                    type="submit"
                    class="btn btn-danger btn-sm"
                    :disabled="processing"
                    @click="closeClient"
                  >
                    <i class="fa fa-times" /> <span class="sr-only">Close</span>
                  </button>
                </div><!--Col-->
              </div><!--Row-->

              <ul class="list-group">
                <template v-if="client.client_reference">
                  <li class="list-group-item">
                    Reference {{ client.client_reference }}
                  </li>
                </template>

                <template v-if="client.type">
                  <li class="list-group-item">
                    Type {{ client.type }}
                  </li>
                </template>

                <template v-if="client.group">
                  <li class="list-group-item">
                    Group {{ client.group }}
                  </li>
                </template>
              </ul>
            </div><!--Body-->
          </div><!--Card-->
        </template>

        <template v-if="client && !client.uuid">
          <client-search />
        </template>

        <div class="card">
          <div class="card-body">

          <p class="card-title">
            Add {{ title }}
            <template v-if="client && client.uuid">
              for {{ client.client_reference }} {{ client.first_name }} {{ client.last_name }}
            </template>
          </p>

          <p>Create a one-off outstanding payment.</p>

    <form @submit.prevent="postForm()">

          <div class="form-group">
            <label for="payment_amount">{{ setCustomField('payments', 'amount') }}</label>
            <div class="input-group">
              <span
                class="input-group-text"
                style="border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important;"
              >$</span>
              <input
                id="payment_amount"
                v-model="form.amount"
                type="number"
                class="form-control"
                min="0"
                step="0.01"
                required
              >
            </div>
          </div>

          <div class="form-group">
            <label for="payment_reference">{{ setCustomField('payments', 'payment_reference') }}</label>
            <input
              id="payment_reference"
              v-model="form.payment_reference"
              type="text"
              class="form-control"
            >
          </div>

          <div class="form-group">
            <label for="due_at">{{ setCustomField('payments', 'due_at') }}</label>
            <input
              id="due_at"
              v-model="form.due_at"
              type="date"
              class="form-control"
            >
          </div>

      <template v-if="!client || !client.uuid">
          <div class="form-group">
            <label for="contact">Contact</label>
            <input id="contact" type="text" class="form-control" v-model="form.contact">
            <small class="form-text text-muted">Phone number or email address of the payee.</small>
          </div>
      </template>




      <button
        type="submit"
        class="btn btn-success"
        :disabled="processing"
      >
        Add
      </button>
    </form>

          </div><!--Body-->
        </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">

            <p class="card-title">
              Search {{ title }}s
            </p>                     

            <form @submit.prevent="searchRecords()">
              <div class="form-group">
                <label
                  for="search"
                  class="sr-only"
                >Search {{ title }}s</label>
                <input
                  id="search"
                  v-model="queries.search"
                  type="search"
                  class="form-control"
                  placeholder="Search by payment reference"
                >
              </div>

              <div class="form-group">
                <label for="payment_type">Payment Type</label>
                <select
                  id="payment_type"
                  v-model="queries.type"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-for="payment_type in payment_types">
                    <option
                      :value="payment_type"
                    >
                      {{ payment_type }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="payment_descriptions">Payment Description</label>
                <select
                  id="payment_descriptions"
                  v-model="queries.description"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-for="payment_description in payment_descriptions">
                    <option
                      :value="payment_description"
                    >
                      {{ payment_description }}
                    </option>
                  </template>
                </select>
              </div>

                <div class="form-group">
                  <label for="search_status">Status</label>
                  <select
                    id="search_status"
                    v-model="queries.status"
                    class="form-control"
                    :disabled="processing"
                  >
                    <option
                      value=""
                    >
                      Select status
                    </option>
                    <option
                      v-for="status in statuses"
                      :value="status.name"
                    >
                      {{ status.name }}
                    </option>
                  </select>
                </div>

              <div class="form-group">
                <label for="search_case_number">Case Number</label>
                <input id="search_case_number" type="text" class="form-control" v-model="queries.case_number">
              </div>

              <div class="form-group">
                <label for="search_citation">Citation</label>
                <input id="search_citation" type="text" class="form-control" v-model="queries.citation">
              </div>

              <div class="form-group">
                <label for="search_contact">Contact</label>
                <input id="search_contact" type="text" class="form-control" v-model="queries.search_contact">
              </div>

              <div class="form-group">
                <label for="location">Location</label>
                <select
                  id="location"
                  v-model="queries.location"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="">
                    Any
                  </option>
                  <option value="none">
                    No Location
                  </option>
                  <template v-if="locations && locations.length > 0">
                    <template v-for="(location, index) in locations">
                      <option
                        :value="location.uuid"
                      >
                        {{ location.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>


              <div class="form-group">
                <label for="portal">Portal</label>
                <select
                  id="portal"
                  v-model="queries.portal"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="">
                    Any
                  </option>
                  <option value="none">
                    No Portal
                  </option>
                  <template v-if="portals && portals.length > 0">
                    <template v-for="(portal, index) in portals">
                      <option
                        :value="portal.uuid"
                      >
                        {{ portal.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>


              <div class="form-group">
                <label for="flow">Flow</label>
                <select
                  id="flow"
                  v-model="queries.flow"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="">
                    Any
                  </option>
                  <option value="none">
                    No Flow
                  </option>
                  <template v-if="flows && flows.length > 0">
                    <template v-for="flow in flows">
                      <option
                        :value="flow.uuid"
                      >
                        {{ flow.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_issued_from">Issued From</label>
                <input
                  id="search_issued_from"
                  type="date"
                  class="form-control"
                  v-model="queries.issued_from"
                >
              </div>

              <div class="form-group">
                <label for="search_issued_to">Issued To</label>
                <input
                  id="search_issued_to"
                  type="date"
                  class="form-control"
                  v-model="queries.issued_to"
                >
              </div>

              <div class="form-group">
                <label for="search_due_from">Due From</label>
                <input
                  id="search_due_from"
                  type="date"
                  class="form-control"
                  v-model="queries.due_from"
                >
              </div>

              <div class="form-group">
                <label for="search_due_to">Due To</label>
                <input
                  id="search_due_to"
                  type="date"
                  class="form-control"
                  v-model="queries.due_to"
                >
              </div>

              <div class="form-group">
                <label for="created_by">Creator</label>
                <select
                    id="created_by"
                    v-model="queries.created_by"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="" />
                  <template v-for="(creator, index) in users">
                    <option
                        :value="creator.email"
                    >
                      {{ creator.name }} {{ creator.email }}
                    </option>
                  </template>
                </select>
              </div>


              <div class="row">
                <div class="col-md-12 col-lg-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                      id="archived"
                      v-model="queries.archived"
                      type="checkbox"
                      class="custom-control-input"
                      :disabled="processing"
                    >
                    <label
                      class="custom-control-label"
                      for="archived"
                    >Archived</label>
                  </div>
                </div><!--Col-->

                <div class="col-md-12 col-lg-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                      id="trashed"
                      v-model="queries.trashed"
                      type="checkbox"
                      class="custom-control-input"
                      :disabled="processing"
                    >
                    <label
                      class="custom-control-label"
                      for="trashed"
                    >Trashed</label>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->


      <div class="col-sm-12 col-md-9">

        <div class="card">
          <div class="card-body">

            <div class="row">

              <div class="col-sm-12 col-md-6">

                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  title }}s
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-right">

                <div class="btn-group d-print-none">
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                </div>
              </div><!--Col-->

            </div><!--Row-->

            <div class="table-responsive my-3">

            <table class="table table-striped table-hover">
              <sort-columns
                :columns="columns"
                :queries="queries"
                :processing="processing"
                :base_url="$route.meta.base_url"
              />

              <tbody>
                <template v-for="record in records">
                  <tr>
                    <td>
                      <a :href="'/'+$route.meta.base_url+'/'+record.uuid">
                        <i class="fa fa-edit"></i> Edit
                      </a>
                    </td>
                    <td>
                          {{ record.payment_reference }}
                    </td>
                    <td>{{ record.formatted_amount || record.amount }}</td>
                    <td>
                      <template v-if="record.status">
                        <span class="badge badge-pill " :class="setStatusClass(record.status)">{{ record.status }}</span>
                      </template>
                    </td>
                    <td>
                      <template v-if="record && record.client">
                        <client-button :uuid="record.client" />
                      </template>
                    </td>
                    <td>{{ record.contact }}</td>
                    <td>{{ record.type }}</td>
                    <td>{{ record.description }}</td>
                    <td>{{ record.issued_at | date }}</td>
                    <td>{{ record.due_at | date }}</td>
                    <td>{{ record.case_number }}</td>
                    <td>{{ record.citation }}</td>
                    <td>
                    <template v-if="record.location">
                      <a :href="'/locations/'+record.location">{{ setLocationName(record) }}</a>
                    </template>
                    </td>
                    <td>
                    <template v-if="record.portal">
                      <a :href="'/portals/'+record.portal">{{ setPortalName(record) }}</a>
                    </template>
                    </td>
                    <td>
                    <template v-if="record.flow">
                      <a :href="'/flows/'+record.flow">{{ setFlowName(record) }}</a>
                    </template>
                    </td>
                    <td>{{ record.created_by }}</td>
                    <td>{{ record.created_at | datetime }}
                      <span v-if="record.deleted_at" class="badge badge-pill badge-danger">TRASHED</span>
                    </td>
                    <td>{{ record.updated_at | datetime }}
                      <span v-if="record.archived_at" class="badge badge-pill badge-warning">ARCHIVED</span>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>

            </div><!--Table responsive-->

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ title }}s found.
              </div>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords()"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>

<script>
import queries from '../mixins/queries';
import _ from 'lodash';

export default {

mixins: [ queries ],

data() {
    return {
        processing: false,
        downloading: false,
        show_load: false,
        title: null,
        form: {},
        records: [],
        downloads: [],
        columns: [
            { label: 'Edit'},
            {key: 'payment_reference', label: 'Payment Reference'},
            {key: 'amount', label: 'Amount'},
            {key: 'status', label: 'Status'},
            {key: 'client', label: 'Client'},
            {key: 'contact', label: 'Contact'},
            {key: 'type', label: 'Type'},
            {key: 'description', label: 'Description'},
            {key: 'issued_at', label: 'Issued At'},
            {key: 'due_at', label: 'Due At'},
            {key: 'case_number', label: 'Case Number'},
            {key: 'citation', label: 'Citation'},
            {key: 'location', label: 'Location'},
            {key: 'portal', label: 'Portal'},
            {key: 'flow', label: 'Flow'},
            {key: 'created_by', label: 'Creator'},
            {key: 'created_at', label: 'Created'},
            {key: 'updated_at', label: 'Updated'}
        ],
        options: [],
        queries: {},
        total: 0,
        client: {},
        payment_types: [],
        payment_descriptions: [],
        locations: [],
        portals: [],
        flows: [],
        auto_message: {},
        languages: [],
        users: [],
        statuses: []
    }
},

mounted() {

    var self = this;

    this.$eventBus.$on('paymentCreated', function (data) {
        self.records.unshift(data);
        self.total++;
    });

    this.$eventBus.$on('refreshRecords', function(view) {

        if(view !== self.$route.meta.base_url) {
            return;
        }

        self.resetRecords();

        self.getRecords();

    });

    this.$eventBus.$on('search_clients', function(uuid) {

       self.queries.client = uuid;

       self.getClient();

    });

    this.$eventBus.$on('payments', function (data) {
      self.pushRecord(data);
    });

},

created() {
      
    this.resetQueries();
    this.setURLParams();

    this.title = this.setCustomLabel(this.$route.meta.title);

    this.getOptions();
    // this.getAutoMessage();

    if(this.queries.client) {
        this.getClient();
    }

    if(!this.queries.client) {
        this.getRecords();        
    }

    this.resetForm();

    if(this.record && this.record.uuid) {
        this.form = this.record;
    }

    this.getTypes();
    this.getDescriptions();

    this.getLocations();
    this.getPortals();

    this.getFlows();

    this.getLanguages();

    this.getUsers();

},

methods: {

    resetQueries() {
      this.queries = {
          sortDirection: 'desc',
          sort: 'created_at',
          skip: 0,
          limit: 10,
          archived: false,
          trashed: false,
          upload: null,
          description: null,
          type: null,
          created_by: null,
          client: null,
          recurring: null,
          location: null,
          portal: null,
          flow: null,
          citation: null,
          case_number: null,
          issued_from: null,
          issued_to: null,
          due_from: null,
          due_to: null
      }
    },

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });
    },

    setStatusClass(status) {
      if(status == 'outstanding') {
        return 'badge-danger';
      }

      if(status == 'paid') {
        return 'badge-success';
      }

      return 'badge-primary';
    },

    getOptions () {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/options.json')
      .then(response => {
        if(response && response.data) {
            this.options = response.data;
            this.getStatuses();
        }
      })
    },

    resetRecords() {
        this.records = [];
        this.queries.skip = 0;
        this.total = 0;
    },

    searchRecords() {
      this.resetRecords();
      this.getRecords();
    },

    updateURL() {
        history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    getRecords() {

        this.processing = true;
        
        this.updateURL();
        this.getTotal();

        this.processing = true;
        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
        .then(response => {
            if(response && response.data) {

                if(response.data.length) {

                    var self = this;

                    response.data.forEach(function(data) {
                        self.records.push(data);
                    });
                
                }

                this.queries.skip = this.records.length;

                this.processing = false;
            }
        })
    },

    setTitle() {
      document.title = this.total + ' ' + this.title + 's | eCourtDate.com';
    },

    getTotal() {
        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
        .then(response => {
            if(response && response.status) {
                this.total = response.data;
                this.setTitle();
            }
        })
    },

    getClient() {

        if(!this.queries.client) {
            return;
        }

        this.$http.get('/clients/'+this.queries.client+'?slim=true&fields=uuid,client_reference,first_name,last_name,group,type,language')
        .then(response => {
            if(response && response.data) {
                this.client = response.data;
                this.resetRecords();
                this.getRecords();
            }
        })
    },

    resetClient() {
        this.queries.client = null;
        this.client = {};
    },

    closeClient() {
        this.resetClient();
        this.resetRecords();
        this.getRecords();
    },

    resetForm() {
        this.form = {
            client: null,
            contact: null,
            amount: 0,
            payment_reference: null,
            auto_message: null,
            due_at: null,
            type: 'fee'
        }
    },

    postForm() {

        if(this.client && this.client.uuid) {
            this.form.client = this.client.uuid;
        }

        this.form.status = 'outstanding';

        this.form.skip_auto = true;

        this.processing = true;

        this.$http.post('/'+this.$route.meta.base_url, this.form)
        .then(response => {
            if(response && response.data) {

                if(response.data.uuid) {
                    new this.$noty({text: this.title + ' created'}).show();
                    this.$eventBus.$emit('paymentCreated', response.data);
                    // this.sendAutoMessage(response.data);
                    this.resetForm();
                }

                this.processing = false;
          
            }
        })
    },

    pushRecord(data) {

      if(this.queries.client && !data.client) {
        return;
      }

      if(this.queries.client && data.client) {
        if(this.queries.client != data.client) {
          return;
        }
      }

      var count = this.records.length;

      if(this.records.length > count) {
        this.records.splice(-1);
      }

      var index = _.findIndex(this.records, {'uuid': data.uuid});

      if(index == -1) {
        // this.records.unshift(data);
        // this.total++;
        return;
      }

      var records = [];

      this.records.forEach(function(record) {

        if(record.uuid == data.uuid) {
          record = {...record, ...data};
        }

        records.push(record);


      })

      this.records = records;
    },

    sendAutoMessage(record) {

      if(!this.auto_message.uuid) {
        return;
      }

      if(!record.client) {
        return;
      }

      this.$http.post('/auto_messages/'+this.auto_message.uuid+'/send?payment='+record.uuid+'&client='+record.client)
      .then(response => {
        if(response && response.data) {
          if(response.type) {
            new this.$noty({text: 'Auto Message Triggered ' +response.type}).show();
          }
        }
      })
    },

    getTypes() {
      this.$http.get('/'+this.$route.meta.base_url+'/uniques?field=type&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.payment_types = response.data;
        }
      })

    },

    getDescriptions() {
      this.$http.get('/'+this.$route.meta.base_url+'/uniques?field=description&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.payment_descriptions = response.data;
        }
      })

    },

    setFlowName(record) {

      if(!record.flow) {
        return;
      }

      var flow = _.find(this.flows, {'uuid': record.flow});

      if(!flow || !flow.name) {
        return;
      }

      return flow.name;
    },

    setLocationName(record) {

      if(!record.location) {
        return;
      }

      var location = _.find(this.locations, {'uuid': record.location});

      if(!location || !location.name) {
        return;
      }

      return location.name;
    },

    setPortalName(record) {

      if(!record.portal) {
        return;
      }

      var portal = _.find(this.portals, {'uuid': record.portal});

      if(!portal || !portal.name) {
        return;
      }

      return portal.name;
    },

    getFlows() {
        this.$http.get('/flows?limit=1000&slim=true&fields=name,uuid&sort=name&sortDirection=asc')
        .then(response => {
            if(response && response.data) {
                this.flows = response.data;
            }
        })
    },

    getLocations() {
        this.$http.get('/locations?limit=1000&slim=true&fields=name,uuid&sort=name&sortDirection=asc')
        .then(response => {
            if(response && response.data) {
                this.locations = response.data;
            }
        })
    },

    getPortals() {
        this.$http.get('/portals?limit=1000&slim=true&fields=name,uuid&sort=name&sortDirection=asc')
        .then(response => {
            if(response && response.data) {
                this.portals = response.data;
            }
        })
    },

    getAutoMessage() {

      this.auto_message = {};

      this.$http.get('/auto_messages?slim=true&fields=uuid&limit=1&trigger=payment_created')
      .then(response => {
        if(response && response.data && response.data[0]) {
          this.auto_message = response.data[0];
        }
      })
    },

  getLanguages () {
    this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/languages.json')
        .then(response => {
          if(response && response.data) {
            this.languages = response.data;
          }
        })
  },

  setLanguageName(record) {

    if(!record.language) {
      return;
    }

    var language = _.find(this.languages, {'key': record.language});

    if(!language || !language.label) {
      return record.language;
    }

    return language.label;
  },

  getUsers () {
    this.$http.get('users?limit=1000&slim=y&fields=name,email,uuid&sort=name&sortDirection=asc')
        .then(response => {
          if(response && response.data) {
            this.users = response.data;
          }
        })
  },

    getStatuses() {
      this.$http.get('/statuses?type=payment&fields=name&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.statuses = response.data;

          if(!this.statuses || !this.statuses.length) {
            this.statuses = this.options.payment_statuses;
            this.statuses.forEach(function(status) {
              status.name = status.label;
            })
            return;
          }
        }
      })
    }


}

}
</script>