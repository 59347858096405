<template>
  <div>

    <page-tabs page="reports" />

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Create
            </p>

            <form @submit.prevent="postForm">
              <div class="form-group">
                <label for="name">Name</label>
                <input
                  id="name"
                  v-model="form.name"
                  class="form-control"
                  required
                >
              </div>

              <div class="form-group">
                  <label for="data_source">Data Source</label>
                  <select
                    id="data_source"
                    v-model="form.data_source"
                    class="form-control"
                    :disabled="processing"
                    required
                  >
                    <option value=""></option>
                    <template v-for="data_source in data_sources">
                      <option :value="data_source">{{ data_source }}</option>
                    </template>
                  </select>
              </div>

              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >
                Add
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">
            <p class="card-title">Search {{ $route.meta.title }}s</p>

            <form @submit.prevent="searchRecords()">

              <div class="form-group">
                <label class="form-label" for="search">Search</label>
                <input
                  id="search"
                  v-model="queries.search"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label class="form-label" for="search_data_source">Data Source</label>
                <select id="search_data_source" class="form-control" v-model="queries.data_source" @change="searchRecords()">
                  <option value=""></option>
                  <template v-for="search_source in data_sources">
                    <option :value="search_source">{{ search_source }}</option>
                  </template>
                </select>
              </div>

              <button type="submit" class="btn btn-primary" :disabled="processing">Search</button>

            </form>
          </div>
        </div>


      </div><!--Col-->

      <div class="col-sm-12 col-md-9">
        <p class="card-title">
          {{ records.length }} {{ $route.meta.title }}s
        </p>

        <template v-if="records && records.length > 0">
          <div class="row">
            <template v-for="record in records">
              <div
                class="col-sm-12 col-md-6 mb-3"
              >
                <div class="card">
                  <div class="card-body">
                    <div class="row">

                      <div class="col-sm-12 col-md-6">
                        <h4 class="text-capitalize">
                          <template v-if="record.uuid">
                            <a :href="'/'+$route.meta.base_url+'/'+record.uuid">{{ record.title }}</a>
                          </template>
                          <template v-else>
                            <a :href="'/'+$route.meta.base_url+'/'+record.title">{{ record.title }}</a>
                          </template>

                        </h4>
                      </div><!--Col-->

                      <div class="col-sm-12 col-md-6 text-right">
                        <template v-if="record.data_source">
                          <span class="badge badge-pill badge-secondary text-uppercase">{{ record.data_source }}</span>
                        </template>
                      </div><!--Col-->

                    </div>

                    <p class="m-0 lead">
                      {{ record.description }}
                    </p>

                    <template v-if="record.created_by">
                      <p class="small text-muted">
                        Creator {{ record.created_by }}
                      </p>
                    </template>
                  </div><!--Body-->
                </div><!--Card-->
              </div>
            </template>
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';
export default {

  mixins: [queries],

  data () {
    return {
      processing: false,
      records: [],
      form: {},
      queries: {},
      data_sources: ['messages', 'contacts', 'events', 'cases', 'payments']
    }
  },

  created () {
    this.resetForm();
    this.resetQueries();
    this.setURLParams();
    this.getRecords();
  },

  methods: {

    setURLParams() {

      var searchParams = new URLSearchParams(window.location.search);

      var self = this;

      searchParams.forEach(function(value, key) {
        self.queries[key] = value;
      });
    },

    resetQueries() {
      this.queries = {
        search: null,
        data_source: null,
        limit: 10,
        skip: 0,
        slim: false,
        sort: 'created_at',
        sortDirection: 'desc'
      }
    },

    resetForm() {
      this.form = {
        name: null,
        data_source: 'messages'
      }
    },

    resetRecords() {
      this.queries.skip = 0;
      this.records = [];
    },

    searchRecords() {
      this.resetRecords();
      this.getRecords();
    },

    postForm() {
      this.processing = true;
      this.$http.post('/datapoints_create', this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.status === 201) {
            this.records.unshift(response.data);
            new this.$noty({text: this.$route.meta.title + ' created'}).show();
            this.resetForm();
          }

        }
      })
    },

    setTitle() {
      document.title = this.records.length + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries(this.$route.meta.base_url, this.queries));
    },

    getRecords () {
        this.processing = true;

        this.updateURL();

        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
        .then(response => {
            if (response && response.data) {
                this.records = response.data;
                this.setTitle();
                this.processing = false;
            }
        })
    }

  }
}
</script>