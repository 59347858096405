<template>
  <div>

    <p class="my-3">File Preview</p>

    <template v-if="url">

      <template v-if="isImage()">

        <a :href="url" target="_blank">
          <img :src="url" class="img-fluid">
        </a>

      </template>

      <template v-if="isPDF()">
        <iframe :src="url" width="100%" height="600px" frameborder="0" border="0px"></iframe>
      </template>

      <template v-if="isVideo()">

        <video preload=auto width="100%" height="600" controls>
          <source :src="url" :type="type">
        </video>

      </template>

      <template v-if="isAudio()">

        <audio controls>
          <source :src="url" :type="type">
        </audio>

      </template>

      <br><br>

      <div class="btn-group">
        <a :href="url" class="btn btn-primary" target="_blank">Download File</a>
        <button type="button" class="btn btn-danger">Trash File</button>
      </div>

    </template>

  </div>
</template>
<script>
export default {

props: ['type', 'url'],

data() {
    return {
    }
},

methods: {

    isImage() {

      const types = ['image/png', 'image/gif', 'image/jpg', 'image/jpeg'];

      return types.includes(this.type);
    },

    isVideo() {

      const types = ['video/mp4', 'video/ogg', 'video/webm', 'video/mp3'];

      return types.includes(this.type);
    },

    isAudio() {

      const types = ['audio/mpeg', 'audio/mp4', 'application/ogg', 'audio/ogg', 'audio/x-wav', 'audio/wav', 'audio/webm', 'audio/aac', 'audio/aacp', 'audio/webm', 'audio/x-caf', 'audio/flac'];

      return types.includes(this.type);
    },

    isPDF() {

      const types = ['application/pdf'];

      return types.includes(this.type);
    },

}

}
</script>