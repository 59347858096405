<template>
  <div>
    <span
      class="badge text-uppercase link-hover"
      :class="upload_status"
      @click="getRecord()"
    >{{ record.status || upload.status }}</span>
  </div>
</template>
<script>
export default {

props: {
    upload: {
        type: Object,
        default: null
    }
},

data() {
    return {
      processing: false,
      base_url: '/uploads',
      record: {
        status: null
      },
      statuses: {
        'complete': 'badge-success',
        'processing': 'badge-primary',
        'upload template not found': 'badge-danger',
        'incomplete': 'badge-danger',
        'queued': 'badge-warning',
        'paused': 'badge-danger',
        'failed': 'badge-danger',
        'skipped': 'badge-info',
        'delayed': 'badge-warning',
      }
    }
},

computed: {
    upload_status: function() {

        const status = this.record.status || this.upload.status;

        return this.statuses[status.toLowerCase()] || 'badge-primary';
    }
},

methods: {

    getRecord () {
      this.processing = true;
      this.record.status = 'Refreshing Status';
      this.$http.get(this.base_url+'/' + this.upload.uuid +'?fields=status')
      .then(response => {
        if (response && response.data) {

          this.processing = false;

          this.record = response.data;

        }
      })
    },

}

}
</script>