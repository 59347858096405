<template>
  <div>

    <page-tabs page="events" />

    <template v-if="!processing && counts">
      <div class="row mb-3">
        <template v-for="(count, key) in counts">
          <div
            class="col-lg-3 col-md-6"
          >
            <div
              class="card mb-3"
              :class="filter === key ? 'bg-primary text-white' : ''"
            >
              <div class="card-body">
                <h4 class="m-0">
                  {{ count.toLocaleString() }}
                </h4>
                <p
                  class="card-title m-0 text-capitalize link-hover"
                  @click="filterCount(key)"
                >
                  {{ key }}
                </p>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <div
          class="card mb-3"
          style="overflow-x:auto"
        >
          <div class="card-body">
            <form @submit.prevent="getWaitlist">
              <div class="row">
                <div class="col-lg-2 col-md-2">
                  <template v-if="!processing">
                    <h4>Waitlist</h4>
                  </template>
                  <loader :is-loading="processing" />
                  <template v-if="waitlists && waitlists.length">
                    <small class="text-muted">{{ waitlists.length }} of {{ total }} events</small>
                  </template>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="row">
                    <div class="col-6 d-flex align-items-end">
                      <waitlist-search />
                    </div>
                    <div class="col-6">
                      <div class="form-group mb-3 ml-2">
                        <label for="date">Waitlist Date</label>
                        <input
                          id="date"
                          v-model="queries.date"
                          placeholder="mm/dd/yyyy"
                          type="date"
                          class="form-control"
                          @change="getWaitlist"
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 text-right d-flex align-items-start justify-content-end">
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-outline-secondary"
                      @click="addClient"
                    >
                      Add Client
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-secondary"
                      @click="downloadCSV"
                    >
                      <i class="fa fa-file-csv" /> CSV
                    </button>

                    <button
                      type="button"
                      class="btn btn-outline-secondary"
                      @click="getWaitlist"
                    >
                      <i class="fa fa-sync" /> <span class="sr-only">Refresh Waitlist</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <table class="table table-striped table-hover waitlistTable">
              <thead>
                <tr>
                  <th
                    class="link-hover"
                    @click="setSort('events.case_number')"
                  >
                    Case #
                  </th>
                  <th
                    class="link-hover"
                    @click="setSort('clients.last_name')"
                  >
                    Client
                  </th>
                  <th>Time</th>
                  <th>Type</th>
                  <!-- <th @click="setSort('events.date')" class="link-hover">Event Scheduled</th> -->
                  <th>Contacts</th>
                  <th>Send Message</th>
                  <th>Check-in Status</th>
                  <th>Add Contact</th>
                </tr>
              </thead>
              <tbody>
                <loader
                  :is-loading="processing"
                  class="mt-3"
                />
                <template v-if="!processing && waitlists && !waitlists.length">
                  <div class="alert alert-warning">
                    No events found.
                  </div>
                </template>
                <template v-if="waitlists && waitlists.length">
                  <template v-for="waitlist in waitlists">
                    <tr>
                      <th>
                        {{ waitlist.case_number }}
                      </th>
                      <th>
                        <router-link
                          :to="'/clients/' + waitlist.client"
                          target="_blank"
                        >
                          {{ waitlist.last_name }}, {{ waitlist.first_name }} {{ waitlist.middle_name }}
                        </router-link>
                      </th>
                      <th>
                        <router-link
                          :to="'/events/' + waitlist.uuid"
                          target="_blank"
                        >
                          {{ waitlist.time | time }}
                          {{ waitlist.description }}
                        </router-link>
                      </th>
                      <th>
                        {{ waitlist.type }}
                      </th>
                      <th>
                        <template v-if="waitlist.contacts && waitlist.contacts.length > 0">
                          <template v-if="waitlist.contacts[0] && waitlist.contacts[0].value">
                            {{ waitlist.contacts[0].value }}
                            <template v-if="waitlist.contacts.length - 1 > 0">
                              <br><small class="text-muted">+ {{ waitlist.contacts.length - 1 }} contacts</small>
                            </template>
                          </template>
                        </template>
                      </th>
                      <th>
                        <template v-if="waitlist.contacts.length">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <button
                                type="button"
                                class="btn btn-outline-success btn-sm"
                                @click="sendOneOffMessage(waitlist.contacts, waitlist.client)"
                              >
                                Send Message
                              </button>
                              <button
                                type="button"
                                class="btn btn-outline-success dropdown-toggle-split"
                                data-toggle="dropdown"
                              >
                                <i class="fa fa-comments" />
                              </button>
                              <div class="dropdown-menu">
                                <p class="dropdown-header">
                                  Send Auto Message
                                </p>
                                <template v-for="auto_message in auto_messages">
                                  <a
                                    class="dropdown-item"
                                    @click="sendAutoMessage(auto_message.uuid, waitlist.client)"
                                  >
                                    Send {{ auto_message.name }}</a>
                                </template>
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-if="!waitlist.contacts.length">
                          <span class="badge badge-pill badge-danger">UNREACHABLE</span>
                        </template>
                      </th>
                      <th class="text-uppercase">
                        <template v-if="waitlist.checkins && waitlist.checkins.length > 0 && waitlist.checkins[waitlist.checkins.length - 1].status">
                          <!-- here -->
                          <a :href="'/checkins/'+waitlist.uuid"><span
                            class="badge badge-pill mr-1"
                            :class="checkinStatus(waitlist.checkins[waitlist.checkins.length - 1].status)"
                          >
                            {{ waitlist.checkins[waitlist.checkins.length - 1].status }}
                            <template>{{ waitlist.checkins[waitlist.checkins.length - 1].created_at | dateTimezone | timeago }}</template>
                          </span>
                          </a>
                          <template v-if="waitlist.checkins[waitlist.checkins.length - 1].message">
                            <span class="badge badge-primary badgepill">{{ waitlist.checkins[waitlist.checkins.length - 1].message }}</span>
                          </template>
                        </template>
                        <template v-if="!waitlist.checkins || waitlist.checkins.length === 0 || !waitlist.checkins[waitlist.checkins.length - 1].status">
                          <span class="badge badge-pill badge-primary mr-1">
                            Scheduled {{ waitlist.scheduled_at | dateTimezone | timeago }}
                          </span>
                        </template>
                        <button
                          v-if="!waitlist.checkins || waitlist.checkins.length === 0 || waitlist.checkins[waitlist.checkins.length - 1].status !== 'checked-in'"
                          type="button"
                          class="btn btn-success btn-sm"
                          :disabled="processing"
                          title="Check-in"
                          @click="checkinEvent(waitlist.uuid, 'checked-in')"
                        >
                          <i class="fa fa-clipboard-list-check" /> Check-in
                        </button>
                        <button
                          v-if="waitlist.checkins && waitlist.checkins.length > 0 && waitlist.checkins[waitlist.checkins.length - 1].status === 'checked-in'"
                          type="button"
                          class="btn btn-danger btn-sm ml-1"
                          :disabled="processing"
                          title="Check-out"
                          @click="checkoutEvent(waitlist.uuid, 'checked-out')"
                        >
                          <i class="fa fa-door-open" /> Check-out
                        </button>
                      </th>
                      <th>
                        <form @submit.prevent="addContact(waitlist.client)">
                          <div
                            class="input-group flex-nowrap"
                            style="min-width: 150px;"
                          >
                            <input
                              v-model="newContact[waitlist.client]"
                              type="text"
                              class="form-control"
                              placeholder="Add contact"
                              required
                            >
                            <div class="input-group-append">
                              <button
                                class="btn btn-outline-success"
                                type="submit"
                                :disabled="processing"
                              >
                                <i class="fa fa-plus" />
                              </button>
                            </div>
                          </div>
                        </form>
                      </th>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table> 
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <template v-if="show_send_one_off">
          <div class="card mb-3">
            <div class="card-body">
              <p class="card-title">
                Send One Off Message
              </p>
              <form @submit.prevent="sendOneOffForm">
                <template v-if="one_off_recipients">
                  <template v-for="recipient in one_off_recipients">
                    <span 
                      class="badge badge-pill badge-success link-hover mr-1" 
                      @click="rmOneOffRecipient(recipient)"
                    >{{ recipient }} x</span>
                  </template>
                </template>
                <div class="form-group mt-3">
                  <label for="message">Message</label>
                  <textarea
                    id="message"
                    v-model="oneOffForm.message"
                    class="form-control"
                    rows="5"
                  />
                  <template v-if="oneOffForm.message && oneOffForm.message.length">
                    <small class="form-text text-muted">{{ oneOffForm.message.length }}</small>
                  </template>
                </div>
                <template v-if="!processing">
                  <button
                    type="submit"
                    class="btn btn-outline-success"
                    :disabled="processing"
                  >
                    Send Message
                  </button>
                </template>
                <loader :is-loading="processing" />
              </form>
            </div>
          </div>
        </template>
        <template v-if="show_add_client">
          <client-add class="mb-3" />
        </template>
        <messages-feed />
      </div>
    </div>
    <div
      v-if="show_load || loading"
      class="row"
    >
      <div class="col-12 text-center">
        <template v-if="show_load && !loading && !processing">
          <button
            type="button"
            class="btn btn-outline-primary"
            @click="loadMore"
          >
            Load more
          </button>
        </template>
        <template v-if="loading">
          <loader :is-loading="loading" />
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>
.waitlistTable {
    min-width: 900px;
}
</style>

<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {
    mixins: [ queries ],
    data() {
        return {
            processing: false,
            loading: false,
            show_load: false,
            total: 0,
            base_url: '/waitlists',
            waitlists: [],
            checkin: {},
            queries: {
                sort_order: 'asc',
                sort_by: '',
                search: '',
                limit: 40,
                date: ''
            },
            filter: '',
            newContact: {},
            auto_messages: [],
            show_send_one_off: false,
            one_off_recipients: [],
            one_off_clients: [],
            oneOffForm: {
                message: null
            },
            show_add_client: false,
            counts: {},
            checkins: [],
            fields: [
                { key: "case_number", title: "Case number" },
                { key: "last_name", title: "Client" },
                { key: "time", title: "Time" },
                { key: "type", title: "Type" },
                { key: "contacts", title: "Contacts" },
                { key: "checkin", title: "Check-in Status" }
            ],
            downloads: [],
            downloadsLimit: 2500
        }
    },

    created() {
        this.getWaitlist();
        this.getAutoMessages();
        this.getCheckins();
    },

    mounted() {
        var self = this
        this.$eventBus.$on('searchWaitlist', function (search) {
            self.queries.search = search
            self.getWaitlist()
        }); 
    },
    methods: {
        downloadCSV () {
            this.downloads = [];
            this.getExportData();
        },
        getExportData () {
            this.processing = true;
            let filtered_url = this.buildQueries(this.base_url, {...this.queries, limit: this.downloadsLimit, skip: this.downloads.length});
            this.$http.get(filtered_url)
            .then(response => {
                if (response && response.data) {
                    this.downloads = this.downloads.concat(response.data);
                    if (this.downloads.length === this.total) {
                        this.processing = false;
                        var date = new Date();
                        var dateString = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
                        if (this.queries.date) {
                            dateString = this.queries.date;
                        }
                        var csvFile, data, link;
                        data = this.convertCSV(response.data.data);
                        csvFile = new Blob([data], {type: "text/csv"})
                        if (window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveBlob(csvFile, 'waitlist-' + dateString + '.csv');
                        } else {
                            link = document.createElement("a");
                            link.download = 'waitlist-' + dateString + '.csv';
                            link.href = window.URL.createObjectURL(csvFile);
                            link.style.display = "none";
                            document.body.appendChild(link);
                            link.click();
                        }
                    } else {
                        this.getExportData();
                    }
                }
                this.processing = false;
            })
        },
        convertCSV (datas) {
            let results = [];
            var keys = this.fields.map((field) => {return field.key});
            var headers = this.fields.map((field) => {return field.title});
            results += headers.join(',');
            results += '\n';
            var self = this;
            datas.forEach(function(item) {
                keys.forEach(function(key) {
                    switch (key) {
                        case "last_name":
                            results += '"' + item[key] + ', ' + item.first_name + ' ' + (item.middle_name ? item.middle_name : '') + '",'
                            break;
                        case "time":
                            results += '"' + (item[key] ? self.$options.filters.time(item[key]) : '') + '",'
                            break;
                        case "contacts":
                            var contacts = item[key]
                            results += '"'
                            contacts.forEach(function (contact, index) {
                                results += index > 0 ? ', ' + contact.value : contact.value
                            })
                            results += '",'
                            break;
                        case "checkins":
                            if (item[key]) {
                                results += '"' + item[key][0].status + ' ' + self.$options.filters.datetime(item[key][0].created_at) + ' ' + (item[key][0].message ? item[key][0].message : '') + '",'
                            } else {
                                results += '"Scheduled at' + self.$options.filters.datetime(item.scheduled_at) + '",'
                            }
                            break;
                        default:
                            results += '"' + (item[key] ? item[key] : '') + '",'
                            break;
                    }
                })
                results += '\n'
            })
            return results;
        },
        getWaitlist() {
            this.processing = true;
            this.filtered_url = this.buildQueries(this.base_url, {...this.queries, date: this.$options.filters.formatDate(this.queries.date)})
            this.$http.get(this.filtered_url)
            .then(response => {
                if(response && response.data && response.data.data && response.data.data.length > 0) {
                    this.waitlists = response.data.data;
                    if(response.data.total) {
                        this.total = response.data.total
                        if(this.total > this.waitlists.length) {
                            this.show_load = true;
                        }
                    }
                } else {
                    this.waitlists = [];
                    this.total = 0;
                    this.show_load = false;
                }
                if(response?.data?.counts) {
                    this.counts = response.data.counts;
                }

                this.processing = false;
            }) 
        },
        getCheckins() {
            this.$http.get('/checkins')
            .then(response => {
                if(response && response.data) {
                    this.checkins = response.data;
                }
            })
        },

        checkinEvent(uuid, status) {
            this.checkin.uuid = uuid;
            this.checkin.status = status;
            this.$http.post('/checkins', this.checkin)
            .then(response => {
                if(response && response.data) {

                    if(response.status === 201) {
                        new this.$noty({text: response.data.status}).show();
                        this.getWaitlist();                        
                    }
                }
            })
        },

        checkoutEvent(uuid, status) {
            this.$http.post('/checkins', {status: status, uuid: uuid})
            .then(response => {
                if(response && response.data) {

                    if(response.status === 201) {
                        new this.$noty({text: response.data.status}).show();
                        this.getWaitlist();                        
                    }
                }
            })
        },

        updateCheckin(checkin, status) {
            let checkinForm = {};
            checkinForm = checkin;
            checkinForm.status = status;
            this.$http.patch('/checkins/'+checkin.uuid, checkinForm)
            .then(response => {
                if(response && response.data && response.data.message) {
                    new this.$noty({text: response.data.message}).show();
                    this.getCheckins();  
                }
            })
        },
        loadMore() {
            this.loading = true;
            if(this.waitlists && this.waitlists.length) {
                this.$http.get(this.filtered_url + '&skip='+this.waitlists.length)
                .then(response => {
                    if(response && response.data && response.data.data && response.data.data.length) {
                        if(response.data.data.length < this.queries.limit) {
                            this.show_load = false;
                        }
                        response.data.data.forEach(r => {  
                            this.waitlists.push(r)
                        })
                    } else {
                        this.show_load = false;
                    }
                    this.loading = false;
                    this.processing = false;
                });
            }
        },

        addContact(uuid) {
            this.processing = true;
            let contact = new FormData;
            contact.append('client', uuid);
            contact.append('contact', this.newContact[uuid]);
            this.$http.post('/contacts', contact)
            .then(response => {
                if(response && response.data) {
                    if(response.data.valid) {
                        new this.$noty({text: 'Adding Contact'}).show();                    
                    } else {
                        new this.$noty({text: 'Contact is invalid', type: 'error'}).show();
                    }
                    this.newContact[uuid] = null;
                    this.getWaitlist();
                }
                this.processing = false;
            })
        },
        getAutoMessages() {
            this.$http.get('auto_messages')
            .then(response => {
                if(response && response.data) {
                    this.auto_messages = response.data;
                }
            })
        },
        sendAutoMessage(auto_message, client) {
            this.processing = true;
            this.$http.post('auto_messages/'+auto_message+'/send?client='+client)
            .then(response => {
                if(response && response.data) {
                    new this.$noty({text: 'Sending Message'}).show();
                    this.processing = false;
                }
            });
        },
        setSort(sort) {
            if(sort === this.queries.sort_by) {
                if(this.queries.sort_order === 'asc') {
                    this.queries.sort_order = 'desc';
                } else {
                    this.queries.sort_order = 'asc';
                }
            } else {
                this.queries.sort_by = sort;
            }
            this.getWaitlist();
        },
        addClient() {
            if(this.show_add_client) {
                this.show_add_client = false;
            } else {
                this.show_add_client = true;
            }
        },
        sendOneOffForm() {
            this.processing = true;
            this.oneOffForm.clients = _.uniq(this.one_off_clients);
            this.$http.post('/messages', this.oneOffForm)
            .then(response => {
                if(response && response.data) {
                    this.oneOffForm = {
                        message: null
                    }
                    this.show_send_one_off = false;
                    this.one_off_recipients = [];
                    this.one_off_clients = [];
                    new this.$noty({text: 'Sending Message'}).show();
                }

                this.processing = false;
            })
        },
        sendOneOffMessage(contacts, client) {
            
            if(!contacts || !contacts.length) {
                return;
            }
            
            let contact = contacts[0].value;
            this.show_send_one_off = true;
            this.one_off_clients.push(client);
            if(this.one_off_recipients.includes(contact)) {
                this.one_off_recipients = _.remove(this.one_off_recipients, function(item) {
                    return item != contact;
                })
            } else {
                this.one_off_recipients.push(contact);
            }
        },

        rmOneOffRecipient(contact, client) {
            this.one_off_recipients = _.remove(this.one_off_recipients, function(item) {
                return item != contact;
            })

            this.one_off_clients = _.remove(this.one_off_clients, function(item) {
                return item != client;
            })
        },

        checkinStatus(status) {

            if(status == 'arrived') {
                return 'badge-danger';
            }
            return 'badge-success';
        },

        filterCount(key) {
            if (this.filter === key) {
                this.filter = '';
                this.queries.status = '';
                this.getWaitlist();
            } else {
                this.filter = key;
                let filter = key.split(" ");
                if(filter[1]) {
                    this.queries.status = filter[1];
                    this.getWaitlist();    
                }
            }
        }
    }
}
</script>