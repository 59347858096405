<template>
  <div>

    <page-tabs :page="$route.meta.base_url" />

    <div class="row">
      <div class="col-9 mx-auto">
        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-sm-12 col-md-9">
                <h2>{{ $route.meta.title }}s</h2>
                <h4>Send automated, omnichannel, and multilingual messages to clients, contacts, and users.</h4>
                <a href="/preview?content=Testing+New+Preview" class="btn btn-primary btn-sm">Preview Messages</a>
              </div>
              <div class="col-sm-12 col-md-3">
                <img src="https://ecdassets.s3.amazonaws.com/images/features/all-in-one-justice-messaging.webp" class="img-fluid" alt="All-in-One Justice Messaging">
              </div>
            </div>

            <router-link tag="a" class="h4" :to="{'name': 'auto_messages.index' }"><i class="fa fa-comments"></i> {{ auto_messages_total }} Auto Messages</router-link>
            <p class="lead">Send automated one-off messages based on a conditional trigger such as for a new client or rescheduled event.</p>

            <router-link tag="a" class="h4" :to="{'name': 'flows.index' }"><i class="fa fa-list-timeline"></i> {{ flows_total }} Flows</router-link>
            <p class="lead">Send a series of automated messages based on a case, event, task, or payment obligation.</p>

          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->
    </div><!--Row-->

  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      auto_messages_total: 0,
      flows_total: 0
    }
  },

  created () {
    this.countRecords('auto_messages');
    this.countRecords('flows');
  },

  methods: {

    countRecords(type) {
      this.$http.get('/'+type+'/total')
      .then(response => {
        if(response && response.status) {
          this[type+'_total'] = response.data;
        }
      })
    }
  }
}
</script>
