<template>
    <div>
        <template v-if="help[$route.meta.base_url] && help[$route.meta.base_url].title">

          <div class="row my-3 py-3">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <template v-if="help[$route.meta.base_url].title">
                  <p class="card-title h4">{{ help[$route.meta.base_url].title }}</p>
                  </template>

                  <template v-if="help[$route.meta.base_url].description">
                    <p class="lead" v-html="help[$route.meta.base_url].description"></p>
                  </template>

                  <div class="row">
                <div class="col-sm-12 col-md-6">

                  <div class="btn-group d-block mb-3">

                  <template v-if="help[$route.meta.base_url] && help[$route.meta.base_url].url">
                    <a :href="help[$route.meta.base_url].url" class="btn btn-primary" target="_blank">Learn more</a>
                  </template>

                  </div>

                  <template v-if="help[$route.meta.base_url] && help[$route.meta.base_url].features">

                  <p class="card-title">Features</p>
                  <ul class="list-unstyled">
                    <li class="lead" v-for="feature in help[$route.meta.base_url].features"><i class="fa fa-circle-check text-success"></i> {{ feature }}.</li>
                  </ul>

                  </template>



                </div>
                <div class="col-sm-12 col-md-6">
                  <template v-if="help[$route.meta.base_url] && help[$route.meta.base_url].video">
                    <iframe width="100%" height="460" :src="help[$route.meta.base_url].video" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  </template>

                  <template v-if="help[$route.meta.base_url] && help[$route.meta.base_url].video_url">
                    <video width="100%" height="460" controls
                    :poster="help[$route.meta.base_url].image ? help[$route.meta.base_url].image : null"
                    >
                      <source :src="help[$route.meta.base_url].video_url" type="video/mp4">
                    </video>
                  </template>

                  <template v-if="help[$route.meta.base_url] && help[$route.meta.base_url].image">
                    <template v-if="!help[$route.meta.base_url].video && !help[$route.meta.base_url].video_url">
                      <img :src="help[$route.meta.base_url].image" class="img-fluid my-3" :alt="$route.meta.title" style="max-width:400px">
                    </template>
                  </template>
                </div><!--Col-->
              </div><!--Row-->

                </div><!--Body-->
              </div><!--Card-->
            </div><!--Col-->
          </div><!--Row-->

          </template>
    </div>
  </template>
  <script>
  export default {

    props: ['help']
  }
  </script>