<template>
    <div>
  
      <archived-trashed-status
          :form="form"
          :processing="processing"
          :title="$route.meta.title"
      />
  
      <div class="row">
        <div class="col-sm-12 col-md-8">
          <h1 class="m-0">{{ form.type }}</h1>
          <p>
            <router-link :to="{name: 'clients.index'}">Clients</router-link> /
            <router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
        </div><!--Col-->
  
        <div class="col-sm-12 col-md-4 text-right">
          <div class="btn-group">
  
            <template v-if="form && form.url">
              <a :href="form.url" class="btn btn-secondary d-print-none" target="_blank" :disabled="processing">PDF</a>
            </template>
  
            <div class="btn-group d-print-none" data-html2canvas-ignore>
              <button
                  type="button"
                  class="btn btn-outline-primary dropdown-toggle radius-right-0"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
              >
                Tools
              </button>
              <div class="dropdown-menu">
                <a
                    v-if="!form.archived_at"
                    class="dropdown-item"
                    @click="archiveRecord"
                >Archive</a>
                <a
                    v-if="form.archived_at || form.deleted_at"
                    class="dropdown-item"
                    @click="restoreRecord"
                >Restore</a>
                <a
                    v-if="!form.deleted_at"
                    class="dropdown-item"
                    @click="trashRecord"
                >Trash</a>
              </div>
            </div>
  
            <button
                type="button"
                class="btn btn-success d-print-none"
                :disabled="processing"
                @click="postForm()"
                data-html2canvas-ignore
            >
              Save
            </button>
  
          </div>
        </div><!--Col-->
      </div><!--Row-->
  
      <div class="row">
        <div class="col-sm-12 col-md-3">
          <div class="card mb-3">
            <div class="card-body">

            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <p class="card-title">Edit</p>
                </div><!--Col-->
                <div class="col-sm-12 col-md-6 text-right">
                    <template v-if="form.status">
                        <span class="badge text-uppercase" :class="setStatusClass">{{ form.status }}</span>
                    </template>
                </div><!--Col-->
            </div>
  
              <form @submit.prevent="postForm">

                <div class="form-group">
                <fieldset>
                  <legend>Mail Template</legend>
                  <template v-for="mail_template in mail_templates">
                    <label
                        class="mr-2 text-capitalize"
                        :for="'template_'+mail_template"
                    >
                      <input
                          :id="'template_'+mail_template"
                          v-model="form.type"
                          type="radio"
                          name="type"
                          :value="mail_template"
                      > {{ mail_template }}
                    </label>
                  </template>
                </fieldset>
            </div>

                <div class="form-group">
                    <label for="name">Name</label>
                    <input type="text" class="form-control" id="name" v-model="form.name" required>
                </div>

                <div class="form-group">
                    <label for="address_1">Address 1</label>
                    <input type="text" class="form-control" id="address_1" v-model="form.address_1" required>
                </div>

                <div class="form-group">
                    <label for="address_2">Address 2</label>
                    <input type="text" class="form-control" id="address_2" v-model="form.address_2">
                </div>

                <div class="form-group">
                    <label for="city">City</label>
                    <input type="text" class="form-control" id="city" v-model="form.city" required>
                </div>

                <div class="form-group">
                    <label for="state">State</label>
                    <select class="form-control" id="state" v-model="form.state" required>
                        <option v-for="state in states" :value="state.key">{{ state.label }}</option>
                    </select>
                </div>

                <div class="form-group">
                    <label for="postal_code">Postal Code</label>
                    <input type="text" class="form-control" id="postal_code" v-model="form.postal_code" required>
                </div>

                <div class="form-group">
                    <label for="country">Country</label>
                    <select class="form-control" id="country" v-model="form.country" required>
                        <option v-for="country in countries" :value="country.code">{{ country.name }}</option>
                    </select>
                </div>

                <button type="submit" class="btn btn-success d-print-none" :disabled="processing" data-html2canvas-ignore>Save</button>
  
              </form>
  
            </div><!--Body-->
          </div><!--Card-->
  
          <template v-if="form.uuid">
            <audit-logs :form="form" />
          </template>
  
        </div><!--Col-->
  
        <div class="col-sm-12 col-md-6">

          <div class="card">
            <div class="card-body">
              <p class="card-title">Tracking</p>

              <template v-if="!form.tracking">
                <p>No tracking information available</p>
              </template>
              
            </div><!--Body-->
          </div><!--Card-->
          
            <template v-if="file && file.uuid">
                <div class="card mb-3">
                    <div class="card-body">
                        <p class="card-title">File</p>
                        <p>{{ file.name }}</p>
                        <template v-if="file.size">
                            <code class="ml-3">Size: {{ file.size | fileSize }}</code>                        
                        </template>

                        <template v-if="form.url">
                            <p><a :href="form.url" class="btn btn-primary" target="_blank" :disabled="processing">Download</a></p>
                        </template>

                    </div><!--Body-->
                </div><!--Card-->

            </template>
  
        </div><!--Col-->
  
        <div class="col-sm-12 col-md-3">
  
            <template v-if="form && form.url">
                <iframe :src="form.url" width="100%" height="960" border="none" :title="form.name"></iframe>
            </template>

        </div><!--Col-->
  
  
      </div><!--Row-->
    </div>
  </template>
  <script>
  import _ from 'lodash';
  export default {
  
    data () {
      return {
        processing: false,
        form: {},
        file: {},
        download: '',
        countries: [],
        states: [],
        mail_templates: ['postcard', 'letter'],
      }
    },
  
    created() {
        this.resetForm();
        this.getRecord();
        this.getStates();
        this.getCountries();
    },
  
  
    methods: {
  
      resetForm() {
        this.form = {
          name: null,
          type: null,
        }
      },

      setStatusClass() 
        {
            if(this.form.status == 'ready') {
            return 'bg-success';
            }
            if(this.form.status == 'inactive') {
            return 'bg-danger';
            }
            return 'bg-secondary';
        },

        getStates() {
            this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/states.json')
            .then(response => {
                if(response && response.data) {
                    this.states = response.data;
                }
            })
        },

        getCountries() {
            this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/country_codes.json')
            .then(response => {
                if(response && response.data) {
                    this.countries = response.data;
                }
            })
        },
  
      getRecord() {
        this.processing = true;
        this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
            .then(response => {
              if(response && response.data) {
                this.processing = false;
                this.form = response.data;
                this.getFile();
              }
            })
      },

      getFile() {
        if(!this.form.file) {
            return;
        }

        this.file = {};

        this.$http.get('/files/'+this.form.file)
            .then(response => {
                if(response && response.data) {
                    this.file = response.data;
                    // this.getDownload();
                }
            })
      },

      getDownload() {

        if(!this.form.file) {
            return;
        }

        this.processing = true;
        this.download = '';
        this.$http.get('/files/'+this.form.file+'/download')
            .then(response => {
                if(response && response.data) {
                    this.processing = false;
                    this.download = response.data;
                }
            })
      },
            
  
      postForm() {
        this.processing = true;
        this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
            .then(response => {
              if(response && response.data) {
                this.processing = false;
                new this.$noty({text: this.$route.meta.title + ' saved'}).show();
              }
            })
      },
  
      restoreRecord () {
        this.processing = true;
        this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
            .then(response => {
              if (response && response.data) {
  
                this.processing = false;
  
                if(response.status === 200) {
                  new this.$noty({text: this.$route.meta.title + ' restored'}).show();
                  this.form.deleted_at = null;
                  this.form.archived_at = null;
                }
  
              }
            })
      },
  
      archiveRecord () {
        this.processing = true;
        this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/archive')
            .then(response => {
              if (response && response.data) {
  
                this.processing = false;
  
                if(response.status === 200) {
                  new this.$noty({text: this.$route.meta.title + ' archived'}).show()
                  this.form.archived_at = new Date().toISOString();
                }
  
              }
  
            })
      },
  
      trashRecord () {
        this.processing = true;
        this.$http.delete('/'+this.$route.meta.base_url + '/' + this.form.uuid)
            .then(() => {
              this.processing = false;
              new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
              this.form.deleted_at = new Date().toISOString();
            })
      },
  
    
  
    },
  }
  </script>