<template>
  <div>
    <div class="card">
      <div class="card-body">
        <template v-if="processing && !form.uuid">
          <div class="alert alert-warning m-0">
            No event found
          </div>
        </template>

        <template v-if="!processing && form.uuid">
          <p class="card-title">
            Event <router-link :to="{ 'name': 'events.edit', 'params': {'id': form.uuid} }">
              {{ form.event_reference }} {{ form.case_number }} {{ form.date | date }} {{ form.time | time }}
            </router-link>
          </p>

    
          <ul class="list-group">

            <template v-if="form.description">
              <li class="list-group-item">
                Description {{ form.description }}
              </li>
            </template>

            <template v-if="form.type">
              <li class="list-group-item">
                Type {{ form.type }}
              </li>
            </template>

            <template v-if="form.status">
              <li class="list-group-item">
                Status {{ form.status }}
              </li>
            </template>

            
              <li class="list-group-item">
                Location 
                <template v-if="location.name">
                <a :href="'/locations/'+location.uuid">{{ location.name }}</a>
                </template>
                <template v-else>
                  No Location
                </template>
                
              </li>

            <template v-if="form.location_notes">
              <li class="list-group-item">
                Location Notes {{ form.location_notes }}
              </li>
            </template>

            <template v-if="form.judge_name">
              <li class="list-group-item">
                Judge {{ form.judge_name }}
              </li>
            </template>
          </ul>
        </template>
      </div><!--Body-->
    </div><!--Card-->
  </div>
</template>
<script>
export default {

props: ['event'],

data() {
    return {
        processing: false,
        form: {},
        location: {}
    }
},

created() {
    this.getForm()
},

methods: {

        getForm() {
            
            if(!this.event) {
                return;
            }

            this.processing = true
            this.form = {}

            this.$http.get('/events/'+this.event+'?slim=true&sort=date&sortDirection=asc')
            .then(response => {
                if(response && response.data) {
                    this.form = response.data
                    this.processing = false

                    if(this.form.location) {
                        this.getLocation(this.form.location)
                    }
                }
            })
        },

        getLocation(uuid) {
            this.$http.get('/locations/'+uuid+'?slim=true&fields=name,uuid')
            .then(response => {
                if(response && response.data) {
                    this.location = response.data
                }
            })
        }

}

}
</script>