<template>
  <div>

    <loader :isLoading="processing"></loader>

    <template v-if="url">

      <template v-if="isImage()">

        <a :href="url" target="_blank">
          <img :src="url" class="img-fluid" :alt="form.name">
        </a>

      </template>

      <template v-if="isPDF()">
        <iframe :src="url" width="100%" height="960" frameborder="0" border="0px"></iframe>
      </template>

      <template v-if="isVideo()">

        <video preload=auto width="100%" height="600" controls>
          <source :src="url" :type="form.type">
        </video>

      </template>

      <template v-if="isAudio()">

        <audio controls>
          <source :src="url" :type="form.type">
        </audio>

      </template>

      <template v-if="isCSV()">

        <template v-if="rows && rows.length > 0">

          <div class="card">
            <div class="card-body">

        <div class="table-responsive">
        <table class="table table-hover table-striped">
          <thead>
          <tr>
            <template v-for="header in headers">
              <th class="font-weight-bold">{{ header }}</th>
            </template>
          </tr>
          </thead>
          <tbody>

        <template v-for="(row, row_index) in rows">
          <tr :hidden="row_index == 0">
            <template v-for="(column, column_index) in row">
              <td>{{ column.replace(/["']/g, "") }}</td>
            </template>
          </tr>
        </template>

          </tbody>
        </table>
        </div><!--Responsive-->

            </div>
          </div>

        </template><!--Table-->


      </template><!--CSV-->

      <template v-if="isExcel()">
        
        <template v-if="sheets && sheets.length > 0">

          <div class="btn-group mb-3">
          
          <template v-for="sheet in sheets">

            <button class="btn btn-secondary" :class="sheet == current_sheet ? 'active' : ''" @click="toggleSheet(sheet)" :disabled="processing">{{ sheet }}</button>

          </template>

          </div>

        </template>

        <template v-if="rows && rows.length > 0">

            <div class="card">
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <template v-for="header in headers">
                          <th>{{ header }}</th>
                        </template>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(row, row_index) in rows">
                        <tr :hidden="row_index == 0">
                          <template v-for="column in row">
                            <td>{{ column }}</td>
                          </template>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div><!--Responsive-->
              </div>
            </div>

        </template>
        
      </template>

      <template v-if="isTXT()">
            <pre v-if="!processing">{{ download }}</pre>
      </template><!--TXT-->

    </template>

  </div>
</template>
<script>
export default {

props: ['form', 'url'],

data() {
    return {
      processing: false,
      base_url: '/files',
      download: {},
      rows: [],
      headers: [],
      sheets: [],
      current_sheet: '',
      workbook: {}
    }
},

  created() {
      this.downloadFile();
  },

methods: {

    isImage() {

      if(!this.form || !this.form.type) {
        return false;
      }

      const types = ['image/png', 'image/gif', 'image/jpg', 'image/jpeg', 'image/svg', 'image/svg+xml'];

      return types.includes(this.form.type);
    },

    isVideo() {

      if(!this.form || !this.form.type) {
        return false;
      }

      const types = ['video/mp4', 'video/ogg', 'video/webm', 'video/mp3'];

      return types.includes(this.form.type);
    },

    isAudio() {

      if(!this.form || !this.form.type) {
        return false;
      }

      const types = ['audio/mpeg', 'audio/mp4', 'application/ogg', 'audio/ogg', 'audio/x-wav', 'audio/wav', 'audio/webm', 'audio/aac', 'audio/aacp', 'audio/webm', 'audio/x-caf', 'audio/flac'];

      return types.includes(this.form.type);
    },

    isPDF() {

      if(!this.form || !this.form.type) {
        return false;
      }

      const types = ['application/pdf'];

      return types.includes(this.form.type);
    },

    isCSV() {

      if(!this.form || !this.form.type) {
        return false;
      }

      const types = ['text/csv'];

      return types.includes(this.form.type);
    },

    isTXT() {
        
        if(!this.form || !this.form.type) {
          return false;
        }
  
        const types = ['text/plain'];
  
        return types.includes(this.form.type);
    },

    isExcel() {
        
        if(!this.form || !this.form.type) {
          return false;
        }
  
        const types = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  
        return types.includes(this.form.type);
    },

    downloadFile() {

      if(!this.url) {
        return;
      }

      this.processing = true;

      this.$axios.get(this.url)
          .then(response => {
            if (response && response.data) {
              this.download = response.data;

              this.processing = false;


              if(this.isCSV()) {
                this.parseCSV();
                return;
              }

              if(this.isExcel()) {
                this.parseExcel();
              }
            }
          })
    },

  parseCSV() {
      if(!this.download) {
        return;
      }

    this.rows = this.download.trim().split('\n');

    this.headers = this.rows[0].split(',');

    this.rows = this.rows.slice(1).map(row => row.split(','));
  },

  parseExcel() {
      if(!this.download) {
        return;
      }

      //download from url
      this.$axios.get(this.url, { responseType: 'arraybuffer' })
          .then(response => {
          if(response && response.status) {
              this.processing = false;

              var buffer = response.data;

              this.workbook = this.$xlsx.read(buffer, { type: 'buffer' });

              const sheetName = this.workbook.SheetNames[0];
              const sheet = this.workbook.Sheets[sheetName];

              this.current_sheet = sheetName;

              this.sheets = this.workbook.SheetNames;

              this.rows = this.$xlsx.utils.sheet_to_json(sheet, { header: 1 });

              this.headers = this.rows[0];

              }

              })
      },

      toggleSheet(sheet) {
        this.current_sheet = sheet;

        const sheetData = this.workbook.Sheets[sheet];

        this.rows = this.$xlsx.utils.sheet_to_json(sheetData, { header: 1 });

        this.headers = this.rows[0];
      }

    

}

}
</script>