<template>
    <div>


            <div class="card">
              <div class="card-body">
  
                <div class="row">
  
                  <div class="col-sm-12 col-md-6">
  
                    <p class="card-title">
                      {{ records.length }} of {{ total.toLocaleString() }} Message<template v-if="total != 1">s</template>
                    </p>
  
                  </div><!--Col-->
  
                  <div class="col-sm-12 col-md-6 text-right">
  
                    <div class="btn-group d-print-none" data-html2canvas-ignore>
                      <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                      <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                    </div>
                  </div><!--Col-->
  
                </div><!--Row-->
  
                <template v-if="actions && actions.messages && actions.messages.length > 0">
  
                  <p class="text-success">
                    {{ actions.messages.length }} selected
                  </p>
  
                  <div class="row">
  
                    <div class="col-sm-12 col-md-6">
                      <button type="button" class="btn btn-danger" @click="deselectAll()" :disabled="processing"><i class="fa fa-times"></i> Deselect All</button>
                      <template v-if="actions.messages.length < this.total">
                        <button type="button" class="btn btn-primary" @click="selectAll()" :disabled="processing"><i class="fa fa-check"></i> Select All</button>                    
                      </template>
                      <button type="button" class="btn btn-success" @click="forwardMessages()" :disabled="processing"><i class="fa fa-forward"></i> Forward</button>
                      <button type="button" class="btn btn-success" @click="resendMessages()" :disabled="processing"><i class="fa fa-redo"></i> Resend</button>
                    </div><!--Col-->
  
                  </div>
  
                  <div class="row my-3" v-if="actions.action">
  
                    <div class="col-sm-12 col-md-6">
  
                      <form @submit.prevent="postAction()">
  
                    <template v-if="actions.action == 'forward'">
                    
                      <p class="lead m-0">Enter an email or phone number to forward each selected message to.</p>
  
                      <div class="form-group">
                      <label for="forward_to">Forward To</label>
                      <input
                        id="forward_to"
                        v-model="actions.forward_to"
                        type="text"
                        class="form-control"
                        required
                        autocomplete="off"
                      >
                    </div>
  
                    </template>
  
                    <template v-if="actions.action == 'resend'">
                      <p class="lead m-0">Resend the selected messages.</p>
  
                      <div class="form-group">
                        <label for="resend_channel">Channel</label>
                        <select
                          id="resend_channel"
                          v-model="actions.resend_channel"
                          class="form-control"
                        >
                          <option value="">Default</option>
                          <option value="text">Text</option>
                          <option value="voice">Voice</option>
                          <option value="email">Email</option>
                        </select>
                      </div>
  
                    </template>
  
                    <button type="button" class="btn btn-secondary" @click="resetActionsForm()" :disabled="processing">Cancel</button>
                  
  
                    <button type="submit" class="btn btn-success" :disabled="processing">Confirm</button>
  
                    </form>
  
  
                    </div><!--Col-->
  
                  </div>
  
                  </template>
  
  
                <div class="table-responsive my-3">
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Edit</th>
                      <th @click="toggleSelect()" class="link-hover">Select</th>
                      <template v-for="(column, index) in columns">
                        <th
                          class="text-uppercase"
                        >
                          <template v-if="column.key">
                            <span
                              class="text-capitalize link-hover"
                              @click="sortColumn(column)"
                            >{{ column.label }}</span>
                          </template>
    
                          <template v-if="!column.key">
                            <span class="text-capitalize">{{ column.label }}</span>
                          </template>
    
                          <template v-if="column.key == queries.sort">
                            <button
                              type="button"
                              class="btn btn-outline-secondary btn-sm ml-1"
                              :disabled="processing"
                              @click="updateSortDirection()"
                            >
                              <template v-if="queries.sortDirection == 'asc'">
                                <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                              </template>
    
                              <template v-if="queries.sortDirection == 'desc'">
                                <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                              </template>
                            </button>
                          </template>
                        </th>
                      </template>
                    </tr>
                  </thead>
  
                  <tbody>
                    <template>
                      <template v-for="(record, index) in records">
                        <tr>
                          <td>
                            <a :href="'/messages/'+record.uuid">
                              <i class="fa fa-edit"></i> Edit
                            </a>
                        </td>
                          <td>
                            <input
                              type="checkbox"
                              :value="record.uuid"
                              v-model="actions.messages"
                            />
                          </td>
                          <td>{{ record.type }}</td>
                          <td>
                            <template v-if="record.client">
                              <client-button :uuid="record.client" />
                            </template>
                          </td>
                          <td>
                            <template v-if="record.event">
                              <router-link :to="{'name': 'events.edit', 'params': {'id': record.event }}">
                                <i class="fa fa-calendar"></i> Event
                              </router-link>
                            </template>
                          </td>
                          <td>
                            <template v-if="record.payment">
                              <router-link :to="{'name': 'payments.edit', 'params': {'id': record.payment }}">
                                <i class="fa fa-credit-card"></i> Payment
                              </router-link>
                            </template>
                          </td>
                          <td>
                            <a
                              :href="'/contacts?search='+record.to"
                            >{{ record.to }}</a>
                          </td>
                          <td>
                            <a
                              :href="'/contacts?search='+record.from"
                            >{{ record.from }}</a>
                          </td>
                          <td>
                            <template v-if="record.last_status">
                              <span class="badge badge-pill text-uppercase" :class="setStatusClass(record.last_status)">{{ record.last_status }}</span>
                            </template>
  
                            <template v-if="record.error_code">
                              <p class="badge badge-pill badge-secondary">
                              {{ record.error_code }}
                              {{ setErrorCode(record.error_code) }}
                              </p>
                            </template>
  
                            <template v-if="record.test">
                              <span class="badge badge-primary badge-pill">SIMULATED</span>
                            </template>
                          </td>
                          <td>
                            <template v-if="record.last_open">
                              <span class="badge badge-pill badge-success">{{ record.last_open | dateTimezone | timeago }}</span>
                            </template>
                          </td>
  
                          <td>{{ record.subject }}</td>
                          <td>
                            <template v-if="record.content">
                              <p :class="record.direction"><span v-html="sanitizeContent(record.content)"></span></p>
                            </template>
                          </td>
                          <td>{{ record.created_by }}</td>
                          <td>{{ record.scheduled_at | datetime }}</td>
                          <td>{{ record.created_at | datetime }}
  
                            <template v-if="record.deleted_at">
                              <span class="badge badge-pill badge-danger">TRASHED</span>
                            </template>
  
                            <template v-if="record.archived_at">
                              <span class="badge badge-pill badge-warning">ARCHIVED</span>
                            </template>
                            
                          </td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>
                </div><!--Responsive-->
  
                <template v-if="!processing && !records.length">
                  <div class="alert alert-warning">
                    No messages found.
                  </div>
                </template>
              </div><!--Body-->
            </div><!--Card-->
  
            <div class="row">
              <div class="col-12 text-center">
                <template v-if="records && records.length && total > records.length">
                  <div class="d-flex justify-content-center align-items-center py-3">
                    <button
                      type="button"
                      ref="loadMoreButton"
                      class="btn btn-outline-primary"
                      :disabled="processing"
                      @click="getRecords"
                    >
                      Load more
                    </button>
                  </div>
                </template>
              </div><!--Col-->
        </div><!--Row-->
  
    </div>
  </template>
  <script>
  import queries from '../mixins/queries';
  import _ from "lodash";
  
  export default {
  
  mixins: [ queries ],
  
  data() {
      return {
          processing: false,
          downloading: false,
          total: 0,
          records: [],
          downloads: [],
          options: [],
          queries: {},
          filters_url: [],
          message_filters: [],
          message_types: [],
          actions: {
            action: null,
            messages: []
          },
          columns: [
              {key: 'type', label: 'Type'},
              {label: 'Client'},
              {label: 'Event'},
              {label: 'Payment'},
              {key: 'to', label: 'To'},
              {key: 'from', label: 'From'},
              {key: 'last_status', label: 'Status'},
              {key: 'last_open', label: 'Opened'},
              {key: 'subject', label: 'Subject'},
              {key: 'content', label: 'Content'},
              {key: 'created_by', label: 'Creator'},
              {key: 'scheduled_at', label: 'Scheduled'},
              {key: 'created_at', label: 'Created'},
            ],
          search_fields: ['from', 'to', 'status', 'type', 'subject', 'content', 'channel', 'error_code', 'language', 'direction'],
          error_codes: [],
          users: []
      }
  },
  
  mounted() {
    var self = this;
  
    this.$eventBus.$on('errors', function (errors) {
      self.processing = false;
    });

    this.$eventBus.$on('scrolling', function(data) {
        self.shouldLoadMore();
    });

    this.$eventBus.$on('searchMessages', function(data) {
        self.queries = data;
        self.searchRecords();
    });
    
  },
  
  created() {
  
      this.resetQueries();
      this.resetRecords();
      this.setURLParams();
      this.getOptions();
      this.getMessageTypes();
  
      if(this.$route.query.type) {
          this.queries.type = this.$route.query.type;
      }
  
      if(this.$route.query.search) {
          this.queries.search = this.$route.query.search;
      }
  
      this.getErrorCodes();
  
      this.getRecords();
  
      this.getUsers();
  },
  
  
  methods: {
  
      resetQueries() {
        this.queries = {
            search: null,
            search_field: 'content',
            subject: null,
            from_date: null,
            from_time: null,
            to_date: null,
            to_time: null,
            type: null,
            live: null,
            created_by: null,
            client: null,
            status: null,
            credits: null,
            limit: 10,
            skip: 0,
            filters: [],
            sort: 'scheduled_at',
            sortDirection: 'desc',
            slim: true,
            simulate: false
        }
      },
  
      resetRecords() {
        this.records = [];
        this.queries.skip = 0;
        this.total = 0;      
      },
  
      setURLParams() {
          var searchParams = new URLSearchParams(window.location.search);
  
          var self = this;
  
          searchParams.forEach(function(value, key) {
  
            if(!value || value == 'undefined' || value == 'null') {
              return;
            }
            
            self.queries[key] = value;
            
          });
  
      },
  
      setStatusClass(status) {
  
        status = status.toLowerCase();
  
        if(_.includes(['undelivered', 'failed', 'duplicate', 'bounced', 'missed', 'invalid', 'busy', 'timeout', 'cancel', 'rejected', 'callback-error'], status)) {
            return 'badge badge-danger';
        }
        if(_.includes(['delivered', 'opened', 'answered', 'hangup'], status)) {
            return 'badge badge-success';
        }
        if(_.includes(['dispatched', 'sent'], status)) {
            return 'badge badge-primary';
        }
        return 'badge badge-primary';
     }, 
  
    getOptions () {
        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/options.json')
        .then(response => {
          if(response && response.data) {
              this.options = response.data;
          }
        })
      },
  
      getMessageTypes() {
          this.$http.get('/message_types')
          .then(response => {
              if(response && response.data) {
                 this.message_types = response.data;
              }
          })
      },
    
      getRecords() {
  
        if(this.settings && this.settings.filter_clients_data && this.settings.filter_clients_data == true) {
              if(this.$root.current_user.super_admin != true) {
                this.getClients();
                return;
              }
        }
  
        this.getMessages();
  
      },
  
      getClients() {
  
      var user = localStorage.getItem('user') || null;
  
      if(!user) {
        return;
      }
  
        this.$http.get('/clients?user='+user+'&fields=uuid&slim=true')
        .then(response => {
            if(response && response.data) {
                this.clients = response.data;
  
                if(this.clients.length > 0) {
                  this.queries.clients = this.clients.map(function(client) {
                    return client.uuid;
                  });
                }
  
                this.getMessages();
              }
        })
      },

      loadMessages() {
        this.$http.get(this.buildQueries('/messages', this.queries))
          .then(response => {
              if(response && response.data) {
  
                  this.processing = false;
  
                  if(response.data.data) {
                      this.records = this.records.concat(response.data.data);
                      this.queries.skip = this.records.length;                    
                  }
              }
          })
      },
  
      getMessages() {
  
          this.processing = true;
  
          let message_filters = this.message_filters.map((field) => {return field.key});
  
          this.queries.filters = message_filters.toString();

          this.queries.sent = 1;
    
          this.getTotal();
      },
  
      getTotal() {
          this.$http.get(this.buildQueries('/messages/total', this.queries))
          .then(response => {
              if(response && response.status) {
                  this.total = response.data;
                   this.loadMessages();
                }
          })
      },
  
      setTitle() {
        document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
      },
  
      setSearchField(field) {
        this.queries.search_field = field;
      },
  
      searchRecords () {
        this.resetRecords();
        this.getRecords();        
      },
  
      getErrorCodes() {
          this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/error_codes.json')
          .then(response => {
              if(response && response.data) {
                  this.error_codes = response.data;
              }
          })
      },
  
      getUsers() {
        this.$http.get('/users?slim=true&limit=1000&fields=name,email&sort=name&sortDirection=asc')
        .then(response => {
          if(response && response.data) {
            this.users = response.data;
          }
        })
      },
  
    setErrorCode(code) {
  
      if(!code) {
        return;
      }
  
      if(!this.$root['error_codes']) {
        return;
      }
  
      if(!this.$root['error_codes'][code]) {
        return;
      }
  
      return this.$root['error_codes'][code];
    },
  
    updateSortDirection() {
    
    if(this.processing) {
      return;
    }
  
    if(this.queries.sortDirection == 'asc') {
      this.queries.sortDirection = 'desc';
    } else {
      this.queries.sortDirection = 'asc';
    }
  
    this.resetRecords();
  
    this.getRecords();
    },
  
    sortColumn(column) {
      
      if(this.processing) {
        return;
      }
  
      this.queries.sort = column.key;
  
      this.updateSortDirection();
    },
  
    resendMessages() {
      if(!this.actions.action || this.actions.action != 'resend') {
        this.actions.action = 'resend';
        return;
      }
  
      this.processing = true;
  
      this.actions.messages.forEach((uuid) => {
  
      var message = this.records.find((record) => {
        return record.uuid == uuid;
      });
  
      if(!message || !message.content) {
        return;
      }
  
      var post = {
        type: message.type,
        to: message.to,
        from: message.from,
        subject: message.subject,
        content: message.content,
        channel: message.channel,
      };
  
      var fields = ['client', 'event', 'payment', 'form', 'case'];
  
      fields.forEach((field) => {
        if(message[field]) {
          post[field] = message[field];
        }
      });
  
      if(this.actions.resend_channel) {
        post.channel = this.actions.resend_channel;
      }
  
      this.$http.post('/messages/oneoffs', post)
      .then(response => {
          this.processing = false;
  
          this.actions.messages.splice(this.actions.messages.indexOf(uuid), 1);
  
          if(this.actions.messages.length < 1) {
            new this.$noty({text: 'Messages resent', type: 'success'}).show();
            this.resetActionsForm();
          }
      })
    })
    },
  
    forwardMessages() {
  
      if(!this.actions.action || this.actions.action != 'forward') {
        this.actions.action = 'forward';
        return;
      }
  
      if(!this.actions.forward_to) {
        new this.$noty({text: 'Please enter a valid email or phone to forward to.', type: 'error'}).show();
        return;
      }
  
      if(!this.actions.messages || this.actions.messages.length < 1) {
        new this.$noty({text: 'Please select at least one message to forward.', type: 'error'}).show();
        return;
      }
  
      this.processing = true;
  
      this.actions.messages.forEach((uuid) => {
  
        var message = this.records.find((record) => {
          return record.uuid == uuid;
        });
  
        if(!message || !message.content) {
          return;
        }
  
        var name = this.settings && this.settings.name ? this.settings.name : 'eCourtDate.com';
  
        this.$http.post('/messages/oneoffs', {
          to: this.actions.forward_to,
          type: 'forward',
          subject: 'Message Forwarded from '+name,
          content: message.content,
        })
        .then(response => {
            this.processing = false;
  
            this.actions.messages.splice(this.actions.messages.indexOf(uuid), 1);
  
            if(this.actions.messages.length < 1) {
              new this.$noty({text: 'Messages forwarded', type: 'success'}).show();
              this.resetActionsForm();
            }
        })
      })
    },
  
    resetActionsForm() {
      this.actions = {
        action: null,
        messages: []
      }
    },
  
    deselectAll() {
      this.actions.messages = [];
    },
  
    selectAll() {
      this.actions.messages = this.records.map(function(record) {
        return record.uuid;
      });
    },
  
    toggleSelect() {
      if(this.actions.messages.length == this.total) {
        this.deselectAll();
      } else {
        this.selectAll();
      }
    },
  
    postAction() {
  
      if(this.actions.action == 'forward') {
        this.forwardMessages();
      }
  
      if(this.actions.action == 'resend') {
        this.resendMessages();
      }
  
    },

    shouldLoadMore() {

        if(this.processing) {
        return;
        }

        if(this.total <= this.records.length) {
        return;
        }

        const loadMoreButton = this.$refs.loadMoreButton;

        if(!loadMoreButton) {
        return;
        }

        const rect = loadMoreButton.getBoundingClientRect();
        if (rect.top <= window.innerHeight) {
        this.getMessages();
        return;
        }
    }
  
    }
  
  }
  </script>