<template>
  <div>
    <div class="card">
      <div class="card-body">
        <template v-if="processing && !form.uuid">
          <div class="alert alert-warning m-0">
            No case found
          </div>
        </template>

        <template v-if="!processing && form.uuid">
          <p class="card-title">
            Case <router-link :to="{ 'name': 'cases.edit', 'params': {'id': form.uuid} }">
              {{ form.case_number }}
            </router-link>
          </p>
          
          <ul class="list-group">
            <template v-if="form.status">
              <li class="list-group-item">
                Status {{ form.status }}
              </li>
            </template>
          </ul>

        </template>
      </div><!--Body-->
    </div><!--Card-->
  </div>
</template>
<script>
export default {

props: ['case'],

data() {
    return {
        processing: false,
        base_url: '/cases',
        form: {}
    }
},

created() {
    this.getForm();
},

methods: {

        getForm() {
            
            if(!this.case) {
                return;
            }

            this.processing = true;
            this.form = {};

            this.$http.get(this.base_url+'/'+this.case+'?slim=true&fields=uuid,case_number,status')
            .then(response => {
                if(response && response.data) {
                    this.form = response.data;
                    this.processing = false;
                }
            })
        }
}

}
</script>