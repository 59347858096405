<template>
  <div>

    <archived-trashed-status
        :form="form"
        :processing="processing"
        :title="$route.meta.title"
    />

    <div class="row">
      <div class="col-sm-12 col-md-8">
        <h1 class="m-0">{{ form.name }}</h1>
        <p>
          <router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4 text-right">
        <div class="btn-group">

          <div class="btn-group d-print-none" data-html2canvas-ignore>
            <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
              Tools
            </button>
            <div class="dropdown-menu">
              <a
                  v-if="!form.archived_at && !form.deleted_at"
                  class="dropdown-item"
                  @click="archiveRecord"
              >Archive</a>
              <a
                  v-if="form.archived_at || form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord"
              >Restore</a>
              <a
                  v-if="!form.deleted_at && !form.archived_at"
                  class="dropdown-item"
                  @click="trashRecord"
              >Trash</a>
            </div>
          </div>

          <button
              type="button"
              class="btn btn-success d-print-none"
              :disabled="processing"
              @click="postForm()"
              data-html2canvas-ignore
          >
            Save
          </button>

          <router-link
              :to="{ name: $route.meta.base_url+'.index' }"
              class="btn btn-danger d-print-none"
              data-html2canvas-ignore
          >
            Close
          </router-link>
        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card mb-3">
          <div class="card-body">
            <p class="card-title">Edit {{ $route.meta.title }}</p>

            <form @submit.prevent="postForm">

              <div class="form-group">
                <label for="name">Name</label>
                <input
                    type="text"
                    class="form-control"
                    id="name"
                    v-model="form.name"
                    required
                >
              </div>

              <div class="form-group">
                  <label for="action">Action Template</label>
                  <select
                    id="action"
                    v-model="form.action"
                    class="form-control text-capitalize"
                    :disabled="processing"
                    required
                  >
                  <option value="" />
                  <template v-for="action in actions">
                    <option :value="action.uuid">
                      {{ action.name }}
                    </option>
                  </template>
                   
                  </select>
                </div>

              <div class="form-group">
                <label for="frequency">Frequency</label>
                <select
                    class="form-control"
                    id="frequency"
                    v-model="form.frequency"
                    required
                >
                  <option value="">Select Frequency</option>
                  <option v-for="frequency in frequencies" :value="frequency">{{ frequency }}</option>
                </select>
              </div>

              <div class="form-group">
                <label for="status">Status</label>
                <select
                    class="form-control"
                    id="status"
                    v-model="form.status"
                    required
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>

              <div class="form-group">
                <label for="start_date">Start Date</label>
                <input
                    type="date"
                    class="form-control"
                    id="start_date"
                    v-model="form.start_date"
                >
              </div>

              <div class="form-group">
                <label for="end_date">End Date</label>
                <input
                    type="date"
                    class="form-control"
                    id="end_date"
                    v-model="form.end_date"
                >
              </div>

              <button type="submit" class="btn btn-success d-print-none" :disabled="processing" data-html2canvas-ignore>Save</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <template v-if="form.uuid">
          <audit-logs :form="form" />
        </template>

      </div><!--Col-->

      <div class="col-sm-12 col-md-6">

        <template v-if="bulk_action.uuid">
          <div class="card mb-3">
            <div class="card-body">

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <p class="card-title">{{ bulk_action.name }}</p>
                </div><!--Col-->
                <div class="col-sm-12 col-md-6 text-right">
                  <a :href="'/bulk_actions/'+bulk_action.uuid" class="btn btn-primary btn-sm">Edit Bulk Action</a>
                </div><!--Col-->
              </div><!--Row-->

              <p>{{ bulk_action.type }}</p>

              <button
                  type="button"
                  class="btn btn-success d-print-none"
                  :disabled="processing"
                  @click="runAction"
                  data-html2canvas-ignore
              >
              Run One-Time Action
              </button>

              <template v-if="bulk_action.action">
                
                <p class="my-3">Action Settings</p>
                <ul class="list-group">

                  <template v-if="bulk_action.action.message_delay">
                    <li class="list-group-item">Message Delay {{ bulk_action.action.message_delay }}</li>
                  </template>

                  <template v-if="bulk_action.action.update_client_group">
                    <li class="list-group-item">Update Client Group {{ bulk_action.action.update_client_group }}</li>
                  </template>

                  <template v-if="bulk_action.type == 'Send a Message'">
                    
                    <template v-if="bulk_action.action.messages">
                      <li class="list-group-item">Message</li>
                      <ul class="list-group">

                        <template v-for="(message, lang) in bulk_action.action.messages">
                          <template v-if="message">
                            <li class="list-group-item">{{ lang }} {{ message }}</li>                          
                          </template>
                        </template>

                      </ul>

                    </template>
                      
                  </template>

                </ul>
                
              
              </template>

              <template v-if="bulk_action.search">

              <p class="my-3">Search Filters</p>

              <ul class="list-group">
              
                <template v-i="bulk_action.search.limit">
                  <li class="list-group-item">Client Limit {{ bulk_action.search.limit }}</li>
                </template>

                <template v-i="bulk_action.search.random_limit">
                  <li class="list-group-item">Random Limit {{ bulk_action.search.random_limit }}</li>
                </template>

                <template v-i="bulk_action.search.groups">
                  <li class="list-group-item">Groups {{ bulk_action.search.groups }}</li>
                </template>
              
              </ul>
              </template>

            </div><!--Body-->
          </div><!--Card-->
        </template>

      </div><!--Col-->

      <div class="col-sm-12 col-md-3">

        <template v-if="ran_actions.length">
          <div class="card mb-3">
            <div class="card-body">
              <p class="card-title">
                <a :href="'/bulk_actions?created_by='+form.uuid">{{ ran_actions.length }} Ran Actions</a>
              </p>
              <ul class="list-group">
                <template v-for="ran_action in ran_actions">
                  <li class="list-group-item">
                    <router-link :to="{name: 'bulk_actions.edit', params: {id: ran_action.uuid}}">
                      {{ ran_action.name }} {{ ran_action.type }} {{ ran_action.status }} {{ ran_action.created_at | datetime }}
                    </router-link>
                  </li>
                </template>
              </ul>
            </div><!--Body-->
          </div><!--Card-->
        </template>


      </div><!--Col-->


    </div><!--Row-->
  </div>
</template>
<script>
export default {

  data () {
    return {
      processing: false,
      form: {},
      frequencies: [
        'daily',
        'weekly',
        'monthly',
        'quarterly',
        'bi-annually',
        'annually'
      ],
      actions: [],
      bulk_action: {},
      ran_actions: []
    }
  },

  created() {
    this.resetForm();
    this.getRecord();
    this.getBulkActions();
  },

  methods: {

    resetForm() {
      this.form = {
        name: null,
        action: null,
        frequency: null,
        status: null,
        start_date: null,
        end_date: null
      }
    },

    getRecord() {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
          .then(response => {
            if(response && response.data) {
              this.processing = false;
              this.form = response.data;
              this.getBulkAction();
              this.getRanActions();
            }
          })
    },

  
    postForm() {
      this.processing = true;
      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
          .then(response => {
            if(response && response.data) {
              this.processing = false;
              new this.$noty({text: this.$route.meta.title + ' saved'}).show();
            }
          })
    },

    restoreRecord () {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
          .then(response => {
            if (response && response.data) {

              this.processing = false;

              if(response.status === 200) {
                new this.$noty({text: this.$route.meta.title + ' restored'}).show();
                this.form.deleted_at = null;
                this.form.archived_at = null;
              }

            }
          })
    },

    archiveRecord () {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/archive')
          .then(response => {
            if (response && response.data) {

              this.processing = false;

              if(response.status === 200) {
                new this.$noty({text: this.$route.meta.title + ' archived'}).show()
                this.form.archived_at = new Date().toISOString();
              }

            }

          })
    },

    trashRecord () {
      this.processing = true;
      this.$http.delete('/'+this.$route.meta.base_url + '/' + this.form.uuid)
          .then(() => {
            this.processing = false;
            new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
            this.form.deleted_at = new Date().toISOString();
          })
    },

    getBulkAction() {
      if(!this.form.action) return;

      this.$http.get('/bulk_actions/'+this.form.action)
          .then(response => {
            if(response && response.data) {
              this.bulk_action = response.data;
            }
          })
    },

    getBulkActions() {
          this.$http.get('/bulk_actions?sort=name&sortDirection=desc&limit=1000&fields=name,uuid')
          .then(response => {
            if(response && response.data) {
              this.actions = response.data;
            }
          })
      },

      getRanActions() {
        this.$http.get('/bulk_actions?sort=created_at&sortDirection=desc&limit=10&fields=name,uuid,status,type&created_by='+this.form.uuid)
            .then(response => {
              if(response && response.data) {
                this.ran_actions = response.data;
              }
            })
      },

      runAction() {
        if(!this.bulk_action.uuid) return;

        let post = {
          name: this.form.name + ' One-Time Action',
          type: this.bulk_action.type,
          action: this.bulk_action.action,
          search: this.bulk_action.search,
          created_by: this.form.uuid
        }

        this.processing = true;

        this.$http.post('/bulk_actions', post)
            .then(response => {
              if(response && response.data) {
                this.processing = false;
                this.$http.put('/bulk_actions/'+response.data.uuid+'/run')
                    .then(() => {
                      if(!this.ran_actions.length) {
                        this.ran_actions = [];
                      }

                      this.ran_actions.unshift(response.data);
                    })
              }
            })
      }


  },
}
</script>