export default {

data() {

  return {

    downloading: false,
    processing: false,
    downloads: [],
    queries: {},
    languages: [],
    errors: null,
    timezone: '',

  }

},

computed: {
  settings: function() {
    let settings = localStorage.getItem('settings') || null;

    if(settings) {
      settings = JSON.parse(settings);
    }

    return settings;
  },

  isPrivacyMode: function() {

    if(!this.$root.preferences || !this.$root.preferences.privacy_mode) {
      return false;
    }

      return true;
  },

},

mounted() {
  let self = this;

  this.$eventBus.$on('errors', function (errors) {
        self.errors = errors.data || errors;
        self.processing = false;
  });

},

created() {

  if(!this.$route.meta.requiresAuth) {
    return;
  }

  this.getSettings();
  
  this.getLanguages();
  this.setUserTimezone();
  this.getSettings();
},

methods: {

setURLParams() {

  var searchParams = new URLSearchParams(window.location.search);

  var self = this;

  searchParams.forEach(function(value, key) {
    self.queries[key] = value;
  });
},

updateURL() {
  history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
},


buildQueries(url, queries) {

    var filters = "";

    for(const query in queries) {

        if(queries[query]) {
            filters += encodeURIComponent(query) + "=" + encodeURIComponent(queries[query]) + "&";
        }
        
    }

    if(filters.length) {
        filters = filters.substring(0, filters.length-1);
    }

    return url + "?" + filters
},

    setCustomLabel(label) {

      label = label.toLowerCase();

      if(!this.settings || !this.settings['labels']) {
        return label;
      }

      return this.settings['labels'][label] || label;

    },

    setDocTitle() {

    var title = this.$route.meta.title + ' | eCourtDate.com';

    if(this.$root.current_user && this.$root.current_user.current_agency && this.$root.current_user.current_agency.name) {
        title = this.$route.meta.title + ' | ' + this.$root.current_user.current_agency.name;
    }

    document.title = title;
    },

    isUUID(uuid) {

      const regex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

      return regex.test(uuid);
    },

    setUserTimezone() {
      var tz = localStorage.getItem('timezone') || this.$moment.tz.guess();

      localStorage.setItem('timezone', tz);

      this.timezone = tz;
    },

    getSettings() {
      if(this.settings && this.settings.timezone) {
        return;
      }

      this.$http.get('/settings')
          .then(response => {
            if(response && response.data) {
              localStorage.setItem('settings', JSON.stringify(response.data));

              if(response.data.icon) {
                document.getElementById('favicon').href = response.data.icon;
              }
            }
          })
    },

    async downloadPDF() {
        try {
    
          window.scrollTo(0,0);
    
          this.downloading = true;
    
          const element = document.getElementById('main');
    
          const canvas = await this.$html2canvas(element, {
            windowWidth: element.scrollWidth,
            windowHeight: element.scrollHeight,
            scrollY: -window.scrollY,
            scrollX: -window.scrollX,
            scale: window.devicePixelRatio,
            useCORS: true,
            logging: false
          });
    
          const imgData = canvas.toDataURL('image/png');
    
          const pdf = new this.$jsPDF({
            orientation: 'portrait',
            unit: 'px',
            format: [canvas.width, canvas.height]
          });
    
          pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
    
          var name = this.$options.filters.slugString(this.$route.meta.base_url+'-'+this.$root.current_user.current_agency.name);
    
          pdf.save(name+'.pdf');
    
          this.downloading = false;
        } catch (error) {
          new this.$noty({text: 'Error capturing screenshot', type: 'error'}).show();
          this.downloading = false;
        }
      },
    
      downloadCSV () {
        this.downloading = true;
    
        var queries = this.queries;
    
        queries.limit = this.total;
    
        queries.skip = this.downloads.length;
    
        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, queries))
            .then(response => {
              if(response && response.data && response.data) {
                this.downloads = this.downloads.concat(response.data.data || response.data);
    
                var csvFile, data, link;
    
                data = this.convertCSV(this.downloads);
    
                var file = this.$route.meta.base_url+'-'+this.downloads.length+'.csv';
    
                csvFile = new Blob([data], {type: "text/csv"});
                if (window.navigator.msSaveOrOpenBlob) {
                  window.navigator.msSaveBlob(csvFile, file);
                } else {
                  link = document.createElement("a");
                  link.download = file;
                  link.href = window.URL.createObjectURL(csvFile);
                  link.style.display = "none";
                  document.body.appendChild(link);
                  link.click();
                }
    
                this.downloading = false;
    
              } else {
                this.downloadCSV();
              }
    
            });
      },
    
      convertCSV(records) {

        if(!records || !records.length) {
          return;
        }
    
        var results = [];
    
        if(records[0] && records[0]['uuid']) {
          var headers = Object.keys(records[0]);
        }
    
        results += headers.join(',')
    
        results += '\n';

        var self = this;
    
        records.forEach(function(item) {
          let record = '';
    
          if(!item) {
            return;
          }
    
          Object.keys(item).forEach(function(key) {

            if(item[key] && typeof item[key] === 'object') {
              item[key] = JSON.stringify(item[key]);
              item[key] = item[key].replace(/"/g, '""');
            }

            if(item[key] && ['created_at', 'updated_at', 'deleted_at', 'archived_at', 'completed_at'].includes(key)) {
                item[key] = self.$options.filters.datetime(item[key]);
            }

            record += '"' + (item[key] ? item[key] : '') + '",';
          });
    
          results += record;
          results += '\n';
        });
    
        return results
      },


      scrollUp() {
        window.scrollTo(0,0);
      },


    
  copyToClipboard(value) {
    navigator.clipboard.writeText(value);
    new this.$noty({text: 'Copied to clipboard'}).show();
  },

  setLanguage(language) {

    if(!language) {
      return;
    }

    var result = this.$_.find(this.languages, {'key': language});

    if(!result) {
      return;
    }

    return result.label;

  },

  getPreferences() {
    this.$eventBus.$emit('refreshPreferences', {});
  },

  getLanguages () {

    this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/languages.json')
        .then(response => {
          if(response && response.data) {
            this.languages = response.data;
          }
        })
  },

  setLayout(layout) {
    this.$eventBus.$emit('updatePreferences', {'default_layout': layout})
    this.layout = layout;
  },

  genRandomCode(length) {
    
    const randomBytes = new Uint8Array(length);
    window.crypto.getRandomValues(randomBytes);

    let base64String = btoa(String.fromCharCode.apply(null, randomBytes));

    let urlSafeString = base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');

    urlSafeString = urlSafeString.replace(/[^a-zA-Z0-9]/g, '');

    return urlSafeString.substring(0, 24);
  },

  sanitizeContent(content) {
        
    if(!content) {
      return;
    }
    
    return this.$DOMPurify.sanitize(content);
  },

  setCustomField(key, field) {

    var fields = localStorage.getItem('fields');

    if(!fields || !fields.length) {
      return this.$options.filters.rmDashes(field);
    }


    var title = _.find(JSON.parse(fields), { 'key': key+'.'+field });

    if(!title || !title.name) {
      return this.$options.filters.rmDashes(field).toUpperCase();
    }

    return title.name;
},
}

}