<template>
  <div class="row">
    <div class="col-12">

        <div class="card mb-3">
          <div class="card-body">
            <p class="card-title">
              Current Agency
            </p>

            <template v-if="$root.current_user && !$root.current_user.current_agency">
              <div class="alert alert-danger">No agencies found.</div>
            </template>

            <template v-if="$root.current_user && $root.current_user.current_agency">
              <h4>{{ $root.current_user.current_agency.name }}</h4>

              <p class="m-0"><strong>Region:</strong> {{ $root.current_user.current_agency.region }}</p>

            </template>
             
          </div><!--Body-->
        </div><!--Card-->

        <p class="card-title">{{ records.length }} Agencies</p>

      <div class="row">

          <template v-for="record in records">

            <div class="col-md-4 mb-3">
              <div class="card" :class="isCurrentRecord(record)">
                <div class="card-body">

                  <p class="card-title">{{ record.name }}</p>

                  <p class="m-0"><strong>Region:</strong> {{ record.region }}</p>

                  <template v-if="record.reference">
                    <p class="m-0"><strong>Reference:</strong> {{ record.reference }}</p>
                  </template>

                  <template v-if="!isCurrentRecord(record)">

                  <template v-if="record.region === $root.current_region">
                    <button @click="switchAgency(record)" class="btn btn-success" :disabled="processing">Switch to {{ record.name }}</button>
                  </template>
                  <template v-else>
                    <a :href="'https://'+record.region+'.ecourtdate.com'" target="_blank" class="btn btn-primary">Go to {{ record.region }}</a>
                  </template>

                  </template>

                </div><!--Body-->
              </div><!--Card-->
            </div><!--Col-->

          </template>

        </div><!--Col-->

      </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

    mixins: [ queries ],

    data () {
        return {
            processing: false,
            base_url: '/users',
            records: [],
            queries: {
                sort: 'name',
                sortDirection: 'asc',
                limit: 1000,
                skip: 0,
                slim: true,
                agency_uuid: null,
                fields: 'uuid,name,reference,region'
            }
        }
    },


    created () {
        this.setURLParams();
        this.getRecords();
    },

    methods: {

        setURLParams() {
            var searchParams = new URLSearchParams(window.location.search);

            var self = this;

            searchParams.forEach(function(value, key) {
                self.queries[key] = value;
            });

        },

        isCurrentRecord(record) {
          if(!this.$root.current_user || !this.$root.current_user.current_agency) {
            return;
          }

          if(this.$root.current_user.current_agency.uuid === record.uuid) {
            return 'bg-primary text-white';
          }
        },

        getRecords () {

          var user = localStorage.getItem('user');

          user = this.$root.current_user.uuid || user || null;

          if(!user) {
            return;
          }

            this.processing = true;
            this.$http.get(this.buildQueries(this.base_url+'/'+user+'/agencies', this.queries))
            .then(response => {
                if(response && response.data) {

                    if(response.data.length) {

                        var self = this;

                        response.data.forEach(function(data) {
                            self.records.push(data);
                        });
                    }

                    this.queries.skip = this.records.length;

                    this.processing = false;

                    this.setTitle();

                }
            })
        },

        switchAgency(agency) {

        this.processing = true;

        new this.$noty({text: 'Switching Agency'}).show();

        this.$http.post('switch_agency/'+agency.uuid+'?region='+agency.region)
        .then(response => {
          if (response && response.status === 200) {

            this.clearAgencySession();

            this.processing = false;

            location.reload();
          }
        })
        },

        clearAgencySession() {
          
          var fields = ['fields', 'timezone', 'agency', 'send_mode', 'settings'];

          fields.forEach(field => {
            localStorage.removeItem(field);
          })

        },

        setTitle() {
          document.title = this.records.length + ' ' + this.$route.meta.title + ' | eCourtDate.com';
        }

    }
}
</script>   