<template>
  <div>
    <form @submit.prevent="getSearch">
      <div class="input-group mb-3">
        <input
          v-model="search"
          type="text"
          class="form-control text-lowercase"
          aria-label="Client"
          aria-describedby="client"
        >
        <div class="input-group-append">
          <button
            id="client"
            class="btn btn-primary"
            type="submit"
            :disabled="processing"
          >
            Search Clients
          </button>
        </div>
      </div>

      <template v-if="records && records.length > 0">
        <ul class="list-group mb-3">
          <template v-for="record in records">
            <li
              class="list-group-item"
            >
              <a :href="'/clients/'+record.uuid" target="_blank">{{ record.result }}</a>

              <template v-if="record.contacts">
                <br><small class="text-muted">{{ record.contacts }}</small>
              </template>

              <button type="button" class="btn btn-success float-right" @click="attachRecord(record)" :disabled="processing">Attach</button>
            </li>
          </template>
        </ul>
      </template>
    </form>
  </div>
</template>
<script>
export default {

props: ['event', 'clients'],

data() {
    return {
        processing: false,
        base_url: '/events',
        search: '',
        records: []
    }
},

methods: {


  getSearch() {
      this.processing = true;
      this.$http.get('/search?search='+this.search)
      .then(response => {
          if(response && response.data) {
              this.processing = false;
              this.records = response.data;
          }
      })
  },

  attachRecord(record) {

    this.processing = true;
    
    this.$http.post(this.base_url+'/'+this.event.uuid+'/attach?client='+record.uuid)
    .then(response => {
      if(response && response.status === 201) {

        this.processing = false;

        this.records = [];
        this.search = '';

        this.$eventBus.$emit('attach_client', record.uuid);

        new this.$noty({text: 'Client attached'}).show();
      }
    })

  }

}

}
</script>