export default {

    data() {
        return {
            processing: false
        }
    },

  methods: {

    getCurrentUser () {

        this.processing = true;

      this.$http.get('/users/current')
      .then(response => {
        if (response && response.data) {

          if(response.data.uuid) {

          this.$root.current_user = response.data;

          this.setLocalStorage();

          this.bootRum();

          this.showGuides();
          
          this.checkCurrentAgency();

          this.subPub();

          this.processing = false;

          }

        }
      })
    },

    checkCurrentAgency() {
        if(this.$root.current_user.current_agency == null && this.$route.name != 'agencies.index') {
          window.location.href = '/agencies';
        }
    },

    setLocalStorage() {
        localStorage.setItem('user', this.$root.current_user.uuid);
        localStorage.setItem('user_name', this.$root.current_user.name);
        if (this.$root.current_user.current_agency) {
          localStorage.setItem('timezone', this.$root.current_user.current_agency.timezone || 'UTC');
          localStorage.setItem('agency', this.$root.current_user.current_agency.uuid);
          localStorage.setItem('send_mode', this.$root.current_user.current_agency.send_mode);
          localStorage.setItem('current_region', this.$root.current_user.current_agency.region);

          if(this.$root.current_user.current_agency.settings) {
            localStorage.setItem('settings', JSON.stringify(this.$root.current_user.current_agency.settings));
          }

          if(this.$root.current_user.current_agency.settings.fields) {
            localStorage.setItem('fields', JSON.stringify(this.$root.current_user.current_agency.settings.fields));
          }
        }
    },

    getLogout () {
      this.$http.post(this.$apiURL + 'end_session')
      .then(response => {
        if(response && response.data) {
          this.clearUser();
          window.location.replace(response.data);
        }
      })
    },

    isHidden(navigation) {

        if(!this.$root.current_user || !this.$root.current_user.uuid) {
            return true;
        }

        if(this.$root.current_user.hidden_navigations && this.$root.current_user.hidden_navigations.length > 0) {
            return this.$root.current_user.hidden_navigations.includes(navigation);
        }

        return false;
    },

    isDevMode() {

      if(process.env.NODE_ENV === 'development') {
        return true;
      }
      
      if(this.$root.current_region && this.$root.current_region == 'dev') {
        return true;
      }
      
      return false;

    },

    bootRum() {

      if(this.isDevMode()) {
        return;
      }

      this.$datadog.init({
            applicationId: process.env.VUE_APP_DDID,
            clientToken: process.env.VUE_APP_DDTOKEN,
            site: process.env.VUE_APP_API_URL,
            env: process.env.VUE_APP_REGION,
            service: 'ecdapp',
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            trackFrustrations: true,
            defaultPrivacyLevel: 'allow'
      });

      let replayUser = {
          id: this.$root.current_user.uuid,
          uuid: this.$root.current_user.uuid,
          name: this.$root.current_user.name,
          email: this.$root.current_user.email
      };

      if(this.$root.current_user.current_agency) {
        replayUser.agency_name = this.$root.current_user.current_agency.name;
        replayUser.agency = this.$root.current_user.current_agency.uuid;
      }

      this.$datadog.setUser(replayUser);

      this.$datadog.startSessionReplayRecording();

    },

    showGuides() {

        if(!this.$root.current_user) {
            return;
        }

        var show_guides = localStorage.getItem('show_guides');

        if(!show_guides) {
          show_guides = 'hide';
        }

        var diff = this.$moment().utc().diff(this.$moment.utc(this.$root.current_user.created_at), 'days');

        if(diff <= 1) {
          show_guides = 'show';
        }

        if(this.$root.current_user.email.includes('@ecourtdate.com')) {
          show_guides = 'show';
        }

        this.$root.show_guides = show_guides;
        localStorage.setItem('show_guides', show_guides);
    },

    clearUser () {
      this.$datadog.stopSessionReplayRecording();
      localStorage.clear();
      delete this.$http.defaults.headers.common['Authorization'];
    },

  isUUID(uuid) {

      const regex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

      return regex.test(uuid);
  },

    subPub() {
    if(!this.$root.agency) {
      return;
    }

    if(!this.$route.meta.requiresAuth) {
      return;
    }

    var self = this;

    const realtime = new this.$ably.Realtime({
        authCallback: async (data, callback) => {
            this.$http.post('/subscribe')
            .then(response => {
              if(response && response.data) {
                callback(null, response.data);
              }
            })
        }
    })

    if(!realtime) {
      return;
    }

    realtime.connection.once('connected');

    var subChannel = null;

    this.$ably.Rest.Crypto.generateRandomKey(256, function(err, key) {
      if(!err) {
        subChannel = realtime.channels.get(self.$root.agency, {cipher: {key: key}});

          subChannel.presence.subscribe('enter', function(member) {
          });

          subChannel.presence.enter();
      }
    });

    if(!subChannel) {
      return;
    }

    var channels = ['settings', 'messages', 'opens', 'bulk_actions', 'uploads', 'clients', 'contacts', 'payments', 'geolocations', 'chats', 'submissions', 'help_chats', 'schedules', 'message_statuses', 'checkins'];

    channels.forEach(function(channel) {

      subChannel.subscribe(self.$root.agency+'/'+channel, (message) => {
        self.$eventBus.$emit(channel, message['data']);
      });

    })

    },


  }
}