<template>
  <div>

    <page-tabs page="cases" />

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Add {{ $route.meta.title }}
            </p>

            <form @submit.prevent="postForm">
              <div class="form-group">
                <label for="bond_number">Bond Number</label>
                <input
                  id="bond_number"
                  v-model="form.bond_number"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="case_number">Case Number</label>
                <input
                  id="case_number"
                  v-model="form.case_number"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="collateral_type">Collateral Type</label>
                <input
                  id="collateral_type"
                  v-model="form.collateral_type"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="status">Status</label>
                <input
                  id="status"
                  v-model="form.status"
                  type="text"
                  class="form-control"
                >
              </div>

              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >
                Add
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">

            <p class="card-title">Search {{ $route.meta.title }}s</p>

            <form @submit.prevent="searchRecords">
              <div class="form-group">
                <label for="search">Search</label>
                <input
                  id="search"
                  v-model="queries.search"
                  type="search"
                  class="form-control"
                  placeholder="Search..."
                >
              </div>

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->

      <div class="col-sm-12 col-md-9">
        <div class="card">
          <div class="card-body">

            <div class="row">

            <div class="col-sm-12 col-md-6">

              <p class="card-title">
                {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
              </p>

            </div><!--Col-->

            <div class="col-sm-12 col-md-6 text-right">

              <div class="btn-group d-print-none">
                <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
              </div>
            </div><!--Col-->

            </div><!--Row-->

            <div class="table-responsive my-3">
              <table class="table table-striped table-hover">
                <thead>
                  <template v-for="column in columns">
                    <th>
                      <template v-if="column.key">
                        <span
                          class="text-capitalize link-hover"
                          @click="sortColumn(column)"
                        >{{ column.label }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key == queries.sort">
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm ml-1"
                          :disabled="processing"
                          @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </thead>
                <tbody>
                  <template v-for="record in records">
                    <tr>
                      <td>
                        <a :href="'/'+$route.meta.base_url+'/'+record.uuid">
                          <i class="fa fa-edit"></i> Edit
                        </a>
                      </td>
                      <td>
                          {{ record.bond_number }}
                      </td>
                      <td>{{ record.case_number }}</td>
                      <td>{{ record.collateral_type }}</td>
                      <td>
                        <template v-if="record.client">
                          <client-button :uuid="record.client" />
                        </template>
                      </td>
                      <td>{{ record.status }}</td>
                      <td>{{ record.created_at | datetime }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div><!--Responsive-->

            <template v-if="!processing && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>
            </div>
          </div><!--Row-->
        </template>

      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>

<script>
import queries from '../mixins/queries';

export default {
    mixins: [ queries ],
    data () {
        return {
            processing: false,
            downloading: false,
            total: 0,
            records: [],
            downloads: [],
            form: {},
            queries: {
                search: '',
                channel: '',
                optin: '',
                limit: 10,
                skip: 0,
                slim: true,
                upload: null,
                client: null,
                sort: 'created_at',
                sortDirection: 'desc'
            },
            columns: [
            { label: 'Edit'},
            { key: 'bond_number', label: 'Bond Number'},
            { key: 'case_number', label: 'Case Number'},
            { key: 'collateral_type', label: 'Collateral Type'},
            { key: 'client', label: 'Client'},
            { key: 'status', label: 'Status'},
            { key: 'created_at', label: 'Created'}
            ]
        }
    },
    
    created () {

        this.resetForm();
        this.resetRecords();

        this.queries.search = this.queries.search || this.$route.query.search || '';

        this.setURLParams();

        this.getRecords();
    },

    methods: {

        setURLParams() {
            var searchParams = new URLSearchParams(window.location.search);

            var self = this

            searchParams.forEach(function(value, key) {
                self.queries[key] = value
            });
        },

        updateURL() {
          history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
        },

        setTitle() {
          document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
        },

        postForm() {
            this.processing = true;
            this.$http.post('/'+this.$route.meta.base_url, this.form)
            .then(response => {
                if(response && response.data) {

                    if(response.status === 201) {
                        new this.$noty({text: 'Bond created'}).show();
                        this.records.unshift(response.data);
                        this.total++;
                        this.resetForm();
                    }

                    this.processing = false;
                }
            })
        },

        resetForm() {
            this.form = {
                bond_number: null,
                case_number: null,
                collateral_type: null,
                status: null
            }
        },

        searchRecords () {
            this.resetRecords();
            this.getRecords();
        },

        resetRecords() {
            this.records = [];
            this.total = 0;
            this.queries.skip = 0;
        },

        getRecords () {

            this.processing = true;

            this.updateURL();
            this.getTotal();

            this.processing = true;
            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
            .then(response => {
                if(response && response.data) {
                    this.records = response.data;
                    this.processing = false;
                }
            })
        },

        getTotal() {
            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
            .then(response => {
                if(response && response.status) {
                    this.total = response.data;
                    this.setTitle();
                }
            })
        },

        updateSortDirection() {

          if(this.queries.sortDirection == 'asc') {
            this.queries.sortDirection = 'desc';
          } else {
            this.queries.sortDirection = 'asc';
          }

          this.resetRecords();

          this.getRecords();
        },

        sortColumn(column) {

          this.queries.sort = column.key;

          this.updateSortDirection();
        }


    }
}
</script>
