<template>
  <div>
    <div class="row my-5 text-center">
      <div class="col-12">
        <a href="/">
          <img
            :src="logoURL()"
            width="120"
            alt="eCourtDate logo"
          >
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data () {
        return {
            theme: ''
        }
    },
 

    methods: {

        logoURL() {

            let theme = localStorage.getItem('theme') || 'light';

            if(theme == 'dark') {
                return '/images/logo-white.png'
            }

          return '/images/logo-blue.png'

        }
    }
}
</script>
