<template>
    <div>
  
      <page-tabs page="cases" />
  
      <template v-if="errors && errors.message">
              <div v-if="errors.message != 'Network Error'">
                <div class="alert alert-danger">
                  {{ errors.message }}
                </div>
              </div>
      </template>
  
      <div class="row">

        <div class="col-4 mx-auto">

            <template v-for="assessment in assessments">
                <div class="card mb-3 text-center">
                    <div class="card-header">
                        <h5 class="card-title m-0">{{ assessment.title }}</h5>
                    </div>
                    <div class="card-body">

                    <template v-if="assessment.description">
                        <p class="lead">{{ assessment.description }}</p>
                    </template>

                        <p><a :href="assessment.url" target="_blank">Learn more</a></p>

                        <template v-if="assessment.key">
                          <a :href="'/assessments/'+assessment.key" class="btn btn-success btn-block my-3">Start Now</a>                        
                        </template>

                    </div>
                </div>
            </template>

         
        </div><!--Col-->
      </div><!--Row-->
    </div>
  </template>
  
  <script>
  import queries from '../mixins/queries';
  import _ from "lodash";
  
  export default {
      mixins: [ queries ],
      data () {
          return {
              processing: false,
              assessments: []
          }
      },
  
      mounted() {
  
          var self = this;
  
          this.$eventBus.$on('search_clients', function(uuid) {
             self.queries.client = uuid;
             self.getClient();
  
          });
  
          this.$eventBus.$on('errors', function (errors) {
              self.processing = false;
              self.errors = errors.data || errors;
          });
  
      },
  
      created () {
  
          this.resetForm();
          this.resetQueries();
          this.setURLParams();
          this.getAssessments();
      },
  
      methods: {
  
          setURLParams() {
              var searchParams = new URLSearchParams(window.location.search);
  
              var self = this;
  
              searchParams.forEach(function(value, key) {
                  self.queries[key] = value;
              });
  
          },
  
          resetQueries() {
            this.queries = {
              search: null,
              location: null,
              archived: false,
              trashed: false,
              limit: 10,
              skip: 0
            }
          },
  
          searchRecords () {
              this.resetRecords();
              this.getRecords();
          },
  
          resetRecords() {
              this.records = [];
              this.total = 0;
              this.queries.skip = 0;
          },

          getAssessments() {
            this.$axios.get('https://ecdassets.s3.us-gov-east-1.amazonaws.com/js/assessments.json')
            .then(response => {
                if(response && response.data) {
                    this.assessments = response.data;
                }
            })
          },
  
          getRecords () {
  
              this.updateURL();
  
              this.getTotal();
  
              this.processing = true;
              this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
              .then(response => {
                  if(response && response.data) {
  
                      if(response.data.length) {
  
                          var self = this;
  
                          response.data.forEach(function(data) {
                              self.records.push(data);
                          });
                      }
  
                      this.queries.skip = this.records.length;
                      this.processing = false;
  
                      this.getStatuses();
  
                  }
              })
          },
  
          updateURL() {
            history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
          },
  
          setTitle() {
            document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
          },
  
          getTotal() {
              this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
              .then(response => {
                  if(response && response.status === 200) {
                      this.total = response.data;
                      this.setTitle();
                  }
              })
          },
  
          closeClient() {
              this.queries.client = null;
              this.client = {};
              this.resetRecords();
              this.getRecords();
          },
  
          getClient() {
  
              if(!this.queries.client) {
                  this.client = {};
                  return;
              }
  
              this.$http.get('/clients/'+this.queries.client+'?slim=true')
              .then(response => {
                  if(response && response.data) {
                      this.client = response.data;
                      this.resetRecords();
                      this.getRecords();
                  }
              })
          },
  
          updateSortDirection() {
  
            if(this.queries.sortDirection == 'asc') {
              this.queries.sortDirection = 'desc';
            } else {
              this.queries.sortDirection = 'asc';
            }
  
            this.resetRecords();
  
            this.getRecords();
          },
  
          sortColumn(column) {
  
            this.queries.sort = column.key;
  
            this.updateSortDirection();
  
          },
  
          resetForm() {
              this.form = {
                  case_number: null,
                  client: null
              }
  
              this.errors = null;
          },
  
          postForm() {
  
              if(this.client && this.client.uuid) {
                  this.form.client = this.client.uuid;
              }
              
              this.processing = true;
  
              this.errors = null;
  
              this.$http.post('/'+this.$route.meta.base_url, this.form)
              .then(response => {
                  if(response && response.data) {
  
                      if(response.data.uuid) {
                          this.resetForm();
                          this.records.unshift(response.data);
                          this.total++;
                          new this.$noty({text: this.$route.meta.title + ' created'}).show();
                      }
  
                      this.processing = false;
                  }
              })
          },
  
  
          searchClients() {
              this.searching = true;
              this.$http.get('/search?search='+this.search_clients)
              .then(response => {
                  if(response && response.data) {
                      this.searching = false;
                      this.clients = response.data;
                  }
              })
          },
  
          setClientCase(client) {
              this.form.client = client.uuid;
              this.queries.client = client.uuid;
              this.clients = [];
              this.getRecords();
          },
  
  
        setLocationName(record) {
  
          if(!record.location) {
            return;
          }
  
          var location = _.find(this.locations, {'uuid': record.location});
  
          if(!location || !location.name) {
            return;
          }
  
          return location.name;
        },
  
        getLocations () {
          this.$http.get('/locations?limit=100&slim=true&fields=name,uuid&sort=name&sortDirection=asc')
              .then(response => {
                if (response && response.data) {
                  this.locations = response.data;
                }
              })
        },
  
        getStatuses() {
  
          if(!this.processing) {
            this.processing = true;
          }
  
          this.$http.get('/statuses?slim=true&fields=name,uuid&limit=100&sort=name&sortDirection=asc&type=case')
              .then(response => {
                if (response && response.data) {
                  this.statuses = response.data;
                  if(this.processing === true) {
                    this.processing = false;
                  }
                }
              })
        },
        
      }
  }
  </script>
  