<template>
  <div>
    <form
      class="inline d-sm-none d-xs-none d-md-none d-lg-block"
      @submit.prevent="searchClients"
      data-html2canvas-ignore
    >
      <div class="dropdown">
        <label
          class="sr-only"
          for="app_search"
          aria-label="Search clients..."
        >Search clients</label>
        <input
          id="app_search"
          ref="app_search"
          v-model="search"
          type="search"
          class="form-control"
          aria-label="Search clients..."
          placeholder="Search clients..."
          @input="searchClients"
          @onblur="clearSearch"
        >

        <template v-if="show_results">

        <ul
          class="dropdown-menu show"
          style="z-index: 99990;"
        >
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <p class="dropdown-header">
                <template v-if="!processing">
                  {{ total }} Results
                </template>

                <template v-if="processing">
                  Searching <template v-if="search">
                    <mark>{{ search }}</mark>
                  </template>
                </template>
              </p>
            </div><!--Col-->

            <div class="col-sm-12 col-md-6 text-right pr-5">
              <button
                type="button"
                class="btn btn-outline-primary btn-sm"
                :disabled="processing"
                @click="clearSearch"
              >
                <i class="fa fa-times" /> <span class="sr-only">Close</span>
              </button>
            </div><!--Col-->
          </div><!--Row-->

          <template v-for="(result, result_index) in results">
            <template v-if="result.url">

              <template v-if="result.result">
                <li
                  :id="'result_'+result_index"
                  class="dropdown-item"
                >
                <a :href="result.url">{{ result.result }}</a>
                </li>
              </template>
            </template><!--Result-->
          </template>

          <template v-if="!processing && no_results && search.length > 1">
            <li class="dropdown-item">
              No results found for <mark>{{ search }}</mark>.<br>View all <a href="/clients">clients</a>
            </li>
          </template>
        </ul>
        </template>
      </div>
    </form>
  </div>
</template>
<script>
import _ from 'lodash';
export default {
  data() {
    return {
      search: '',
      total: 0,
      results: [],
      processing: false,
      search_results: false,
      no_results: false,
      show_results: false
    }
  },

  mounted() {

        this._keyListener = function(e) {
            if (e.key === "/" && e.ctrlKey) {
                e.preventDefault();

                this.$refs.app_search.focus();
            }

            if(e.key === "Escape") {
              e.preventDefault();

              this.clearSearch();
            }
        };

        document.addEventListener('keydown', this._keyListener.bind(this));

  },

  methods: {
    searchClients: _.debounce(function () {
      this.searchIndex()
    }, 600),
    searchIndex () {

      if(!this.search) {
        return;
      }

      // this.results = []
      this.total = 0
      this.show_results = true;

      this.processing = true;

      this.$http.get('/search?limit=5&search='+this.search)
      .then(response => {
        if(response && response.data && this.search) {
          this.results = response.data
          this.total = response.data.length
          this.search_results = true;

          if(this.total >= 1) {
            this.no_results = false;
          } else {
            this.no_results = true;
          }
        }
        this.processing = false
      })

    },
    clearSearch () {
      this.search = ''
      this.results = []
      this.total = 0
      this.search_results = false;
      this.show_results = false;
    }

  }
}
</script>
