<template>
    <div class="row">
      <div class="col-md-3 col-sm-12">
  
          <div class="card mb-3">
            <div class="card-body">
              <p class="card-title">
                Current Agency
              </p>
  
              <template v-if="$root.current_user && !$root.current_user.current_agency">
                <div class="alert alert-danger">No agencies found.</div>
              </template>
  
              <template v-if="$root.current_user && $root.current_user.current_agency">
                <h4>{{ $root.current_user.current_agency.name }}</h4>
  
                <p class="m-0"><strong>Region:</strong> {{ $root.current_user.current_agency.region }}</p>

                <div class="btn-group">

                <template v-if="record && record.ticket_url">
                    <a :href="record.ticket_url" target="_blank" class="btn btn-primary btn-sm">Report URL</a>
                </template>

                <a :href="'https://console.ecourtdate.com/agencies/'+$root.current_user.current_agency.uuid" target="_blank" class="btn btn-primary btn-sm">View in Console</a>

                <template v-if="record && record.uuid">
                    <a :href="'https://help.ecourtdate.com/tickets/'+record.uuid" target="_blank" class="btn btn-primary btn-sm">View in Help Center</a>
                </template>

                </div>
  
              </template>
               
            </div><!--Body-->
          </div><!--Card-->

          <div class="card my-3">
            <div class="card-body">
                <p class="card-title">Current User</p>

                <template v-if="$root.current_user">
                    <p class="m-0"><strong>Name:</strong> {{ $root.current_user.name }}</p>
                    <p class="m-0"><strong>Email:</strong> {{ $root.current_user.email }}</p>
                    <template v-if="$root.current_user.roles && $root.current_user.roles.length">
                    <p class="m-0"><strong>Roles:</strong> 

                        <template v-for="role in $root.current_user.roles">
                            <span class="badge badge-pill badge-secondary">{{ role }}</span>
                        </template>
                    </p>
                    </template>
                        <p class="m-0"><strong>Super Admin:</strong>

                            <template v-if="$root.current_user.super_admin">
                                <span class="badge badge-pill badge-success ml-1">YES</span>
                            </template>
                            <template v-else>
                                <span class="badge badge-pill badge-danger ml-1">NO</span>
                            </template>
                        </p>
                </template>
            </div><!--Body-->
          </div><!--Card-->
          
        </div><!--Col-->

        <div class="col-md-9 col-sm-12">

            <loader :isLoading="processing"></loader>

            <template v-if="record && record.uuid">

                <div class="card">
                    <div class="card-body">

                        <template v-if="record.title">
                            <h4>{{ record.title }}</h4>
                        </template>

                        <template v-if="record.description">
                            <p class="lead">{{ record.description }}</p>
                        </template>

                        <template v-if="record.status">
                            <p class="m-0"><strong>Status:</strong> <span class="badge badge-pill badge-secondary">{{ record.status }}</span></p>
                        </template>

                        <template v-if="record.created_by">
                            <p class="m-0"><strong>Created By:</strong> {{ record.created_by }}</p>
                        </template>

                        <template v-if="record.created_at">
                            <p class="m-0"><strong>Created At:</strong> {{ record.created_at }}</p>
                        </template>

                    </div><!--Body-->
                </div><!--Card-->

                <h6 class="mt-3">{{ record.comments.length }} Comments</h6>
                <template v-for="comment in record.comments">
                    <p class="outbound m-0">{{ comment.content }}</p>
                    <p class="m-0"><strong>Created By:</strong> {{ comment.created_by }}
                        <span class="float-right"><strong>Created At:</strong> {{ comment.created_at | datetime }}</span>
                    </p>
                    
                </template>

                
            </template>
            
          </div><!--Col-->
  
        </div><!--Row-->
    </div>
  </template>
  <script>
  import queries from '../mixins/queries';
  
  export default {
  
      mixins: [ queries ],
  
      data () {
          return {
              processing: false,
              record: {}
            }
      },
  
  
      created () {
          this.getRecord();
      },
  
      methods: {

          getRecord () {
  
              this.processing = true;
              this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
              .then(response => {
                  if(response && response.data) {
  
                      this.record = response.data;

                      if(!this.record.comments) {
                          this.record.comments = [];
                      }
    
                      this.processing = false;
    
                  }
              })
          }
  
  
      }
  }
  </script>   