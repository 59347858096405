<template>
  <div>
    <template v-if="show_onboarding && $root.current_user && $root.current_user.current_agency && $root.current_user.current_agency.name">
        <div class="alert alert-success">
          👋 Welcome to eCourtDate! <a href="/setup" class="alert-link">Review the setup status for {{ $root.current_user.current_agency.name }} here.</a>
        </div>
      </template>
  </div>
</template>
<script>
export default {

    data() {
        return {
          show_onboarding: false
        }
    },

    created() {
      this.getOnboarding();
    },

    methods: {

      isNewAgency() {

        if(!this.$root.current_user || !this.$root.current_user.current_agency) {
          return false;
        }

        var created = this.$moment(this.$root.current_user.current_agency.created_at).tz('UTC');

        var tz = this.$moment.tz.guess() || localStorage.getItem('timezone');

        return this.$moment(Date.now()).tz(tz).diff(created, 'days') <= 30;
      },

      getOnboarding() {

        if(this.isNewAgency()) {
          this.show_onboarding = true;
          return;
        }


        this.show_onboarding = false;

        this.$http.get('/agencies/onboarding')
        .then(response => {
          if(response && response.data) {

            if(response.data.status && response.data.status == 'active') {
              this.show_onboarding = true;
            }
          }
        })
      }
    
    }

}
</script>
