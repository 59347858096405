<template>
  <div>
    <template v-if="$root.current_user && $root.show_guides == 'show'">
      <div class="row">

        <div class="col-sm-12 col-md-6">

          <div class="card mb-3">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-sm-12 col-md-6">
                  <p class="card-title">Getting Started</p>
                </div><!--Col-->
                <div class="col-sm-12 col-md-6 text-right">
                  <div class="btn-group">
                    <a href="/checklist" class="btn btn-primary btn-sm"><i class="fa fa-check-to-slot"></i> Setup Checklist</a>
                    <template v-if="$root.current_user && $root.current_user.uuid">
                      <a :href="'/users/'+$root.current_user.uuid" class="btn btn-primary btn-sm"><i class="fa fa-user"></i> Your Profile</a>
                    </template>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <p class="lead m-0">👋 Welcome to eCourtDate! Send and receive omnichannel messages, accept online payments, schedule events, and more.</p>

              <p>Learn more about how eCourtDate works by <a href="https://help.ecourtdate.com" target="_blank">visiting our help center</a>.</p>

              <ul class="list-group">


                  <li class="list-group-item list-group-item-action">
                    <h5 class="m-0">Create test client</h5>
                    <p>Clients are recipients to messages such as defendants, jurors, victims, or employees.</p>

                    <template v-if="test_client && test_client.uuid">
                      <a :href="'/clients/'+test_client.uuid" class="btn btn-primary" target="_blank">Go to Client</a>
                    </template>
                    <template v-else>
                      <button type="button" class="btn btn-success" @click="createClient()" :disabled="processing">Create Test Client</button>
                    </template>

                  </li>

                <template v-if="test_client && test_client.uuid">

                <li class="list-group-item list-group-item-action">
                  <h5 class="m-0">Schedule an event</h5>
                  <p>Schedule an upcoming event for your test client.</p>

                  <template v-if="test_event && test_event.uuid">
                    <a :href="'/events/'+test_event.uuid" class="btn btn-primary" target="_blank">Go to Event</a>
                  </template>
                  <template v-else>
                    <button type="button" class="btn btn-success" @click="createEvent()" :disabled="processing">Create Test Event</button>
                  </template>

                </li>

                  <li class="list-group-item list-group-item-action">
                    <h5 class="m-0">Send an outstanding payment</h5>
                    <p>Send your test client an outstanding payment.</p>

                    <template v-if="test_payment && test_payment.uuid">
                      <a :href="'/payments/'+test_payment.uuid" class="btn btn-primary" target="_blank">Go to Payment</a>
                    </template>
                    <template v-else>
                    <button type="button" class="btn btn-success" @click="createPayment()" :disabled="processing">Create Test Payment</button>
                    </template>

                  </li>

                  <li class="list-group-item list-group-item-action">
                    <h5 class="m-0">Send a one-off message</h5>
                    <p>Schedule a message to your test client.</p>

                    <template v-if="test_message && test_message.uuid">
                      <a :href="'/messages/'+test_message.uuid" class="btn btn-primary" target="_blank">Go to Message</a>
                    </template>
                    <template v-else>
                      <button type="button" class="btn btn-success" @click="createMessage()" :disabled="processing">Create Test Message</button>
                    </template>

                  </li>

                </template>


              </ul>




            </div>
          </div>

        </div><!--Col-->

        <div class="col-sm-12 col-md-6">

            <div class="card mb-3">
              <div class="card-body">
                <p class="card-title">Overview</p>

                <p>Watch this short video to learn more about eCourtDate.</p>

                <video class="object-fit-contain" width="100%" height="540" poster="https://ecdassets.s3-us-gov-east-1.amazonaws.com/videos/welcome-video-poster.png" preload="auto" controls>
                  <source src="https://ecdassets.s3-us-gov-east-1.amazonaws.com/videos/welcome-onboarding.mp4" type="video/mp4">
                </video>

              </div>
            </div>


        </div><!--Col-->


      </div>
    </template>
  </div>
</template>
<script>
import confetti from "canvas-confetti";
export default {

  data() {
    return {
      processing: false,
      current_action: 'overview',
      actions: [
        {
          title: 'Send your first one-off message',
          url: '/messages/create'
        },
        {
          title: 'Create your first client',
          url: '/clients/create'
        },
        {
          title: 'Create your first payment',
          url: '/payments/create'
        },
        {
          title: 'Run your first bulk action',
          url: '/bulk-actions/create'
        }
      ],
      form: {},
      test_client: {},
      test_event: {},
      test_message: {},
      test_payment: {}
    }
  },

  created() {
    this.resetForm();
  },

  mounted() {
    this.getClient();
  },

  methods: {

    resetForm() {
      this.form = {
        to: null,
        content: null,
      }
    },

    sendTest(to) {
      if(!this.$root.current_user || !this.$root.current_user.uuid) {
        return;
      }

      this.processing = true;

      this.form.to = to;

      this.form.content = 'Test message from [AgencyName]';

      this.$http.post('/messages/oneoffs', this.form)
          .then(response => {
            if(response && response.data) {
              if(response.status == 201) {
                new this.$noty({text: 'Test message sent!', type: 'success', timeout: 3000}).show();
                this.processing = false;
                this.resetForm();
                this.shouldCelebrate();

              }
            }
          })
    },

    getClient() {

      var group = null;

      if(this.$root.current_user && this.$root.current_user.name) {
        group = this.$root.current_user.name;
      }

      if(localStorage.getItem('user_name')) {
        group = localStorage.getItem('user_name');
      }

      if(!group) {
        return;
      }

      this.$http.get('/clients?limit=1&slim=true&group='+group + ' Test Clients')
          .then(response => {
            if(response && response.data) {

              if(response.data[0]) {
                this.test_client = response.data[0];
                this.current_action = 'run-actions';
              }


            }
          })
    },

    createClient() {
      this.processing = true;

      var form = {
        first_name: 'John',
        last_name: 'Doe',
        type: 'Defendant',
        group: this.$root.current_user.name + ' Test Clients',
        email: this.$root.current_user.email,
        users: [this.$root.current_user.uuid]
      }

      if(this.$root.current_user.phone) {
        form.phone = this.$root.current_user.phone;
      }

      this.$http.post('/clients', form)
          .then(response => {
            if(response && response.status == 201) {
              new this.$noty({text: 'Client created!', type: 'success', timeout: 3000}).show();
              this.test_client = response.data;
              this.current_action = 'run-actions';
              this.processing = false;
              this.shouldCelebrate();

            }
          })
    },

    createEvent() {
      this.processing = true;

      var types = ['Court Date', 'Hearing', 'Appointment', 'Deadline', 'Trial'];

      var form = {
        date: this.$moment().add(30, 'days').format('YYYY-MM-DD'),
        time: this.$moment().add(Math.floor(Math.random() * 24), 'hours').format('HH:mm:ss'),
        type: types[Math.floor(Math.random() * types.length)],
        description: this.$root.current_user.name + ' Test Event',
        client: this.test_client.uuid
      }

      this.$http.post('/events', form)
          .then(response => {
            if(response && response.status == 201) {
              new this.$noty({text: 'Event created!', type: 'success', timeout: 3000}).show();
              this.processing = false;

              if(response.data) {
                this.test_event = response.data;
              }

              this.shouldCelebrate();

            }
          })
    },

    createMessage() {
      this.processing = true;

      var form = {
        client: this.test_client.uuid,
        content: '[ClientName] this is a test message from [AssignedUserName] created on [DateNow] [TimeNow].',
        skip_duplicate: true
      }

      if(this.$root.current_user.phone) {
        form.to = this.$root.current_user.phone;
      } else {
        form.to = this.$root.current_user.email;
      }

      if(this.test_event && this.test_event.uuid) {
        form.event = this.test_event.uuid;
        form.content += ' Your Next Event: [EventDate] [EventTime] [EventType] [EventDescription]';
      }

      if(this.test_payment && this.test_payment.uuid) {
        form.payment = this.test_payment.uuid;
        form.content += ' Outstanding Payment: [PaymentAmount] [PaymentType] [PaymentDueDate]';
      }


      form.content += ' Please respond to this message to confirm receipt. Thank you! - [AgencyName]'

      this.$http.post('/messages/oneoffs', form)
          .then(response => {
            if(response && response.status == 201) {
              new this.$noty({text: 'Message created!', type: 'success', timeout: 3000}).show();
              this.processing = false;

              if(response.data) {
                this.test_message = response.data;
              }

              this.shouldCelebrate();

            }
          })
    },

    createPayment() {
      this.processing = true;

      var types = ['Fine', 'Fee', 'Restitution', 'Bail', 'Bond', 'Penalty'];

      var form = {
        client: this.test_client.uuid,
        amount: Math.floor(Math.random() * 100) + 1,
        status: 'outstanding',
        payment_reference: 'Test Payment ' + this.$moment().format('YYYY-MM-DD'),
        type: types[Math.floor(Math.random() * types.length)],
        description: this.$root.current_user.name + ' Test Payment'
      }

      this.$http.post('/payments', form)
          .then(response => {
            if(response && response.status == 201) {
              new this.$noty({text: 'Payment created!', type: 'success', timeout: 3000}).show();
              this.processing = false;

              if(response.data) {
                this.test_payment = response.data;
              }

              this.shouldCelebrate();
            }
          })
    },

    shouldCelebrate() {

      if(!this.$root.current_user || !this.$root.current_user.uuid) {
        return false;
      }

      if(!this.test_client || !this.test_client.uuid) {
        return false;
      }

      if(!this.test_event || !this.test_event.uuid) {
        return false;
      }

      if(!this.test_message || !this.test_message.uuid) {
        return false;
      }

      if(!this.test_payment || !this.test_payment.uuid) {
        return false;
      }

      confetti({
        particleCount: 150
      });

      setTimeout(() => {
        confetti.reset();
      }, 10000);

    },

  }

}
</script>
