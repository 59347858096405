<template>
    <div>
  
        <page-tabs :page="$route.meta.tabs" />

        <div class="row">
            <div class="col-md-6 mx-auto">


                    <div class="card">
                        <div class="card-body">

                            <template v-if="!show_test">
                                <button type="button" class="btn btn-success mb-3" @click="show_test = !show_test" :disabled="processing">Start a New Test</button>
                            </template>

                            <template v-else>
                                <button type="button" class="btn btn-danger mb-3" @click="show_test = !show_test" :disabled="processing">Cancel Test</button>
                            </template>

                            <div class="list-group" :hidden="show_test == false">

                            <template v-for="test in tests">
                            
                                <li class="list-group-item mb-3">
                                    <div class="d-flex w-100 justify-content-between">
                                        <h5 class="mb-1">{{ test.name }}</h5>
                                    </div>
                                    <p class="mb-1">{{ test.description }}</p>

                                    <div class="btn-group">
                                        <button type="button" class="btn btn-success btn-sm">Pass</button>
                                        <button type="button" class="btn btn-danger" btn-sm>Fail</button>
                                        <button type="button" class="btn btn-warning btn-sm">Skip</button>
                                    </div>

                                    <template v-if="test.steps">
                                        <p class="m-0">Instructions</p>
                                        <p class="text-small">{{ test.steps }}</p>
                                    </template>


                                </li>
                                
                            </template>

                            </div><!--ListGroup-->

                            <p class="m-0">Previous Tests</p>

                            <template v-if="!records && records.length === 0">
                                <div class="alert alert-info">No previous tests found.</div>
                            </template>

                        </div><!--Body-->
                    </div><!--Card-->

            </div><!--Col-->

        </div><!--Row-->

    </div>
  </template>
  
  <script>
  import queries from '../mixins/queries';
  
  export default {
  
    mixins: [ queries ],
  
  data() {
      return {
          processing: false,
          show_test: false,
          tests: [
            {
                name: 'Create a new client',
                description: 'Create a new client profile',
                steps: 'Click the CLIENTS tab, then fill in the ADD CLIENT form. Click EDIT to view the client profile.',
            },
            {
                name: 'Send a one-off text message',
                description: 'Send a one-off text message to your phone number.',
                steps: 'Click the DASHBOARD tab, then fill in the SEND MESSAGE form. Fill in the form with your own phone number and click SEND MESSAGE.'
            },
            {
                name: 'Send a one-off email message',
                description: 'Send a one-off email message to your email address.',
                steps: 'Click the DASHBOARD tab, then fill in the SEND MESSAGE form. Fill in the form with your own email address and click SEND MESSAGE.'
            },
            {
                name: 'Send a text to your Agency Phone',
                description: 'Send a text to your Agency Phone and make sure it is received.',
                steps: 'Click ADMIN > SETTINGS > AGENCY PHONES, then click EDIT on your Agency Phone. Send a text message from your phone to the phone number listed. Verify that the inbound message is received.'
            },
            {
                name: 'Send an email to your Agency Email',
                description: 'Send an email to your Agency Email and make sure it is received.',
                steps: 'Click ADMIN > SETTINGS > AGENCY EMAILS, then click EDIT on your Agency Email. Send an email from your email to the email address listed. Verify that the inbound email is received.'
            },
            {
                name: 'Sign up as a client',
                description: 'Sign up as a new client',
                steps: 'Click the ADMIN > PORTALS tab, then click OPEN PORTAL. From the portal, click REGISTER to sign up as a new client. Verify that a new client is created in the CLIENTS tab from the first step.'
            },
            {
                name: 'Create a new case',
                description: 'Create a new case in the system.',
                steps: 'Click the CASES tab, then fill in the ADD CASE form. Click EDIT to view the case details. Click OPEN PORTAL to view the case in the client portal.'
            },
            {
                name: 'Create a new event',
                description: 'Create a new event assigned to a client.',
                steps: 'From your test client, click EVENTS in the left side. Fill in the ADD EVENT form. Click EDIT to view the event details. Click OPEN PORTAL to view the event in the client portal.'
            },
            {
                name: 'Create a new payment',
                description: 'Create a new payment assigned to a client.',
                steps: 'From your test client, click PAYMENTS in the left side. Fill in the ADD PAYMENT form. Click EDIT to view the payment details. Click OPEN PORTAL to view the payment in the client portal.'
            },
            {
                name: 'Download a Report',
                description: 'Download a report and make sure it has the correct data.',
                steps: 'Click ADMIN > REPORTS, then click OPEN REPORT on a report. Verify that the data is correct.'
            },
            {
                name: 'View Data in a Dashboard',
                description: 'View data in a dashboard and make sure it is correct.',
                steps: 'Click ADMIN > DASHBOARDS, then click OPEN DASHBOARD on a dashboard. Verify that the data is correct.'
            },
            {
                name: 'Review your Locations',
                description: 'Review your Locations and make sure they are correct.',
                steps: 'Click ADMIN > SETTINGS > LOCATIONS and review the locations listed.'
            },
            {
                name: 'Review your Flows',
                description: 'Review your Flows and make sure they are working correctly.',
                steps: 'Click ADMIN > MESSAGES > FLOWS and review the flows listed.'
            },
            {
                name: 'Review your Auto Messages',
                description: 'Review your Auto Messages and make sure they are working correctly.',
                steps: 'Click ADMIN > MESSAGES > AUTO MESSAGES and review the auto messages listed.'
            }
          ],
          records: []
      }
  },
  
  created() {
      document.title = this.$route.meta.title + ' | eCourtDate.com';
  },
  
  methods: {
  
    
  
  }
  
  }
  </script>