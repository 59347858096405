<template>
    <div>
  
      <page-tabs page="portals" />
  
      <div class="row">
        <div class="col-sm-12 col-md-3">
  
          <div class="card">
            <div class="card-body">
  
              <p class="card-title">Create {{ $route.meta.title }}</p>
         
              <form @submit.prevent="postForm()">
  
                <div class="form-group">
                  <label for="name" class="form-label">Name</label>
                  <input id="name" type="text" class="form-control" v-model="form.name" required>
                </div>

                <div class="form-group">
                    <label for="redirect_url" class="form-label">Redirect URL</label>
                    <input id="redirect_url" type="text" class="form-control" v-model="form.redirect_url" required>
                </div>

                <div class="form-group">
                  <label for="portal" class="form-label">Portal</label>
                  <select id="portal" class="form-control" v-model="form.portal" required>
                    <option value="">Select Portal</option>
                    <template v-for="portal in portals">
                      <option :value="portal.uuid">{{ portal.name }}</option>
                    </template>
                  </select>
                </div>
  
                <button type="submit" class="btn btn-success" :disabled="processing">Add</button>
  
              </form>
  
            </div><!--Body-->
          </div><!--Card-->
  
  
          <div class="card mt-3">
            <div class="card-body">
  
              <p class="card-title">Search {{ $route.meta.title }}s</p>
  
              <form @submit.prevent="searchRecords">
                <div class="form-group">
                  <label for="search">Search by Name</label>
                  <input
                    id="search"
                    v-model="queries.search"
                    type="search"
                    class="form-control"
                    placeholder="Search..."
                  >
                </div>

                <div class="form-group">
                  <label for="search_redirect_url">Search by Redirect URL</label>
                  <input
                    id="search_redirect_url"
                    v-model="queries.redirect_url"
                    type="redirect_url"
                    class="form-control"
                  >
                </div>
  
                <div class="form-group">
                  <label for="search_portal">Portal</label>
                  <select id="search_portal" class="form-control" v-model="queries.portal"
                   :disabled="processing"
                   @change="searchRecords"
                   >
                    <option value="">Any</option>
                    <template v-for="portal in portals">
                      <option :value="portal.uuid">{{ portal.name }}</option>
                    </template>
                  </select>
                </div>
  
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group custom-control custom-switch">
                      <input
                        id="archived"
                        v-model="queries.archived"
                        type="checkbox"
                        class="custom-control-input"
                        @change="searchRecords"
                        :disabled="processing || queries.trashed"
                      >
                      <label
                        class="custom-control-label"
                        for="archived"
                      >Archived</label>
                    </div>
                  </div><!--Col-->
  
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group custom-control custom-switch">
                      <input
                        id="trashed"
                        v-model="queries.trashed"
                        type="checkbox"
                        class="custom-control-input"
                        @change="searchRecords"
                        :disabled="processing || queries.archived"
                      >
                      <label
                        class="custom-control-label"
                        for="trashed"
                      >Trashed</label>
                    </div>
                  </div><!--Col-->
                </div><!--Row-->
  
                <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="processing"
                >
                  Search
                </button>
              </form>
            </div><!--Body-->
          </div><!--Card-->
  
        </div><!--Col-->
  
  
        <div class="col-sm-12 col-md-9">
  
          <div class="card">
            <div class="card-body">
  
              <div class="row">
  
                <div class="col-sm-12 col-md-6">
  
                  <p class="card-title">
                    {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                  </p>
  
                </div><!--Col-->
  
                <div class="col-sm-12 col-md-6 text-right">
  
                  <div class="btn-group d-print-none">
                    <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                    <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                  </div>
                </div><!--Col-->
  
                </div><!--Row-->
  
              <div class="table-responsive my-3">
              <table class="table table-striped table-hover">
                <sort-columns
                  :columns="columns"
                  :queries="queries"
                  :processing="processing"
                  :base_url="$route.meta.base_url"
                />
  
                <tbody>
                  <template v-for="record in records">
                    <tr>
                      <td>
                        <a :href="'/'+$route.meta.base_url+'/'+record.uuid">
                          <i class="fa fa-edit"></i> Edit
                        </a>
                      </td>
                      <td>{{ record.name }}</td>
                      <td>
                        <template v-if="record.portal">
                            <a :href="setPortalLink(record)" class="btn btn-secondary btn-sm" target="_blank">
                                Open Portal
                            </a>
                        </template>
                    </td>
                      <td>
                        <template v-if="record.redirect_url">
                            <a :href="record.redirect_url" class="btn btn-secondary btn-sm" target="_blank">
                                Open Redirect
                            </a>
                        </template>
                      </td>
                      <td>
                        <template v-if="record.message">
                            <a :href="'/messages/'+record.message" class="btn btn-secondary btn-sm" target="_blank">
                                Open Message
                            </a>
                        </template>
                      </td>
                        <td>{{ record.created_at | datetime }}</td>
                        <td>{{ record.created_by }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
              </div>
  
              <template v-if="!processing && records && !records.length">
                <div class="alert alert-warning">
                  No {{ $route.meta.title }}s found.
                </div>
              </template>
            </div><!--Body-->
          </div><!--Card-->
  
          <template v-if="records && records.length && total > records.length">
            <div class="row mt-3">
              <div class="col-12 text-center">
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="processing"
                  @click="loadMore"
                >
                  Load more
                </button>
              </div><!--Col-->
            </div><!--Row-->
          </template>
        </div><!--Col-->
      </div><!--Row-->
    </div>
  </template>
  
  <script>
  import queries from '../mixins/queries';
  
  export default {
  
  mixins: [ queries ],
  
  data() {
      return {
          processing: false,
          show_load: false,
          searched: false,
          records: [],
          columns: [
              {label: 'Edit'},
              {key: 'name', label: 'Name'},
              {label: 'Portal URL'},
              {label: 'Redirect URL'},
              {label: 'Message'},
              {key: 'created_at', label: 'Created'},
              {key: 'created_by', label: 'Creator'},
          ],
          queries: {},
          total: 0,
          form: {},
          portals: []
      }
  },
  
  
  mounted() {
  
  var self = this;
  
  this.$eventBus.$on('refreshRecords', function(view) {
  
      if(view !== self.$route.meta.base_url) {
          return;
      }
  
      self.resetRecords();
  
      self.getRecords();
  
  });
  
  },
  
  
  created() {
      this.resetForm();
      this.resetQueries();
      this.resetRecords();
      this.setURLParams();
      this.getRecords();
      this.getPortals();
  },
  
  methods: {
  
      setURLParams() {
          var searchParams = new URLSearchParams(window.location.search);
  
          var self = this;
  
          searchParams.forEach(function(value, key) {
              self.queries[key] = value;
          });
      },
  
      resetForm() {
        this.form = {
          name: null,
          portal: null,
          redirect_url: null,
        }
      },
  
      resetQueries() {
        this.queries = {
          skip: 0,
          limit: 10,
          sort: 'created_at',
          sortDirection: 'desc',
          redirect_url: null,
          url: null,
          search: null,
          portal: null
        }
      },
  
      resetRecords() {
        this.records = [];
        this.queries.skip = 0;
        this.total = 0;      
      },
    
      postForm() {
        this.processing = true;
  
        this.form.notify = this.$root.current_user.email;
  
        this.form.reference = this.$options.filters.slugString(this.form.name);
  
        if(this.portals && this.portals.length) {
          this.form.portal = this.portals[0].uuid;
        }
  
        this.$http.post('/'+this.$route.meta.base_url, this.form)
        .then(response => {
          if(response && response.data) {
  
            this.processing = false;
  
            if(!response.data.uuid) {
              this.$noty({text: 'Error creating '+this.$route.meta.title}).show();
              return;
            }
  
            new this.$noty({text: this.$route.meta.title + ' created'}).show();
            this.records.unshift(response.data);
            this.total++;
            this.resetForm();
            this.processing = false;
            this.$router.push({name: this.$route.meta.base_url+'.edit', params: {id: response.data.uuid}});
          }
        })
      },
  
      getRecords() {
  
        this.processing = true;
  
          this.getTotal();
  
          this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
          .then(response => {
              if(response && response.data) {
  
                  this.records = response.data
  
                  if (response.data.length < this.queries.limit) {
                      this.show_load = false
                  } else {
                      this.show_load = true
                  }
  
                  this.processing = false
              }
          })
      },
  
      getTotal() {
          this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
          .then(response => {
              if(response && response.status) {
                  this.total = response.data;
                  this.setTitle();
              }
          })
      },
  
      setTitle() {
        document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
      },
  
      loadMore() {
        this.processing = true;
        this.show_load = false;
  
        this.queries.skip = this.records.length;
  
        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
          .then(response => {
            if(response && response.data) {
  
                if(response.data.length < this.queries.limit) {
                  this.show_load = false;
                } else {
                  this.show_load = true;
                }
  
                if(response.data.length > 0) {
                  response.data.forEach(r => {
                      this.records.push(r);
                      this.total++;
                  })                
                }
  
                this.processing = false;
            } else {
              this.show_load = false;
            }
          });
      },
  
      setPortalLink(record)
      {
        if(!record.portal || !record.url) {
          return;
        }
  
        let portal = this.portals.find(p => p.uuid == record.portal);
    
        if(portal && portal.link) {
          return 'https://'+portal.link+'.'+portal.domain+'/r/'+record.url;
        }
  
      },
    
      getPortals() {
        this.portals = [];
        this.$http.get('/portals?limit=100&sort=default&sortDirection=desc&slim=true&fields=name,uuid,link,domain')
        .then(response => {
          if(response && response.data) {
            this.portals = response.data;
          }
        })
      },
  
      searchRecords() {
        this.searched = true;
        this.resetRecords();
        this.getRecords();
      },
  
  
  }
  
  }
  </script>