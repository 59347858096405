<template>
  <div>
    <loader :is-loading="processing" />
  </div>
</template>
<script>
export default {

data() {
    return {
        processing: false,
        base_url: '/reports/download/'
    }
},

created() {
    this.getReport()
},

methods: {

    getReport() {
        this.processing = true
        this.$http.get(this.base_url+this.$route.params.id + '?agency='+this.$route.query.agency)
        .then(response => {
            if(response && response.data) {
                window.open(response.data, '_blank')

                this.$router.push('/reports/'+this.$route.params.id)
            }

            this.processing = false
        })
    },
}

}
</script>