<template>
    <div>
  
      <page-tabs :page="$route.meta.tabs" />

      <template v-if="errors && errors.message">
            <div v-if="errors.message != 'Network Error'">
              <div class="alert alert-danger">
                {{ errors.message }}
              </div>
            </div>
      </template>
  
      <div class="row">
        <div class="col-sm-12 col-md-3">
          <div class="card mb-3" v-if="!['opens', 'geolocations'].includes($route.meta.base_url)">
            <div class="card-body">
              <p class="card-title">
                Create
              </p>
              
  
              <form @submit.prevent="postForm()">

                <template v-for="field in fields">

                  <div>

                    <template v-if="!field.type">
                    </template>

                    <template v-else-if="field.type == 'text'">

                  <div class="form-group">
                  <label :for="field.key">{{ field.label }}</label>
                  <input
                    :id="field.key"
                    :type="field.type"
                    v-model="form[field.key]"
                    class="form-control"
                    :required="field.required"
                    :hidden="field.hidden"
                    :disabled="processing"
                  >
                  <template v-if="field.description">
                    <small class="form-text text-muted">{{ field.description }}</small>
                  </template>
                  </div>

                    </template>

                    <template v-if="field.type == 'select' && field.required">


               <div class="form-group">
                <label :for="field.key">{{ field.label }}</label>
                <select
                  :id="field.key"
                  v-model="form[field.key]"
                  class="form-control"
                  :disabled="processing"
                  :required="field.required"
                >
                  <option value="">
                    Choose
                  </option>
                  <template v-if="field.options && field.options.length > 0">
                  <template v-for="option in field.options">
                    <option
                      :value="option.uuid || option"
                    >
                      {{ option.name || option }}
                    </option>
                  </template>
                  </template>
                </select>

              </div>
                  
                    </template>

                  </div>

                  <template v-if="field.type == 'address'">
                    

                  
                  </template>

                </template>
  
                  <button
                    type="submit"
                    class="btn btn-success"
                    :disabled="processing"
                  >
                    Create
                  </button>
  
              </form>
            </div><!--Body-->
          </div><!--Card-->

          <div class="card mb-3">
            <div class="card-body">

              <div class="row">

                <div class="col-sm-12 col-md-9">
                  <p class="card-title">Search</p>
                </div><!--Col-->

                <div class="col-sm-12 col-md-3 text-right">
                  <button type="button" class="btn btn-outline-primary btn-sm" @click="resetSearch()" :disabled="processing">
                    <i class="fa fa-undo"></i>
                    Reset
                  </button>
                </div><!--Col-->

              </div><!--Row-->

              <form @submit.prevent="searchRecords()">

                <div class="form-group">
                <label for="search">General Search</label>
                <input
                  id="search"
                  type="text"
                  v-model="queries.search"
                  class="form-control"
                  :disabled="processing"
                >
              </div>
              
                <template v-for="field in tableFields">

                  <div v-show="showSearchField(field)">

                  <template v-if="field.type == 'text'">

                  <div class="form-group">
                    <label :for="'search_'+field.key">{{ field.label }}</label>
                    <input :id="'search_'+field.key" :type="field.type" v-model="queries[field.key]" class="form-control" :disabled="processing">
                  </div>

                  </template>

                  <template v-if="field.type == 'select'">
                    
                    <div class="form-group">
                      <label :for="'search_'+field.key">{{ field.label }}</label>
                      <select :id="'search_'+field.key" v-model="queries[field.key]" class="form-control" 
                      @change="searchRecords()"
                      :disabled="processing">
                        <option value=""></option>
                        <template v-if="field.options && field.options.length">
                        <template v-for="option in field.options">
                          <option :value="option.uuid || option">{{ option.name || option }}</option>
                        </template>
                        </template>
                      </select>
                    </div>

                  </template>

                  <template v-if="field.type == 'checkbox'">

                    <div class="form-group custom-control custom-switch">
                    <input
                      :id="'search_'+field.key"
                      v-model="queries[field.key]"
                      type="checkbox"
                      class="custom-control-input"
                      @change="searchRecords"
                      :disabled="processing"
                    >
                    <label
                      class="custom-control-label"
                      :for="'search_'+field.key"
                      >{{ field.label }}</label>
                    <template v-if="field.description">
                      <small class="form-text text-muted">{{ field.description }}</small>
                    </template>
                  </div>

                  </template>

                  </div>

                </template>

                <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                      id="search_archived"
                      v-model="queries.archived"
                      type="checkbox"
                      class="custom-control-input"
                      @change="searchRecords"
                      :disabled="processing || queries.trashed"
                    >
                    <label
                      class="custom-control-label"
                      for="search_archived"
                    >Archived</label>
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                      id="search_trashed"
                      v-model="queries.trashed"
                      type="checkbox"
                      class="custom-control-input"
                      @change="searchRecords"
                      :disabled="processing || queries.archived"
                    >
                    <label
                      class="custom-control-label"
                      for="search_trashed"
                    >Trashed</label>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

                <div class="row">

                  <div class="col-sm-12 col-md-9">
                    <button type="submit" class="btn btn-primary" :disabled="processing">
                      Search
                    </button>
                  </div><!--Col-->

                  <div class="col-sm-12 col-md-3 text-right">
                    <button type="button" class="btn btn-outline-primary btn-sm" @click="toggleSearchFields()">
                      <template v-if="show_search === true">
                        <i class="fa fa-minus"></i> 
                      </template>
                      <template v-else>
                        <i class="fa fa-plus"></i>
                      </template>
                      Fields
                    </button>
                  </div>

                </div>


              </form>
            </div>
          </div>


        </div><!--Col-->
  
        <div class="col-sm-12 col-md-9">

          <div class="card">
            <div class="card-body">

              <div class="row">

                <div class="col-sm-12 col-md-6">

                  <p class="card-title">
                    <template v-if="processing">
                      <i class="fa fa-spinner fa-spin text-primary"></i>
                    </template>
                    <template v-else>
                      {{ records.length }} of {{ total.toLocaleString() }} results
                    </template>
                  </p>

                </div><!--Col-->

                <div class="col-sm-12 col-md-6 text-right">

                  <div class="btn-group d-print-none">
                    <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                    <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                  </div>
                </div><!--Col-->

                </div><!--Row-->
  
  
              <div class="table-responsive my-3">
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Edit</th>
                    <template v-for="column in tableFields">
                      <th
                        class="text-uppercase"
                      >
                        <template v-if="column.key">
                          <span
                            class="text-capitalize link-hover"
                            @click="sortColumn(column)"
                          >{{ column.label }}</span>
                        </template>
  
                        <template v-if="!column.key">
                          <span class="text-capitalize">{{ column.label }}</span>
                        </template>
  
                        <template v-if="column.key == queries.sort">
                          <button
                            type="button"
                            class="btn btn-outline-secondary btn-sm ml-1"
                            :disabled="processing"
                            :aria-label="queries.sortDirection == 'asc' ? 'Sort Descending' : 'Sort Ascending'"
                            @click="updateSortDirection()"
                          >
                            <template v-if="queries.sortDirection == 'asc'">
                              <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                            </template>
  
                            <template v-if="queries.sortDirection == 'desc'">
                              <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                            </template>
                          </button>
                        </template>
                      </th>
                    </template>
                  </tr>
                </thead>
  
                <tbody>
                  <template v-if="records && records.length > 0">
                    <template v-for="record in records">
                      <tr>
                        <td>
                          <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid}}">
                            <i class="fa fa-edit"></i> Edit
                          </router-link>
                        </td>
                        <template v-for="column in tableFields">
                          <td
                            v-if="column.key"
                          >

                          <template v-if="column.type == 'checkbox'">
                            
                              <template v-if="record[column.key]">
                              <i class="fa fa-circle-check text-success"></i>
                              </template>
                              <template v-else>
                              <i class="fa fa-circle-xmark text-danger"></i>
                              </template>
                          
                          </template>

                          <template v-if="column.key == 'portal' && record.portal">
                            <router-link :to="{'name': 'portals.edit', 'params': {'id': record.portal}}">
                              {{ setPortalName(record) }}
                            </router-link>
                          </template>

                          <template v-if="column.key == 'location' && record.location">
                            <router-link :to="{'name': 'locations.edit', 'params': {'id': record.location}}">
                              {{ setLocationName(record) }}
                            </router-link>
                          </template>

                          <template v-if="column.type == 'select' && !['location', 'portal'].includes(column.key)">
                            <span class="badge badge-secondary text-uppercase">{{ record[column.key] }}</span>
                          </template>

                          <template v-if="column.type == 'date' && record[column.key]">
                            {{ record[column.key] | date }}
                          </template>

                          <template v-if="column.type == 'time' && record[column.key]">
                            {{ record[column.key] | time }}
                          </template>

                          <template v-if="column.type == 'datetime' && record[column.key]">
                            {{ record[column.key] | datetime }}
                          </template>

                          <template v-if="column.key == 'created_at'">
                            <template v-if="record['archived_at']">
                              <span class="badge badge-warning">Archived</span>
                            </template>
                            <template v-if="record['deleted_at']">
                              <span class="badge badge-danger">Trashed</span>
                            </template>
                          </template>

                          <template v-if="column.type == 'text'">
                            <span contenteditable="true" @blur="updateRecord(record, column.key, $event.target.innerText)">{{ record[column.key] }}</span>
                          </template>

                          <template v-if="column.type == 'number'">
                            <span contenteditable="true" @blur="updateRecord(record, column.key, $event.target.innerText)">{{ record[column.key] }}</span>
                          </template>

                          </td>
                        </template>

                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>
              </div><!--Responsive-->
  
              <template v-if="!processing && !records.length">
                <div class="alert alert-warning">
                  No results found.
                </div>
              </template>
            </div><!--Body-->
          </div><!--Card-->
  
          <template v-if="records && records.length && total > records.length">
            <div class="row">
              <div class="col-12 text-center">
                <button
                  type="button"
                  ref="loadMoreButton"
                  class="btn btn-outline-primary mt-3"
                  :disabled="processing"
                  @click="getRecords()"
                >
                  Load more
                </button>
              </div>
            </div>
          </template>
  
        </div><!--Col-->
      </div><!--Row-->

    </div>
  </template>
  <script>
  import _ from "lodash";
  import queries from '../mixins/queries';
  
  export default {
  
    mixins: [ queries ],
  
    data () {
      return {
        processing: false,
        downloading: false,
        show_search: false,
        errors: null,
        total: 0,
        queries: {},
        downloads: [],
        records: [],
        fields: {},
        tableFields: {},
        form: {},
        options: {
          portals: [],
          statuses: [],
          locations: []
        }
      }
    },

    mounted() {

    var self = this;

    this.$eventBus.$on('errors', function (errors) {
      self.processing = false;
      self.downloading = false;
      self.errors = errors.data || errors;
    });

    this.$eventBus.$on('scrolling', function(data) {
        self.shouldLoadMore();
    });

    },

    created() {
      this.initCreated();
    },

    watch: {
      $route(to, from) {
        this.onRouteChange();
      }
    },
    
    methods: {

      initCreated() {
      this.setFields();
      this.resetForm();
      this.resetQueries();
      this.resetRecords();
      this.setURLParams();
      this.getRecords();

      },

      onRouteChange() {
        this.initCreated();
      },
  
  
      setURLParams() {
  
          var searchParams = new URLSearchParams(window.location.search);
  
          var self = this;
  
          searchParams.forEach(function(value, key) {
              self.queries[key] = value;
          });

      },

      setTableFields() {

        var fields = {};

        Object.assign(fields, this.fields);

        fields['created_at'] = {
          'label': 'Created',
          'key': 'created_at',
          'type': 'datetime'
        }

        fields['updated_at'] = {
          'label': 'Updated',
          'key': 'updated_at',
          'type': 'datetime'
        }

        fields['created_by'] = {
          'label': 'Created By',
          'key': 'created_by',
          'type': 'text'
        }

        this.tableFields = fields;
        
      },

      setFields () {
        var path = 'app_fields';

        if(this.$root.current_region == 'staging') {
          path = 'app_fields_staging';
        }

        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/'+path+'.json')
          .then(response => {
              if(response && response.data) {

                  this.fields = response.data[this.$route.meta.base_url];

                  if(this.options['statuses']) {
                    this.options['statuses'] = [
                      {
                        'name': 'Draft',
                        'uuid': 'draft'
                      },
                      {
                        'name': 'Private',
                        'uuid': 'private'
                      },
                      {
                        'name': 'Public',
                        'uuid': 'public'
                      },
                      {
                        'name': 'Passcode',
                        'uuid': 'passcode'
                      }
                    ]
                  }

                  this.setTableFields();

                  this.getPortals();
                  this.getLocations();
              }
          })
        },
  
      resetQueries() {
        this.queries = {
          limit: 10,
          skip: 0,
          sort: 'created_at',
          sortDirection: 'desc',
          archived: false,
          trashed: false,
          type: null,
          status: null,
        }
      },
  
      resetForm() {
        this.form = {}
      },

      resetSearch() {
        this.resetQueries();
        this.resetRecords();
        this.getRecords();
      },
  
      resetRecords() {
        this.records = [];
        this.total = 0;
        this.queries.skip = 0;
        this.queries.limit = 10;
      },
  
      updateSortDirection() {
  
        if(this.processing) {
          return;
        }
  
        if(this.queries.sortDirection == 'asc') {
          this.queries.sortDirection = 'desc';
        } else {
          this.queries.sortDirection = 'asc';
        }
  
        this.resetRecords();
  
        this.getRecords();
      },
  
      sortColumn(column) {
  
        if(this.processing) {
          return;
        }
  
        this.queries.sort = column.key;
  
        this.updateSortDirection();
      },

      searchRecords() {
        this.resetRecords();
        this.getRecords();
      },
  
      getRecords() {
  
        this.processing = true;
  
        this.updateURL();
        this.getTotal();
  
        this.$http.get(this.buildQueries(this.$route.meta.base_url, this.queries))
        .then(response => {
          if(response && response.data) {
              
              if(response.data.length) {
  
                  var self = this;
  
                  response.data.forEach(function(data) {
                      self.records.push(data);
                  });
              }
              
              this.queries.skip = this.records.length;
  
              this.processing = false;
          }
        })
      },
  
      getTotal() {
        this.$http.get(this.buildQueries(this.$route.meta.base_url+'/total', this.queries))
        .then(response => {
          if(response && response.status === 200) {
            this.total = response.data;
            this.setTitle();
          }
        })
      },
  
      updateURL() {
        history.pushState({}, null, this.buildQueries(this.$route.meta.base_url, this.queries));
      },
  
      setTitle() {

        var title = this.$route.meta.title;

        if(['Status'].includes(title)) {
          title = title + 'es';
        } else {
          title = title + 's';
        }

        document.title = this.total + ' ' + title + ' | eCourtDate.com';
      },
  
      postForm() {
        this.processing = true;

        this.errors = null;

        if(!this.form.name) {
            this.form.name = this.form.redirect_url;
        }

        this.$http.post(this.$route.meta.base_url, this.form)
        .then(response => {
          if(response && response.data) {
  
            this.processing = false;
  
            if(response.status === 201) {
              this.resetForm();
              this.total++;
              this.records.unshift(response.data);
              this.setTitle();
              new this.$noty({text: this.$route.meta.title + ' created'}).show();
            }
  
          }
        })
      },
  
      setPortalName(record) {
  
        if(!record.portal) {
          return;
        }
  
        var portal = _.find(this.options['portals'], {'uuid': record.portal});
  
        if(!portal || !portal.name) {
          return;
        }
  
        return portal.name;
      },

      setLocationName(record) {
                      
          if(!record.location) {
            return;
          }
    
          var location = _.find(this.options['locations'], {'uuid': record.location});
    
          if(!location || !location.name) {
            return;
          }
    
          return location.name;
      },
  
      getPortals () {

        if(!this.fields || !this.fields['portal']) {
          return;
        }

        this.fields['portal'].options = [];

        this.$http.get('/portals?slim=true&limit=1000&sort=default&sortDirection=desc&fields=uuid,name,link')
            .then(response => {
              if (response && response.data && response.data.length) {
                this.options['portals'] = response.data;

                this.fields['portal'].options = response.data;

                if(!this.form.portal && this.options['portals'] && this.options['portals'].length) {
                  this.form.portal = this.options['portals'][0].uuid;
                }
              }
            })
      },

      getLocations () {

        if(!this.fields || !this.fields['location']) {
          return;
        }

        this.fields['location'].options = [];

        this.$http.get('/locations?slim=true&limit=1000&sort=default&sortDirection=desc&fields=uuid,name')
        .then(response => {
              if (response && response.data && response.data.length) {
                this.options['locations'] = response.data;

                this.fields['location'].options = response.data;

                if(!this.form.location && this.options['locations'] && this.options['locations'].length) {
                  this.form.location = this.options['locations'][0].uuid;
                }
              }
            })
      },

      shouldLoadMore() {

      if(this.processing) {
        return;
      }

      if(this.total <= this.records.length) {
        return;
      }

      const loadMoreButton = this.$refs.loadMoreButton;

      if(!loadMoreButton) {
        return;
      }

      const rect = loadMoreButton.getBoundingClientRect();
      if (rect.top <= window.innerHeight) {
        this.getRecords();
        return;
      }
      },

      showSearchField(field) {
        if(this.show_search) {
          return true;
        }

        if(field.required) {
          return true;
        }

        if(field.hidden) {
          return false;
        }

        if(field.key.includes['search', 'name', 'title', 'description']) {
          return true;
        }

        return false;
      },

      toggleSearchFields() {
        this.show_search = !this.show_search;
      },

      updateRecord(record, key, value) {

        if(this.processing) {
          return;
        }

        value = value.trim();

        value = value.replace(/<[^>]*>?/gm, '');

        if(value == record[key]) {
          return;
        }

        this.$http.patch(this.$route.meta.base_url+'/'+record.uuid, {
          [key]: value
        })
        .then(response => {
          if(response && response.data) {

            var index = this.records.findIndex(x => x.uuid === record.uuid);

            if(index > -1) {
              this.records[index] = response.data;
            }

            new this.$noty({text: this.$route.meta.title + ' updated'}).show();
          }
        })
      },

    },
  }
  </script>