<template>
  <div>
    <template v-if="form.created_at">
      <small class="text-muted mr-1">Created {{ form.created_at | datetime }}</small>
    </template>
    <template v-if="form.updated_at">
      <small class="text-muted mr-1">Updated {{ form.updated_at | datetime }}</small>
    </template>

    <template v-if="form.archived_at">
      <small class="text-warning mr-1">Archived {{ form.archived_at | datetime }}</small>
    </template>
    <template v-if="form.deleted_at">
      <small class="text-danger mr-1">Trashed {{ form.deleted_at | datetime }}</small>
    </template>
  </div>
</template>

<script>

export default {

props: ['form'],

}

</script>