<template>
  <div>

    <template v-if="errors && errors.message">
      <div v-if="errors.message != 'Network Error'">
        <div class="alert alert-danger">
          {{ errors.message }}
        </div>
      </div>
    </template>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="title"
    />

    <div class="row">
      <div class="col-sm-12 col-md-4">
        <h3><span v-if="form && form.case_number">Case {{ form.case_number }}</span></h3>
        <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ title }}s</router-link> / Edit {{ title }}</p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4">

        <template v-if="cmsURL">
            <a :href="cmsURL" target="_blank" class="btn btn-secondary-outline"><i class="fa fa-link"></i> CMS</a>
        </template>

      </div><!--Col-->

      <div class="col-sm-12 col-md-4 text-right">
        <button type="button" class="btn btn-secondary" @click="getPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>

        <div class="btn-group">
          <button
            type="button"
            class="btn btn-outline-primary dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            :disabled="processing"
          >
            Tools
          </button>
          <div class="dropdown-menu">
            <a
              v-if="!form.archived_at && !form.deleted_at"
              class="dropdown-item"
              @click="syncRecord"
            >Sync Messages</a>
            <a
              v-if="!form.archived_at && !form.deleted_at"
              class="dropdown-item"
              @click="archiveRecord"
            >Archive</a>
            <a
              v-if="!form.deleted_at && !form.archived_at"
              class="dropdown-item"
              @click="trashRecord"
            >Trash</a>
            <a
              v-if="form.archived_at || form.deleted_at"
              class="dropdown-item"
              @click="restoreRecord"
            >Restore</a>
          </div>

          <template v-if="!form.archived_at && !form.deleted_at">
            <button
              type="button"
              class="btn btn-success"
              :disabled="processing"
              @click="postForm"
            >
              Save
            </button>
          </template>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>
        </div><!--BtnGroup-->
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-4">
        <div class="card">
          <div class="card-body">

            <form @submit.prevent="postForm()">
              
              <div class="form-group">
                <label for="case_number">Case Number</label>
                <input
                  id="case_number"
                  v-model="form.case_number"
                  type="text"
                  class="form-control"
                  required
                >
              </div>

              <div class="form-group">
                <label for="location">{{ setCustomField('client_cases', 'location') }}

                  <template v-if="form.location">
                    <a
                        :href="'/locations/'+form.location"
                        target="_blank"
                        data-tooltip="Edit Location">
                      <i class="fa fa-edit"></i> <span class="sr-only">Edit Location</span>
                    </a>
                  </template>

                </label>
                <select
                    id="location"
                    v-model="form.location"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="" />
                  <template v-for="location in locations">
                    <option
                        :value="location.uuid"
                    >
                      {{ location.name }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group" :hidden="!processing && !statuses.length">
                <label for="status">Status

                  <template v-if="form.status && setCurrentStatus(form.status)">
                    <a :href="'/statuses/'+setCurrentStatus(form.status)" target="_blank" data-tooltip="Edit Status" data-toggle="tooltip"><i class="fa fa-edit"></i> <span class="sr-only">Edit Status</span></span></a>
                  </template>

                </label>
                <select 
                  id="status"
                  v-model="form.status"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-for="status in statuses">
                    <option
                      :value="status.name"
                    >
                      {{ status.name }}
                    </option>
                  </template>
                </select>
              </div>

              <template v-if="!processing && !statuses.length">
                  <div class="alert alert-info">
                    <a href="/statuses?type=case" class="alert-info" target="_blank">No case statuses found</a>

                    <button type="button" class="btn btn-secondary btn-sm float-right" @click="getStatuses()" :disabled="processing">
                      <i class="fa fa-refresh"></i>
                    </button>

                  </div>

              </template>

              <div class="form-group">
                <label for="type">{{ setCustomField('client_cases', 'type') }}</label>
                <select
                  id="type"
                  v-model="form.type"
                  class="form-control"
                  :disabled="processing"
                  >
                  <option value=""></option>
                  <template v-for="case_type in case_types">
                    <option
                      :value="case_type"
                    >
                      {{ case_type }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="attorneys">Attorneys</label>
                <multi-select 
                  id="attorneys"
                  v-model="form.attorneys" 
                  :options="attorneys"
                  placeholder="Choose attorneys"
                  track-by="uuid"
                  :multiple="true"
                  :searchable="true"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :show-no-results="true"
                  :hide-selected="true"
                  @search-change="searchAttorneys"
                >
                  <template
                    slot="clear"
                    slot-scope="props"
                  >
                    <div
                      v-if="form.attorneys && form.attorneys.length > 0"
                      class="multiselect__clear"
                      @mousedown.prevent.stop="clearAll(props.search)"
                    />
                  </template>
                  <template
                    slot="option"
                    slot-scope="option"
                  >
                    {{ option.option.firm_name }} {{ option.option.firm_name && (option.option.first_name || option.option.last_name) ? ',' : '' }} {{ option.option.first_name }} {{ option.option.last_name }} {{ option.option.bar_number || '' }}
                  </template>
                  <template
                    slot="tag"
                    slot-scope="{ option, remove }"
                  >
                    <span class="multiselect__tag"><span>{{ option.firm_name }} {{ option.firm_name && (option.first_name || option.last_name) ? ',' : '' }} {{ option.first_name }} {{ option.last_name }} </span> <i
                      aria-hidden="true"
                      tabindex="1"
                      class="multiselect__tag-icon primary"
                      @click="remove(option)"
                    /></span>
                  </template>

                  <span slot="noResult"><template v-if="searching">Searching attorneys</template> <template v-else>No attorneys found</template></span>
                  <span slot="noOptions">Type to search attorneys</span>
                </multi-select>
              </div>


              <div class="row">
                <div class="form-group col">
                  <label for="file_date">{{ setCustomField('client_cases', 'file_date') }}</label>
                  <input
                    id="file_date"
                    v-model="form.file_date"
                    type="date"
                    class="form-control"
                  >
                </div>

                <div class="form-group col">
                  <label for="arrest_date">{{ setCustomField('client_cases', 'arrest_date') }}</label>
                  <input
                    id="arrest_date"
                    v-model="form.arrest_date"
                    type="date"
                    class="form-control"
                  >
                </div>
                <div class="form-group col">
                  <label for="offense_date">{{ setCustomField('client_cases', 'offense_date') }}</label>
                  <input
                    id="offense_date"
                    v-model="form.offense_date"
                    type="date"
                    class="form-control"
                  >
                </div>
                <div class="form-group col">
                  <label for="judgment_date">{{ setCustomField('client_cases', 'judgment_date') }}</label>
                  <input
                    id="judgment_date"
                    v-model="form.judgment_date"
                    type="date"
                    class="form-control"
                  >
                </div>
              </div>
              <div class="form-group">
                <label for="arresting_agency">{{ setCustomField('client_cases', 'arresting_agency') }}</label>
                <select
                  id="arresting_agency"
                  v-model="form.arresting_agency"
                  class="form-control"
                  :disabled="processing"
                >
                <option value=""></option>
                <template v-for="organization in organizations">
                  <option
                    :value="organization.uuid"
                  >
                    {{ organization.name }}
                  </option>
                </template>
                </select>
                <p class="text-muted"><a href="/organizations?type=Law%20Enforcement" target="_blank">Manage arrest agencies</a></p>
              </div>

              <div class="form-group">
                <label for="arrest_tracking">{{ setCustomField('client_cases', 'arrest_tracking') }}</label>
                <input
                  id="arrest_tracking"
                  v-model="form.arrest_tracking"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="victim_name">{{ setCustomField('client_cases', 'victim_name') }}</label>
                <input
                  id="victim_name"
                  v-model="form.victim_name"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="offender_name">{{ setCustomField('client_cases', 'offender_name') }}</label>
                <input
                  id="offender_name"
                  v-model="form.offender_name"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="codes">{{ setCustomField('client_cases', 'codes') }}</label>
                    <input
                      id="codes"
                      v-model="form.codes"
                      type="text"
                      class="form-control"
                    >
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="charge_description">{{ setCustomField('client_cases', 'description') }}</label>
                    <input
                      id="charge_description"
                      v-model="form.description"
                      type="text"
                      class="form-control"
                    >
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <div class="form-group">
                <label for="offense_type">{{ setCustomField('client_cases', 'offense_type') }}</label>
                <input
                  id="offense_type"
                  v-model="form.offense_type"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="offense_description">{{ setCustomField('client_cases', 'offense_description') }}</label>
                <textarea
                  id="offense_description"
                  v-model="form.offense_description"
                  class="form-control"
                  rows="3"
                />
              </div>

              <div class="form-group">
                <label for="conditions">{{ setCustomField('client_cases', 'conditions') }}</label>
                <textarea
                  id="conditions"
                  v-model="form.conditions"
                  class="form-control"
                  rows="3"
                />
              </div>

              <div class="form-group">
                <label for="charge_number">{{ setCustomField('client_cases', 'charge_number') }}</label>
                <input
                  id="charge_number"
                  v-model="form.charge_number"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="warrant_status">{{ setCustomField('client_cases', 'warrant_status') }}</label>
                <input
                  id="warrant_status"
                  v-model="form.warrant_status"
                  type="text"
                  class="form-control"
                >
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

            </form>
          </div><!--Body-->
        </div><!--Card-->
                        

        <audit-logs
          :form="form"
          class="mt-3"
        />
      </div><!--Col-->

      <div class="col-sm-12 col-md-4">

        <template v-if="form.case_number && !form.archived_at && !form.deleted_at">

          <div class="card mb-3">
            <div class="card-body">
              <p class="card-title">Risk Assessment</p>

              <template v-if="form.case_number">
                <a :href="'/assessments/psa?case_number='+form.case_number" class="btn btn-outline-danger" target="_blank">View Public Safety Assessment</a>
              </template>

            </div><!--Body-->
          </div><!--Card-->

          </template>

        <template v-if="form.location">
          <location-card :uuid="form.location" />
        </template><!--Location-->

        <template v-if="events && events.length > 0">
          <template v-for="event in events">
            <event-card
              :event="event.uuid"
              class="mt-3"
            />
          </template>
        </template>


        <template v-if="form.attorneys && form.attorneys.length > 0">
          <div class="card mt-3">
            <div class="card-body">
              <p class="card-title">
                {{ form.attorneys.length }} Attorneys
              </p>

              <ul class="list-group">
                <template v-for="attorney in form.attorneys">
                  <router-link
                    :to="{ 'name': 'attorneys.edit', 'params': {'id': attorney.uuid }}"
                    tag="li"
                    class="list-group-item list-group-item-action link-hover"
                  >
                    {{ attorney.firm_name }} {{ attorney.bar_number }}
                  </router-link>
                </template>
              </ul>
            </div><!--Body-->
          </div><!--Card-->
        </template>


        <div class="card my-3">
          <div class="card-body">
            <p class="card-title">Case Files</p>
            <p class="card-text">Upload files to this case.</p>

            <div class="form-group">
              <label for="new_case_file">File</label>
              <input
                id="new_case_file"
                type="file"
                class="form-control-file"
              >
            </div>

          </div><!--Body-->
        </div><!--Card-->

        <div class="card my-3">
          <div class="card-body">

            <p class="card-title">Add Internal Comment</p>

            <form @submit.prevent="postComment()">

              <div class="form-group">
                <label for="new_comment">Comment</label>
                <textarea
                  id="new_comment"
                  v-model="commentForm.content"
                  class="form-control"
                  rows="3"
                />
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <template v-if="comments && comments.length > 0">
          <div class="card my-3">
            <div class="card-body">
              <p class="card-title">
                <a :href="'/comments?case='+form.case_number" target="_blank">{{ comments.length }} Comments</a>
              </p>

              <div class="list-group">

                <template v-for="comment in comments">

                  <small>{{ comment.created_by }} commented {{ comment.created_at | dateTimezone | timeago }}</small>
                  <a :href="'/comments/'+comment.uuid" target="_blank"><p class="outbound m-0">{{ comment.content }}</p></a>

                </template>

              </div>

            </div><!--Body-->
          </div><!--Card-->
        </template>

      </div><!--Col-->

      <div class="col-sm-12 col-md-4">

        <form @submit.prevent="searchClients()">
      <div class="input-group mb-3">
        <input
          v-model="search_clients"
          type="text"
          class="form-control"
          aria-label="Client"
          aria-describedby="client"
          placeholder="Search clients"
        >
        <div class="input-group-append">
          <button
            id="client"
            class="btn btn-primary"
            type="submit"
            :disabled="processing"
          >
            Search
          </button>
        </div>
      </div>

      <template v-if="clients && clients.length > 0">
        <ul class="list-group mb-3">
          <template v-for="search_client in clients">
            <li
              class="list-group-item"
            >
              <a :href="'/clients/'+search_client.uuid" target="_blank">{{ search_client.result }}</a>

              <template v-if="search_client.contacts">
                <br><small class="text-muted">{{ search_client.contacts }}</small>
              </template>

              <button type="button" class="btn btn-success float-right" @click="attachClient(search_client)" :disabled="processing">Attach</button>
            </li>
          </template>
        </ul>
      </template>
    </form>

        <template v-if="client && client.uuid">

          <div class="card">
      <div class="card-body">

        <div class="row">

          <div class="col-sm-12 col-md-6 mb-3">

            <router-link
                  tag="p"
                  :to="{ 'name': 'clients.edit', 'params': {'id': client.uuid} }"
                  class="card-title"
                >
                  Case Client
            </router-link>

          </div><!--Col-->

          <div class="col-sm-12 col-md-6">
              
              <div class="btn-group float-right">
                <button type="button" class="btn btn-danger btn-sm" @click="detachClient()" :disabled="processing">Detach</button>
                <router-link
                  :to="{ 'name': 'clients.edit', 'params': {'id': client.uuid} }"
                  class="btn btn-primary btn-sm"
                >
                  Edit
                </router-link>
              </div><!--BtnGroup-->
          </div><!--Col-->

        </div><!--Row-->

          <ul class="list-group">

            <template v-if="client.name">
              <li class="list-group-item" 
              :class="isPrivacyMode ? 'privacy-mode' : ''"
              >Name {{ client.name }}
              </li>
            </template>

            <template v-if="client.client_reference">
              <li class="list-group-item">Reference {{ client.client_reference }}</li>
            </template>

            <template v-if="client.language">
              <li class="list-group-item">
                Language

                {{ setLanguage(client.language) }}

              </li>
            </template>

            <template v-if="client.type">
              <li class="list-group-item">
                Type {{ client.type }}
              </li>
            </template>

            <template v-if="client.group">
              <li class="list-group-item">
                Group {{ client.group }}
              </li>
            </template>

            <template v-if="client.status">
              <li class="list-group-item">
                Status {{ client.status }}
              </li>
            </template>

          </ul>

        </div><!--Body-->
    </div><!--Card-->

          <client-contacts :client_uuid="client.uuid" class="mt-3" />

        </template>

        <template v-if="form && form.uuid">
          <message-form
            :client="form.client"
            :case="form.uuid"
          />
        </template>

        <p class="mt-3"><a :href="'/messages?case='+form.uuid" target="_blank">{{ messages.length }} of {{ total_messages }} Outbound Messages</a></p>

        <template v-if="messages && messages.length > 0">
          <template v-for="(message, message_index) in messages">
            <message :message="message" />
          </template>
        </template>


      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

    mixins: [ queries ],

    data () {
        return {
            processing: false,
            searching: false,
            title: null,
            errors: null,
            form: {},
            client: {},
            messages: [],
            attorneys: [],
            events: [],
            locations: [],
            statuses: [],
            case_types: [],
            languages: [],
            clients: [],
            organizations: [],
            search_clients: '',
            total_messages: 0,
            message_queries: {
                slim: true,
                limit: 100,
                direction: 'outbound',
                sort: 'scheduled_at',
                sortDirection: 'asc',
            },
            commentForm: {
                case: null,
                content: null
            },
            comments: []
        }
    },

    computed: {

        cmsURL: function() {
            if(this.$root.current_user && this.$root.current_user.current_agency && this.$root.current_user.current_agency.settings) {
                if(this.$root.current_user.current_agency.settings.cms_url && this.form.case_number) {
                    return this.$root.current_user.current_agency.settings.cms_url + this.form.case_number;
                }
            }

            return false;
        }

    },

    mounted() {

    var self = this;

    this.$eventBus.$on('messages', function (data) {

        if(data && data.case && data.case === self.form.uuid) {
            if(!self.messages) {
                self.messages = [];
            }

            self.messages.push(data);
        }

    });

    this.$eventBus.$on('errors', function (errors) {
        self.errors = errors.data || errors;
        self.processing = false;
    });

    },

    created () {
        this.resetForm();

        this.title = this.setCustomLabel(this.$route.meta.title);

        this.getRecord();
        this.getLocations();
        this.getStatuses();
        this.getLanguages();
        this.getOrganizations();
    },
    
    methods: {

      resetForm() {
        this.form = {
          case_number: null,
          location: null,
          status: null
        }
      },

        getRecord () {

            this.processing = true;
            
            this.$http.get('/'+this.$route.meta.base_url + '/' + this.$route.params.id)
            .then(response => {
                if (response && response.data) {

                    this.form = response.data;
                    this.processing = false;

                    this.getMessages();

                    if(this.form.case_number) {
                        this.setTitle();
                        this.getEvents(this.form.case_number);
                    }

                    this.getClient();
                    this.setCaseTypes();

                    this.getComments();
                }
            })
        },

        setCaseTypes() {
          if(!this.settings || !this.settings.case_types) {
            return;
          }

          this.case_types = this.settings.case_types.split(',');

          this.case_types = this.case_types.map(function(item) {
            return item.trim();
          });

        },

        getClient() {
          if(!this.form.client) {
            return;
          }

          this.$http.get('/clients/'+this.form.client)
          .then(response => {
            if(response && response.data) {
              this.client = response.data;
            }
          })
        },

        setTitle() {
          document.title = this.title + ' ' + this.form.case_number +  ' | eCourtDate.com';
        },

        searchAttorneys (query) {

          if(!query) {
            return;
          }

          this.searching = true;
          this.$http.get('/attorneys?fields=firm_name,uuid&slim=true&search='+query)
          .then(response => {
            if (response && response.data) {
              this.attorneys = response.data;
              this.searching = false;
            }
          })
        },

        getEvents(case_number) {
            this.$http.get('/events?slim=true&case_number='+case_number)
            .then(response => {
                if(response && response.data) {
                    this.events = response.data;
                }
            })
        },

      getLocations () {
        this.$http.get('/locations?limit=100&slim=true&fields=name,uuid&sort=name&sortDirection=asc')
            .then(response => {
              if (response && response.data) {
                this.locations = response.data;
              }
            })
      },

      getStatuses () {
        this.$http.get('/statuses?limit=100&slim=true&fields=name,uuid&sort=name&sortDirection=asc&type=case')
            .then(response => {
              if (response && response.data) {
                this.statuses = response.data;
              }
            })
      },

        getMessages() {

          if(!this.form.uuid) {
            return;
          }
            
            this.message_queries.case = this.form.uuid;

            this.getTotalMessages();

            this.messages = [];

            this.$http.get(this.buildQueries('/messages', this.message_queries))
            .then(response => {
                if(response && response.data && response.data.data) {
                    this.messages = response.data.data;
                }
            })
        },

        getTotalMessages() {
            this.$http.get(this.buildQueries('/messages/total', this.message_queries))
            .then(response => {
                if(response && response.status === 200) {
                    this.total_messages = response.data;
                }
            })
        },

        postForm() {
            this.errors = null;
            this.processing = true;
            this.$http.patch('/'+this.$route.meta.base_url + '/' + this.form.uuid, this.form)
            .then(response => {
                if(response && response.data) {

                    if(response.data.updated_at) {
                        this.form.updated_at = response.data.updated_at;
                        new this.$noty({text: this.title + ' updated'}).show();
                    }

                    this.processing = false;
                }
            })  
        },

        restoreRecord () {
            this.processing = true;
            this.$http.put('/'+this.$route.meta.base_url+'/' + this.form.uuid + '/restore')
            .then(response => {
                if (response && response.status === 200) {
                    new this.$noty({text: this.title + ' restored'}).show();
                    this.form.archived_at = null;
                    this.form.deleted_at = null;
                    this.processing = false;
                }
                
            })
        },

        archiveRecord () {
            this.processing = true;
            this.$http.put('/'+this.$route.meta.base_url+'/' + this.form.uuid + '/archive')
            .then(response => {
                if (response && response.status === 200) {
                    new this.$noty({text: this.title + ' archived'}).show();
                    this.form.archived_at = new Date().toISOString();
                    this.processing = false;
                }
            })
        },

        trashRecord () {
            this.processing = true;
            this.$http.delete('/'+this.$route.meta.base_url+'/' + this.form.uuid)
            .then(response => {
                if(response && response.status === 204) {
                    new this.$noty({text: this.title + ' trashed', type: 'error'}).show();
                    this.form.deleted_at = new Date().toISOString();
                    this.processing = false;             
                }
            })
        },

        syncRecord() {
            this.processing = true;
            this.$http.post('/'+this.$route.meta.base_url+'/'+ this.form.uuid + '/sync')
            .then(response => {
                if(response && response.data) {
                    
                    if(response.status === 202) {
                        new this.$noty({text: 'Syncing messages'}).show();
                    } 

                    this.processing = false
                }

            })
        },

        sortMessages() {
            this.messages = this.messages.sort((a, b) => Number(new Date(a.scheduled_at)) - Number(new Date(b.scheduled_at)));
        },

        setLanguage(language) {
          
          if(!language) {
            return;
          }

          var result = _.find(this.languages, {'key': language});

          if(!result) {
            return language;
          }

          return result.label;

        },

        setCurrentStatus(value) {

          if(!value) {
            return;
          }

          var status = _.find(this.statuses, {'name': value});

          if(!status || !status.name) {
            return;
          }

          return status.uuid;
          },

          searchClients() {
            this.processing = true;
            this.$http.get('/search?search='+this.search_clients)
            .then(response => {
              if(response && response.data) {
                this.clients = response.data;
                this.processing = false;
              }
            })
          },

          detachClient() {
            this.form.client = null;
            this.client = {};
            this.postForm();
          },

          attachClient(client) {
            this.form.client = client.uuid;
            this.clients = [];
            this.search_clients = '';
            this.getClient();
            this.postForm();
          },

          getLanguages() {
            this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/languages.json')
            .then(response => {
              if(response && response.data) {
                this.languages = response.data;
              }
            })
          },

    getPDF() {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/pdf', {responseType: 'blob'})
      .then(response => {
        if(response && response.data) {
          this.processing = true;

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.$route.meta.base_url+'-'+this.form.uuid+'.pdf');
          document.body.appendChild(link);
          link.click();

          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);

          this.processing = false;
        }
      })
    },

    getOrganizations() {
      this.$http.get('/organizations?limit=100&slim=true&fields=name,uuid&sort=name&sortDirection=asc&type=Law%20Enforcement')
      .then(response => {
        if(response && response.data) {
          this.organizations = response.data;
        }
      })
    },

    getComments() {
      this.$http.get('/comments?case='+this.form.case_number)
      .then(response => {
        if(response && response.data) {
          this.comments = response.data;
        }
      })
    },

    postComment() {
      if(!this.form.case_number) {
        return;
      }

      this.commentForm.case = this.form.case_number;

      this.processing = true;
      this.$http.post('/comments', this.commentForm)
      .then(response => {
        if(response && response.data) {

          this.commentForm.content = null;

          this.processing = false;
          new this.$noty({text: 'Comment added'}).show();

          if(!this.comments) {
            this.comments = [];
          }

          this.comments.unshift(response.data);
        }
      })
    }

    }
}
</script>
