<template>
  <div>
    <p class="card-title">
      <span v-if="form && form.uuid">Edit</span>
      <span v-else>Add</span>

      {{ title }}
    </p>
  </div>
</template>
<script>
export default {
    props: ['title', 'form']
}
</script>