<template>
  <div>
    <template v-if="record && record.uuid">
      <div class="card mt-3">
        <div class="card-body">
          <p class="card-title">
            <router-link :to="{'name': 'portals.edit', 'params': {'id': record.uuid }}">
              Edit Portal
            </router-link>
          </p>

          <ul class="list-group">
            <template v-if="record.name">
              <li class="list-group-item">
                Name {{ record.name }}
              </li>
            </template>

            <template v-if="record.link">
              <li class="list-group-item">
                Link {{ record.link }}
              </li>
            </template>
          </ul>
        </div><!--Body-->
      </div><!--Card-->
    </template>
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

mixins: [ queries ],

props: ['uuid'],

data() {
    return {
        record: {},
        base_url: '/portals',
        queries: {
            slim: true,
            fields: 'uuid,name,link'
        }
    }
},

created() {
    this.getRecord();
},

methods: {

    getRecord() {

        if(!this.uuid) {
            return;
        }

        this.$http.get(this.buildQueries(this.base_url+'/'+this.uuid, this.queries))
        .then(response => {
            if(response && response.data) {
                this.record = response.data;
            }
        })
    }

}

}
</script>