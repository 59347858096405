<template>
  <div>
    <template v-if="!processing && is_disabled">
      <a :href="base_url+'?name='+status">
        <span class="badge badge-danger">{{ status }} Messages Disabled</span>
      </a>
    </template>
  </div>
</template>
<script>
export default {

props: ['status'],

data() {
    return {
        processing: false,
        is_disabled: false,
        base_url: '/statuses'
    }
},

created() {
    this.getTotal();
},

methods: {

  getTotal() {
            
            if(!this.status) {
                return;
            }

            this.processing = true;

            this.$http.get(this.base_url+'/total?disable_messages=1&name='+this.status)
            .then(response => {
                if(response && response.status) {

                    if(response.data >= 1) {
                      this.is_disabled = true;
                    }

                    this.processing = false;
                }
            })
        },

}

}
</script>