<template>
  <code
    class="link-hover small text-muted"
    @click="copyToClipboard()"
  >{{ merge_tag }}</code>
</template>

<script>

export default {

props: ['merge_tag'],

methods: {

    copyToClipboard() {
        navigator.clipboard.writeText(this.merge_tag);
        new this.$noty({text: 'Merge Tag ' + this.merge_tag + ' copied to clipboard'}).show();
    },

}
}

</script>