<template>
  <div>
      <div class="card">
        <div class="card-body">

              <p class="card-title m-0"><i class="fas fa-map-marker-alt"></i> Geolocation {{ geolocation.timestamp | timeago }}</p>

              <span class="badge badge-primary">Distance {{ calculateDistance(geolocation) }}</span>


              <span class="badge badge-secondary">Accuracy {{ geolocation.accuracy | metersFeet }}</span>

                <div
                id="geo_map"
                class="map-lg"
                />

        </div><!--Body-->
      </div><!--Card-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

mixins: [ queries ],

props: ['geolocation', 'location'],

data() {
    return {
        map: {},
        current_location: {}
    }
},

mounted() {
    this.initMap();

    navigator.geolocation.getCurrentPosition((position) => {

        if(!position.coords || !position.coords.latitude || !position.coords.longitude) {
            return;
        }

        this.current_location = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
        };
    });
},

beforeDestroy() {
    if(this.map) {
        this.map.remove();
    }
},

methods: {

    initMap() {

    this.map = window.L.map("geo_map").setView(this.$root.coordinates, 4);

    window.L.tileLayer(this.$root.map_layer).addTo(this.map);

    this.setMarker();
    },

    setMarker() {

    if(!this.geolocation.latitude || !this.geolocation.longitude) {
        return;
    }

    const userIcon = window.L.divIcon({
    html: '<i class="fa fa-user text-primary fa-2x"></i>',
    iconSize: [20, 20],
    className: 'map-icon'
    });

    var popup = '<p class="m-0">Geolocation</p>';

    var marker = window.L.marker([this.geolocation.latitude, this.geolocation.longitude], {icon: userIcon, draggable: false}).addTo(this.map).bindPopup(popup);

    this.map.flyTo([this.geolocation.latitude, this.geolocation.longitude], 14);
    },

    calculateDistance(geolocation) {
            if(!geolocation) {
                return;
            }

            if(!this.current_location || !this.current_location.lat || !this.current_location.lng) {
                return;
            }

            var lat1 = geolocation.latitude;
            var lon1 = geolocation.longitude;

            var lat2 = this.current_location.lat || this.$root.coordinates[0];
            var lon2 = this.current_location.lng || this.$root.coordinates[1];

            // Radius of the Earth in km
            var R = 6371;
            // Convert degrees to radians
            var dLat = (lat2 - lat1) * Math.PI / 180;
            var dLon = (lon2 - lon1) * Math.PI / 180;
            // Convert latitude from degrees to radians
            lat1 = lat1 * Math.PI / 180;
            lat2 = lat2 * Math.PI / 180;

            // Haversine formula
            var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            var distance = R * c;

              if(distance < 10) {
                return (distance * 5280).toFixed(0) + ' ft';
              }

            return distance.toFixed(2) + ' mi';
          },

}

}
</script>