<template>
  <div>
    <span
      class="badge text-uppercase"
      :class="file_status"
    >{{ status }}</span>
  </div>
</template>
<script>
export default {

props: [ 'status' ],

data() {
    return {
      statuses: {
        'complete': 'badge-success',
        'draft': 'badge-secondary',
        'sent': 'badge-primary',
        'canceled': 'badge-danger'
      }
    }
},

computed: {
    file_status: function() {
        const status = this.status.toLowerCase();
        return this.statuses[status] || 'badge-primary';
    }
}

}
</script>