<template>
  <div>
    <div
      v-if="form && form.uuid"
      class="card"
    >
      <div class="card-body">
        <p class="card-title">
          Message <a :href="'/messages/'+form.uuid">{{ form.to }} {{ form.scheduled_at | dateTimezone | timeago }}</a>
        </p>

        <ul class="list-group">
          <template v-if="form.subject">
            <li class="list-group-item">
              SUBJECT {{ form.subject }}
            </li>
          </template>

          <template v-if="form.content">
            <li class="list-group-item">
              CONTENT {{ form.content }}
            </li>
          </template>

          <template v-if="form.type">
            <li class="list-group-item">
              TYPE {{ form.type }}
            </li>
          </template>

          <template v-if="form.to">
            <li class="list-group-item">
              TO {{ form.to }}
            </li>
          </template>

          <template v-if="form.last_status">
            <li class="list-group-item">
              STATUS {{ form.last_status }}
            </li>
          </template>
        </ul>

        <timestamps :form="form" />
      </div><!--Body-->
    </div><!--Card-->
  </div>
</template>
<script>
export default {

props: ['message'],

data() {
    return {
        form: {}
    }
},

created() {
    this.getMessage()
},

methods: {

        getMessage() {
            
            if(!this.message) {
                return;
            }

            this.form = {}

            this.$http.get('/messages/'+this.message+'?slim=true')
            .then(response => {
                if(response && response.data) {
                   this.form = response.data;
                }
            })
        },

}

}
</script>