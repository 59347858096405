<template>
  <div>
    <template v-if="record.name">
      <router-link
        class="text-capitalize"
        :to="{ 'name': 'locations.edit', 'params': {'id': uuid} }"
        :disabled="processing"
      >
        {{ record.name }}
      </router-link>
    </template>
    <div
      v-if="processing"
      class="progress"
    >
      <div
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        aria-valuenow="100"
        aria-valuemin="0"
        aria-valuemax="100"
        style="width: 100%"
      />
    </div>

    <template v-if="!processing && !record.name">
      <i class="fa fa-times-circle text-danger"></i>
    </template>
    
  </div>
</template>
<script>
export default {

props: ['uuid'],

data() {
    return {
        processing: false,
        record: {},
        base_url: '/locations'
    }
},

created() {
    this.getRecord();
},

methods: {

        getRecord() {
            
            if(!this.uuid) {
                return;
            }

            this.processing = true;
            this.record = {};

            this.$http.get(this.base_url+'/'+this.uuid+'?slim=true&fields=name')
            .then(response => {
                if(response && response.status) {

                    if(response.data && response.data.name) {                      
                      this.record = response.data;
                    }

                    this.processing = false;
                }
            })
        },

}

}
</script>