<template>
  <div>
    <template v-if="isLoading">
      <div class="row">
        <div class="col-md-12">
          <div
            class="spinner-grow text-primary"
            :class="spinnerSize"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

export default {

props: ['isLoading', 'size'],


computed: {

	spinnerSize: function() {

		var size = this.size || 'sm';

		return 'spinner-grow-'+size;
	}
}
}

</script>