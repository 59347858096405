<template>
    <div>
      <div class="card">
          <div class="card-body">
  
              <p class="card-title">Generate {{ $route.meta.title }}s</p>
  
              <p class="card-text">Generate your locations based on publicly available data.</p>
    
             <form @submit.prevent="postForm()">
  
                <div class="form-group">
                    <label for="location_type">Location Type</label>
                    <select v-model="form.location_type" class="form-control" id="location_type" required>
                        <option v-for="location_type in location_types" :value="location_type">{{ location_type }}</option>
                    </select>
                </div>

                <div class="form-group">
                    <label for="region">Region</label>
                    <input v-model="form.region" type="text" class="form-control" id="region" required>
                    <p class="text-muted">Enter a region such as your city, county, or state to generate locations.</p>
                </div>
  
                <button type="submit" class="btn btn-success" :hidden="processing">Generate</button>

                <template v-if="processing">
                  <button type="button" class="btn btn-success" disabled>
                    <output class="spinner-border spinner-border-sm" aria-hidden="true"></output>
                    Generating...
                  </button>
                </template>
                
             </form>
  
          </div><!--Body-->
      </div><!--Card-->

      <template v-if="locations.length > 0">
        <div class="card mt-3">
            <div class="card-body">
                <p class="card-title">{{ locations.length }} Locations</p>

                <ul>
                    <li v-for="location in locations">

                        <ul>
                            <li v-for="field in fields">
                                <strong>{{ field }}</strong>: {{ location[field] || '' }}
                            </li>
                        </ul>

                    </li>
                </ul>
                
            </div><!--Body-->
        </div><!--Card-->
        </template>

    </div>
  </template>
  
  
  <script>
  import queries from '../mixins/queries';
  export default {
  
    mixins: [ queries ],
  
    data() {
      return {
          processing: false,
          location_types: [
                'Court',
                'Jail',
                'Law Enforcement',
                'Probation Office',
                'Treatment Center',
                'Other'
          ],
          form: {
              location_type: 'Court',
              location: '',
              format: 'json_object',
              temperature: 0.8,
              model: 'gpt-4o',
              max_tokens: 100
          },
          fields: ['name', 'address', 'address_2', 'city', 'state', 'zip', 'phone', 'email', 'website'],
          locations: []
      }
  },

  created() {
    if(this.settings && this.settings.state) {
        this.form.region = this.settings.state;
    }
  },
  
  methods: {
        

    postForm() {

        let prompt = 'Generate a JSON list of '+this.form.location_type+' locations in '+this.form.region ;

        prompt += ' with the following fields: '+this.fields.join(', ');

        prompt += 'Avoid including any additional data besides the JSON object. Do not include a nested name for the JSON object.';

        this.processing = true;
        
       this.$http.post('/generator', {prompt: prompt, format: 'json_object'})
       .then(response => {
        if(response && response.data) {
            this.processing = false;

            if(response.data.message) {
                this.locations = response.data.message;

                if(this.locations['court_locations']) {
                    this.locations = this.locations['court_locations'];
                }

                if(this.locations['locations']) {
                    this.locations = this.locations['locations'];
                }

                if(this.locations['courts']) {
                    this.locations = this.locations['courts'];
                }
            }
        }
       })
    }

  
      }
  }
  </script>