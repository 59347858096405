<template>
    <div v-if="isTourActive" class="tour-guide">
      <div class="tour-overlay" @click="nextStep"></div>
      <div class="tour-popup" :style="popupStyle">
        <h2>{{ currentStep.title }}</h2>
        <p>{{ currentStep.content }}</p>
        <button @click="nextStep">Next</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isTourActive: true,
        currentStepIndex: 0,
        steps: [
          {
            element: '#step1',
            title: 'Step 1',
            content: 'This is the first step of the tour.'
          },
          {
            element: '#step2',
            title: 'Step 2',
            content: 'This is the second step of the tour.'
          }
        ]
      };
    },
    computed: {
      currentStep() {
        return this.steps[this.currentStepIndex];
      },
      popupStyle() {
        const element = document.querySelector(this.currentStep.element);
        if (element) {
          const rect = element.getBoundingClientRect();
          return {
            top: `${rect.top + window.scrollY + rect.height + 10}px`,
            left: `${rect.left + window.scrollX}px`
          };
        }
        return {};
      }
    },
    methods: {
      nextStep() {
        if (this.currentStepIndex < this.steps.length - 1) {
          this.currentStepIndex++;
        } else {
          this.isTourActive = false;
        }
      }
    }
  };
  </script>
  
  <style>
  .tour-guide {
    position: relative;
    z-index: 1000;
  }
  .tour-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  .tour-popup {
    position: absolute;
    background: white;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1000;
  }
  </style>
  