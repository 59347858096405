<template>
  <div>

    <page-tabs page="reports" />


    <template v-if="records && records.length > 0">
      <p class="card-title">{{ records.length }} Sample {{ $route.meta.title }}s</p>
    </template>

    <div class="row">

      <template v-for="record in records">

        <div class="col-sm-12 col-md-4">

        <div class="card mb-3">
          <div class="card-body">

            <div class="row mb-3">

              <div class="col-sm-12 col-md-9">
                <p class="card-title">{{ record.name }}</p>
              </div><!--Col-->

              <div class="col-sm-12 col-md-3 text-right">
                  <button type="button" class="btn btn-success btn-sm" @click="importRecord(record)" :disabled="processing"><i class="fa fa-download"></i> Import</button>
              </div><!--Col-->

            </div><!--Row-->

              <ul class="list-group">

              <template v-if="record.components && record.components.length">

                <template v-for="component in record.components">
                  <li class="list-group-item">{{ component.name }}</li>
                </template>

              </template>

              </ul>


          </div><!--Body-->
        </div><!--Card-->

        </div><!--Col-->

      </template>

    </div><!--Row--> 

  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {
  
  mixins: [ queries ],

  data () {
    return {
      processing: false,
      records: [],
      queries: {},
      triggers: [],
      templates: [],
      form: {}
    }
  },

  created () {
    this.getRecords();
  },

  methods: {

    getRecords() {
      this.processing = true;
      this.$axios.get('https://ecdassets.s3.amazonaws.com/demo/dashboards.json')
      .then(response => {
        if(response && response.data) {
          this.records = response.data;
          this.processing = false;
        }
      })
    },

    // getRecords() {
    //   this.processing = true;
    //   this.$http.get(this.$apiURL + this.$route.meta.base_url)
    //   .then(response => {
    //     if(response && response.data) {

    //       var self = this;

    //       response.data.forEach(r => {

    //         self.getComponent(r);

    //       })

    //       this.processing = false;
    //     }
    //   })
    // },

    getComponent(r) {
      this.$http.get(this.$apiURL + 'components?dashboard='+r.uuid)
      .then(response => {
        if(response && response.data) {

          r['components'] = response.data;

          this.records.push(r);
        }
      })
    },

    copyToClipboard(value) {
        navigator.clipboard.writeText(value);
        new this.$noty({text: 'Copied to clipboard'}).show();
    },

    importRecord(record) {

      this.form.name = record.name;
      this.form.description = record.description;
      this.form.data_source = record.data_source;
      this.form.theme = record.theme;
      this.form.status = 'private';
      this.form.simulate = false;
      this.form.allow_downloads = true;
      this.form.read_only = false;
      this.form.domain = 'courtdashboards.com';
      this.form.contact_email = this.$root.current_user.email;
      this.form.agencies = [];
      this.form.agencies.push(this.$root.current_user.current_agency.uuid);

      this.processing = true;
      this.$http.post('/'+this.$route.meta.base_url, this.form)
      .then(response => {
        if(response && response.data) {

          if(response.data.uuid) {
            this.form = {};
            new this.$noty({text: 'Importing '+this.$route.meta.title}).show();

            if(record.components && record.components.length) {

              var self = this;

              record.components.forEach(c => {
                c.dashboard = response.data.uuid;
                self.$http.post('/components', c)
              })
            }

            setTimeout(() => {
              this.processing = false;
              this.$router.push('/'+this.$route.meta.base_url+'/'+response.data.uuid);
            }, 6000);
          }

        }
      })
    }

  }
}
</script>
