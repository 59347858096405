<template>
  <div>

    <page-tabs page="reports" />
    
    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Create
            </p>

            <form @submit.prevent="postForm">
              <div class="form-group">
                <label for="name">Name</label>
                <input
                  id="name"
                  v-model="form.name"
                  class="form-control"
                  required
                >
                <p class="form-text text-muted">The name of the dashboard. You can change this later.</p>
              </div>

              <div class="form-group">
                <label for="data_source">Data Source</label>
                <select id="data_source" class="form-control" v-model="form.data_source" :disabled="processing" required>
                  <option value=""></option>
                  <template v-for="data_source in data_sources">
                    <option :value="data_source">{{ data_source }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="status" class="form-label">Status</label>
                <select id="status" class="form-control" v-model="form.status">
                  <option value=""></option>
                  <template v-for="status in statuses">
                    <option :value="status">{{ status }}</option>
                  </template>
                </select>
              </div>

              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >
                Add
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

        <div class="card my-3">
          <div class="card-body">
            <p class="card-title">Search</p>

            <form @submit.prevent="searchRecords()">

              <div class="form-group">
                <label for="search" class="form-label">Search</label>
                <input id="search" class="form-control" type="search" v-model="queries.search">
              </div>

              <div class="form-group">
                <label for="search_data_source" class="form-label">Data Source</label>
                <select id="search_data_source" class="form-control" v-model="queries.data_source">
                  <option value=""></option>
                  <template v-for="search_source in data_sources">
                    <option :value="search_source">{{ search_source }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_status" class="form-label">Status</label>
                <select id="search_status" class="form-control" v-model="queries.status">
                  <option value=""></option>
                  <template v-for="search_status in statuses">
                    <option :value="search_status">{{ search_status }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_subdomain" class="form-label">Subdomain</label>
                <input id="search_subdomain" class="form-control" type="text" v-model="queries.subdomain">
              </div>

              <div class="form-group">
                <label for="search_domain" class="form-label">Domain</label>
                <input id="search_domain" class="form-control" type="text" v-model="queries.domain">
              </div>

              <button type="submit" class="btn btn-primary" :disabled="processing">Search</button>

            </form>

          </div>
        </div>


      </div><!--Col-->

      <div class="col-sm-12 col-md-9">

        <div class="card">
          <div class="card-body">
            <div class="row">

            <div class="col-sm-12 col-md-6">

              <p class="card-title">
                {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
              </p>

            </div><!--Col-->

            <div class="col-sm-12 col-md-6 text-right">

              <div class="btn-group d-print-none">
                <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
              </div>
            </div><!--Col-->

            </div><!--Row-->


            <div class="table-responsive my-3">

            <table
              class="table table-hover table-striped"
            >
              <thead>
                <tr>
                  <template v-for="column in columns">
                    <th>
                      <template v-if="column.key">
                        <span
                          class="text-capitalize link-hover"
                          @click="sortColumn(column)"
                        >{{ column.label }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key == queries.sort">
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm ml-1"
                          :disabled="processing"
                          @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
              <tbody>
                <template v-for="record in records">
                  <tr>
                    <td>
                      <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': { 'id': record.uuid } }">
                        <i class="fa fa-edit"></i> Edit
                      </router-link>
                    </td>
                    <td>
                      {{ record.name }}
                    </td>
                    <td>
                      <a :href="'https://'+record.subdomain+'.'+record.domain+'?preview='+record.url" class="btn btn-sm btn-secondary" target="_blank" :disabled="record.status == 'draft'">
                        <i class="fa fa-link"></i> Open Dashboard
                      </a>
                    </td>
                    <td>
                      <template v-if="record.status != 'draft'">
                      <a :href="'https://'+record.subdomain+'.'+record.domain+'?preview='+record.url" target="_blank">{{ record.subdomain }}.{{ record.domain }}</a>
                      </template>
                    </td>
                    <td>
                    <span v-if="record.status" class="badge badge-pill text-uppercase" :class="setStatusClass(record.status)">{{ record.status }}</span>
                    </td>
                    <td><span v-if="record.data_source" class="badge badge-pill badge-primary text-uppercase">{{ record.data_source }}</span></td>
                    <td>
                      <template v-if="record.read_only">
                        <i class="fa fa-circle text-success"></i>
                      </template>
                      <template v-else>
                        <i class="fa fa-times text-danger"></i>
                      </template>
                    </td>
                    <td>{{ record.created_by }}</td>
                    <td>{{ record.created_at | datetime }}</td>
                    <td><template v-if="record.updated_at">{{ record.updated_at | datetime }}</template></td>
                  </tr>
                </template><!--Record-->
              </tbody>
            </table>
          </div><!--Responsive-->

            <template v-if="!processing && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

    mixins: [ queries ],

    data() {
        return {
        processing: false,
        records: [],
        queries: {},
        form: {},
        total: 0,
        domain: 'courtdashboards.com',
        columns: [
          {label: 'edit', key: 'uuid'},
          {label: 'name', key: 'name'},
          {label: 'Link'},
          {label: 'subdomain', key: 'subdomain'},
          {label: 'status', key: 'status'},
          {label: 'data source', key: 'data_source'},
          {label: 'read only', key: 'read_only'},
          {label: 'creator', key: 'created_by'},
          {label: 'created', key: 'created_at'},
          {label: 'updated', key: 'updated_at'}
        ],
        statuses: ['draft', 'private', 'public'],
        data_sources: ['messages', 'events', 'clients', 'contacts', 'client_cases', 'payments', 'opts'],
        themes: ['auto', 'light', 'dark'],

        }
    },

      mounted() {
        
        var self = this;

        this.$eventBus.$on('errors', function () {
            self.processing = false;
        });
      },

    created () {
        this.setTitle();
        this.resetQueries();
        this.resetForm();
        this.setURLParams();
        this.getRecords();
    },

    methods: {

        resetQueries() {
            this.queries = {
                limit: 10,
                slim: true,
                search: null,
                data_source: null,
                status: null,
                subdomain: null,
                domain: null,
                sortDirection: 'desc',
                sort: 'created_at'
            }
        },

        resetRecords() {
          this.records = [];
          this.queries.skip = 0;
          this.total = 0;
        },

        searchRecords() {
            this.resetRecords();
            this.getRecords();
        },

        setStatusClass(status) {

          if(status == 'private') {
            return 'badge-danger';
          }

          if(status == 'draft') {
            return 'badge-warning';
          }

          if(status == 'public') {
            return 'badge-success';
          }

          return 'badge-primary';
        },

        resetForm() {
            this.form = {
                name: null,
                subdomain: null,
                data_source: 'messages',
                status: 'private',
                domain: this.domain,
                hide_pii: 1,
                allow_downloads: 1
            }
        },

        setURLParams() {

            var searchParams = new URLSearchParams(window.location.search);

            var self = this;

            searchParams.forEach(function(value, key) {
                self.queries[key] = value;
            });

        },

        setTitle() {
            document.title = this.total + ' ' +this.$route.meta.title+'s' + ' | eCourtDate.com';
        },
        
        updateURL() {
          history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
        },

        getRecords() {

            this.processing = true;
            this.updateURL();
            this.getTotal();

            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
            .then(response => {
                if(response && response.data) {
                    this.records = response.data;
                    this.processing = false;
                }
            })
        },

        getTotal() {
            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
            .then(response => {
                if(response && response.status) {
                    this.total = response.data;
                    this.setTitle();
                }
            })
        },

        postForm() {
            this.processing = true;

            this.form.agencies = [this.$root.agency];

            this.$http.post('/'+this.$route.meta.base_url, this.form)
            .then(response => {
                if(response && response.data) {

                    this.processing = false;

                    if(response.status === 201) {
                        this.records.unshift(response.data);
                        this.total++;
                        this.resetForm();
                        new this.$noty({text: this.$route.meta.title + ' created'}).show();
                        window.location.href = '/'+this.$route.meta.base_url+'/'+response.data.uuid;
                    }

                }
            })
        },

        updateSortDirection() {

          if(this.queries.sortDirection == 'asc') {
            this.queries.sortDirection = 'desc';
          } else {
            this.queries.sortDirection = 'asc';
          }

          this.resetRecords();

          this.getRecords();
        },

        sortColumn(column) {

          this.queries.sort = column.key;

          this.updateSortDirection();
        }
    
    }

}
</script>