<template>
  <div>

    <div class="row">
      <div class="col-sm-12 col-md-8">
        <h4>Geolocate IP {{ form.ip }}</h4>
        <p><router-link :to="{name: 'dashboard.index'}">Dashboard</router-link> /
          <router-link :to="{name: 'geolocations.index'}">Geolocations</router-link> /
          {{ $route.meta.title }}</p>
      </div><!--Col-->


      <div class="col-sm-12 col-md-4 text-right">
        <div class="btn-group">
        <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
        <router-link
            :to="{ name: 'geolocations.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>
        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-3">
        
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              <i class="fa fa-tower-cell"></i>

              {{ $route.meta.title }}

              <template v-if="form.location && form.location.country_flag_emoji">
                {{ form.location.country_flag_emoji }}
              </template>

            </p>

            <ul class="list-group">
              <template v-if="form.ip">
                <li class="list-group-item">
                  IP ADDRESS {{ form.ip }}
                </li>
              </template>

              <template v-if="form.type">
                <li class="list-group-item">
                  TYPE {{ form.type }}
                </li>
              </template>

              <template v-if="form.city && form.city.names && form.city.names['en']">
                <li class="list-group-item">
                  LOCATION {{ form.city.names['en'] }}
                </li>
              </template>

              <template v-if="form.country && form.country.names && form.country.names['en']">
                <li class="list-group-item">
                  COUNTRY {{ form.country.names['en'] }}
                </li>
              </template>

              <template v-if="form.location">
                
                <template v-if="form.location.time_zone">
                <li class="list-group-item">
                  TIME ZONE {{ form.location.time_zone }}
                </li>
              </template>
              
              </template>

              <template v-if="form.traits">

              <template v-if="form.traits.connection_type">
                <li class="list-group-item">
                CONNECTION TYPE {{ form.traits.connection_type }}
                </li>
              </template>

              <template v-if="form.traits.user_type">
                <li class="list-group-item">
                USER TYPE {{ form.traits.user_type }}
                </li>
              </template>

              <template v-if="form.traits.isp">
                <li class="list-group-item">
                  ISP {{ form.traits.isp }}
                </li>
              </template>

              <template v-if="form.traits.static_ip_score">
                <li class="list-group-item">
                  STATIC IP SCORE {{ form.traits.static_ip_score }}
                </li>
              </template>

              <template v-if="form.traits.autonomous_system_number">
                <li class="list-group-item">
                  ASN {{ form.traits.autonomous_system_number }}
                </li>
              </template>

              <template v-if="form.traits.autonomous_system_organization">
                <li class="list-group-item">
                  ASN ORG {{ form.traits.autonomous_system_organization }}
                </li>
              </template>

              <template v-if="form.traits.domain">
                <li class="list-group-item">
                  DOMAIN {{ form.traits.domain }}
                </li>
              </template>

              <template v-if="form.traits.organization">
                <li class="list-group-item">
                  ORG {{ form.traits.organization }}
                </li>
              </template>


              </template><!--Traits-->


              <template v-if="form.latitude">
                <li class="list-group-item">
                  Latitude {{ form.latitude}}
                </li>
              </template>

              <template v-if="form.longitude">
                <li class="list-group-item">
                  Longitude {{ form.longitude }}
                </li>
              </template>
            </ul>

          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->

      <div class="col-sm-12 col-md-9">
        <div
          id="map"
          class="map-lg"
        />

        <div class="row mt-3">

          <div class="col-sm-12 col-md-6">

            <div class="card">
              <div class="card-body">

                <p class="card-title">Location Details</p>

                <template v-if="form.location">
                
                  <template v-if="form.location.average_income">
                    <p class="m-0">Average Income</p>
                    <h4 class="m-0">${{ form.location.average_income.toLocaleString() }}</h4>
                  </template>
                  
                  <template v-if="form.location.population_density">
                    <p class="m-0">Population Density</p>
                    <h4 class="m-0">{{ form.location.population_density }} per square mile</h4>
                  </template>
                
                </template>

              </div>
            </div>

          </div><!--Col-->

          <div class="col-sm-12 col-md-6">

            <div class="card">
              <div class="card-body">
                <p class="card-title">
                  <a :href="'/geolocations?ip_address='+form.ip">{{ geolocations.length }} of {{ total_geolocations }} Geolocations</a>
                </p>

                <ul class="list-group">
                  <template v-if="geolocations && geolocations.length > 0">
                    <template v-for="geolocation in geolocations">
                      <li
                        class="list-group-item"
                      >
                        <a :href="'/geolocations/'+geolocation.uuid">View Geolocation</a>
                      </li>
                    </template>
                  </template>
                </ul>
              </div><!--Body-->
            </div><!--Card-->

          </div><!--Col-->

          </div><!--Row-->

      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

  mixins: [queries],

  data () {
    return {
      processing: false,
      map: null,
      geolocations: [],
      total_geolocations: 0,
      form: {}
    }
  },

  created() {
    this.getRecord();
  },

  beforeDestroy() {
    if (this.map) {
      this.map.remove();
    }
  },

  methods: {

    getRecord() {
      this.$http.get(this.$route.meta.base_url+'/'+this.$route.params.id)
      .then(response => {
        if(response && response.data) {
          this.form = response.data;

          this.getGeolocations();

          if(this.form.latitude && this.form.longitude) {
            this.setTitle();
            this.initMap();
          }
        }
      })
    },

    setTitle() {
      document.title = this.form.ip + ' - ' + this.$route.meta.title;
    },

    getTotalGeolocations() {
      this.$http.get('/geolocations/total?ip_address='+this.form.ip)
      .then(response => {
        if(response && response.status === 200) {
          this.total_geolocations = response.data;
        }
      })
    },

    getGeolocations() {

      if(!this.form.ip) {
        return;
      }

      this.getTotalGeolocations();

      this.$http.get('/geolocations?ip_address='+this.form.ip)
      .then(response => {
        if(response && response.data) {
          this.geolocations = response.data;
        }
      })
    },

    initMap() {
        this.map = window.L.map("map").setView([this.form.latitude, this.form.longitude], 10);

        window.L.tileLayer(this.$root.map_layer).addTo(this.map);

        window.L.circle([this.form.latitude, this.form.longitude], {
          color: 'red',
          fillColor: '#f03',
          fillOpacity: 0.5,
          radius: 10000
        }).addTo(this.map);
    },

  },
}
</script>