<template>
  <div>
    <div class="help-container mr-3 mb-3 d-print-none" data-html2canvas-ignore>
        <div class="row" :hidden="close_help">
          <div class="col-12">
            <div class="card" style="opacity:.9; z-index:99">
              <div class="card-body">

                <div class="row mb-3">
                      <div class="col-sm-12 col-md-6">
                        <a href="https://help.ecourtdate.com" target="_blank" class="btn btn-primary btn-sm"><i class="fa fa-link"></i> Help Center</a>
                      </div><!--Col-->
                      <div class="col-sm-12 col-md-6 text-right">
                        <button type="button" class="btn btn-danger btn-sm" @click="closeHelp()" :disabled="processing"><i class="fa fa-times"></i> Close</button>
                      </div><!--Col-->
                </div><!--Row-->

                <template v-if="records && records.length > 0">

                <div ref="helpFeed" class="row" style="max-height:400px;overflow-y:auto">

                  <div class="col-12">

                      <template v-for="record in records">
                        <div class="list-group">
                          <a :href="'/help_chats/'+record.uuid">
                          <span :class="setHelpClass(record) + ' list-group-item list-group-item-action mb-1'">
                            <span class="small m-0">from {{ record.created_by }} {{ record.created_at | dateTimezone | timeago }}</span><br>
                            {{ record.content }}
                          </span>
                          </a>

                          <template v-if="record.file">
                            <small>{{ record.file }}</small>
                          </template>

                        </div>
                      </template>

                  </div><!--Col-->

                </div><!--Row-->

                </template>

                <template v-if="records && records.length == 0 && !processing">
                  <div class="alert alert-warning">No help chats found</div>
                </template>

                <form @submit.prevent="postForm()">

                    <div class="form-group mt-1">
                      <label for="help_message">Message</label>
                      <textarea id="help_message" class="form-control" v-model="form.content" rows="3" required></textarea>
                    </div>

                    <div class="form-group">
                    <label for="help_file">Attach File</label>
                    <input 
                      id="help_file"
                      type="file" 
                      ref="fileInput" 
                    />
                  </div>

                  <button type="submit" class="btn btn-success btn-block" :disabled="processing || !form.content">Send Chat</button>

                </form>


                </div><!--Body-->
            </div><!--Card-->
          </div><!--Col-->
        </div><!--Row-->
        <div class="row" :hidden="!close_help">
          <div class="col-12">
            <button type="button" class="btn btn-secondary tooltip-right" @click="openHelp()" data-tooltip="Ask a question" aria-label="Ask a question" :disabled="processing"><i class="fa fa-message-question"></i></button>
          </div>
        </div>
    </div><!--HelpContainer-->
  </div>
</template>
<style>
.help-container {position: absolute; bottom:0;left:10px;z-index:9; width:25%;min-width:200px;max-width:600px}
</style>
<script>
import queries from '../mixins/queries';
export default {

  mixins: [ queries ],

  data() {
    return {
      processing: false,
      close_help: true,
      base_url: 'help_chats',
      queries: {},
      form: {},
      records: [],
      articles: [],
      uploadProgress: 0
    }
  },

  mounted() {

    var self = this;

    this.$eventBus.$on(this.base_url, function(data) {

      if(!data.uuid) {
        return;
      }

      self.pushRecord(data);

    });


  },

  created() {

    var help_status = localStorage.getItem('help_status');

    if(help_status && help_status == 'opened') {
      this.help_status = false;
    }


    this.resetForm();
    this.resetRecords();
    this.resetQueries();
    this.getRecords();
  },

  methods: {

    resetForm() {
      this.form = {
        content: null,
        direction: 'outbound'
      }
    },

    resetQueries() {
      this.queries = {
        search: null,
        skip: 0,
        sort: 'created_at',
        sortDirection: 'desc'
      }
    },

    searchRecords() {
      this.resetRecords();
      this.getRecords();
    },

    resetRecords() {
      this.records = [];
      this.queries.skip = 0;
    },

    pushRecord(record) {

      if(!record) {
        return;
      }

      if(!record.uuid) {
        return;
      }

      if(!this.records) {
        this.records = [];
      }

      this.records.push(record);

      var helpFeed = this.$refs.helpFeed;

      if(helpFeed) {
        helpFeed.scrollTop = helpFeed.scrollHeight + 1000;
      }
    },

    searchArticles() {

      this.articles = [];

      this.queries.limit = 5;

      this.processing = true;
      this.$http.get('/help_articles?search='+this.queries.search)
          .then(response => {
            if(response && response.data) {
              this.articles = response.data;
              this.processing = false;
            }
          })
    },

    getRecords() {

      if(this.close_help) {
        return;
      }

      this.queries.limit = 10;

      this.processing = true;
      this.$http.get(this.buildQueries('/'+this.base_url, this.queries))
          .then(response => {
            if(response && response.data) {
              this.processing = false;

              if(response.data.length) {
                var self = this;
                response.data.forEach(record => {
                  self.pushRecord(record);
                });
              }
            }
          })
    },

    setHelpClass(record) {
      if(!record.direction) {
        return;
      }

      return record.direction;
    },

    postForm() {
      this.processing = true;

      var form = this.form;

      form.url = window.location.href;

      if(this.$refs.fileInput && this.$refs.fileInput.files[0]) {
        form.file = this.$refs.fileInput.files[0].name;

        form.file = form.file.replace(/\s+/g, '-');
        form.file = form.file.replace(/[^a-zA-Z0-9.-]/g, '');

        this.storeUpload(this.$refs.fileInput.files[0], {fileName: form.file});
      }

      this.postChat(form);
    },

    postChat(form) {
      this.$http.post('/'+this.base_url, form)
          .then(response => {
            if(response && response.data) {

              this.pushRecord(response.data);

              this.resetForm();
              this.processing = false;

            }
          })
    },


    closeHelp() {
      localStorage.setItem('help_status', 'closed');
      this.close_help = true;
      this.resetRecords();
    },

    openHelp() {
      localStorage.setItem('help_status', 'opened');
      this.close_help = false;
      this.getRecords();
    },

    setEmailCreator(email) {
      var parts = email.split("@");

      if (parts.length > 1) {
        return parts[0]+'@';
      } else {
        return email;
      }
    },


async storeUpload(file, options = {}) {
  const response = await this.$http.post(this.$apiURL + 'v1/signed_url', {
    'file_path': options.fileName || file.name,
    'bucket': 'ecdhelp',
    'name': file.name,
    'size': file.size,
    'type': file.type,
    'content_length': file.size,
    'extension': file.name.split('.').pop(),
    'content_type': options.contentType || file.type,
    'expires': options.expires || '',
    'visibility': options.visibility || ''
  }, {
    baseURL: options.baseURL || null,
    headers: options.headers || {},
    ...options.options
  });
  if (response && response.data) {
    let headers = response.data.headers;

    if ('Host' in headers) {
      delete headers.Host;
    }

    if (typeof options.progress === 'undefined') {
      options.progress = () => {};
    }

    const cancelToken = options.cancelToken || ''

    await this.$axios.put(response.data.url, file, {
      cancelToken: cancelToken,
      headers: headers,
      onUploadProgress: (progressEvent) => {
        options.progress(progressEvent.loaded / progressEvent.total);
      }
    }).catch(function (error) {
      if(error.response) {
        console.log(error.response.data);
      }
    });

    response.data.extension = file.name.split('.').pop();

    return response.data;
  }

  return null;

}

  }
}

</script>
